import React, { PureComponent } from "react";

export default class SR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.338"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="118.728"
            transform="translate(0 196.636)"
            fill="#a2001d"
          />
          <g>
            <rect
              width="512"
              height="74.207"
              transform="translate(0 352.462)"
              fill="#6da544"
            />
            <rect
              width="512"
              height="74.207"
              transform="translate(0 85.331)"
              fill="#6da544"
            />
          </g>
          <path
            d="M256.742,218l9.43,29.021H296.69L272,264.963l9.431,29.023-24.689-17.937-24.689,17.937,9.431-29.023-24.69-17.939h30.518Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
