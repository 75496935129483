import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import BidButton from "./BidButton";
import Buttons from "./Buttons";
import { Countdown, UnregisteredUserModal } from "storefront/features/shared";
import { isExpired } from "storefront/features/shared/utils";
import { getWinnerNickname, getProductType, AUCTION_FORMAT } from "../utils";
import * as actions from "../actions";
import {
  StyledCountdownHolder,
  StyledWinner,
  StyledImage,
  IconWrapper,
  StyledFlags,
} from "./Auction.styled";
import Icon from "storefront/features/shared/icons";
import { getAuctionData } from "../../shared/components/Header/selectors";

class Auction extends PureComponent {
  state = {
    closed: isExpired(this.props.product.end_at),
    modalOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {
      product: { end_at },
    } = this.props;
    if (end_at !== prevProps.product.end_at) {
      this.setState({ closed: isExpired(this.props.product.end_at) });
    }
  }

  onBidClose = () => {
    this.props.setFlagUpdateProductGrid(true);
    this.setState({ closed: true });
  };

  onModalOpen = () => this.setState({ modalOpen: true });

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const {
      product,
      product: { end_at, winning_customer_avatar },
      variantId,
      productPrice,
      userAuctionData: { vendor },
      showBidValue,
    } = this.props;

    const { closed, modalOpen } = this.state;

    const isAuction = getProductType(product) === AUCTION_FORMAT;
    const { countryCode, winnerName } = getWinnerNickname(product);

    return (
      <>
        {end_at && (
          <StyledCountdownHolder data-cy="countdown-timer">
            <Countdown endDate={end_at} onClose={this.onBidClose} />
          </StyledCountdownHolder>
        )}
        <div className="mb-2 d-flex justify-content-center">
          {isAuction ? (
            <BidButton
              vendorPresent={vendor}
              closed={closed}
              product={product}
              onModalOpen={this.onModalOpen}
              showBidValue={showBidValue}
              width="155px"
            />
          ) : (
            <Buttons
              vendor={vendor}
              product={product}
              variantId={variantId}
              productPrice={productPrice}
              buttonType={getProductType(product)}
            />
          )}
        </div>
        {winnerName && (
          <StyledWinner className="d-inline-block">
            winning
            {winning_customer_avatar ? (
              <StyledImage src={winning_customer_avatar} roundedCircle />
            ) : (
              <IconWrapper className="rounded-circle d-inline-flex justify-content-center">
                <Icon type="login" size={10} />
              </IconWrapper>
            )}
            <StyledFlags>
              {winnerName}
              {countryCode && <Icon type={`flag${countryCode}`} />}
            </StyledFlags>
          </StyledWinner>
        )}

        {modalOpen && (
          <UnregisteredUserModal
            product={product}
            onClose={this.handleModalClose}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  setFlagUpdateProductGrid: (value) => {
    dispatch(actions.setFlagUpdateProductGrid(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Auction));
