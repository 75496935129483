import React, { PureComponent } from "react";

export default class TT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <path
            d="M6.066,85.337,214.027,297.973l131.584,128.69H505.934L297.973,214.027,166.389,85.337Z"
            fill="#f0f0f0"
          />
          <path d="M43.364,85.337,384.69,426.663h83.946L127.31,85.337Z" />
        </g>
      </svg>
    );
  }
}
