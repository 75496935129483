import React, { PureComponent } from "react";
import { Col, Form } from "react-bootstrap";

class CollectionFilter extends PureComponent {
  handleFilterChange = (e) => {
    const { filterItemHandleChange } = this.props;
    const value = e.target.value;
    filterItemHandleChange(value);
  };

  render() {
    const { filter } = this.props;
    return (
      <Col xs={12} sm={4} md={3} className="px-sm-2">
        <Form>
          <Form.Control
            as="select"
            size="sm"
            onChange={this.handleFilterChange}
            name="collection-filter"
            value={filter}
          >
            <option value="default" disabled>
              Filter by Product
            </option>
            <option value="end_at">Ending soon</option>
            <option value="featured">Featured products</option>
            <option value="low_price">Price, low to high</option>
            <option value="high_price">Price, high to low</option>
          </Form.Control>
        </Form>
      </Col>
    );
  }
}

export default CollectionFilter;
