import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ themeProps: { backgroundColor } }) =>
      backgroundColor};
    font-family: 'Roboto Condensed', sans-serif;
    .four-o-four-container{
      padding: 10% 0;
      .four-o-four-title {
        font-size: 6rem;
      }
    }
    .header-navbar.navbar{
      background-color: ${({ themeProps: { stripColor } }) => stripColor};
      color: ${({ isVendorPage, themeProps: { stripColor } }) =>
        isVendorPage && stripColor !== "#ffffff" ? "#ffffff" : "initial"};
      a {
        color: ${({ isVendorPage, themeProps: { stripColor } }) =>
          isVendorPage && stripColor !== "#ffffff" ? "#ffffff" : "initial"};
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .btn-primary {
    background: ${({
      themeProps: {
        buttonColor: { end },
      },
    }) => end};
    background: ${({
      themeProps: {
        buttonColor: { start, end },
      },
    }) => `linear-gradient(${start}, ${end})`}; 
    color: #fff;
    padding: 12px 30px;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 3px 0 rgba(0,0,0,0.04);
    vertical-align: baseline;
    text-align: center;
    min-width: 140px;
    transition: initial;
    &:hover, &:active, &:focus {
      background: ${({
        themeProps: {
          buttonColor: { end },
        },
      }) => end} !important;
      border-color: ${({
        themeProps: {
          buttonColor: { end },
        },
      }) => end} !important;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  label[type="button"] {
    appearance: initial;
  }
  ul.pagination{
    li.page-item.active
      a.page-link{
        background: ${({
          themeProps: {
            buttonColor: { end },
          },
        }) => end};
        border-color: ${({
          themeProps: {
            buttonColor: { end },
          },
        }) => end};
      }
    }
  form{
    .form-group{
      select{
        &.form-control.is-valid {
          background-image: none;
        }
      }
    } 
  }
`;
