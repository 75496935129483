import React, { PureComponent } from "react";

export default class MenuIcon extends PureComponent {
  render() {
    return (
      <svg
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style={{ enableBackground: "new 0 0 20 20" }}
      >
        <path d="M0,13.3v-1.7h20v1.7H0z M0,5.8h20v1.7H0V5.8z M0,0h20v1.7H0V0z" />
      </svg>
    );
  }
}
