import React, { PureComponent } from "react";
import { BuyNowButton } from "storefront/features/shared";
import { MakeAnOfferButton } from "storefront/features/shared";
import { BUY_NOW_FORMAT, MAKE_AN_OFFER_FORMAT } from "../utils";
import { FREE_SHIPPING, isTagExist } from "storefront/features/shared/utils";

class Buttons extends PureComponent {
  render() {
    const {
      product,
      buttonType,
      productPrice,
      variantId,
      buyNowDisabled,
      vendor,
    } = this.props;

    const freeShippingTagExist = isTagExist(product.tags, FREE_SHIPPING);

    return (
      <>
        {buttonType === BUY_NOW_FORMAT && (
          <BuyNowButton
            vendor={vendor}
            variantId={variantId}
            amount={productPrice}
            disabledButton={buyNowDisabled}
            freeShippingLabel={freeShippingTagExist}
          />
        )}
        {buttonType === MAKE_AN_OFFER_FORMAT && (
          <MakeAnOfferButton product={product} vendor={vendor} />
        )}
      </>
    );
  }
}

export default Buttons;
