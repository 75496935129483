import React, { PureComponent } from "react";

export default class ZA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path d="M114.024,256,0,141.926V370.1Z" />
          <path
            d="M161.192,256,0,94.7v47.226L114.024,256,0,370.1v47.138Z"
            fill="#ffda44"
          />
          <path
            d="M509.833,289.391h0c.058-.44.8-.878,2.167-1.318V222.609H222.6L85.33,85.337H0V94.7L161.192,256,0,417.234v9.429H85.33L222.6,289.391Z"
            fill="#6da544"
          />
          <path
            d="M503.181,322.783H236.433L132.552,426.663H512V322.783Z"
            fill="#0052b4"
          />
          <path
            d="M503.181,189.217H512V85.337H132.552l103.881,103.88Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
