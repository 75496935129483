import React, { PureComponent } from "react";

export default class CK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.338"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.332)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M384,156.705l5.283,16.254h17.089L392.546,183l5.281,16.254L384,189.211l-13.825,10.047L375.455,183,361.63,172.959h17.089Z"
              fill="#f0f0f0"
            />
            <path
              d="M313.791,185.786l15.228,7.758L341.1,181.461,338.43,198.34l15.227,7.759-16.879,2.673-2.674,16.88-7.76-15.227L309.466,213.1l12.086-12.084Z"
              fill="#f0f0f0"
            />
            <path
              d="M284.71,255.995l16.254-5.281V233.625l10.044,13.825,16.254-5.282-10.046,13.827,10.046,13.826-16.254-5.28-10.044,13.825v-17.09Z"
              fill="#f0f0f0"
            />
            <path
              d="M313.791,326.2l7.759-15.229-12.084-12.084,16.881,2.674,7.757-15.227,2.674,16.879,16.879,2.672-15.227,7.759L341.1,330.53l-12.084-12.087Z"
              fill="#f0f0f0"
            />
            <path
              d="M384,355.284l-5.281-16.253H361.628l13.827-10.045-5.28-16.254L384,322.776l13.827-10.044-5.281,16.254,13.826,10.045H389.283Z"
              fill="#f0f0f0"
            />
            <path
              d="M454.209,326.2l-15.229-7.758L426.9,330.53l2.673-16.882-15.227-7.756,16.879-2.675,2.675-16.879,7.756,15.227,16.881-2.674-12.086,12.085Z"
              fill="#f0f0f0"
            />
            <path
              d="M483.29,255.995l-16.254,5.281v17.09L456.991,264.54l-16.254,5.281,10.046-13.826-10.046-13.827,16.254,5.282,10.045-13.825v17.089Z"
              fill="#f0f0f0"
            />
            <path
              d="M454.209,185.788l-7.757,15.226L458.534,213.1l-16.881-2.673L433.9,225.652l-2.675-16.88L414.343,206.1l15.227-7.757L426.9,181.462l12.084,12.082Z"
              fill="#f0f0f0"
            />
            <path
              d="M0,186.665v16H46.069L0,233.377v7.539l57.377-38.252H80L0,256H112V186.664H0ZM96,256H22.628L96,207.083Z"
              fill="#f0f0f0"
            />
            <path
              d="M176,138.665l80-53.334H144v69.334H256v-16H209.931L256,107.951v-7.539l-57.377,38.251H176ZM160,85.333h73.372L160,134.246Z"
              fill="#f0f0f0"
            />
            <path
              d="M144,256H256l-80-53.334h22.623L256,240.917v-7.539l-46.069-30.713H256v-16H144Zm16-48.915L233.372,256H160Z"
              fill="#f0f0f0"
            />
            <path
              d="M112,85.331H0l80,53.334H57.377L0,100.413v7.539l46.069,30.712H0v16H112ZM96,134.246,22.628,85.333H96Z"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M144,85.331H112v69.334H0v32H112V256h32V186.665H256v-32H144Z"
              fill="#d80027"
            />
            <path
              d="M80,138.665,0,85.331v15.082l57.377,38.252Z"
              fill="#d80027"
            />
            <path d="M176,138.665h22.623L256,100.413V85.331Z" fill="#d80027" />
            <path d="M57.377,202.665,0,240.917V256l80-53.333Z" fill="#d80027" />
            <path
              d="M176,202.665,256,256V240.917l-57.377-38.252Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
