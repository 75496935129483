import React, { PureComponent } from "react";

export default class SG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="170.663"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <g>
            <path
              d="M83.478,170.666a51.952,51.952,0,0,1,40.812-50.734,51.942,51.942,0,1,0,0,101.467A51.949,51.949,0,0,1,83.478,170.666Z"
              fill="#f0f0f0"
            />
            <path
              d="M150.261,122.435l3.684,11.337h11.921l-9.645,7.007,3.684,11.337-9.644-7.006-9.645,7.006,3.685-11.337-9.645-7.007h11.921Z"
              fill="#f0f0f0"
            />
            <path
              d="M121.344,144.7l3.683,11.337h11.921L127.3,163.04l3.684,11.337-9.643-7.006-9.645,7.006,3.685-11.337-9.645-7.007H117.66Z"
              fill="#f0f0f0"
            />
            <path
              d="M179.178,144.7l3.684,11.337h11.921l-9.645,7.007,3.684,11.337-9.644-7.006-9.644,7.006,3.685-11.337-9.645-7.007h11.921Z"
              fill="#f0f0f0"
            />
            <path
              d="M168.047,178.087l3.684,11.337h11.921l-9.644,7.007,3.684,11.337-9.645-7.006-9.643,7.006,3.684-11.337-9.644-7.007h11.92Z"
              fill="#f0f0f0"
            />
            <path
              d="M132.474,178.087l3.683,11.337h11.921l-9.644,7.007,3.684,11.337-9.644-7.006-9.644,7.006,3.684-11.337-9.644-7.007h11.92Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
