import styled from "styled-components";
import { Button } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledBidButton = styled(Button)`
  transition: transform 0.3s ease-in;
  min-height: 52px;
  &.btn-primary {
    width: 100% !important;
    padding: 0;

    span {
      padding: 6px 10px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:focus,
    &:active {
      outline: none;
      box-shadow: none !important;
    }

    &.animate {
      transform: scale(1.05);

      span:nth-child(1) {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        transform: rotateX(0deg);
      }

      span:nth-child(2) {
        color: transparent;
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
      }
    }

    @media ${device.mobileL} {
      width: ${(props) => props.width || "140px"} !important;

      span {
        padding: ${(props) => props.padding || "6px 30px"};
      }
    }
  }

  &.rotate-btn {
    right: 20px;
    bottom: 20px;
    border: none;
    box-shadow: none;
    -webkit-perspective: 230px;
    perspective: 230px;

    span {
      background: #00509d;
      background: linear-gradient(#0079bc, #00509d);
      display: block;
      top: 0;
      width: 100%;
      height: 52px;
      position: absolute;
      border-radius: 5px;
      margin: 0;
      text-align: center;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    span:nth-child(1) {
      -webkit-transform: rotateX(90deg);
      -moz-transform: rotateX(90deg);
      transform: rotateX(90deg);
      -webkit-transform-origin: 50% 50% -20px;
      -moz-transform-origin: 50% 50% -20px;
      transform-origin: 50% 50% -20px;
    }

    span:nth-child(2) {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: 50% 50% -20px;
      -moz-transform-origin: 50% 50% -20px;
      transform-origin: 50% 50% -20px;

      &:hover {
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      }
    }
  }
`;

export const StyledShippingInfo = styled.p`
  width: 100%;
  font-size: 11px;
  margin: 0;
  font-weight: 400;
`;
