import React, { PureComponent } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default class FooterNav extends PureComponent {
  render() {
    return (
      <Navbar className="border-top border-bottom">
        <Container fluid>
          <Row className="w-100">
            <Col>
              <Nav className="footer-menu justify-content-center text-uppercase flex-wrap">
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/about-us"
                    id="footer-nav-link-about-us"
                  >
                    About us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/placing-a-bid"
                    id="footer-nav-link-placing-a-bid"
                  >
                    Placing bids
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/authenticity"
                    id="footer-nav-link-authenticity"
                  >
                    Authenticity guaranteed
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/ring-size-guide"
                    id="footer-nav-link-ring-size-guide"
                  >
                    Jewelry guides
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/contact-us"
                    id="footer-nav-link-contact-us"
                  >
                    Contact us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/getting-started"
                    id="footer-nav-link-getting-started"
                  >
                    FAQ
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/shipping-payment"
                    id="footer-nav-link-shipping-payment"
                  >
                    Shipping and payment
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/return-policy"
                    id="footer-nav-link-return-policy"
                  >
                    Return policy
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/terms-and-conditions"
                    id="footer-nav-link-terms-and-conditions"
                  >
                    Terms&Conditions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={NavLink}
                    to="/pages/privacy-policy"
                    id="footer-nav-link-privacy-policy"
                  >
                    Privacy policy
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
}
