import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { SEARCH_PAGE_LOADER } from "storefront/features/shared/utils";

import * as actions from "../actions";
import { getFlagUpdateProductGrid } from "storefront/features/shared/selectors";
import {
  getSearchProducts,
  getSearchPageParams,
  getSearchError,
} from "../selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams } from "storefront/features/shared/utils";
import { metaTags } from "../metaTags";

class Search extends PureComponent {
  componentDidMount() {
    const { fetchSearchRequest, history } = this.props;
    const { searchTitle, buyingFormat, pageNumber } = this.getSearchValues();
    history.action !== "PUSH" &&
      fetchSearchRequest(
        searchTitle,
        buyingFormat,
        pageNumber,
        SEARCH_PAGE_LOADER
      );
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    const { clearErrorMsg } = this.props;
    clearErrorMsg({});
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  getSearchValues = () => {
    const { location } = this.props;
    const searchTitle = getSearchParams(location.search, "title");
    const buyingFormat = getSearchParams(location.search, "buying_format");
    const pageNumber = getSearchParams(location.search, "page");
    return { searchTitle, buyingFormat, pageNumber };
  };

  handleVisibilityChange = () => {
    if (!document.hidden) {
      const isVisibilityChange = true;
      this.handleUpdateGrid(isVisibilityChange);
    }
  };

  pageItemHandleClick = (page) => {
    const { fetchSearchRequest } = this.props;
    const { searchTitle, buyingFormat } = this.getSearchValues();
    fetchSearchRequest(searchTitle, buyingFormat, page, SEARCH_PAGE_LOADER);
  };

  handleUpdateGrid = (isVisibilityChange) => {
    const { fetchSearchRequest, updateProductGrid } = this.props;
    const { searchTitle, buyingFormat, pageNumber } = this.getSearchValues();
    const withDelay = isVisibilityChange ? false : updateProductGrid;
    fetchSearchRequest(
      searchTitle,
      buyingFormat,
      pageNumber,
      SEARCH_PAGE_LOADER,
      withDelay
    );
  };

  render() {
    const { searchProducts, pageParams, searchErrorMsg } = this.props;
    return (
      <>
        <Helmet metaTags={metaTags} />
        <h1 className="text-center mt-4">Search</h1>
        <WithLoader loader={SEARCH_PAGE_LOADER}>
          {isEmpty(searchProducts) && searchErrorMsg ? (
            <h4 className="text-center mt-4 text-uppercase">
              {searchErrorMsg}
            </h4>
          ) : (
            <ProductGrid products={searchProducts} />
          )}
          {pageParams && (
            <Pagination
              pageItemHandleClick={this.pageItemHandleClick}
              pageParams={pageParams}
            />
          )}
        </WithLoader>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  searchProducts: getSearchProducts,
  searchErrorMsg: getSearchError,
  updateProductGrid: getFlagUpdateProductGrid,
  pageParams: getSearchPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSearchRequest: (title, buyingFormat, page, loader, withDelay) => {
    dispatch(
      actions.fetchSearchRequest({
        title,
        buyingFormat,
        page,
        loader,
        withDelay,
      })
    );
  },
  clearErrorMsg: (data) =>
    dispatch(actions.fetchSearchRequestResultError(data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(Search);
