import React, { PureComponent } from "react";
import { StyledSproutCartBadgeContainer } from "./SproutCartBadge.styled";

export class SproutCartBadge extends PureComponent {
  render() {
    return (
      <StyledSproutCartBadgeContainer className="mt-2">
        <div id="sprout-cart-badge-icon">
          <img
            alt="an icon of a tree"
            src="https://storage.googleapis.com/treeshop-app-assets/tree_icon_badges.svg"
          />
        </div>
        <div id="sprout-cart-badge-text">
          Your impact:&nbsp;<span>1 tree will be planted</span>&nbsp;with this
          purchase.
        </div>
      </StyledSproutCartBadgeContainer>
    );
  }
}

export default SproutCartBadge;
