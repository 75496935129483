import React, { PureComponent } from "react";

export default class AU extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M223.4,256c.273-.3.543-.609.813-.916-.27.308-.546.61-.82.916Z"
              fill="#f0f0f0"
            />
            <path
              d="M189.217,297.923l10.958,22.913,24.747-5.718-11.083,22.853,19.9,15.781-24.776,5.585.069,25.4-19.815-15.89L169.4,384.735l.069-25.4L144.7,353.752l19.9-15.781-11.083-22.853,24.746,5.718Z"
              fill="#f0f0f0"
            />
            <path
              d="M387.076,326.387l5.227,10.929,11.8-2.728-5.286,10.9,9.492,7.528-11.818,2.663.032,12.114-9.45-7.578-9.45,7.578.032-12.114-11.817-2.663,9.491-7.528-5.285-10.9,11.8,2.728Z"
              fill="#f0f0f0"
            />
            <path
              d="M338.453,210.448l5.227,10.93,11.8-2.729-5.286,10.9,9.491,7.528-11.817,2.663.032,12.115-9.45-7.58L329,251.856l.033-12.115-11.818-2.663,9.491-7.528-5.284-10.9,11.8,2.729Z"
              fill="#f0f0f0"
            />
            <path
              d="M387.076,144.2l5.227,10.93,11.8-2.73-5.286,10.9,9.491,7.527-11.817,2.664.032,12.114-9.45-7.58-9.45,7.58.032-12.114-11.817-2.664,9.491-7.527-5.285-10.9,11.8,2.73Z"
              fill="#f0f0f0"
            />
            <path
              d="M429.547,193.886l5.227,10.929,11.8-2.728-5.284,10.9,9.491,7.527-11.818,2.664L439,235.292l-9.451-7.578-9.45,7.578.032-12.114-11.817-2.664,9.491-7.527-5.286-10.9,11.8,2.728Z"
              fill="#f0f0f0"
            />
            <path
              d="M399.179,251.856l4.11,12.652h13.3l-10.763,7.82,4.112,12.652-10.763-7.819-10.765,7.819,4.112-12.652-10.763-7.82h13.3Z"
              fill="#f0f0f0"
            />
            <path
              d="M256,85.333v30.553l-45.167,25.1H256v59.359H196.9L256,233.179V256H229.32L155.826,215.17V256H100.174V207.423L12.744,256H0V225.443l45.167-25.1H0V140.985H59.1L0,108.139V85.333H26.68l73.494,40.827V85.333h55.652v48.573l87.43-48.573Z"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M144,85.33H112v69.332H0v32H112V256h32V186.662H256v-32H144Z"
            fill="#d80027"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#0052b4"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#d80027"
            />
            <path
              d="M71.846,200.344,0,240.259V256H0l100.174-55.652Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#0052b4"
          />
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
              fill="#d80027"
            />
            <path
              d="M184.154,140.982,256,101.067V85.33h0L155.826,140.982Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
