import React, { PureComponent } from "react";
import { StyledSproutBannerContainer } from "./SproutBanner.styled";
import { createStructuredSelector } from "reselect";
import { getSproutBannerInfo } from "../selectors";
import * as actions from "../actions";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { connect } from "react-redux";

class SproutBanner extends PureComponent {
  LEFT_BANNER =
    "https://images.prismic.io/bids-com-app/6d4368e0-a63a-4fa0-b0e6-4a6cef7b6a35_Olive+Tree+Wide+Presentation+left.jpg?auto=compress,format";

  RIGHT_BANNER =
    "https://images.prismic.io/bids-com-app/ff1140ce-9830-42b5-888b-145dbae7e572_Olive+Tree+Wide+Presentation+right.jpg?auto=compress,format";

  BANNERS_URL =
    "https://www.edenprojects.org/?atrkid=V3ADW8BA6461C_51671918555_kwd-388216407527__320020610326_g_c___&gclid=CjwKCAjwkLCkBhA9EiwAka9QRhN90P0DEXvc7dC3Z7CiZmvlgRDLV7jK5RsaJYGrKUySg9S9i4Ib-hoCG6cQAvD_BwE";

  componentDidMount() {
    const { fetchSproutBannerRequest } = this.props;
    fetchSproutBannerRequest();
  }

  render() {
    const { sproutBannerInfo } = this.props;

    return (
      <StyledSproutBannerContainer className="d-flex justify-content-center my-3">
        <a className="sprout-banner-left" href={this.BANNERS_URL}>
          <img src={this.LEFT_BANNER} />
        </a>
        <a
          className="sprout-banner-center text-decoration-none"
          href={this.BANNERS_URL}
          target="blank"
        >
          <div
            id="SproutTreeCountVintage"
            dangerouslySetInnerHTML={{ __html: sproutBannerInfo }}
          ></div>
        </a>
        <a className="sprout-banner-right" href={this.BANNERS_URL}>
          <img src={this.RIGHT_BANNER} />
        </a>
      </StyledSproutBannerContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sproutBannerInfo: getSproutBannerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSproutBannerRequest: () => {
    dispatch(actions.fetchSproutBannerRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(SproutBanner));
