import React, { PureComponent } from "react";

export default class PA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path d="M256,256V426.663H0V256H256Z" fill="#0052b4" />
          <path d="M512,85.337V256H256V85.337H512Z" fill="#d80027" />
          <path
            d="M128,123.034l11.824,36.389h38.263l-30.955,22.491L158.955,218.3,128,195.813,97.045,218.3l11.823-36.389L77.913,159.423h38.263Z"
            fill="#0052b4"
          />
          <path
            d="M384,293.7l11.824,36.389h38.263l-30.955,22.491,11.823,36.389L384,366.476l-30.955,22.49,11.823-36.389-30.955-22.491h38.263Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
