import React, { PureComponent } from "react";
import { Col, Form } from "react-bootstrap";

class ProductFilter extends PureComponent {
  handleFilterChange = (e) => {
    const { filterItemHandleChange } = this.props;
    const value = e.target.value;
    filterItemHandleChange(value);
  };

  render() {
    const { filter } = this.props;
    return (
      <Col>
        <Form className="mb-3">
          <Form.Control
            as="select"
            size="sm"
            onChange={this.handleFilterChange}
            name="product-filter"
            value={filter}
          >
            <option value="buy-now,make-an-offer,auction">All products</option>
            <option value="auction">All auctions</option>
            <option value="buy-now">All Buy Now</option>
            {/* <option value="make-an-offer">All Make an Offer</option> */}
          </Form.Control>
        </Form>
      </Col>
    );
  }
}

export default ProductFilter;
