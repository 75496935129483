import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const StyledContainer = styled(Container)`
  .form-group {
    position: relative;
  }
  .form-footer-info {
    color: #9d9d9d;
  }
`;

export const StyledLinkForgotPass = styled(Link)`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 13px;
  color: #9d9d9d;
  text-decoration: none;
  &:hover,
  &:active {
    color: #9d9d9d;
    text-decoration: none;
  }
`;
export const StyledLinkRegister = styled(Link)`
  color: #454545;
  text-decoration: none;
  &:hover,
  &:active {
    color: #1e1e1e;
    text-decoration: none;
  }
`;
