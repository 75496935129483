import React, { PureComponent } from "react";

export default class WF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 338.162"
      >
        <path d="M0,0H512V338.162H0Z" fill="#ed2939" />
        <path d="M0,0H135.265V135.265H0Z" fill="#fff" />
        <path d="M0,0H67.632V135.265H0Z" fill="#002395" />
        <path
          d="M0,135.265H202.9V0"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          d="M143.106,74.579,102.526,34h81.159ZM129.579,88.106,89,47.526v81.159Zm13.526,13.526-40.579,40.579h81.159Zm13.526-13.526,40.579-40.579v81.159Z"
          transform="translate(215.552 80.975)"
          fill="#fff"
        />
      </svg>
    );
  }
}
