import React, { PureComponent } from "react";

export default class OM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <g>
            <rect
              width="512"
              height="341.326"
              transform="translate(0 85.331)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#d80027"
            />
          </g>
          <g>
            <path
              d="M189.746,160.439,174.007,144.7l-10.494,10.494L153.02,144.7l-15.741,15.741,10.494,10.493-10.495,10.494,15.74,15.741,10.494-10.494,10.5,10.494,15.739-15.741-10.494-10.494Z"
              fill="#f0f0f0"
            />
            <rect
              width="300.522"
              height="111.315"
              transform="translate(211.478 85.331)"
              fill="#f0f0f0"
            />
          </g>
          <rect
            width="300.522"
            height="111.304"
            transform="translate(211.478 315.353)"
            fill="#6da544"
          />
        </g>
      </svg>
    );
  }
}
