import { createSelector } from "reselect";

export const sproutBannerSelector = (state) => {
  return state.getIn(["sproutBannerReducer", "sproutBanner"]);
};

export const getSproutBannerInfo = createSelector(
  sproutBannerSelector,
  (banner) => {
    return banner;
  }
);
