import React, { PureComponent } from "react";
import { SpinnerContainer, SpinerSpan } from "./Loader.styled";

export default class Loader extends PureComponent {
  render() {
    return (
      <SpinnerContainer>
        <SpinerSpan />
        <SpinerSpan />
        <SpinerSpan />
      </SpinnerContainer>
    );
  }
}
