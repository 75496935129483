import React, { PureComponent } from "react";

export default class VN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <path d="M196.641,85.337H0V426.663H512V85.337Z" fill="#d80027" />
          <path
            d="M256,157.279l22.663,69.747H352l-59.332,43.106,22.664,69.749L256,296.774l-59.332,43.107,22.664-69.749L160,227.026h73.337Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
