import React, { PureComponent } from "react";

export default class BY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 340.964"
      >
        <g transform="translate(0 -85.518)">
          <rect
            width="511.444"
            height="340.955"
            transform="translate(0.556 85.523)"
            fill="#6da544"
          />
          <path d="M0,315.3H511.444V85.52H0" fill="#a2001d" />
          <path d="M100.065,315.3V85.52H0V426.482H100.065Z" fill="#f0f0f0" />
          <g>
            <path
              d="M68.564,243.647,50.033,210.028l18.531-33.091,18.529,33.091Z"
              fill="#a2001d"
            />
            <path
              d="M31.5,243.647l-18.53-33.619L31.5,176.937l18.531,33.091Z"
              fill="#a2001d"
            />
            <path
              d="M68.564,335.064,50.033,301.446l18.531-33.092,18.529,33.092Z"
              fill="#a2001d"
            />
            <path
              d="M31.5,335.064l-18.53-33.618L31.5,268.354l18.531,33.092Z"
              fill="#a2001d"
            />
            <path
              d="M68.564,426.482,50.033,392.863l18.531-33.092,18.529,33.092Z"
              fill="#a2001d"
            />
            <path
              d="M31.5,426.482l-18.53-33.619L31.5,359.771l18.531,33.092Z"
              fill="#a2001d"
            />
            <path
              d="M68.564,152.23,50.033,118.611,68.564,85.518l18.529,33.093Z"
              fill="#a2001d"
            />
            <path
              d="M31.5,152.23l-18.53-33.619L31.5,85.518l18.531,33.093Z"
              fill="#a2001d"
            />
          </g>
        </g>
      </svg>
    );
  }
}
