export const FETCH_WON_AUCTIONS_REQUEST = "cart/FETCH_WON_AUCTIONS_REQUEST";
export const FETCH_WON_AUCTIONS_REQUEST_RESULT =
  "cart/FETCH_WON_AUCTIONS_REQUEST_RESULT";
export const FETCH_WON_AUCTIONS_COUNT_REQUEST =
  "cart/FETCH_WON_AUCTIONS_COUNT_REQUEST";
export const FETCH_WON_AUCTIONS_COUNT_REQUEST_RESULT =
  "cart/FETCH_WON_AUCTIONS_COUNT_REQUEST_RESULT";
export const FETCH_CHECKOUT = "cart/FETCH_CHECKOUT";
export const REMOVE_AUCTION_REQUEST = "cart/REMOVE_AUCTION_REQUEST";
export const FETCH_SHIPPING_MESSAGE_DATA_REQUEST =
  "cart/FETCH_SHIPPING_MESSAGE_REQUEST";
export const FETCH_SHIPPING_MESSAGE_DATA_REQUEST_RESULT =
  "cart/FETCH_SHIPPING_MESSAGE_REQUEST_RESULT";

export function fetchWonAuctionsRequest() {
  return { type: FETCH_WON_AUCTIONS_REQUEST };
}

export function fetchWonAuctionsRequestResult(response) {
  return { type: FETCH_WON_AUCTIONS_REQUEST_RESULT, payload: response };
}

export function fetchWonAuctionsCountRequest() {
  return { type: FETCH_WON_AUCTIONS_COUNT_REQUEST };
}

export function fetchWonAuctionsCountRequestResult(response) {
  return { type: FETCH_WON_AUCTIONS_COUNT_REQUEST_RESULT, payload: response };
}

export function fetchCheckout(value) {
  return { type: FETCH_CHECKOUT, payload: value };
}

export function removeAuctionRequest(value) {
  return { type: REMOVE_AUCTION_REQUEST, payload: value };
}

export function fetchShippingMessageDataRequest() {
  return { type: FETCH_SHIPPING_MESSAGE_DATA_REQUEST };
}

export function fetchShippingMessageDataRequestResult(value) {
  return { type: FETCH_SHIPPING_MESSAGE_DATA_REQUEST_RESULT, payload: value };
}
