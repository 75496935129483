import React, { PureComponent } from "react";

export default class TR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <g>
            <path
              d="M247.213,216.787l17.594,24.246,28.493-9.239-17.621,24.224,17.592,24.244-28.484-9.274-17.621,24.225.018-29.955L218.7,255.983l28.494-9.239Z"
              fill="#f0f0f0"
            />
            <path
              d="M199.2,316.6a60.6,60.6,0,1,1,28.827-113.92,74.586,74.586,0,1,0,0,106.634A60.3,60.3,0,0,1,199.2,316.6Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
