import React, { PureComponent } from "react";
import { compose } from "redux";
import { Button, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import {
  validateEmail,
  validateRequired,
  composeValidators,
  validateMinLength,
  validateMaxLength,
} from "storefront/features/shared/components/field/utils";
import withRedirectRegisteredUser from "storefront/features/shared/components/RedirectRegisteredUser";
import { Link } from "react-router-dom";

class RegisterForm extends PureComponent {
  state = { errorMsg: "" };

  handleResponseErrors = (error) => {
    this.setState({ errorMsg: error });
  };

  handleSubmitForm = (values) => {
    const { fetchCustomerRegisterRequest } = this.props,
      callback = this.handleResponseErrors,
      isVendor = false;
    fetchCustomerRegisterRequest({ isVendor, callback, ...values });
  };

  render() {
    return (
      <Form
        noValidate
        initialValues={{ acceptsMarketing: true }}
        onSubmit={this.handleSubmitForm}
        render={({
          handleSubmit,
          submitting,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <BSForm onSubmit={handleSubmit}>
            <BSForm.Group>
              <Field
                id="register-first-name"
                name="firstName"
                type="text"
                placeholder="First name"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                id="register-last-name"
                name="lastName"
                type="text"
                placeholder="Last name"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                id="register-email"
                name="email"
                type="email"
                placeholder="Email"
                validate={composeValidators(
                  validateEmail,
                  validateRequired,
                  validateMaxLength(255)
                )}
                disabled={submitting}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                id="register-password"
                name="password"
                type="password"
                placeholder="Password"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMinLength(5),
                  validateMaxLength(40)
                )}
              />
            </BSForm.Group>
            <BSForm.Group className="d-flex align-items-center">
              <Field
                id="agreement-checkbox"
                name="acceptsMarketing"
                type="checkbox"
                checked
                disabled={submitting}
              />
              <small>
                I agree to receive
                <Link to="/" variant="link" id="home-page-link">
                  {" "}Bids.com{" "}
                </Link>
                news, events and exclusive offers and agree to its
                <Link
                  to="/pages/privacy-policy"
                  variant="link"
                  data-cy="privacy-policy-page-link"
                >
                  {" "}Privacy Policy.
                </Link>
              </small>
            </BSForm.Group>

            <BSForm.Group>
              {this.state.errorMsg && !dirtySinceLastSubmit && (
                <p
                  id="form-registration-error"
                  className="invalid-feedback d-block"
                >
                  {this.state.errorMsg}
                </p>
              )}
              <Button
                id="register-submit"
                className="w-100"
                type="submit"
                variant="primary"
                disabled={submitting || pristine}
              >
                Register
              </Button>
            </BSForm.Group>
          </BSForm>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerRegisterRequest: (values) => {
    dispatch(actions.fetchCustomerRegisterRequest(values));
  },
});

export default compose(
  withRedirectRegisteredUser,
  connect(null, mapDispatchToProps)
)(RegisterForm);
