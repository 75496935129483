import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";
import { MakeAnOfferIframeContainer } from "./MakeAnOfferButton.styled";
import { moneyFormat } from "storefront/features/shared/utils";
import { get } from "lodash";
import RestrictVendorModal from "../../buyNowButton/components/RestrictVendorModal";

class MakeAnOfferButton extends PureComponent {
  state = { visible: false, show: false };

  handleClick = () => {
    this.setState((prevState) => ({ visible: !prevState.visible }));
  };

  handleIframeClose = (e) => {
    const { visible } = this.state;
    if (e.data.type === "close" && visible) {
      this.setState({ visible: false });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  componentDidMount() {
    window.addEventListener("message", this.handleIframeClose);
  }

  render() {
    const {
        product: { title, images, handle, variants },
        vendor,
      } = this.props,
      { shopify_id, price, sku } = variants[0];
    const { visible } = this.state;
    const image_url = get(images, "0.src", undefined);
    return (
      <div className="mb-2">
        <Button
          className="text-uppercase"
          variant="primary"
          onClick={vendor ? this.handleShow : this.handleClick}
        >
          offer
        </Button>
        {visible && (
          <MakeAnOfferIframeContainer className="active">
            <iframe
              src={`https://www.makeofferapp.com/offers/new?mf_tkn=312c44cc-0814-4114-acdb-57dccf05841a&email=&currency_iso=USD&currency_rate=1.0&products[][title]=${encodeURI(
                title
              )}&products[][img]=${encodeURI(
                image_url
              )}&products[][url]=/product/${handle}&products[][variant]=${shopify_id}&products[][available]=true&products[][price]=${moneyFormat(
                price
              )}&products[][sku]=${sku}&products[][tags]=["Buying Format_Make an Offer"]&products[][collections]=["Make An Offer"]&products[][product_id]=undefined&products[][options]=&products[][min_purchase_quantity]=1&`}
              frameBorder="0"
              scrolling="no"
              className="mfIframe"
              sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts allow-popups"
            ></iframe>
          </MakeAnOfferIframeContainer>
        )}
        {vendor && (
          <RestrictVendorModal
            show={this.state.show}
            onHide={this.handleClose}
          />
        )}
      </div>
    );
  }
}

export default MakeAnOfferButton;
