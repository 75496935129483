import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";

import Icon from "storefront/features/shared/icons";
import { StyledTd, StyledImage, StyledButton } from "./ProductTableRow.styled";
import ModalDeleteTableRow from "./ModalDeleteTableRow";

export class ProductTableAuctionRow extends PureComponent {
  state = {
    show: false,
  };

  showProductModal = () => {
    const { product, handleModalShow } = this.props;
    handleModalShow(product);
  };

  showAuctionModal = () => {
    const { product, handleAuctionModalShow } = this.props;
    handleAuctionModalShow(product);
  };

  handleModalDeleteTableRowShow = () => {
    this.setState(({ show }) => ({ show: !show }));
  };

  deleteVendorAuction = () => {
    const {
      deleteAuction,
      product: { id },
    } = this.props;
    deleteAuction(id);
    this.handleModalDeleteTableRowShow();
  };

  render() {
    const {
      product: { product_title, image_url, status },
      positionNumber,
    } = this.props;

    const { show } = this.state;

    return (
      <>
        <ModalDeleteTableRow
          show={show}
          handleModalClose={this.handleModalDeleteTableRowShow}
          title={product_title}
          deleteTableRow={this.deleteVendorAuction}
        />
        <tr data-cy="auction-row">
          <td className="align-middle pl-3" data-cy="position-number-td">
            {positionNumber}
          </td>
          <td className="align-middle" data-cy="image-td">
            {image_url ? (
              <StyledImage src={image_url} rounded />
            ) : (
              <div className="rounded">
                <Icon type="noImage" size={35} />
              </div>
            )}
          </td>
          <td className="align-middle" data-cy="title-td">
            {product_title}
          </td>
          <td className="align-middle" data-cy="auction-status-td">
            {status}
          </td>
          <td className="align-middle" data-cy="format-td">
            auction
          </td>
          <StyledTd className="align-middle pr-2 text-right">
            <Button
              variant="link"
              data-cy="edit-auction-btn"
              onClick={this.showAuctionModal}
            >
              Edit auction
            </Button>
            <StyledButton
              color="red"
              backgroundcolor="transparent"
              variant="link"
              data-cy="delete-auction-btn"
              onClick={this.handleModalDeleteTableRowShow}
            >
              Cancel Auction
            </StyledButton>
          </StyledTd>
        </tr>
      </>
    );
  }
}

export default ProductTableAuctionRow;
