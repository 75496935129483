import styled from "styled-components";
import { Button, Image } from "react-bootstrap";

export const StyledTd = styled.td`
  width: 250px;
`;

export const StyledImage = styled(Image)`
  width: 38px;
  height: 38px;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ backgroundcolor }) =>
    backgroundcolor ? backgroundcolor : "white"};
  color: ${({ color }) => (color ? color : "white")};
  &:hover {
    color: ${({ color }) => (color ? color : "white")};
  }
`;
