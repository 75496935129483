import styled from "styled-components";

export const MakeAnOfferIframeContainer = styled.div`
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: none;
    visibility: hidden;
    height: auto;
    z-index: 9999999999;
    position: fixed;
    &.active{
        display: block;
        visibility: visible;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: none;
        margin: 0;
        padding: 0;
        z-index: 9999999999;
    },
`;
