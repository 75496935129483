import React, { PureComponent } from "react";

export default class HT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.332"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.34)"
            fill="#a2001d"
          />
          <path d="M512,85.334v166.69L0,256.175V85.334Z" fill="#0052b4" />
          <path
            d="M315.362,300.525,256,293.1l-59.362,7.421V211.481H315.362Z"
            fill="#f0f0f0"
          />
          <circle
            cx="29.682"
            cy="29.682"
            r="29.682"
            transform="translate(226.318 233.745)"
            fill="#0052b4"
          />
          <circle
            cx="14.84"
            cy="14.84"
            r="14.84"
            transform="translate(241.16 248.587)"
            fill="#a2001d"
          />
          <path d="M233.739,226.321h44.522L256,248.582Z" fill="#6da544" />
          <rect
            width="14.84"
            height="44.522"
            transform="translate(248.576 241.166)"
            fill="#ffda44"
          />
          <path
            d="M279.746,281.232H232.254l-35.616,19.293H315.362Z"
            fill="#6da544"
          />
        </g>
      </svg>
    );
  }
}
