import { fromJS } from "immutable";

import * as actions from "./actions";

const initialState = fromJS({
  wonAuctions: [],
  wonAuctionsCount: 0,
  shippingMessageEnabled: false,
});

const setWonAuctions = (state, action) =>
  state.set("wonAuctions", fromJS(action.payload));

const setWonAuctionsCount = (state, action) =>
  state.set("wonAuctionsCount", fromJS(action.payload));

const setShippingMessageData = (state, action) =>
  state.set("shippingMessageEnabled", fromJS(action.payload));

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_WON_AUCTIONS_REQUEST_RESULT:
      return setWonAuctions(state, action);
    case actions.FETCH_WON_AUCTIONS_COUNT_REQUEST_RESULT:
      return setWonAuctionsCount(state, action);
    case actions.FETCH_SHIPPING_MESSAGE_DATA_REQUEST_RESULT:
      return setShippingMessageData(state, action);
    default:
      return state;
  }
};

export default cartReducer;
