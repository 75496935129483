import React, { PureComponent } from "react";

export default class CY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#fcfcfc"
          />
          <path
            d="M352,233.88H329.846a73.846,73.846,0,1,1-147.692,0H160a96.043,96.043,0,0,0,69.787,92.377,24.576,24.576,0,0,0,2.564,26.822l24.141-19.346,24.141,19.346A24.575,24.575,0,0,0,283.067,326,96.037,96.037,0,0,0,352,233.88Z"
            fill="#6da544"
          />
          <path
            d="M196.923,226.5s0,36.923,36.923,36.923l7.385,7.385H256s7.385-22.154,22.154-22.154c0,0,0-14.769,14.769-14.769h22.154s-7.385-29.538,29.538-51.692l-14.769-7.385s-51.692,36.923-88.615,29.538v14.769H226.462l-7.385-7.385Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
