import React, { PureComponent } from "react";

export default class PT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <path d="M196.641,85.337V426.663H0V85.337Z" fill="#6da544" />
          <circle
            cx="64"
            cy="64"
            r="64"
            transform="translate(132.641 192)"
            fill="#ffda44"
          />
          <path d="M160.638,224v40a36,36,0,0,0,72,0V224Z" fill="#d80027" />
          <path
            d="M196.638,276a12.013,12.013,0,0,1-12-12V248h24v16A12.016,12.016,0,0,1,196.638,276Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
