import React, { PureComponent } from "react";

export default class LI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.332"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.34)"
            fill="#d80027"
          />
          <path d="M512,85.334v166.69L0,256.175V85.334Z" fill="#0052b4" />
          <path
            d="M211.478,204.06a22.258,22.258,0,0,0-37.1-16.589V174.378H181.8v-14.84h-7.421v-7.421h-14.84v7.421h-7.421v14.84h7.421v13.093a22.258,22.258,0,1,0-29.68,33.178v13.093h74.2V220.649A22.21,22.21,0,0,0,211.478,204.06Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
