import React, { PureComponent } from "react";

export default class GL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.332"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.34)"
            fill="#d80027"
          />
          <g>
            <path d="M512,85.334v166.69L0,256.175V85.334Z" fill="#f0f0f0" />
            <circle
              cx="81.619"
              cy="81.619"
              r="81.619"
              transform="translate(122.435 174.384)"
              fill="#f0f0f0"
            />
          </g>
          <path d="M122.435,256a81.623,81.623,0,1,1,163.246,0" fill="#d80027" />
        </g>
      </svg>
    );
  }
}
