import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import withRemoveAuction from "storefront/features/shared/components/RemoveAuction";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { HOME_PAGE_LOADER as homeLoader } from "storefront/features/shared/utils";
import * as actions from "storefront/features/shared/actions";
import {
  getProducts,
  getFlagUpdateProductGrid,
  getPageParams,
} from "storefront/features/shared/selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams } from "storefront/features/shared/utils";
import { metaTags } from "../metaTags";
import HomePageVendorsFilter from "./HomePageVendorsFilter";
import { CollectionFilter } from "storefront/features/shared/components/CollectionFilter";
import {
  PrismicHomepageBanners,
  PrismicReviewsCarousel,
} from "../../../features/prismicBanner/index";
import { SproutBanner } from "../../../features/sproutBanner/index";

const FIRST_PAGE_NUMBER = 1;

class Home extends PureComponent {
  state = {
    productsFilter: "default",
  };

  componentDidMount() {
    const { location, fetchProductsForHome, history } = this.props;
    const page = getSearchParams(location.search, "page");
    history.action === "PUSH" &&
      fetchProductsForHome({
        page,
        loader: homeLoader,
      });
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange = () => {
    if (!document.hidden) {
      const isLoader = true;
      const isVisibilityChange = true;
      this.handleUpdateGrid(isLoader, isVisibilityChange);
    }
  };

  pageItemHandleClick = (page) => {
    const { productsFilter } = this.state;
    const { fetchProductsForHome } = this.props;
    fetchProductsForHome({
      page,
      loader: homeLoader,
      filter_products_by: productsFilter,
    });
  };

  handleUpdateGrid = (isLoader, isVisibilityChange) => {
    const { productsFilter } = this.state;
    const { location, fetchProductsForHome, updateProductGrid } = this.props;
    const page = getSearchParams(location.search, "page");
    const loader = isLoader ? homeLoader : null;
    const withDelay = isVisibilityChange ? false : updateProductGrid;
    fetchProductsForHome({
      page,
      withDelay,
      loader,
      filter_products_by: productsFilter,
    });
  };

  handleVendorsFilterChange = (vendorsFilter) => {
    const { history } = this.props;
    history.push(`/vendors/${vendorsFilter}`);
  };

  handleProductsFilterChange = (productsFilter) => {
    const { fetchProductsForHome } = this.props;
    this.setState({ productsFilter });

    fetchProductsForHome({
      loader: homeLoader,
      page: FIRST_PAGE_NUMBER,
      withDelay: false,
      filter_products_by: productsFilter,
    });
  };

  render() {
    const { products, pageParams } = this.props;
    const { productsFilter } = this.state;

    return (
      <>
        <Helmet metaTags={metaTags} />
        <PrismicHomepageBanners />
        <WithLoader loader={homeLoader}>
          <Container>
            <Row className="justify-content-end mt-4">
              <CollectionFilter
                filterItemHandleChange={this.handleProductsFilterChange}
                filter={productsFilter}
              />
              <HomePageVendorsFilter
                filterItemHandleChange={this.handleVendorsFilterChange}
              />
            </Row>
          </Container>
          <ProductGrid products={products} />
        </WithLoader>
        <Pagination
          pageItemHandleClick={this.pageItemHandleClick}
          pageParams={pageParams}
        />
        <PrismicReviewsCarousel />
        {process.env.SHOW_SPROUT === "true" && <SproutBanner />}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  updateProductGrid: getFlagUpdateProductGrid,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductsForHome: ({ page, loader, withDelay, filter_products_by }) => {
    dispatch(
      actions.fetchProductsForHomeRequest({
        page,
        loader,
        withDelay,
        filter_products_by,
      })
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withRemoveAuction
)(Home);
