import styled from "styled-components";
import { Col } from "react-bootstrap";

export const StyledIconsContainer = styled(Col)`
  span {
    svg {
      margin: 0.25rem 0;
    }
  }
`;
