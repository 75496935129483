import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import { validateMinLength } from "storefront/features/shared/components/field/utils";

class ActivateForm extends PureComponent {
  state = { errorMsg: "" };

  handleResponsErrors = (error) => {
    this.setState((prevState) => ({ ...prevState, errorMsg: error }));
  };

  getActivationUrl = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("activation_url");
  };

  handleSubmitForm = (values) => {
    const { location, fetchCustomerActivateRequest } = this.props,
      callback = this.handleResponsErrors,
      activationUrl = this.getActivationUrl(location);
    fetchCustomerActivateRequest({ activationUrl, callback, ...values });
  };

  handleValidateForm = ({ password, confirm }) => {
    const errors = {};
    confirm !== password && (errors.confirm = "Must match");
    !password && (errors.empty = true);
    return errors;
  };

  render() {
    const { errorMsg } = this.state;
    return (
      <Form
        validate={this.handleValidateForm}
        onSubmit={this.handleSubmitForm}
        render={({
          handleSubmit,
          dirtySinceLastSubmit,
          errors: { empty, confirm },
        }) => (
          <BSForm onSubmit={handleSubmit}>
            <BSForm.Group>
              <Field
                name="password"
                type="password"
                placeholder="Password"
                validate={validateMinLength(5)}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="confirm"
                type="password"
                placeholder="Confirm password"
                validate={validateMinLength(5)}
              />
            </BSForm.Group>
            <BSForm.Group>
              {errorMsg && !dirtySinceLastSubmit && (
                <p className="invalid-feedback d-block">{errorMsg}</p>
              )}
              <Button
                className="w-100"
                type="submit"
                variant="primary"
                disabled={empty || confirm}
              >
                Activate
              </Button>
            </BSForm.Group>
          </BSForm>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerActivateRequest: (values) => {
    dispatch(actions.fetchCustomerActivateRequest(values));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(ActivateForm));
