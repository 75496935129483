import React, { PureComponent } from "react";

export default class VU extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect width="512" height="341.326" transform="translate(0 85.337)" />
          <path d="M512,256V426.663H0L215.185,256Z" fill="#6da544" />
          <path d="M512,85.337V256H215.185L0,85.337Z" fill="#d80027" />
          <path d="M221,239.3,26.868,85.337H8.956l208.168,165.1H512V239.3Z" />
          <path d="M8.956,426.663H26.868L221,272.7H512V261.565H217.124Z" />
          <path d="M0,92.44v14.206L188.317,256,0,405.354v14.2L206.229,256Z" />
          <g>
            <path
              d="M8.956,85.337H0v7.1L206.229,256,0,419.559v7.1H8.956l208.168-165.1H512v-11.13H217.124Z"
              fill="#ffda44"
            />
            <path
              d="M63.718,292.382v-14.3a25.9,25.9,0,0,0,25.87-25.869,18.324,18.324,0,0,0-18.3-18.3,12.483,12.483,0,0,0-12.469,12.469,7.983,7.983,0,0,0,7.974,7.974A4.514,4.514,0,0,0,71.3,249.85H85.592a18.825,18.825,0,0,1-18.8,18.8,22.3,22.3,0,0,1-22.269-22.271,26.794,26.794,0,0,1,26.764-26.764,32.635,32.635,0,0,1,32.6,32.6A40.211,40.211,0,0,1,63.718,292.382Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
