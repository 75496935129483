import styled from "styled-components";
import { InputGroup, Dropdown } from "react-bootstrap";

import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    background-color: #fff;
    border-left: 0px;
    cursor: pointer;
  }
  input {
    border-right: 0px;
    &:focus {
      border: 1px 0px 1px 1px;
      border-style: solid;
      border-color: #ced4da;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .mobile-search-bar & {
    margin: 0 auto;
  }
  .form-group {
    margin-bottom: 1rem;
    @media ${device.laptop} {
      margin-bottom: 0;
    }
  }
  @media ${device.tabletS} {
    min-width: 400px;
    max-width: 400px;
  }
  .dropdown-menu {
    display: ${(props) => (props.showDropDown ? "block" : "none")};
    opacity: ${(props) => props.showDropDown} !important;
    top: 40px !important;
    pointer-events: auto !important;
    .dropdown-item {
      &:active {
        background: #f8f9fa;
        color: #000;
      }
    }
    a:not(:last-child) {
      border-bottom: 1px solid #ced4da;
    }
  }
`;
