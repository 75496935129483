import React, { PureComponent } from "react";
import {
  StyledModal,
  StyledModalContent,
  StyledModalCloseBtn,
} from "./RingSizingModal.styled";

export default class RingSizingModal extends PureComponent {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    this.wrapperRef === event.target && this.props.onClose();
  };

  render() {
    const { onClose } = this.props;
    return (
      <StyledModal ref={this.setWrapperRef}>
        <StyledModalContent>
          <h2 className="text-uppercase">Not your size?</h2>
          <p>
            We can resize your ring up or down two full sizes, that way you`ll
            get the perfect fit.
          </p>
          <p>
            <strong>
              Options will be available at checkout when you win the item.
            </strong>
          </p>
          <StyledModalCloseBtn
            variant="link"
            className="round-btn-close"
            onClick={onClose}
          >
            &times;
          </StyledModalCloseBtn>
        </StyledModalContent>
      </StyledModal>
    );
  }
}
