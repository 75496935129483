import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

import ModalProduct from "./ModalProduct";
import ModalAuction from "./ModalAuction";
import { getProducts, getPageParams } from "../selectors";
import * as actions from "../actions";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import { Pagination, Helmet, BackButton } from "storefront/features/shared";
import {
  getSearchParams,
  isVendorAccepted,
} from "storefront/features/shared/utils";
import ProductTable from "./ProductTable";
import { metaTags } from "../metaTags";
import ProductFilter from "./ProductFilter";
import withRedirectUnregisteredUser from "storefront/features/shared/components/RedirectUnregisteredUser";
import UploadFileButton from "./UploadFileButton";
import { StyledButton, StyledRow } from "./ProductsSettings.styled";
import Icon from "storefront/features/shared/icons";
import { StyledInputGroup } from "../../search/components/Search.styled";

class ProductsSettings extends PureComponent {
  state = {
    show: false,
    auctionModalShow: false,
    selectedProduct: {},
    errorMsg: undefined,
    successMsg: undefined,
    filter: "buy-now,make-an-offer,auction",
    searchValue: "",
  };

  componentDidMount() {
    const {
      userAuctionData: { vendor },
    } = this.props;
    const filter = "buy-now,make-an-offer,auction";

    vendor && this.getTableData({ filter });
  }

  componentDidUpdate(prevProps) {
    const {
      userAuctionData: { vendor_id },
    } = this.props;
    const filter = "buy-now,make-an-offer,auction";

    if (vendor_id !== prevProps.userAuctionData.vendor_id) {
      this.getTableData({ filter });
    }
  }

  filterItemHandleChange = (filter) => {
    this.setState({ filter, searchValue: "" });
    const isFirstPage = true;
    const searchValue = "";
    this.getTableData({ filter, searchValue, isFirstPage });
  };

  getTableData = (values) => {
    const { filter, searchValue, isFirstPage } = values;
    const {
      history,
      fetchProductRequest,
      userAuctionData: { vendor_id, vendor, vendor_status },
      location,
    } = this.props;
    const isVendor = isVendorAccepted(vendor, vendor_status);
    const page = isFirstPage ? "1" : getSearchParams(location.search, "page");

    if (!isVendor) {
      history.push("/");
    }

    fetchProductRequest({ vendor_id, page, filter, title: searchValue });
  };

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  resetSuccessMsg = (timeToShowMsg = 3000) => {
    setTimeout(() => this.setState({ successMsg: undefined }), timeToShowMsg);
  };

  handleResponseSuccess = (successMsg, timeToShowMsg) => {
    this.setState({ successMsg });
    this.resetSuccessMsg(timeToShowMsg);
  };

  handleDeleteProduct = (id) => {
    const {
      fetchDeleteProductRequest,
      userAuctionData: { vendor_id },
    } = this.props;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    this.setState({ errorMsg: undefined });
    fetchDeleteProductRequest({ handleErrors, handleSuccess, id, vendor_id });
  };

  handleDeleteAuction = (id) => {
    const {
      fetchDeleteAuctionRequest,
      userAuctionData: { vendor_id },
    } = this.props;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    this.setState({ errorMsg: undefined });
    fetchDeleteAuctionRequest({ handleErrors, handleSuccess, id, vendor_id });
  };

  handleModalClose = () => {
    this.setState({ show: false, filter: "buy-now,make-an-offer,auction" });
    this.setSelectedProduct();
  };

  handleModalShow = (selectedProduct) => {
    this.setState({ show: true });
    this.setSelectedProduct(selectedProduct);
  };

  handleAuctionModalClose = (successMsg) => {
    this.setState({ auctionModalShow: false, successMsg });
    this.setSelectedProduct();
    this.resetSuccessMsg();
  };

  handleAuctionModalShow = (selectedProduct) => {
    this.setState({ auctionModalShow: true });
    this.setSelectedProduct(selectedProduct);
  };

  setSelectedProduct = (selectedProduct = {}) => {
    this.setState({ selectedProduct });
  };

  pageItemHandleClick = (page) => {
    const { filter, searchValue } = this.state;
    const {
      fetchProductRequest,
      userAuctionData: { vendor_id },
    } = this.props;
    fetchProductRequest({ vendor_id, page, filter, title: searchValue });
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    const { filter, searchValue } = this.state;
    this.getTableData({ filter, searchValue });
  };

  render() {
    const {
      show,
      selectedProduct,
      errorMsg,
      successMsg,
      auctionModalShow,
      filter,
      searchValue,
    } = this.state;
    const {
      pageParams,
      products,
      userAuctionData: { vendor_id },
    } = this.props;

    return (
      <>
        <Helmet metaTags={metaTags} />
        <ModalProduct
          show={show}
          handleModalClose={this.handleModalClose}
          product={selectedProduct}
        />
        <ModalAuction
          show={auctionModalShow}
          handleModalClose={this.handleAuctionModalClose}
          product={selectedProduct}
        />
        <Container className="my-5">
          <Row className="flex-row justify-content-between align-items-center back">
            <p className="ml-md-3">
              <BackButton color="black" />
            </p>
            <p className="mr-md-3">
              Need help? Read the
              <Link to="#" data-cy="ap-back-to-account">
                {" "}
                guideline
              </Link>
            </p>
          </Row>
          <Row className="flex-column flex-lg-row justify-content-between align-items-center back">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <h1 className="ml-md-3 text-uppercase">My products</h1>
              <h6 className="ml-md-3 mb-md-3 align-self-end">
                Here are the current product on your store
              </h6>
            </div>
            <div className="d-flex flex-row">
              <p className="align-self-center">Filter by</p>
              <ProductFilter
                filterItemHandleChange={this.filterItemHandleChange}
                filter={filter}
              />
            </div>
          </Row>
          <StyledRow
            className="
              d-flex
              flex-column
              flex-lg-row
              rounded
            "
          >
            <StyledButton
              variant="success"
              onClick={this.handleModalShow}
              data-cy="add-product-btn"
              className="mr-lg-auto"
            >
              <div className="d-flex justify-content-center align-items-center">
                <Icon type="plusIcon" />
                <p className="m-0 ml-2">Add New Product</p>
              </div>
            </StyledButton>
            <UploadFileButton
              handleResponseErrors={this.handleResponseErrors}
              handleResponseSuccess={this.handleResponseSuccess}
            />
            <a href={process.env.PRODUCTS_CSV_EXAMPLE}>
              <StyledButton
                className="mr-2"
                data-cy="download-file-btn"
                variant="secondary"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Icon type="downloadIcon" color="white" />
                  <p className="m-0 ml-2">Products CSV Template</p>
                </div>
              </StyledButton>
            </a>
            <a
              href={`/api/marketplace/products/products_export?vendor_id=${vendor_id}`}
            >
              <StyledButton
                data-cy="export-products-btn"
                variant="light"
                className="mb-0"
              >
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <Icon type="downloadIcon" color="black" />
                  <p className="m-0 ml-2">Export Products</p>
                </div>
              </StyledButton>
            </a>
          </StyledRow>
          <Row>
            <Col>
              {errorMsg && (
                <p
                  className="invalid-feedback d-block mt-3"
                  data-cy="error-msg"
                >
                  {errorMsg}
                </p>
              )}
              {successMsg && (
                <p
                  className="valid-feedback d-block mt-3"
                  data-cy="success-msg"
                >
                  {successMsg}
                </p>
              )}
            </Col>
          </Row>
          <Row className="justify-content-end align-items-center">
            <Col lg={5}>
              <Form onSubmit={this.handleSearchSubmit}>
                <Form.Group>
                  <StyledInputGroup>
                    <FormControl
                      placeholder="Search"
                      value={searchValue}
                      onChange={this.handleSearchChange}
                      data-cy="searchInput"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        onClick={this.handleSearchSubmit}
                        data-cy="searchButton"
                      >
                        <Icon type="search" size={25} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </StyledInputGroup>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              {!isEmpty(products) && (
                <ProductTable
                  products={products}
                  handleModalShow={this.handleModalShow}
                  deleteProduct={this.handleDeleteProduct}
                  handleAuctionModalShow={this.handleAuctionModalShow}
                  deleteAuction={this.handleDeleteAuction}
                />
              )}
            </Col>
          </Row>
        </Container>
        {pageParams && (
          <Pagination
            pageItemHandleClick={this.pageItemHandleClick}
            pageParams={pageParams}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  userAuctionData: getAuctionData,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductRequest: (values) => {
    dispatch(actions.fetchProductRequest(values));
  },
  fetchDeleteProductRequest: (values) => {
    dispatch(actions.fetchDeleteProductRequest(values));
  },
  fetchDeleteAuctionRequest: (values) => {
    dispatch(actions.fetchDeleteAuctionRequest(values));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withRedirectUnregisteredUser
)(ProductsSettings);
