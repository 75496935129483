import React, { PureComponent } from "react";
import { StyledBadge } from "./Badge.styled";

export default class Badge extends PureComponent {
  render() {
    const { children, color, title } = this.props;
    return (
      <StyledBadge title={title} color={color}>
        {children}
      </StyledBadge>
    );
  }
}
