import React, { PureComponent } from "react";

export default class AL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <path d="M352.464,212H290.647A22.257,22.257,0,1,0,256,184.329,22.258,22.258,0,1,0,221.353,212H159.536c0,16.393,14.278,29.68,30.67,29.68h-.988A29.682,29.682,0,0,0,218.9,271.362a29.491,29.491,0,0,0,3.842,14.6l-24.619,24.621,18.888,18.888L243.8,302.672a20.438,20.438,0,0,0,3.257.9l-16.191,36.557L256,367.3l25.13-27.167-16.191-36.557a20.3,20.3,0,0,0,3.257-.9l26.794,26.794,18.888-18.888-24.621-24.621a29.494,29.494,0,0,0,3.842-14.6,29.682,29.682,0,0,0,29.682-29.682h-.988C338.186,241.681,352.464,228.393,352.464,212Z" />
        </g>
      </svg>
    );
  }
}
