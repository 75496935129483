import React, { PureComponent } from "react";

export default class NP extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path
            d="M0,426.663V85.337l280.419,174.75H87.61L271.186,426.663Z"
            fill="#0052b4"
          />
          <path
            d="M244.769,249.888,10.2,103.71V416.464h234.57L61.193,249.888Z"
            fill="#d80027"
          />
          <g>
            <path
              d="M98,324.433,83.414,317.57l7.77-14.13L75.34,306.47l-2.008-16.005L62.3,302.237,51.261,290.465,49.253,306.47,33.41,303.439l7.77,14.131-14.591,6.863L41.18,331.3l-7.77,14.13L49.254,342.4l2.007,16L62.3,346.628,73.332,358.4l2.008-16,15.843,3.03L83.414,331.3Z"
              fill="#f0f0f0"
            />
            <path
              d="M88.268,191.662l-10.612-4.991,5.651-10.278L71.784,178.6l-1.461-11.641L62.3,175.518l-8.026-8.561L52.811,178.6l-11.525-2.205,5.652,10.278-10.613,4.991L62.3,196.856Z"
              fill="#f0f0f0"
            />
            <path
              d="M93.462,191.662a31.165,31.165,0,0,1-62.33,0"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
