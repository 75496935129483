import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "moment";
import { moneyFormat } from "storefront/features/shared/utils";

class Orders extends PureComponent {
  render() {
    const {
        orders: { edges },
      } = this.props,
      OrdersList = () => (
        <tbody>
          {edges.map((order) => {
            const {
                node: {
                  id,
                  name,
                  processedAt,
                  financialStatus,
                  fulfillmentStatus,
                  totalPriceV2,
                },
              } = order,
              orderDate = new Date(processedAt);

            return (
              <tr key={id} data-cy="ap-orders-list-row">
                <td className="d-none d-md-table-cell">
                  <Link
                    to={`/account/order?${id}`}
                    orderid={id}
                    data-cy="ap-order-details-link"
                  >
                    {name}
                  </Link>
                </td>
                <td className="d-none d-md-table-cell">
                  {Moment(orderDate).format("LL")}
                </td>
                <td className="d-none d-md-table-cell">{financialStatus}</td>
                <td className="d-none d-md-table-cell">{fulfillmentStatus}</td>
                <td className="text-right d-none d-md-table-cell">
                  {moneyFormat(Math.round(totalPriceV2.amount))}
                </td>
                <td className="d-table-cell d-md-none">
                  <p>
                    <b>Order</b> -{" "}
                    <Link to={`/account/order?${id}`} orderid={id}>
                      {name}
                    </Link>
                  </p>
                  <p>
                    <b>Date</b> - {Moment(orderDate).format("LL")}
                  </p>
                  <p>
                    <b>Payment status</b> - {financialStatus}
                  </p>
                  <p>
                    <b>Fulfillment status</b> - {fulfillmentStatus}
                  </p>
                  <p className="d-flex justify-content-between">
                    <b>Total - </b>{" "}
                    <b className="total-order-amount">
                      {moneyFormat(Math.round(totalPriceV2.amount))}
                    </b>
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      ),
      NoOrders = () => (
        <tbody>
          <tr>
            <td colSpan="5" className="text-center">
              You have no orders yet!
            </td>
          </tr>
        </tbody>
      );

    return (
      <Table hover data-cy="ap-orders-list">
        <thead>
          <tr className="d-none d-md-table-row">
            <th>Order</th>
            <th>Date</th>
            <th>Payment status</th>
            <th>Fulfillment status</th>
            <th className="text-right">Total</th>
          </tr>
        </thead>
        {edges ? <OrdersList /> : <NoOrders />}
      </Table>
    );
  }
}

export default Orders;
