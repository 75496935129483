import React, { PureComponent } from "react";

export default class TH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.331"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="123.882"
            transform="translate(0 194.056)"
            fill="#0052b4"
          />
          <g>
            <rect
              width="512"
              height="54.522"
              transform="translate(0 85.334)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="54.522"
              transform="translate(0 372.143)"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
