import React, { PureComponent } from "react";

export default class GI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.882)"
              fill="#d80027"
            />
            <path
              d="M322.783,226.313V204.052h7.42v-14.84H315.362v7.419h-14.84v-7.419h-14.84v14.84H293.1v22.261h-14.84v-51.94h7.421V159.531H270.84v7.42h-7.419v-7.42H248.579v7.42H241.16v-7.42H226.318v14.842h7.421v51.94H218.9V204.052h7.419v-14.84h-14.84v7.419h-14.84v-7.419H181.8v14.84h7.42v22.261h-14.84v59.362H337.623V226.313Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M256,278.255a22.262,22.262,0,0,0-7.421,43.25v30.953H226.318V382.14h37.1V321.506A22.262,22.262,0,0,0,256,278.255Zm0,29.681a7.421,7.421,0,1,1,7.421-7.421A7.421,7.421,0,0,1,256,307.936Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
