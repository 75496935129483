import { fromJS } from "immutable";
import { LOCATION_CHANGE } from "connected-react-router";
import * as actions from "./actions";

import {
  getCustomerData,
  mergeUserAuctionData,
} from "storefront/features/shared/utils";

const initialState = fromJS({
  open: false,
  drawer: false,
  customer: {},
  redirectTo: "",
  userAuctionData: {},
  checkout: {},
});

const setMenuVisibility = (state, value) => state.set("open", value);

const setMobileMenuVisibility = (state, value) => state.set("drawer", value);

const setBothMenuVisibility = (state, value) =>
  state.set("open", value).set("drawer", value);

const getCustomer = (state, value) =>
  state.set("customer", fromJS(getCustomerData(value)));

const setRedirectTo = (state, value) => state.set("redirectTo", value);

const setUserAuctionData = (state, value) =>
  state.set("userAuctionData", fromJS(mergeUserAuctionData(value)));

const updateUserAuctionData = (state, value) =>
  state.setIn(["userAuctionData", "bid_auctions"], fromJS(value));

const setCheckout = (state, value) => {
  return state.set("checkout", fromJS(value));
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MENU_TOGGLE:
      return setMenuVisibility(state, action.payload);
    case actions.MOBILE_MENU_TOGGLE:
      return setMobileMenuVisibility(state, action.payload);
    case LOCATION_CHANGE:
      return setBothMenuVisibility(state, undefined);
    case actions.FETCH_CUSTOMER_REQUEST_RESULT:
      return getCustomer(state, action.payload);
    case actions.SET_REDIRECT_TO:
      return setRedirectTo(state, action.payload);
    case actions.FETCH_USER_AUCTION_DATA_REQUEST_RESULT:
      return setUserAuctionData(state, action.payload);
    case actions.UPDATE_USER_AUCTION_DATA:
      return updateUserAuctionData(state, action.payload);
    case actions.CREATE_CHECKOUT_REQUEST_RESULT:
      return setCheckout(state, action.payload);
    default:
      return state;
  }
};

export default headerReducer;
