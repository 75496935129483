import React, { PureComponent } from "react";
import { compose } from "redux";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import * as actions from "../actions";
import {
  QUICK_VIEW_LOADER,
  getSearchParams,
} from "storefront/features/shared/utils";
import { getProduct } from "../selectors";
import { getMetaTags } from "../metaTags";
import QuickViewProduct from "./QuickViewProduct";
import { StyledModal } from "./ModalProductView.styled";
import { Helmet } from "storefront/features/shared";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { getAuctionData } from "../../shared/components/Header/selectors";

class ModalProductView extends PureComponent {
  state = {
    show: true,
  };

  componentDidMount() {
    const { location, fetchProduct } = this.props;
    const handle = location.pathname.split("/").pop();
    const auctionId = getSearchParams(location.search, "auction_id");
    fetchProduct(handle, auctionId, QUICK_VIEW_LOADER);
  }

  handleClose = () => {
    const { history } = this.props;
    this.setState({ show: false });
    const pageNumber = getSearchParams(history.location.search, "page");
    const searchTitle = getSearchParams(location.search, "title");
    const buyingFormat = getSearchParams(location.search, "buying_format");
    const pathName = history.location.pathname;
    const regex = /^.*(?=(\/quick-view))/;
    const matchPathName = pathName.match(regex)[0];
    const homeLocation = pageNumber > 1 ? `/?page=${pageNumber}` : `/`;
    const pageLocation =
      pageNumber > 1 ? `${matchPathName}?page=${pageNumber}` : matchPathName;
    const characterIfPageExist = pageNumber > 1 ? "&" : "?";
    const searchLocation =
      searchTitle && buyingFormat
        ? `${pageLocation}${characterIfPageExist}title=${searchTitle}&buying_format=${buyingFormat}`
        : pageLocation;

    isEmpty(matchPathName)
      ? history.push(homeLocation)
      : history.push(searchLocation);
  };

  render() {
    const { show } = this.state;
    const {
      product,
      userAuctionData: { vendor },
    } = this.props;
    const metaTags = getMetaTags(product);
    return (
      <>
        <Helmet metaTags={metaTags} />
        <StyledModal
          show={show}
          onHide={this.handleClose}
          size="lg"
          data-cy="modal-quick-view"
        >
          <Modal.Header closeButton className="border-0" />
          <Modal.Body className="show-grid p-0 p-sm-2 p-lg-4">
            <WithLoader
              loader={QUICK_VIEW_LOADER}
              forceLoading={isEmpty(product)}
            >
              <QuickViewProduct product={product} vendor={vendor} />
            </WithLoader>
          </Modal.Body>
        </StyledModal>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  product: getProduct,
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProduct: (handle, auctionId, loader) => {
    dispatch(actions.fetchProductRequest({ handle, auctionId, loader }));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(ModalProductView);
