export const FETCH_ALL_PRODUCTS_REQUEST =
  "bid_auctions/FETCH_ALL_PRODUCTS_REQUEST";

export const FETCH_PRODUCTS_FOR_HOME_REQUEST =
  "bid_auctions/FETCH_PRODUCTS_FOR_HOME_REQUEST";

export const FETCH_ALL_AUCTION_PRODUCTS_REQUEST_RESULT =
  "bid_auctions/FETCH_ALL_AUCTION_PRODUCTS_REQUEST_RESULT";

export const FETCH_BUY_IT_NOW_REQUEST = "bid_auctions/FETCH_BUY_IT_NOW_REQUEST";

export const FETCH_MAKE_AN_OFFER_REQUEST =
  "bid_auctions/FETCH_MAKE_AN_OFFER_REQUEST";

export const FETCH_ALL_PRODUCTS_REQUEST_RESULT =
  "bid_auctions/FETCH_ALL_PRODUCTS_REQUEST_RESULT";

export const POST_ANONYMOUS_BID = "bid_auctions/POST_ANONYMOUS_BID";

export const UPDATE_PRODUCT = "bid_auctions/UPDATE_PRODUCT";

export const FETCH_FILTERED_AUCTIONS_REQUEST =
  "bid_auctions/FETCH_FILTERED_AUCTIONS_REQUEST";

export const FETCH_CUSTOMER_CONTACT_FORM_SUBMIT =
  "bid_auctions/FETCH_CUSTOMER_CONTACT_FORM_SUBMIT";

export const FETCH_STATIC_PAGE_FROM_PRISMIC =
  "bid_auctions/FETCH_STATIC_PAGE_FROM_PRISMIC";

export const SAVE_STATIC_PAGE_FROM_PRISMIC =
  "bid_auctions/SAVE_STATIC_PAGE_FROM_PRISMIC";

export const REMOVE_LOADER = "bid_auctions/REMOVE_LOADER";

export const FETCH_VENDOR_PRODUCTS_REQUEST =
  "bid_auctions/FETCH_VENDOR_PRODUCTS_REQUEST";

export const FETCH_VENDOR_PRODUCTS_REQUEST_RESULT =
  "bid_auctions/FETCH_VENDOR_PRODUCTS_REQUEST_RESULT";

export function fetchAllProductsRequest(params) {
  return { type: FETCH_ALL_PRODUCTS_REQUEST, payload: params };
}

export function fetchProductsForHomeRequest(params) {
  return { type: FETCH_PRODUCTS_FOR_HOME_REQUEST, payload: params };
}

export function fetchAllAuctionProductsRequestResult(response) {
  return { type: FETCH_ALL_AUCTION_PRODUCTS_REQUEST_RESULT, payload: response };
}

export function fetchBuyItNowRequest(params) {
  return { type: FETCH_BUY_IT_NOW_REQUEST, payload: params };
}

export function fetchMakeAnOfferRequest(params) {
  return { type: FETCH_MAKE_AN_OFFER_REQUEST, payload: params };
}

export function fetchAllProductsRequestResult(response) {
  return { type: FETCH_ALL_PRODUCTS_REQUEST_RESULT, payload: response };
}

export function updateProduct(params) {
  return { type: UPDATE_PRODUCT, payload: params };
}

export function postBidRequest(data) {
  return { type: POST_ANONYMOUS_BID, payload: data };
}

export function fetchFilteredAuctionsRequest(params) {
  return { type: FETCH_FILTERED_AUCTIONS_REQUEST, payload: params };
}

export function fetchCustomerContactFormSubmit(data) {
  return { type: FETCH_CUSTOMER_CONTACT_FORM_SUBMIT, payload: data };
}

export function fetchStaticPageFromPrismic(data) {
  return { type: FETCH_STATIC_PAGE_FROM_PRISMIC, payload: data };
}

export function saveStaticPageFromPrismic(data) {
  return { type: SAVE_STATIC_PAGE_FROM_PRISMIC, payload: data };
}

export function removeLoader(data) {
  return { type: REMOVE_LOADER, payload: data };
}

export const fetchVendorProductsRequest = (params) => {
  return { type: FETCH_VENDOR_PRODUCTS_REQUEST, payload: params };
};

export const fetchVendorProductsRequestResult = (response) => {
  return { type: FETCH_VENDOR_PRODUCTS_REQUEST_RESULT, payload: response };
};
