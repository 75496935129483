import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";

import * as actions from "./actions";

export function* fetchVendorDataRequest(action) {
  const request = () => superagent.get(`/api/vendors/${action.payload}`);
  const redirectLink = `/`;
  try {
    const response = yield call(request);
    if (response?.body?.state === "accepted") {
      yield put(actions.fetchVendorDataRequestResult(response.body));
    } else {
      yield window.location.replace(redirectLink);
    }
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchVendorSettingsRequest(action) {
  const request = () => superagent.get(`/api/vendors/${action.payload}`);
  try {
    const response = yield call(request);
    yield put(actions.fetchVendorSettingsRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchVendorNamesRequest() {
  const request = () => superagent.get(`/api/suggestion/vendors`);
  try {
    const response = yield call(request);
    yield put(actions.fetchVendorNamesRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchVendorSettingsSaveRequest(action) {
  const { slug, data, handleErrors, handleSuccess } = action.payload;
  const requestBody = { vendor: data };
  const request = () =>
    superagent.put(`/api/vendors/${slug}`).send(requestBody);
  try {
    const response = yield call(request);
    yield put(actions.fetchVendorSettingsRequestResult(response.body));
    handleSuccess("Your store info has been updated");
  } catch (res) {
    handleErrors("Your store info has not been updated");
    console.log(`error - ${res}`);
  }
}

export function* fetchChangeVendorLogoRequest(action) {
  const { logo, slug, handleErrors, handleSuccess } = action.payload;
  const request = () =>
    superagent
      .post(`/api/vendors/save_logo`)
      .field("id", slug)
      .attach("logo", logo);
  try {
    yield call(request);
    handleSuccess("Your store logo has been updated");
  } catch (res) {
    handleErrors("Your store logo has not been updated");
  }
}

export function* fetchDeleteVendorLogoRequest(action) {
  const { slug, handleErrors, handleSuccess } = action.payload;
  const request = () =>
    superagent
      .post(`/api/vendors/save_logo`)
      .field("id", slug)
      .attach("logo", "");
  try {
    yield call(request);
    handleSuccess("Your store logo has been deleted");
  } catch (res) {
    handleErrors("Your store logo has not been deleted");
  }
}

export default [
  takeLatest(actions.FETCH_VENDOR_DATA_REQUEST, fetchVendorDataRequest),
  takeLatest(actions.FETCH_VENDOR_SETTINGS_REQUEST, fetchVendorSettingsRequest),
  takeLatest(actions.FETCH_VENDOR_NAMES_REQUEST, fetchVendorNamesRequest),
  takeLatest(
    actions.FETCH_VENDOR_SETTINGS_SAVE_REQUEST,
    fetchVendorSettingsSaveRequest
  ),
  takeLatest(
    actions.FETCH_CHANGE_VENDOR_LOGO_REQUEST,
    fetchChangeVendorLogoRequest
  ),
  takeLatest(
    actions.FETCH_DELETE_VENDOR_LOGO_REQUEST,
    fetchDeleteVendorLogoRequest
  ),
];
