import React from "react";
import { ConnectedRouter } from "connected-react-router/immutable";
import { Provider } from "react-redux";
import { Switch, Route } from "react-router-dom";

import configureStore from "./config/configureStore";
import { createHistory } from "./config/history";
import { appRoutes, quickViewRoutes } from "./config/utils";
import { Header, Footer } from "./features/shared/";
import { PrismicBanner, PrismicStripeBanner } from "./features/prismicBanner";

import "bootstrap/dist/css/bootstrap.css";
import GlobalStylesWrapper from "./features/shared/styles/GlobalStylesWrapper";

export default (props, railsContext) => {
  const history = createHistory(railsContext);

  const mapRoute = (routeKey) => {
    const { path, exact, component } =
      appRoutes[routeKey] || quickViewRoutes[routeKey];
    return (
      <Route key={routeKey} path={path} exact={exact} component={component} />
    );
  };

  const store = configureStore(props, history);
  const appComponent = () => (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <>
          <GlobalStylesWrapper />
          <PrismicBanner />
          <PrismicStripeBanner />
          <Header />
          <Switch>{Object.keys(appRoutes).map(mapRoute)}</Switch>
          {Object.keys(quickViewRoutes).map(mapRoute)}
          <Footer />
        </>
      </ConnectedRouter>
    </Provider>
  );
  return appComponent;
};
