import React, { PureComponent } from "react";
import { Button, Form as BSForm } from "react-bootstrap";
import { OnChange } from "react-final-form-listeners";
import { isEqual } from "lodash";

import { Field } from "storefront/features/shared";
import VariantImageForm from "./VariantImageForm";
import { IconWrapper } from "./ProductImageForm.styled";
import Icon from "storefront/features/shared/icons";
import { getSplittedVariant } from "../utils";

export class ProductVariants extends PureComponent {
  state = {
    variantData: {
      options: [],
      image: { src: "" },
      price: 0,
      compare_at_price: 0,
      quantity: 0,
      sku: "",
      deprecated: false,
    },
  };

  componentDidMount() {
    const {
      variantData: {
        price = 0,
        compare_at_price = 0,
        quantity = 0,
        sku = "",
        image,
      },
      variant = "",
    } = this.props;
    const options = getSplittedVariant(variant);
    const initVariantData = {
      options,
      image,
      price,
      compare_at_price,
      quantity,
      sku,
      deprecated: false,
    };
    this.setState({ variantData: initVariantData });
  }

  componentDidUpdate(prevProps, prevState) {
    const { variantData } = this.state;
    const { handleVariantChange, positionNumber } = this.props;
    if (!isEqual(variantData, prevState.variantData)) {
      handleVariantChange(variantData, positionNumber);
    }
  }

  deprecateVariant = () => {
    const {
      variantData,
      variantData: { deprecated },
    } = this.state;
    const deprecatedValue = !deprecated;
    const variantDataArr = {
      ...variantData,
      deprecated: deprecatedValue,
    };
    this.setState({
      variantData: variantDataArr,
    });
  };

  addVariantImage = (image) => {
    const { variantData } = this.state;
    const { addVariantsImage, positionNumber } = this.props;
    const variantDataArr = { ...variantData, image };
    this.setState({
      variantData: variantDataArr,
    });
    addVariantsImage(image, positionNumber);
  };

  deleteVariantImage = () => {
    const { variantData } = this.state;
    const { deleteVariantsImage, positionNumber } = this.props;
    const variantDataArr = { ...variantData, image: { src: "" } };
    this.setState({
      variantData: variantDataArr,
    });
    deleteVariantsImage(positionNumber);
  };

  handleChange = (value, key) => {
    const { variantData } = this.state;
    const variantDataArr = { ...variantData, [key]: value };
    this.setState({
      variantData: variantDataArr,
    });
  };

  render() {
    const {
      variantData: { deprecated },
    } = this.state;
    const {
      variant,
      index,
      variantData: {
        price = 0,
        compare_at_price = 0,
        quantity = 0,
        sku = "",
        image = { src: "" },
      },
    } = this.props;
    return (
      <>
        {deprecated ? (
          <tr data-cy="variant-row">
            <td className="align-middle pr-1" data-cy="deprecated-variant-td">
              {variant}
            </td>
            <td
              className="align-middle px-1"
              colSpan="5"
              data-cy="variant-not-created"
            >
              This variant will not be created
            </td>
            <td className="align-middle pl-1">
              <Button
                variant="link"
                className="p-0"
                onClick={this.deprecateVariant}
                data-cy="undo-variant-btn"
              >
                Undo
              </Button>
            </td>
          </tr>
        ) : (
          <tr>
            <td className="align-middle pr-1" data-cy="variant-td">
              {variant}
            </td>
            <td className="align-middle px-1">
              <VariantImageForm
                index={index}
                addVariantImage={this.addVariantImage}
                deleteVariantImage={this.deleteVariantImage}
                imagePreviewUrl={image.src}
              />
            </td>
            <td className="align-middle px-1">
              <BSForm.Group className="mb-0">
                <Field
                  name={`price-${index}`}
                  type="number"
                  placeholder="Price"
                  initialValue={price}
                  min="0"
                  step="0.1"
                  data-cy="variant-price"
                  required
                />
                <OnChange name={`price-${index}`}>
                  {(value) => {
                    this.handleChange(Number(value), "price");
                  }}
                </OnChange>
              </BSForm.Group>
            </td>
            <td className="align-middle px-1">
              <BSForm.Group className="mb-0">
                <Field
                  name={`compare_at_price-${index}`}
                  type="number"
                  placeholder="Compare at price"
                  initialValue={compare_at_price}
                  min="0"
                  step="0.1"
                  data-cy="variant-compare-at-price"
                  required
                />
                <OnChange name={`compare_at_price-${index}`}>
                  {(value) => {
                    this.handleChange(Number(value), "compare_at_price");
                  }}
                </OnChange>
              </BSForm.Group>
            </td>
            <td className="align-middle px-1">
              <BSForm.Group className="mb-0">
                <Field
                  name={`quantity-${index}`}
                  type="number"
                  placeholder="Quantity"
                  initialValue={quantity}
                  min="0"
                  step="1"
                  data-cy="variant-quantity"
                  required
                />
                <OnChange name={`quantity-${index}`}>
                  {(value) => {
                    this.handleChange(Number(value), "quantity");
                  }}
                </OnChange>
              </BSForm.Group>
            </td>
            <td className="align-middle px-1">
              <BSForm.Group className="mb-0">
                <Field
                  name={`sku-${index}`}
                  type="text"
                  data-cy="variant-sku"
                  initialValue={sku}
                  required
                />
                <OnChange name={`sku-${index}`}>
                  {(value) => {
                    this.handleChange(value, "sku");
                  }}
                </OnChange>
              </BSForm.Group>
            </td>
            <td className="align-middle pl-1">
              <IconWrapper
                className="rounded"
                onClick={this.deprecateVariant}
                data-cy="remove-variant-icon"
              >
                <Icon type="trashIcon" size={40} />
              </IconWrapper>
            </td>
          </tr>
        )}
      </>
    );
  }
}

export default ProductVariants;
