import React, { PureComponent } from "react";

export default class VendorRegisterIcon extends PureComponent {
  render() {
    return (
      <svg height="20px" width="30px" x="0px" y="0px" viewBox="0 0 85 100">
        <path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" />
        <path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z" />
        <path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" />
        <path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" />
        <path d="M65,51.5c-2.6-4.7-7.1-8.2-12.5-9.5c-3.7,2.1-7.9,3.3-12.4,3.3c-4.5,0-8.8-1.2-12.4-3.3c-8.6,2-15,9.7-15,18.8v9.6     c0,2.2,1.8,4,4,4h24.6c0,12.7,10.3,23,23,23c12.7,0,23-10.3,23-23C87.3,62,77.4,51.9,65,51.5z M64.3,92.2     c-9.8,0-17.7-8-17.7-17.7c0-9.8,8-17.7,17.7-17.7c9.8,0,17.7,8,17.7,17.7C82,84.2,74.1,92.2,64.3,92.2z" />
        <path d="M65,72.3l-1.6,0c-1,0-1.9-0.7-2-1.6c-0.2-1.2,0.7-2.3,1.9-2.3h2.2c0.6,0,1.2,0.4,1.3,1c0.1,0.2,0.2,0.4,0.5,0.4h3.5     c0.3,0,0.5-0.2,0.5-0.5c-0.2-2.6-2.2-4.6-4.8-5.1V61c0-0.3-0.2-0.5-0.5-0.5h-3.5c-0.3,0-0.5,0.2-0.5,0.5v3.1     c-3,0.6-5.3,3.4-5.1,6.6c0.2,3.4,3.2,5.9,6.6,5.9h1.3c1.2,0,2.1,0.7,2.2,1.6c0.2,1.2-0.7,2.3-1.9,2.3h-2.2c-0.6,0-1.2-0.4-1.3-1     c-0.1-0.2-0.2-0.4-0.5-0.4h-3.5c-0.3,0-0.5,0.2-0.5,0.5c0.2,2.6,2.2,4.6,4.8,5.1v3.2c0,0.3,0.2,0.5,0.5,0.5H66     c0.3,0,0.5-0.2,0.5-0.5v-3.1c3-0.6,5.3-3.4,5.1-6.6C71.4,74.8,68.4,72.3,65,72.3z" />
        <circle cx="40.1" cy="20.2" r="17.7" />
      </svg>
    );
  }
}
