import React, { PureComponent } from "react";

export default class GE extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M288,85.33H224V224H0v64H224V426.662h64V288H512V224H288Z"
              fill="#d80027"
            />
            <path
              d="M123.13,143.534V121.273H100.87v22.261H78.609v22.261H100.87v22.261h22.26V165.795h22.261V143.534Z"
              fill="#d80027"
            />
            <path
              d="M411.13,143.534V121.273H388.87v22.261H366.609v22.261H388.87v22.261h22.26V165.795h22.261V143.534Z"
              fill="#d80027"
            />
            <path
              d="M123.13,346.2V323.936H100.87V346.2H78.609v22.261H100.87v22.261h22.26V368.458h22.261V346.2Z"
              fill="#d80027"
            />
            <path
              d="M411.13,346.2V323.936H388.87V346.2H366.609v22.261H388.87v22.261h22.26V368.458h22.261V346.2Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
