import React, { PureComponent } from "react";

export default class WS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M165.483,181.79l2.764,8.5h8.939l-7.232,5.254,2.763,8.5-7.234-5.255-7.233,5.255,2.763-8.5-7.233-5.254h8.94Z"
              fill="#f0f0f0"
            />
            <path
              d="M120.579,115.007l4.606,14.173h14.9l-12.055,8.757,4.6,14.171-12.056-8.758-12.054,8.758,4.6-14.171-12.056-8.757h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M165.1,129.848l4.606,14.172h14.9l-12.055,8.757,4.6,14.173L165.1,158.19l-12.054,8.76,4.6-14.173L145.6,144.02h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M129.909,189.211l4.606,14.172h14.9l-12.055,8.758,4.6,14.171-12.055-8.759-12.054,8.759,4.6-14.171L110.4,203.383h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M90.9,152.108l4.6,14.173h14.9l-12.055,8.757,4.6,14.173L90.9,180.451l-12.055,8.76,4.6-14.173-12.055-8.757h14.9Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
