import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";

import { getVendorInfo } from "storefront/features/shared/selectors";

const withRedirectBlockedVendor = (WrappedComponent) => {
  class RedirectBlockedVendor extends PureComponent {
    componentDidMount() {
      this.redirectUser();
    }

    componentDidUpdate() {
      this.redirectUser();
    }

    redirectUser = () => {
      const { history, vendorInfo } = this.props;
      vendorInfo?.state === "blocked" && history.push("/");
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return RedirectBlockedVendor;
};

const mapStateToProps = createStructuredSelector({
  vendorInfo: getVendorInfo,
});

export { withRedirectBlockedVendor };

export default compose(
  connect(mapStateToProps, null),
  withImmutablePropsToJS,
  withRouter,
  withRedirectBlockedVendor
);
