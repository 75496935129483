import React, { PureComponent } from "react";
import { Container } from "react-bootstrap";
import { PaymentIconsList } from "storefront/features/shared/components/PaymentIconsList";

export default class FooterPromo extends PureComponent {
  render() {
    return (
      <Container fluid>
        <PaymentIconsList />
        {/* <Row>
          <Col className="text-center my-3" id="footer-bbb-logo">
            <BBBLogo width={200} />
          </Col>
        </Row> */}
      </Container>
    );
  }
}
