import styled from "styled-components";
import { InputGroup } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledInput = styled.input`
  text-align: center;
  height: 50px;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  &:focus,
  &:active {
    outline: none;
  }
  @media ${device.tabletL} {
    width: 170px;
    padding-left: 12px;
  }
`;
export const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    background-color: #fff;
  }
`;
