import React from "react";
import { Pagination } from "react-bootstrap";
import { isEmpty } from "lodash";

class PageItem extends React.Component {
  handleClick = (page) => {
    const { onClick } = this.props;
    onClick(page);
  };

  generateId = (text) => {
    switch (text) {
      case "...":
        return "more";
      case "⟩":
        return "next";
      case "⟨":
        return "prev";
      default:
        return text;
    }
  };

  render() {
    const { text, page, className } = this.props;
    const pageItemClass = isEmpty(className) ? "" : className;
    return (
      <Pagination.Item
        id={`pagination-${this.generateId(text)}`}
        onClick={() => this.handleClick(page)}
        className={`page-item ${pageItemClass}`}
      >
        {text}
      </Pagination.Item>
    );
  }
}

export default PageItem;
