import React, { PureComponent } from "react";

export default class NR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="32"
            transform="translate(0 239.994)"
            fill="#ffda44"
          />
          <path
            d="M174.8,341.329l-19.124,9,10.184,18.521L145.1,364.873l-2.631,20.978L128,370.422l-14.465,15.429-2.63-20.978-20.767,3.973,10.183-18.521-19.123-9,19.124-8.995-10.184-18.52,20.766,3.971,2.632-20.978L128,312.236l14.465-15.429,2.63,20.978,20.767-3.971-10.183,18.521Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
