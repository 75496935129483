import React, { PureComponent } from "react";

export default class HK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <g>
            <path
              d="M273.621,214.44c-3.878,16.132-10.77,13.056-14.152,27.125a37.1,37.1,0,0,1,17.344-72.149C270.048,197.555,277.09,200.011,273.621,214.44Z"
              fill="#f0f0f0"
            />
            <path
              d="M221.924,226.394c14.145,8.674,9.088,14.278,21.423,21.842a37.1,37.1,0,0,1-63.256-38.791C204.762,224.574,209.272,218.636,221.924,226.394Z"
              fill="#f0f0f0"
            />
            <path
              d="M217.318,279.255c12.621-10.772,16.387-4.232,27.393-13.626A37.1,37.1,0,1,1,188.273,313.8C210.284,295.016,206.03,288.89,217.318,279.255Z"
              fill="#f0f0f0"
            />
            <path
              d="M266.17,299.971c-6.347-15.331,1.037-16.894-4.494-30.263a37.1,37.1,0,1,1,28.376,68.564C278.984,311.532,271.845,313.685,266.17,299.971Z"
              fill="#f0f0f0"
            />
            <path
              d="M300.967,259.912c-16.542,1.3-15.747-6.209-30.171-5.077a37.1,37.1,0,1,1,73.976-5.8C315.921,251.3,315.76,258.753,300.967,259.912Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
