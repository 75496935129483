import styled from "styled-components";
import { Container } from "react-bootstrap";

export const StyledContainer = styled(Container)`
  .add-new-address {
    color: #454545;
  }
  .no-addresses-text {
    margin-bottom: 6rem;
  }
`;
