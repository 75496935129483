import React, { PureComponent } from "react";

export default class BN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <path d="M0,199.078,512,406.261v-46.67L273.232,265.2,0,152.412Z" />
          <path d="M512,359.588V312.923L0,105.739v46.673" fill="#f0f0f0" />
          <g>
            <path
              d="M304.224,237.452a48.224,48.224,0,1,1-90.652-22.94,59.353,59.353,0,1,0,84.856,0A48,48,0,0,1,304.224,237.452Z"
              fill="#d80027"
            />
            <rect
              width="22.258"
              height="111.282"
              transform="translate(244.87 181.805)"
              fill="#d80027"
            />
            <path
              d="M293.1,204.066H218.9a9.543,9.543,0,0,0,9.583,9.274h-.309a9.275,9.275,0,0,0,9.274,9.274,9.275,9.275,0,0,0,9.274,9.274h18.548a9.274,9.274,0,0,0,9.274-9.274,9.274,9.274,0,0,0,9.274-9.274h-.308A9.542,9.542,0,0,0,293.1,204.066Z"
              fill="#d80027"
            />
            <rect
              width="103.869"
              height="22.258"
              transform="translate(204.065 300.511)"
              fill="#d80027"
            />
            <rect
              width="44.514"
              height="22.258"
              transform="translate(278.261 307.935)"
              fill="#d80027"
            />
            <rect
              width="44.514"
              height="22.258"
              transform="translate(189.229 307.935)"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
