import React, { PureComponent } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { getCustomerOrderByID, getCustomerBillingAddress } from "../selectors";
import Moment from "moment";

import SingleOrderAddress from "./SingleOrderAddress";
import SingleOrderData from "./SingleOrderData";

class SingleOrder extends PureComponent {
  render() {
    const { order, customerBillingAddress } = this.props;
    return (
      <Container>
        <Row className="my-5">
          <Col>
            <Link to="/account/my-account">
              <Button
                variant="primary"
                className="text-uppercase"
                data-cy="op-back-to-account"
              >
                {`<    Back to account `}
              </Button>
            </Link>
            {order ? (
              <div>
                <h1 className="text-uppercase mt-4">{`Order ${order.name}`}</h1>
                <p className="mt-4">{`Order placed on ${Moment(
                  order.processedAt
                ).calendar()}`}</p>
              </div>
            ) : (
              <p>Loading ...</p>
            )}
          </Col>
        </Row>
        {order && (
          <Row className="my-5" data-cy="op-order-info">
            <Col lg={9}>
              <SingleOrderData
                orderItems={order.lineItems.edges}
                subtotal={order.subtotalPriceV2.amount}
                totalTax={order.totalTaxV2.amount}
                totalShipping={order.totalShippingPriceV2.amount}
                totalPrice={order.totalPriceV2.amount}
              />
            </Col>
            <Col lg={3} className="mt-5 mt-md-0 pl-md-3">
              <SingleOrderAddress
                address={order.shippingAddress}
                type={`shipping`}
              />
              <SingleOrderAddress
                address={customerBillingAddress}
                type={`billing`}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, location) => ({
  order: getCustomerOrderByID(state, location),
  customerBillingAddress: getCustomerBillingAddress(state),
});

export default connect(mapStateToProps)(
  withImmutablePropsToJS(withRouter(SingleOrder))
);
