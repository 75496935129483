import React, { PureComponent } from "react";

export default class NF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#6da544"
            />
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#6da544"
            />
            <path
              d="M304,311.995l-48-152-48,152h36v40h24v-40Z"
              fill="#6da544"
            />
          </g>
        </g>
      </svg>
    );
  }
}
