import React, { PureComponent } from "react";

export default class LK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <path
            d="M0,85.329V426.666H512V85.329ZM489.739,404.4H222.609V107.59h267.13Z"
            fill="#ffda44"
          />
          <rect
            width="267.13"
            height="296.815"
            transform="translate(222.609 107.595)"
            fill="#a2001d"
          />
          <rect
            width="200.348"
            height="296.815"
            transform="translate(22.261 107.595)"
            fill="#d80027"
          />
          <rect
            width="22.261"
            height="341.337"
            transform="translate(200.348 85.334)"
            fill="#ffda44"
          />
          <rect
            width="89.043"
            height="296.815"
            transform="translate(22.261 107.595)"
            fill="#6da544"
          />
          <rect
            width="89.043"
            height="296.815"
            transform="translate(111.304 107.595)"
            fill="#ff9811"
          />
          <g>
            <path
              d="M358.675,250.438V205.916c0-7.424-11.13-14.848-11.13-14.848s-11.13,7.424-11.13,14.848H291.893v44.522h22.261V272.7a35.43,35.43,0,0,0,2.76,13.768L306.73,294.96H277.056V157.677a22.262,22.262,0,0,0-22.261,22.261V276.4a22.19,22.19,0,0,0,7.413,16.584V328.35h14.848V309.8h66.783v29.685H328.99v14.837h29.685V324.633l13.6-14.837h44.288v29.685H401.716v14.837H431.4V324.633L445,309.8l12.552-40.259-11.038-19.1H407.571C393.4,250.315,366.934,250.527,358.675,250.438Z"
              fill="#ffda44"
            />
            <path
              d="M370.551,211.844v25.229h71.513v13.365h4.452V232.621H375V216.3h75.965v-4.452Z"
              fill="#ffda44"
            />
            <rect
              width="22.261"
              height="22.261"
              transform="translate(222.609 107.595)"
              fill="#ffda44"
            />
            <rect
              width="22.261"
              height="22.261"
              transform="translate(467.478 107.595)"
              fill="#ffda44"
            />
            <rect
              width="22.261"
              height="22.261"
              transform="translate(222.609 382.138)"
              fill="#ffda44"
            />
            <rect
              width="22.261"
              height="22.261"
              transform="translate(467.478 382.138)"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
