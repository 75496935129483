import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";

export default class SuccessRecoverRequest extends PureComponent {
  render() {
    return (
      <Container>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>
              Recover password proceed
              <br /> successfully initiated.
            </h1>
            <p>
              Please check your mailbox, and follow the instructions from the
              letter.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
