import styled from "styled-components";
import { Container, Col } from "react-bootstrap";

const setContentColor = ({ contentcolor }) =>
  contentcolor ? `#${contentcolor}` : "#000";

export const StyledPrismicBannerContainer = styled(Container)`
  background-color: ${(props) =>
    props.backgroundcolor ? `#${props.backgroundcolor}` : "#fff"};
  height: 40px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  a {
    color: ${(props) => setContentColor(props)};
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;

export const StyledColumn = styled(Col)`
  color: ${(props) => setContentColor(props)};
`;

export const MainTitle = styled.span`
  font-size: 20px;
`;

export const MobileTitle = styled.span`
  font-size: 15px;
`;
