import React, { PureComponent } from "react";

export default class BI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <g>
            <path d="M215.864,256.006,0,399.911V112.089Z" fill="#6da544" />
            <path d="M512,112.089V399.911l-215.864-143.9Z" fill="#6da544" />
          </g>
          <path
            d="M512,112.089,296.136,256.006,512,399.911v26.757H471.875L256,282.752,40.125,426.668H0V399.911l215.864-143.9L0,112.089V85.331H40.125L256,229.248,471.875,85.331H512Z"
            fill="#0052b4"
          />
          <g>
            <path
              d="M512,112.089,296.136,256.006,512,399.911v26.757H471.875L256,282.752,40.125,426.668H0V399.911l215.864-143.9L0,112.089V85.331H40.125L256,229.248,471.875,85.331H512Z"
              fill="#f0f0f0"
            />
            <circle
              cx="74.207"
              cy="74.207"
              r="74.207"
              transform="translate(181.793 181.793)"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M256,204.058l6.426,11.13h12.852l-6.426,11.13,6.426,11.131H262.426L256,248.579l-6.426-11.13H236.722l6.426-11.131-6.426-11.13h12.852Z"
              fill="#d80027"
            />
            <path
              d="M223.337,256l6.425,11.13h12.853l-6.426,11.131,6.426,11.13H229.762l-6.425,11.131-6.427-11.131H204.058l6.426-11.13-6.426-11.131H216.91Z"
              fill="#d80027"
            />
            <path
              d="M288.663,256l6.427,11.13h12.852l-6.426,11.131,6.426,11.13H295.09l-6.427,11.131-6.425-11.131H269.385l6.426-11.13-6.426-11.131h12.853Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
