import React, { PureComponent } from "react";

export default class CN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <g>
            <path
              d="M178.923,189.567l14.656,45.107h47.429l-38.371,27.878,14.657,45.109-38.371-27.879-38.371,27.879,14.656-45.109-38.371-27.878h47.429Z"
              fill="#ffda44"
            />
            <path
              d="M271.3,339.593l-14.39-10.455-14.388,10.454,5.495-16.914-14.389-10.455h17.786l5.5-16.916,5.5,16.916h17.785L265.8,322.677Z"
              fill="#ffda44"
            />
            <path
              d="M308.837,287.927H291.052l-5.5,16.914-5.5-16.913H262.273l14.391-10.455-5.5-16.915,14.39,10.453,14.389-10.453-5.5,16.915Z"
              fill="#ffda44"
            />
            <path
              d="M308.837,224.063l-14.388,10.455,5.495,16.914-14.388-10.453-14.39,10.455,5.5-16.917-14.391-10.452,17.788,0,5.495-16.916,5.5,16.916Z"
              fill="#ffda44"
            />
            <path
              d="M271.3,172.4,265.8,189.313l14.388,10.453H262.408l-5.5,16.917-5.5-16.918-17.785,0,14.389-10.456-5.5-16.916,14.388,10.455Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
