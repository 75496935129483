import React, { createRef, PureComponent } from "react";

import * as FireworksCanvas from "./fireworksCanvas";
import { FireworksContainer, CanvasContainer } from "./Fireworks.styled";

class Fireworks extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  handleResize = () => {
    this.fireworks.resetSize();
  };

  componentDidMount() {
    if (!this.ref.current) {
      return null;
    }
    this.fireworks = new FireworksCanvas(this.ref.current);
    window.addEventListener("resize", this.handleResize);
    this.fireworks.start();
  }

  componentWillUnmount() {
    this.fireworks.destroy();
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <FireworksContainer fluid className="mx-auto my-auto p-0">
        <CanvasContainer id="container" ref={this.ref} />
      </FireworksContainer>
    );
  }
}

export default Fireworks;
