import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { StyledHomepageBannersContainer } from "./PrismicHomepageBanners.styled";
import { getHomepageBannersInfo } from "../selectors";
import * as actions from "../actions";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

class PrismicHomepageBanners extends PureComponent {
  state = { homepageBannersInfo: {} };

  componentDidMount() {
    const { fetchHomepageBannersRequest } = this.props;
    fetchHomepageBannersRequest();
  }

  componentDidUpdate() {
    const { homepageBannersInfo } = this.props;
    if (homepageBannersInfo) {
      this.setState({ homepageBannersInfo });
    }
  }

  render() {
    const {
      homepageBannersInfo: { banners, enabled, autoplay_timeout },
    } = this.state;

    return (
      <>
        {enabled && (
          <StyledHomepageBannersContainer className="mt-2 mt-lg-4">
            <Swiper
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: autoplay_timeout * 1000,
                disableOnInteraction: false,
              }}
            >
              {banners.map(function (banner, index) {
                return (
                  <SwiperSlide key={index}>
                    <a href={banner.link}>
                      <img src={banner.banner} />
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </StyledHomepageBannersContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  homepageBannersInfo: getHomepageBannersInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHomepageBannersRequest: () => {
    dispatch(actions.fetchHomepageBannersRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PrismicHomepageBanners));
