import React, { PureComponent } from "react";
import { Col, Form } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { getVendorNames } from "../../shared/selectors";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import * as actions from "../../vendor/actions";

class HomePageVendorsFilter extends PureComponent {
  componentDidMount() {
    const { fetchVendorNames } = this.props;
    fetchVendorNames();
  }

  handleFilterChange = (e) => {
    const { filterItemHandleChange } = this.props;
    const value = e.target.value;
    filterItemHandleChange(value);
  };

  render() {
    const { vendorNames = [] } = this.props;

    return (
      (vendorNames.length > 0 && <Col xs={12} sm={4} md={3} className="px-sm-2">
        <Form>
          <Form.Control
            as="select"
            size="sm"
            onChange={this.handleFilterChange}
            name="home-filter"
            value=""
          >
            <option value="" disabled>
              Sort by Vendor
            </option>
            {vendorNames.map(({ slug, store_name }, key) => (
              <option key={key} value={slug}>
                {store_name}
              </option>
            ))}
          </Form.Control>
        </Form>
      </Col>
      )
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorNames: getVendorNames,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorNames: () => {
    dispatch(actions.fetchVendorNamesRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(HomePageVendorsFilter));
