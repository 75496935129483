import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
class SuccessAccountCreation extends PureComponent {
  render() {
    const {
      location: { search },
    } = this.props;
    return (
      <Container>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>
              Your Account <br /> successfully created
            </h1>
            <p>
              Please, use the following link to
              <Link to="/account/login"> Login</Link> with your credentials
            </p>
            {search && (
              <p>
                Your request to become a <Link to="/">bids.com</Link> partner
                and sell your goods are pending. After approval from the
                <Link to="/"> bids.com</Link> management team, you will get a
                confirmation email.
              </p>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(SuccessAccountCreation);
