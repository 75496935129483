import React, { PureComponent } from "react";
import Loader from "./Loader";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import { getloaderState } from "../selectors";
import { Container } from "react-bootstrap";
import { StyledContainer } from "./WithLoader.styled";

class WithLoader extends PureComponent {
  render() {
    const { loaders, loader, forceLoading } = this.props;
    const isLoading = forceLoading || loaders.includes(loader);
    return isLoading ? (
      <StyledContainer height={60}>
        <Loader />
      </StyledContainer>
    ) : (
      <Container className="component-content">{this.props.children}</Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loaders: getloaderState,
});

export default connect(mapStateToProps)(withImmutablePropsToJS(WithLoader));
