import React, { PureComponent } from "react";

export default class SV extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#0052b4"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#0052b4"
            />
          </g>
          <path
            d="M228.582,261.936,256,214.447l27.418,47.489Z"
            fill="#ffda44"
          />
          <path
            d="M291.616,277.616,256,295.425l-35.616-17.809V253.872h71.232Z"
            fill="#6da544"
          />
          <path
            d="M289.579,216.485l-12.592,12.592a29.68,29.68,0,1,1-41.974,0l-12.592-12.592a47.489,47.489,0,1,0,67.158,0Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
