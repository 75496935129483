import { createSelector } from "reselect";

export const bannerSelector = (state) => {
  return state.getIn(["bannerReducer", "banner"]);
};

export const stripeBannerSelector = (state) => {
  return state.getIn(["bannerReducer", "stripeBanner"]);
};

export const prismicHomepageBannersSelector = (state) => {
  return state.getIn(["bannerReducer", "homepageBanners"]);
};

export const prismicReviewsCarouselSelector = (state) => {
  return state.getIn(["bannerReducer", "reviewsCarousel"]);
};

export const getBannerInfo = createSelector(bannerSelector, (products) => {
  return products.get("banner");
});

export const getStripeBannerInfo = createSelector(
  stripeBannerSelector,
  (products) => {
    return products.get("stripe_banner");
  }
);

export const getHomepageBannersInfo = createSelector(
  prismicHomepageBannersSelector,
  (products) => {
    return products;
  }
);

export const getReviewsCarouselInfo = createSelector(
  prismicReviewsCarouselSelector,
  (products) => {
    return products;
  }
);
