import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export default class SubmenuLinkList extends PureComponent {
  render() {
    const { title, url, data } = this.props;
    const LinkList = () => {
      if (data) {
        return (
          <>
            <Link to={url}>{title}</Link>
            <ul variant="flush">
              {data.map((item, index) => (
                <li key={title + index}>
                  <Link to={item.url}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </>
        );
      } else {
        return (
          <>
            <Link to={url}>{title}</Link>
          </>
        );
      }
    };

    return <LinkList />;
  }
}
