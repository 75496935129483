import React, { PureComponent } from "react";

export default class AG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.33"
      >
        <g transform="translate(0 -85.335)">
          <rect width="512" height="341.326" transform="translate(0 85.337)" />
          <path
            d="M88.751,221.865a172.2,172.2,0,0,0,0,68.268L256,300.519l167.249-10.388a172.2,172.2,0,0,0,0-68.268"
            fill="#0052b4"
          />
          <path
            d="M423.249,290.131H88.751a170.7,170.7,0,0,0,334.5,0Z"
            fill="#f0f0f0"
          />
          <path
            d="M330.2,221.865H181.8l30.32-14.262-16.146-29.365,32.923,6.3,4.174-33.26L256,175.738l22.933-24.462,4.172,33.26,32.924-6.3L299.884,207.6Z"
            fill="#ffda44"
          />
          <g>
            <path d="M256,426.661,0,85.335V426.661Z" fill="#a2001d" />
            <path d="M512,426.661V85.335L256,426.661Z" fill="#a2001d" />
          </g>
        </g>
      </svg>
    );
  }
}
