import React, { PureComponent } from "react";

export default class MD extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(330.207 85.331)"
            fill="#d80027"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <path
            d="M315.362,219.607H274.55a18.551,18.551,0,0,0-37.1,0H196.636c0,10.088,8.787,18.265,18.874,18.265H214.9a18.265,18.265,0,0,0,18.265,18.266,18.259,18.259,0,0,0,14.886,17.939L233.66,306.572a59.378,59.378,0,0,0,44.676,0l-14.392-32.495a18.26,18.26,0,0,0,14.886-17.939,18.265,18.265,0,0,0,18.265-18.266h-.609C306.576,237.871,315.362,229.693,315.362,219.607Z"
            fill="#ff9811"
          />
          <path
            d="M256,244.864l-24.116,11.13v22.261L256,293.1l24.116-14.84V255.994Z"
            fill="#0052b4"
          />
          <rect
            width="48.228"
            height="22.261"
            transform="translate(231.88 233.734)"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
