import React, { PureComponent } from "react";

export default class SA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#6da544"
          />
          <g>
            <path
              d="M183.548,289.386a22,22,0,0,0,21.736,22.261h65.208A18.337,18.337,0,0,0,288.606,330.2h21.736a18.337,18.337,0,0,0,18.114-18.551V289.386Z"
              fill="#f0f0f0"
            />
            <path
              d="M330.264,181.791v51.942a14.685,14.685,0,0,1-14.491,14.84v22.261c19.976,0,36.226-16.643,36.226-37.1V181.791Z"
              fill="#f0f0f0"
            />
            <path
              d="M174.491,233.734A14.685,14.685,0,0,1,160,248.574v22.261c19.976,0,36.226-16.643,36.226-37.1V181.792H174.49v51.942Z"
              fill="#f0f0f0"
            />
            <rect
              width="21.736"
              height="51.942"
              transform="translate(297.661 181.788)"
              fill="#f0f0f0"
            />
            <path
              d="M265.057,211.473a3.624,3.624,0,1,1-7.246,0V181.791H236.075v29.682a3.624,3.624,0,1,1-7.246,0V181.791H207.093v29.682c0,14.32,11.376,25.971,25.358,25.971a24.8,24.8,0,0,0,14.491-4.677,24.639,24.639,0,0,0,17.691,4.462,14.578,14.578,0,0,1-14.068,11.345v22.261c19.976,0,36.226-16.643,36.226-37.1V181.791H265.055l0,29.682Z"
              fill="#f0f0f0"
            />
            <rect
              width="32.601"
              height="22.261"
              transform="translate(207.093 248.57)"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
