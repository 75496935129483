import React, { PureComponent } from "react";

export default class KW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#d80027"
          />
          <path d="M166.957,312.889,0,426.663V85.337L166.957,199.111Z" />
        </g>
      </svg>
    );
  }
}
