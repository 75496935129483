import styled from "styled-components";

export const FooterStyled = styled.div`
  margin-top: 1rem;
  .footer-menu {
    font-size: 15px;
  }
`;

export const SocialLinkContainerStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  opacity: 0.99;
  border: 1px solid #f1f1f1;
  &:before {
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
    content: "";
    width: 140%;
    height: 140%;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 90%;
    left: -110%;
    background-color: ${(props) => props.color || "black"};
  }
  &:hover {
    svg {
      fill: #fff;
      transform: scale(1.1);
    }
    &:before {
      top: -20%;
      left: -20%;
    }
  }
  svg {
    fill: ${(props) => props.color || "black"};
    z-index: 1;
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  }
`;
