import React, { PureComponent } from "react";

import australia from "../hymns/Australia.mp3";
import canada from "../hymns/Canada.mp3";
import egypt from "../hymns/Egypt.mp3";
import germany from "../hymns/Germany.mp3";
import guatemala from "../hymns/Guatemala.mp3";
import ireland from "../hymns/Ireland.mp3";
import mexico from "../hymns/Mexico.mp3";
import norway from "../hymns/Norway.mp3";
import pakistan from "../hymns/Pakistan.mp3";
import panama from "../hymns/Panama.mp3";
import peru from "../hymns/Peru.mp3";
import russia from "../hymns/Russia.mp3";
import saudiArabia from "../hymns/SaudiArabia.mp3";
import southAfrica from "../hymns/SouthAfrica.mp3";
import spain from "../hymns/Spain.mp3";
import ukraine from "../hymns/Ukraine.mp3";
import unitedKingdom from "../hymns/UnitedKingdom.mp3";
import usa from "../hymns/USA.mp3";

class Hymn extends PureComponent {
  componentDidUpdate() {
    const { muted } = this.props;
    const audioElem = document.querySelector("audio");

    if (audioElem && !muted) {
      audioElem.play();
      this.setVolumeMuteAndPause(audioElem);
    }
  }

  setVolumeMuteAndPause = (audioElem) => {
    const { playHymn } = this.props;
    const interval = 1000;
    const fadeout = setInterval(() => {
      if (audioElem.volume.toFixed(1) > 0) {
        audioElem.volume -= 0.1;
      } else {
        clearInterval(fadeout);
        audioElem.pause();
        playHymn("");
      }
    }, interval);
  };

  render() {
    const { countryCode, muted } = this.props;
    switch (countryCode) {
      case "AU":
        return <audio src={australia} muted={muted} />;
      case "CA":
        return <audio src={canada} muted={muted} />;
      case "EG":
        return <audio src={egypt} muted={muted} />;
      case "DE":
        return <audio src={germany} muted={muted} />;
      case "GT":
        return <audio src={guatemala} muted={muted} />;
      case "IE":
        return <audio src={ireland} muted={muted} />;
      case "MX":
        return <audio src={mexico} muted={muted} />;
      case "NO":
        return <audio src={norway} muted={muted} />;
      case "PK":
        return <audio src={pakistan} muted={muted} />;
      case "PA":
        return <audio src={panama} muted={muted} />;
      case "PE":
        return <audio src={peru} muted={muted} />;
      case "RU":
        return <audio src={russia} muted={muted} />;
      case "SA":
        return <audio src={saudiArabia} muted={muted} />;
      case "ZA":
        return <audio src={southAfrica} muted={muted} />;
      case "ES":
        return <audio src={spain} muted={muted} />;
      case "UA":
        return <audio src={ukraine} muted={muted} />;
      case "GB":
        return <audio src={unitedKingdom} muted={muted} />;
      case "US":
        return <audio src={usa} muted={muted} />;
      default:
        return "";
    }
  }
}

export default Hymn;
