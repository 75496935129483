import React, { PureComponent } from "react";

export default class LoginIcon extends PureComponent {
  render() {
    const { size = 20 } = this.props;
    return (
      <svg viewBox="0 0 31.96 32" width={size} height={size}>
        <path d="M16,0C14.64,0,7.59,0,7.59,9.8,7.59,22.08,16,22.08,16,22.08s8.52,0,8.52-12.31C24.52,0,17.35,0,16,0Zm0,19.47s-5.91,0-5.91-9.62C10.09,2.52,15,2.52,16,2.52s6,0,6,7.34C22,19.47,16,19.47,16,19.47Z"></path>
        <path
          d="M22.15,23.21A1.4,1.4,0,0,0,23,24.5c3,.82,4.7,1.53,6.53,3.85v.51a.63.63,0,0,1-.63.64H3.1a.62.62,0,0,1-.62-.61v-.52C4.27,26.08,6,25.34,9,24.5a1.32,1.32,0,0,0,.77-1.29c0-.71-.42-1.28-.77-1.19A16,16,0,0,0,.13,27.24a.63.63,0,0,0-.12.37v3.75A.6.6,0,0,0,.59,32H31.34a.63.63,0,0,0,.63-.64h0V27.61a.63.63,0,0,0-.12-.37c-2.47-3.46-7-4.72-8.84-5.2C22.63,21.94,22.14,22.52,22.15,23.21Z"
          transform="translate(-0.01 0)"
        ></path>
      </svg>
    );
  }
}
