import React, { PureComponent } from "react";

export default class RegisterIcon extends PureComponent {
  render() {
    return (
      <svg
        width="20"
        height="20"
        style={{ enableBackground: "new 0 0 512 512" }}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m432.134 119.349v-9.805c0-27.851-22.598-50.509-50.374-50.509h-55.655c-5.966-14.047-19.779-23.969-35.891-24.202-6.362-20.17-25.205-34.833-47.398-34.833-22.192 0-41.035 14.663-47.397 34.833-16.112.233-29.925 10.156-35.891 24.202h-55.656c-27.776 0-50.373 22.658-50.373 50.509v351.947c.001 27.851 22.598 50.509 50.374 50.509h277.887c27.776 0 50.374-22.658 50.374-50.509v-17.864l18.344-30.867.001-.001c5.248-8.832 8.021-18.934 8.021-29.212v-225.558c0-17.561-10.95-32.607-26.366-38.64zm-7.446 278.085-7.555 12.712-7.555-12.711c-2.283-3.842-3.58-8.19-3.783-12.647h22.675c-.203 4.456-1.5 8.805-3.782 12.646zm-18.921-42.647v-156.444h22.733v156.444zm0-186.444v-10.354c0-6.32 5.1-11.462 11.367-11.462s11.366 5.142 11.366 11.462v10.354zm-209.767-103.514h12.121c8.284 0 15-6.716 15-15 0-10.934 8.835-19.829 19.696-19.829s19.696 8.896 19.696 19.829c0 8.284 6.716 15 15 15h12.121c5.303 0 9.617 4.356 9.617 9.712 0 5.354-4.314 9.711-9.617 9.711h-93.634c-5.303 0-9.617-4.356-9.617-9.711s4.314-9.712 9.617-9.712zm185.76 417.171h-277.887c-11.234 0-20.373-9.2-20.373-20.509v-351.947c0-11.309 9.139-20.509 20.373-20.509h55.242c5.789 14.75 20.14 25.217 36.885 25.217h93.633c16.745 0 31.096-10.467 36.885-25.217h55.242c11.234 0 20.374 9.2 20.374 20.509v9.804c-15.417 6.032-26.367 21.079-26.367 38.641v225.558c0 10.278 2.773 20.38 8.022 29.213l18.345 30.867v17.864c0 11.309-9.14 20.509-20.374 20.509z"></path>
        <path d="m334.06 155.742h-187.93c-8.284 0-15 6.716-15 15s6.716 15 15 15h187.93c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m146.13 235.738h148.086c8.284 0 15-6.716 15-15s-6.716-15-15-15h-148.086c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
        <path d="m146.13 285.734h165.373c8.284 0 15-6.716 15-15s-6.716-15-15-15h-165.373c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
        <path d="m146.13 335.73h54.388c8.284 0 15-6.716 15-15s-6.716-15-15-15h-54.388c-8.284 0-15 6.716-15 15s6.716 15 15 15z"></path>
        <path d="m334.06 355.726h-187.93c-8.284 0-15 6.716-15 15s6.716 15 15 15h187.93c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m294.216 405.722h-148.086c-8.284 0-15 6.716-15 15s6.716 15 15 15h148.086c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
      </svg>
    );
  }
}
