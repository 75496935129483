import React, { PureComponent } from "react";

class SingleOrderAddress extends PureComponent {
  render() {
    const {
      address: { name, address1, address2, city, zip, country },
      type,
    } = this.props;
    return (
      <div className="mb-5">
        {type === "shipping" && <h5>Shipping address</h5>}
        {type === "billing" && <h5>Billing address</h5>}
        <hr className="mb-3" />
        <p>{name}</p>
        {address1 ? <p>{address1}</p> : address2 ? <p>address2</p> : ""}
        <p>{`${zip} ${city}`}</p>
        <p>{`${country}`}</p>
      </div>
    );
  }
}

export default SingleOrderAddress;
