import React, { PureComponent } from "react";

export default class MN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <rect
              width="181.793"
              height="341.337"
              transform="translate(330.207 85.331)"
              fill="#a2001d"
            />
            <rect
              width="181.793"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#a2001d"
            />
          </g>
          <g>
            <rect
              width="14.84"
              height="59.359"
              transform="translate(113.163 267.125)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="59.359"
              transform="translate(53.793 267.125)"
              fill="#ffda44"
            />
            <circle
              cx="14.84"
              cy="14.84"
              r="14.84"
              transform="translate(76.062 274.546)"
              fill="#ffda44"
            />
            <circle
              cx="7.421"
              cy="7.421"
              r="7.421"
              transform="translate(83.481 230.019)"
              fill="#ffda44"
            />
            <rect
              width="29.682"
              height="14.84"
              transform="translate(76.054 311.647)"
              fill="#ffda44"
            />
            <rect
              width="29.682"
              height="14.84"
              transform="translate(76.054 252.288)"
              fill="#ffda44"
            />
            <path
              d="M90.9,185.5l5.255,16.173h17.006l-13.757,10,5.252,16.173-13.756-10-13.756,10,5.251-16.173-13.756-10h17Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
