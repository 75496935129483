import React, { PureComponent } from "react";

export default class SZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.333"
      >
        <path d="M0,0H512V341.333H0Z" fill="#3e5eb9" />
        <path
          d="M0,112.5H512V325.8H0Z"
          transform="translate(0 -48.485)"
          fill="#ffd900"
        />
        <path
          d="M0,150H512V320.707H0Z"
          transform="translate(0 -64.687)"
          fill="#b10c0c"
        />
        <g transform="translate(51.052 131.648)">
          <g transform="translate(53.805)">
            <path
              d="M723.576,231.42l-20.728,7.316,20.728,7.316,20.728-7.316Z"
              transform="translate(-407.888 -231.42)"
              fill="#fff"
              stroke="#000"
              strokeWidth="1.512"
            />
            <rect
              width="304.702"
              height="4.877"
              rx="2.439"
              transform="translate(0 4.882)"
              fill="#ffd900"
              stroke="#000"
              strokeWidth="1.512"
            />
            <path
              d="M703.462,244.871a2.439,2.439,0,1,0,0-4.877m-2.442,4.877a2.439,2.439,0,1,0,0-4.877m-2.442,4.877a2.439,2.439,0,1,0,0-4.877"
              transform="translate(-406.049 -235.115)"
              fill="#ffd900"
              stroke="#000"
              strokeWidth="1.512"
            />
          </g>
          <g transform="translate(33.943 14.638)">
            <path
              d="M723.576,231.42l-20.728,7.316,20.728,7.316,20.728-7.316Z"
              transform="translate(-407.888 -231.42)"
              fill="#fff"
              stroke="#000"
              strokeWidth="1.512"
            />
            <rect
              width="304.702"
              height="4.877"
              rx="2.439"
              transform="translate(0 4.882)"
              fill="#ffd900"
              stroke="#000"
              strokeWidth="1.512"
            />
            <path
              d="M703.462,244.871a2.439,2.439,0,1,0,0-4.877m-2.442,4.877a2.439,2.439,0,1,0,0-4.877m-2.442,4.877a2.439,2.439,0,1,0,0-4.877"
              transform="translate(-406.049 -235.115)"
              fill="#ffd900"
              stroke="#000"
              strokeWidth="1.512"
            />
          </g>
          <rect
            width="414.441"
            height="7.316"
            rx="3.658"
            transform="translate(0 35.37)"
            fill="#ffd900"
            stroke="#000"
            strokeWidth="1.62"
          />
        </g>
        <path
          d="M479.567,244.588c-24.386,24.384-56.9,73.157-121.936,73.157-56.9,0-97.542-48.772-121.936-73.157,24.387-24.389,65.029-73.162,121.936-73.162C422.66,171.426,455.173,220.2,479.567,244.588Z"
          transform="translate(-101.639 -73.921)"
          fill="#fff"
          stroke="#000"
          strokeWidth="1.512"
        />
        <path
          d="M349.128,196.872c6.488,7.3,1.07,10.239,7.938,10.718,7.242.532,3.136,7.711,9.228,7.961,4.259.186-.425,17.2,3.674,22.814,4.177,5.853,7.674,1.715,7.748,5.943.072,4.374-11.367,3.933-11.567,17.4-.336,7.764-9.653,8.26-10.1,13.257-.553,4.7,18.357,7.336,18.1,11.542s-20.418,3.537-21.3,8.32c-.45,4.3,27.694,7.817,29.957,20.24-4.2,1.373-16.148,2.666-25.179,2.673-56.894.041-97.542-48.777-121.927-73.162,24.387-24.388,65.029-73.162,121.936-73.162,0,0-16.89,15.434-8.51,25.448Z"
          transform="translate(-101.63 -73.917)"
        />
        <g transform="translate(182.551 144.651)">
          <g>
            <g>
              <path
                d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
                transform="translate(-320.9 -254.28)"
                stroke="#fff"
                strokeWidth="10.391"
              />
              <path
                d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
                transform="translate(-320.9 -221.76)"
                stroke="#fff"
                strokeWidth="10.391"
              />
            </g>
            <g transform="translate(35.473)">
              <path
                d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
                transform="translate(-320.9 -254.28)"
                stroke="#fff"
                strokeWidth="10.391"
              />
              <path
                d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
                transform="translate(-320.9 -221.76)"
                stroke="#fff"
                strokeWidth="10.391"
              />
            </g>
          </g>
        </g>
        <g transform="translate(271.943 144.651)">
          <g>
            <path
              d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
              transform="translate(-320.9 -254.28)"
              stroke="#fff"
              strokeWidth="10.391"
            />
            <path
              d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
              transform="translate(-320.9 -221.76)"
              stroke="#fff"
              strokeWidth="10.391"
            />
          </g>
          <g transform="translate(35.473)">
            <path
              d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
              transform="translate(-320.9 -254.28)"
              stroke="#fff"
              strokeWidth="10.391"
            />
            <path
              d="M320.9,273.789V254.28m11.821,0v19.509m11.83-19.509v19.509"
              transform="translate(-320.9 -221.76)"
              stroke="#fff"
              strokeWidth="10.391"
            />
          </g>
        </g>
        <g transform="translate(54.69 110.495)">
          <path
            d="M751.952,294.9c-3.694,6.4,2.835-2.128,18.765,8.583,2.952,1.99,5.531,7.246,5.531,10.891-.749-.5-1.306-1.617-2.131-2.342-1.156,1.158,1.1,6.467,1.434,8.976-2.491-1.394-2.251-2.054-3.078-3.766.165,2.071-.427,7.773.563,9.969-1.963-.561-1.832-2.141-2.857-2.458.7,2.486-.608,5.191-.262,7.985-1.147-1.135-2.369-2.025-2.9-2.483-.089,1.33-2.206,4.828-2.254,6.086a3.193,3.193,0,0,1-1.4-2.122c-1.21,1.52-5.509,7.134-5.784,8.846-3.313-2.926-11.8-10.051-12.9-13.832-.976,2.146-2.39,2.871-5.184,4.438-1.1-5.993-5.232-12.73-3.024-17.868-1.565.927-2.853,1.856-4.416,3,1.482-8.68,9.036-18.859,19.9-23.9Z"
            transform="translate(-370.308 -237.628)"
            fill="#3d5da7"
            stroke="#000"
            strokeWidth="1.283"
          />
          <path
            d="M742.815,338.3c1.533-3.066,2.946-4.038,3.938-6.012,1.75-3.47,2-6.23,3.526-5.826s1.523,1.826-.452,5.237S746.91,335.844,742.815,338.3Zm10.37,7.306c-.2-2.335.48-3.309.357-4.812-.211-2.645-1.292-4.461.114-4.59s2.064.774,2.068,3.439-.408,3.366-2.538,5.963Zm7.87,4.575c-.615-3.259-.154-4.7-.548-6.8-.685-3.694-2.039-6.126-.736-6.464s2.091.857,2.583,4.6S762.588,346.3,761.055,350.185Zm8.185-22.832c-1.922-1.459-3.044-1.557-4.283-2.494-2.183-1.644-3.288-3.406-3.991-2.532s-.2,1.83,2.084,3.348,3.063,1.637,6.19,1.678Zm-1.357,10.814c-1.013-2.1-2.079-2.637-2.735-3.991-1.158-2.379-1.16-4.437-2.416-3.909s-1.357,1.6-.014,3.907S764.766,336.894,767.883,338.167Z"
            transform="translate(-374.947 -249.348)"
            fill="#a70000"
          />
          <path
            d="M110.373,302.154c-.207-1.863.192-2.086-.954-3.491,1.669.7,1.732,2.554,3.761,1.237.745-.386,1.07-.4.164-2.468,2.116.1,9.044,2.474,10.154,2.529,2.913.134,8.231-3.06,11.923.868,3.543,3.581,2.36,7.3,2.36,12.258-1.424-.682-.737-1.019-2.248-2.792,1.132,4.335-.06,12.152-.06,16.75-.582-1.108-.5-.628-1.065-1.775-1.511,4.2-3.321,5.06-3.321,10.021-.545-1.945-.019-1.534-.625-2.557-1.468,3.187-11.318,5.909-7.522,9.121-3.426-2.009-4.928-1.8-6.481-3.6a6.5,6.5,0,0,0-1.824,2.37c-6.023-2.771-3.869-8.749-8.588-12.742-.817,1.674-.444,1.432-1.327,4.156-.955-3.785-1.217-6.189-2.291-8.972-.927,1.542-.844.951-2.528,3.282-.7-4.61-1.83-5.7-1.382-8.749-1.789,1.262-.618.751-2.406,2.3,1.695-11.813,9.012-20.542,14.263-17.747Z"
            transform="translate(-96.112 -238.719)"
            fill="#3d5da7"
            stroke="#000"
            strokeWidth="1.6"
          />
          <path
            d="M108.151,327.356c1.552-3.8,2.951-5,3.954-7.446,1.771-4.3,2.059-7.744,3.547-7.219s1.464,2.3-.526,6.525-2.922,5.126-6.975,8.139Zm5.876,9.517c.623-3.41,1.6-4.652,2.008-6.848.716-3.861.317-6.737,1.7-6.585s1.7,1.609.775,5.45-1.555,4.751-4.483,7.983Zm7.028,6.615c.175-3.306.95-4.6,1.066-6.726.21-3.745-.511-6.426.807-6.441s1.788,1.332,1.384,5.081-.892,4.685-3.258,8.086Zm15.092-21.62c-2.886-2.875-4.63-3.412-6.492-5.261-3.279-3.247-4.863-6.3-6.031-5.215s-.465,2.7,2.987,5.79S131.264,320.723,136.146,321.868Zm-1.758,11.113c-1.879-2.887-3.259-3.634-4.473-5.492-2.14-3.264-2.876-6.069-4.088-5.374s-.942,2.16,1.377,5.324,3.251,3.745,7.184,5.542Z"
            transform="translate(-101.301 -244.64)"
            fill="#a70000"
          />
          <path
            d="M366.915,194.25c-3.378,6.534,5.915-2.173,20.483,8.765,2.7,2.032,6.57,10.06,6.57,13.782-3.1-.66-8.595-3.721-8.595-3.721s6.446,6.456,6.446,13.9c-2.277-1.424-3.416-1.21-4.174-2.958,0,2.409,2.026,3.945,2.026,7.666a30.121,30.121,0,0,0-4.427-2.953c2.149,3.721-3.915,10.941-.88,13.35-5.307-.768-10.737-4.158-12.886-7.88-1.139.767-1.262,1.972-1.306,3.257.167.139-8.079-6.12-7.321-8.525a7.654,7.654,0,0,0-1.514,4.154c-3.029-2.987-5.806-5.975-6.816-9.837-1.346,1.749-1.431,1.749-2.777,3.5-1.009-6.121-1.009-5.9,1.01-11.148a49.049,49.049,0,0,0-4.039,3.061c1.354-8.864,8.264-19.259,18.2-24.407Z"
            transform="translate(-205.026 -194.25)"
            fill="#3d5da7"
            stroke="#000"
            strokeWidth="1.239"
          />
          <path
            d="M366.06,233.613c.541-4.5,1.717-6.2,2.069-9.1.628-5.1-.117-8.827,1.69-8.732s2.327,1.975,1.434,7.064-1.646,6.324-5.193,10.766Zm9.432,8.245c-.366-3.8.336-5.415.1-7.863-.4-4.305-1.731-7.221-.156-7.489s2.371,1.176,2.541,5.523-.251,5.505-2.488,9.829Zm14.723,6.726c-1.6-3.287-2.977-4.267-4.01-6.383-1.826-3.718-2.208-6.754-3.616-6.2s-1.331,2.121.7,5.761S386.245,246.139,390.215,248.584Zm5.1-29.438c-4.078-2.147-6.173-2.174-8.8-3.554-4.628-2.418-7.322-5.151-8.278-3.647s.32,3,5.091,5.2,6.237,2.3,11.988,2Zm1.494,12.3c-2.969-2.47-4.725-2.834-6.641-4.423-3.374-2.788-5.053-5.527-6.18-4.417s-.374,2.577,3.167,5.2S391.9,230.762,396.8,231.445Z"
            transform="translate(-212.503 -201.691)"
            fill="#a70000"
          />
        </g>
      </svg>
    );
  }
}
