import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export default class SoldByLink extends PureComponent {
  render() {
    const { vendorSlug, vendorName } = this.props;
    return (
      <Link
        to={vendorSlug ? `/vendors/${vendorSlug}` : "/"}
        data-cy="sold-by-vendor-link"
      >
        {" "}
        {vendorName}
      </Link>
    );
  }
}
