import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { MAKE_AN_OFFER_PAGE_LOADER } from "storefront/features/shared/utils";

import * as actions from "storefront/features/shared/actions";
import {
  getProducts,
  getPageParams,
} from "storefront/features/shared/selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams, getTags } from "storefront/features/shared/utils";
import { metaTags } from "../metaTags";

class MakeAnOfferWithTags extends PureComponent {
  componentDidMount() {
    const { location, fetchMakeAnOfferRequest, history } = this.props;
    const page = getSearchParams(location.search, "page");
    const tags = getTags(location.pathname);
    const loader = MAKE_AN_OFFER_PAGE_LOADER;
    history.action === "PUSH" &&
      fetchMakeAnOfferRequest({ tags, loader, page });
  }

  componentDidUpdate(prevProps) {
    const { location, fetchMakeAnOfferRequest } = this.props;
    const tags = getTags(location.pathname);
    const loader = MAKE_AN_OFFER_PAGE_LOADER;
    if (tags !== getTags(prevProps.location.pathname)) {
      const page = getSearchParams(location.search, "page");
      fetchMakeAnOfferRequest({ tags, loader, page });
    }
  }

  pageItemHandleClick = (page) => {
    const { location, fetchMakeAnOfferRequest } = this.props;
    const tags = getTags(location.pathname);
    const loader = MAKE_AN_OFFER_PAGE_LOADER;
    fetchMakeAnOfferRequest({ tags, loader, page });
  };

  render() {
    const { products, pageParams } = this.props;
    return (
      <>
        <Helmet metaTags={metaTags} />
        <WithLoader loader={MAKE_AN_OFFER_PAGE_LOADER}>
          <ProductGrid products={products} />
        </WithLoader>
        <Pagination
          pageItemHandleClick={this.pageItemHandleClick}
          pageParams={pageParams}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMakeAnOfferRequest: (value) => {
    dispatch(actions.fetchMakeAnOfferRequest(value));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(MakeAnOfferWithTags);
