import { isEmpty } from "lodash";

export const getBuyingFormat = (product, pathName) => {
  if (pathName.includes("/collections/all-auctions")) {
    return 3;
  } else if (pathName.includes("/collections/buy-it-now")) {
    return 0;
  } else if (pathName.includes("/collections/make-an-offer")) {
    return 1;
  } else if (!isEmpty(product)) {
    return product.buying_format;
  } else {
    return "";
  }
};
