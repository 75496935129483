import React, { PureComponent } from "react";

export default class DZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#496e2d"
          />
          <rect
            width="256"
            height="341.337"
            transform="translate(256 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M292.669,223.259l-14,19.295L255.989,235.2l14.024,19.28-14,19.3,22.671-7.381,14.025,19.28-.014-23.841,22.669-7.381L292.684,247.1Z"
              fill="#d80027"
            />
            <path
              d="M270.158,304.226A48.232,48.232,0,1,1,293.1,213.559a59.362,59.362,0,1,0,0,84.87A48,48,0,0,1,270.158,304.226Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
