import styled from "styled-components";
import { Container } from "react-bootstrap";

export const FireworksContainer = styled(Container)`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1055;
  pointer-events: none;
  animation: changeBackgroundColor 7s infinite;
  @keyframes changeBackgroundColor {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: rgba(0, 0, 0, 0.8);
    }
    100% {
      background-color: transparent;
    }
  }
`;

export const CanvasContainer = styled.div`
  margin: 0 auto;
  border: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  pointer-events: none;
`;
