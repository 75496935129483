import React, { PureComponent } from "react";

export default class KE extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <rect width="512" height="90.579" transform="translate(0 85.331)" />
          <rect
            width="512"
            height="90.568"
            transform="translate(0 336.089)"
            fill="#496e2d"
          />
          <rect
            width="512"
            height="114.799"
            transform="translate(0 198.606)"
            fill="#a2001d"
          />
          <g>
            <path
              d="M323.265,139.8l-25.583-11.809L256,222.376l-41.682-94.382L188.735,139.8l51.749,116.191L188.735,372.186l25.583,11.808L256,289.613l41.682,94.381,25.583-11.808L271.516,255.994Z"
              fill="#f0f0f0"
            />
            <path
              d="M273.376,150.931A198.917,198.917,0,0,0,256,133.9a198.791,198.791,0,0,0-17.376,17.032V361.057A199.038,199.038,0,0,0,256,378.091a198.732,198.732,0,0,0,17.376-17.033Z"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M209.04,191.226V320.761a226.562,226.562,0,0,0,32.872,43.818V147.408A226.45,226.45,0,0,0,209.04,191.226Z"
              fill="#a2001d"
            />
            <path
              d="M302.96,191.226a226.53,226.53,0,0,0-32.872-43.818V364.58a226.552,226.552,0,0,0,32.872-43.818Z"
              fill="#a2001d"
            />
          </g>
          <path d="M302.96,191.226V320.761c10.594-18.774,18.784-40.973,18.784-64.767S313.554,210,302.96,191.226Z" />
          <path d="M209.04,191.226V320.761c-10.594-18.774-18.784-40.973-18.784-64.767S198.446,210,209.04,191.226Z" />
        </g>
      </svg>
    );
  }
}
