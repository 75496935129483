import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import withRemoveAuction from "storefront/features/shared/components/RemoveAuction";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { ALL_AUCTIONS_PAGE_LOADER } from "storefront/features/shared/utils";

import * as actions from "storefront/features/shared/actions";
import {
  getProducts,
  getFlagUpdateProductGrid,
  getPageParams,
} from "storefront/features/shared/selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams } from "storefront/features/shared/utils";
import { metaTags } from "../metaTags";

class Auctions extends PureComponent {
  componentDidMount() {
    const { location, fetchAllProducts, history } = this.props;
    const pageNumber = getSearchParams(location.search, "page");
    history.action === "PUSH" &&
      fetchAllProducts(pageNumber, ALL_AUCTIONS_PAGE_LOADER);
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange = () => {
    if (!document.hidden) {
      const isLoader = true;
      const isVisibilityChange = true;
      this.handleUpdateGrid(isLoader, isVisibilityChange);
    }
  };

  pageItemHandleClick = (page) => {
    const { fetchAllProducts } = this.props;
    fetchAllProducts(page, ALL_AUCTIONS_PAGE_LOADER);
  };

  handleUpdateGrid = (isLoader, isVisibilityChange) => {
    const { location, fetchAllProducts, updateProductGrid } = this.props;
    const pageNumber = getSearchParams(location.search, "page");
    const loader = isLoader ? ALL_AUCTIONS_PAGE_LOADER : null;
    const withDelay = isVisibilityChange ? false : updateProductGrid;
    fetchAllProducts(pageNumber, loader, withDelay);
  };

  render() {
    const { products, pageParams } = this.props;
    return (
      <>
        <Helmet metaTags={metaTags} />
        <WithLoader loader={ALL_AUCTIONS_PAGE_LOADER}>
          <ProductGrid products={products} />
        </WithLoader>
        <Pagination
          pageItemHandleClick={this.pageItemHandleClick}
          pageParams={pageParams}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  updateProductGrid: getFlagUpdateProductGrid,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllProducts: (page, loader, withDelay) => {
    dispatch(actions.fetchAllProductsRequest({ page, loader, withDelay }));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withRemoveAuction
)(Auctions);
