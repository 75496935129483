import React, { PureComponent } from "react";

export default class PS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect width="512" height="113.775" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#6da544"
          />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#d80027" />
        </g>
      </svg>
    );
  }
}
