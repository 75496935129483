import React, { PureComponent } from "react";

export default class VC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#6da544"
            />
            <path
              d="M214.261,283.82l-33.393-50.086,33.392-50.087,33.392,50.087Z"
              fill="#6da544"
            />
            <path
              d="M297.739,283.82l-33.391-50.086,33.391-50.087,33.393,50.087Z"
              fill="#6da544"
            />
            <path
              d="M256,350.6l-33.391-50.087L256,250.429l33.391,50.087Z"
              fill="#6da544"
            />
          </g>
        </g>
      </svg>
    );
  }
}
