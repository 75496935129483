import React, { PureComponent } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import {
  isLogin,
  FREE_SHIPPING,
  isTagExist,
  mapShippingPriceToVendor,
} from "storefront/features/shared/utils";
import { shippingPricesSelector } from "storefront/features/shared/selectors";
import { setRedirectTo } from "storefront/features/shared/components/Header/actions";
import { getCustomerId } from "storefront/features/shared/components/Header/selectors";
import * as actions from "storefront/features/shared/actions";
import { bidEventName } from "storefront/features/shared/utils";
import { StyledBidButton, StyledShippingInfo } from "./BidButton.styled";

class AutoBidButton extends PureComponent {
  handleClickAutoBid = () => {
    const {
      location,
      setRedirectTo,
      history,
      postBidRequest,
      customerId,
      shopifyProductId,
      autoBidValue,
      product: { bidAuctionId },
    } = this.props;
    const isRegistered = isLogin();
    if (!isRegistered) {
      const handle = location.pathname.split("/").pop();
      const quickViewLink = `/quick-view/${handle}`;
      setRedirectTo(quickViewLink);
      history.push("/account/login");
    } else {
      const data = {
        product_id: shopifyProductId,
        bid: autoBidValue,
        shop: `${process.env.SHOP}`,
        event: bidEventName.NEW_MAX_BID,
        customer_id: customerId,
        auction_id: bidAuctionId,
      };
      postBidRequest(data);
    }
  };

  render() {
    const {
      product: { tags, vendor },
      closed,
      shippingPrices,
    } = this.props;
    const freeShippingTagExist = isTagExist(tags, FREE_SHIPPING);
    const shippingPrice = mapShippingPriceToVendor(vendor, shippingPrices)
      ? `+ $${mapShippingPriceToVendor(vendor, shippingPrices)} shipping`
      : null;

    return (
      <>
        <StyledBidButton
          className="ml-1"
          variant="primary"
          disabled={closed}
          onClick={this.handleClickAutoBid}
          width="100px"
          padding="0"
        >
          Bid
          <StyledShippingInfo>
            {freeShippingTagExist ? "+ Free shipping" : shippingPrice}
          </StyledShippingInfo>
        </StyledBidButton>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerId: getCustomerId,
  shippingPrices: shippingPricesSelector,
});

const mapDispatchToProps = (dispatch) => ({
  setRedirectTo: (quickViewLink) => {
    dispatch(setRedirectTo(quickViewLink));
  },
  postBidRequest: (data) => {
    dispatch(actions.postBidRequest({ data }));
  },
});

export { AutoBidButton };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(AutoBidButton);
