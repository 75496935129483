import React, { PureComponent } from "react";

export default class KY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#0052b4"
          />
          <circle
            cx="29.682"
            cy="29.682"
            r="29.682"
            transform="translate(354.318 152.108)"
            fill="#6da544"
          />
          <rect
            width="103.88"
            height="44.522"
            transform="translate(332.054 174.377)"
            fill="#d80027"
          />
          <path d="M435.942,256h0Z" fill="#496e2d" />
          <path
            d="M332.058,285.678V315.36h15.616a51.88,51.88,0,0,0,72.652,0h15.616V285.678Z"
            fill="#ffda44"
          />
          <path
            d="M332.058,211.475V256c0,39.759,51.942,51.942,51.942,51.942S435.942,295.757,435.942,256V211.475H332.058Z"
            fill="#338af3"
          />
          <g>
            <path
              d="M384,235.256c-12.985,0-12.985,11.873-25.97,11.873s-12.985-11.873-25.97-11.873v20.777c12.985,0,12.985,11.873,25.97,11.873s12.985-11.873,25.97-11.873,12.986,11.873,25.973,11.873,12.986-11.873,25.973-11.873V235.256c-12.986,0-12.986,11.873-25.973,11.873S396.984,235.256,384,235.256Z"
              fill="#f3f3f3"
            />
            <path
              d="M384,193.666c-12.985,0-12.985,11.873-25.97,11.873s-12.985-11.873-25.97-11.873v20.777c12.985,0,12.985,11.873,25.97,11.873s12.985-11.873,25.97-11.873,12.986,11.873,25.973,11.873,12.986-11.873,25.973-11.873V193.666c-12.986,0-12.986,11.873-25.973,11.873S396.984,193.666,384,193.666Z"
              fill="#f3f3f3"
            />
          </g>
          <path
            d="M256,85.334v22.617L209.931,138.66H256v64H209.931L256,233.38V256H233.372L160,207.078V256H96V207.078L22.628,256H0V233.38l46.069-30.72H0v-64H46.069L0,107.951V85.334H22.628L96,134.241V85.334h64v48.907l73.372-48.907Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.329H112v69.334H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.082l57.377,38.252H80Z" fill="#d80027" />
            <path d="M256,85.329v15.082l-57.377,38.252H176Z" fill="#d80027" />
            <path d="M0,85.329v15.082l57.377,38.252H80Z" fill="#d80027" />
            <path d="M256,85.329v15.082l-57.377,38.252H176Z" fill="#d80027" />
            <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
            <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          </g>
        </g>
      </svg>
    );
  }
}
