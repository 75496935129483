import styled from "styled-components";
import { Image } from "react-bootstrap";

export const StyledImage = styled(Image)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-left: 5px;
`;

export const IconWrapper = styled.div`
  border: 1px solid black;
  width: 18px;
  height: 18px;
  padding-top: 3px;
  margin-right: 5px;
  margin-left: 5px;
`;

export const StyledCountdownHolder = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  letter-spacing: 1px;
  text-align: center;
  white-space: normal;
  font-weight: 700;
  color: #454545;
  text-transform: uppercase;
`;

export const StyledWinner = styled.span`
  min-height: 19px;
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  color: #454545;
  font-family: Futura, sans-serif;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
`;

export const StyledFlags = styled.span`
  svg {
    vertical-align: baseline;
    position: relative;
    top: 1px;
    margin-left: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
