import React, { PureComponent } from "react";

export default class IT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <path d="M341.334,85.33H0V426.662H512V85.33Z" fill="#f0f0f0" />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#6da544"
          />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(341.337 85.333)"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
