import styled from "styled-components";
import { Container } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledContainer = styled(Container)`
  .contact-us-btn {
    max-width: 200px;
    @media ${device.laptop} {
      max-width: initial;
    }
  }
  .btn {
    padding: 10px;
    @media ${device.mobileL} {
      padding: 12px 30px;
    }
  }
`;
