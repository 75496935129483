import React, { PureComponent } from "react";

export default class CL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(256 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <path
            d="M128,109.8l15.108,46.5H192l-39.554,28.739,15.108,46.5L128,202.8,88.446,231.536l15.108-46.5L64,156.3h48.892Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
