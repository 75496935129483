import { fromJS } from "immutable";

import * as actions from "./actions";

const initialState = fromJS({
  products: [],
});

const setProducts = (state, action) =>
  state.set("products", fromJS(action.payload));

const productsSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PRODUCT_REQUEST_RESULT:
      return setProducts(state, action);
    default:
      return state;
  }
};

export default productsSettingsReducer;
