import React, { PureComponent } from "react";

export default class ST extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#6da544"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#6da544"
            />
          </g>
          <path d="M256,256.006,0,426.668V85.331Z" fill="#d80027" />
          <path d="M302.049,226.318l7.368,22.674h23.842l-19.288,14.016,7.366,22.674-19.288-14.015-19.287,14.015,7.366-22.674L270.84,248.992h23.842Z" />
          <path d="M376.252,226.318l7.367,22.674h23.842l-19.288,14.016,7.367,22.674-19.288-14.015-19.288,14.015,7.367-22.674-19.288-14.016h23.842Z" />
        </g>
      </svg>
    );
  }
}
