import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import { isLogin } from "../utils";
import { getAuctionData } from "./Header/selectors";

const withRedirectRegisteredUser = (WrappedComponent) => {
  class RedirectRegisteredUser extends PureComponent {
    componentDidMount() {
      this.redirectIfLoggedIn();
    }

    componentDidUpdate() {
      this.redirectIfLoggedIn();
    }

    redirectIfLoggedIn() {
      const {
        history,
        customerData: { vendor },
      } = this.props;
      const isUserLogged = isLogin();
      if (isUserLogged && (vendor || !this.isVendorRegisterPage())) {
        history.push("/");
      }
    }

    isVendorRegisterPage() {
      const {
        location: { pathname },
      } = this.props;
      return pathname === "/account/register_vendor";
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return RedirectRegisteredUser;
};

const mapStateToProps = createStructuredSelector({
  customerData: getAuctionData,
});

export default compose(
  connect(mapStateToProps),
  withImmutablePropsToJS,
  withRouter,
  withRedirectRegisteredUser
);
