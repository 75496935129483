import React, { PureComponent } from "react";

export default class EC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="85.337"
            transform="translate(0 256)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="85.326"
            transform="translate(0 341.337)"
            fill="#d80027"
          />
          <circle
            cx="59.359"
            cy="59.359"
            r="59.359"
            transform="translate(196.641 196.641)"
            fill="#ffda44"
          />
          <path
            d="M256,295.3a38.3,38.3,0,0,1-38.261-38.261V234.086a38.261,38.261,0,1,1,76.522,0v22.957A38.3,38.3,0,0,1,256,295.3Z"
            fill="#338af3"
          />
          <path d="M317.217,165.217H271.3a15.3,15.3,0,1,0-30.608,0H194.783c0,8.452,7.363,15.3,15.814,15.3h-.51a15.3,15.3,0,0,0,15.3,15.3,15.3,15.3,0,0,0,15.3,15.3H271.3a15.3,15.3,0,0,0,15.3-15.3,15.3,15.3,0,0,0,15.3-15.3h-.51C309.855,180.521,317.217,173.669,317.217,165.217Z" />
        </g>
      </svg>
    );
  }
}
