import React, { PureComponent } from "react";

export default class CH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <path
            d="M356.174,222.609H289.391V155.826H222.609v66.783H155.826v66.782h66.783v66.783h66.782V289.391h66.783Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
