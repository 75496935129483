export const FETCH_SPROUT_BANNER_REQUEST = "banner/FETCH_SPROUT_BANNER_REQUEST";
export const FETCH_SPROUT_BANNER_REQUEST_RESULT =
  "banner/FETCH_SPROUT_BANNER_REQUEST_RESULT";

export function fetchSproutBannerRequest() {
  return { type: FETCH_SPROUT_BANNER_REQUEST };
}

export function fetchSproutBannerRequestResult(params) {
  return { type: FETCH_SPROUT_BANNER_REQUEST_RESULT, payload: params };
}
