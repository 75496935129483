import React, { PureComponent } from "react";
import SearchIcon from "./SearchIcon";
import ShareIcon from "./ShareIcon";
import LoginIcon from "./LoginIcon";
import MyAuctionsIcon from "./MyAuctionsIcon";
import MenuIcon from "./MenuIcon";
import RegisterIcon from "./RegisterIcon";
import WishlistIcon from "./WishlistIcon";
import CartIcon from "./CartIcon";
import CloseIcon from "./CloseIcon";
import WhatsappIcon from "./WhatsappIcon";
import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import TwitterIcon from "./TwitterIcon";
import CopyIcon from "./CopyIcon";
import Amex from "./paymentIcons/Amex";
import ApplePay from "./paymentIcons/ApplePay";
import DinersClub from "./paymentIcons/DinersClub";
import Discover from "./paymentIcons/Discover";
import Elo from "./paymentIcons/Elo";
import GooglePay from "./paymentIcons/GooglePay";
import JCB from "./paymentIcons/JCB";
import Klarna from "./paymentIcons/Klarna";
import Mastercard from "./paymentIcons/Mastercard";
import PayPal from "./paymentIcons/PayPal";
import ShopPay from "./paymentIcons/ShopPay";
import Venmo from "./paymentIcons/Venmo";
import Visa from "./paymentIcons/Visa";
import VolumeUp from "./VolumeUp";
import VolumeMute from "./VolumeMute";
import NoImage from "./NoImage";
import TrashIcon from "./TrashIcon";
import DeleteIcon from "./DeleteIcon";
import UploadIcon from "./UploadIcon";
import PlusIcon from "./PlusIcon";
import DownloadIcon from "./DownloadIcon";
import ArrowLeftIcon from "./ArrowLeftIcon";
import VendorRegisterIcon from "./VendorRegisterIcon";
import QuestionMarkIcon from "./QuestionMarkIcon";
import AuctionIcon from "./AuctionIcon";
import UA from "./flags/UA";
import US from "./flags/US";
import AU from "./flags/AU";
import CA from "./flags/CA";
import EG from "./flags/EG";
import DE from "./flags/DE";
import GT from "./flags/GT";
import IE from "./flags/IE";
import MX from "./flags/MX";
import NO from "./flags/NO";
import PK from "./flags/PK";
import PA from "./flags/PA";
import PE from "./flags/PE";
import RU from "./flags/RU";
import SA from "./flags/SA";
import ZA from "./flags/ZA";
import ES from "./flags/ES";
import GB from "./flags/GB";
import ET from "./flags/ET";
import OM from "./flags/OM";
import TZ from "./flags/TZ";
import SI from "./flags/SI";
import PR from "./flags/PR";
import MZ from "./flags/MZ";
import IQ from "./flags/IQ";
import LB from "./flags/LB";
import UG from "./flags/UG";
import NG from "./flags/NG";
import IT from "./flags/IT";
import MT from "./flags/MT";
import TN from "./flags/TN";
import NI from "./flags/NI";
import SV from "./flags/SV";
import ZM from "./flags/ZM";
import DO from "./flags/DO";
import QA from "./flags/QA";
import NA from "./flags/NA";
import MU from "./flags/MU";
import LU from "./flags/LU";
import LT from "./flags/LT";
import JM from "./flags/JM";
import HN from "./flags/HN";
import MM from "./flags/MM";
import KE from "./flags/KE";
import CY from "./flags/CY";
import LV from "./flags/LV";
import JP from "./flags/JP";
import KZ from "./flags/KZ";
import RS from "./flags/RS";
import KP from "./flags/KP";
import UY from "./flags/UY";
import SY from "./flags/SY";
import IS from "./flags/IS";
import JO from "./flags/JO";
import MC from "./flags/MC";
import SK from "./flags/SK";
import NZ from "./flags/NZ";
import EC from "./flags/EC";
import RO from "./flags/RO";
import CL from "./flags/CL";
import FI from "./flags/FI";
import HU from "./flags/HU";
import BE from "./flags/BE";
import KR from "./flags/KR";
import MY from "./flags/MY";
import VE from "./flags/VE";
import IL from "./flags/IL";
import CZ from "./flags/CZ";
import CO from "./flags/CO";
import IR from "./flags/IR";
import AR from "./flags/AR";
import HK from "./flags/HK";
import AE from "./flags/AE";
import LA from "./flags/LA";
import GR from "./flags/GR";
import DK from "./flags/DK";
import SE from "./flags/SE";
import PH from "./flags/PH";
import FR from "./flags/FR";
import ID from "./flags/ID";
import TW from "./flags/TW";
import CK from "./flags/CK";
import CC from "./flags/CC";
import CX from "./flags/CX";
import AS from "./flags/AS";
import MA from "./flags/MA";
import AI from "./flags/AI";
import KM from "./flags/KM";
import CF from "./flags/CF";
import KY from "./flags/KY";
import IO from "./flags/IO";
import BT from "./flags/BT";
import TD from "./flags/TD";
import CV from "./flags/CV";
import CH from "./flags/CH";
import BJ from "./flags/BJ";
import AW from "./flags/AW";
import AD from "./flags/AD";
import BI from "./flags/BI";
import AG from "./flags/AG";
import AX from "./flags/AX";
import CM from "./flags/CM";
import BN from "./flags/BN";
import PL from "./flags/PL";
import BQ from "./flags/BQ";
import BY from "./flags/BY";
import BB from "./flags/BB";
import VG from "./flags/VG";
import BA from "./flags/BA";
import BM from "./flags/BM";
import BZ from "./flags/BZ";
import BH from "./flags/BH";
import AL from "./flags/AL";
import TR from "./flags/TR";
import AM from "./flags/AM";
import AF from "./flags/AF";
import AO from "./flags/AO";
import AZ from "./flags/AZ";
import DZ from "./flags/DZ";
import BW from "./flags/BW";
import BD from "./flags/BD";
import CU from "./flags/CU";
import CR from "./flags/CR";
import KH from "./flags/KH";
import BO from "./flags/BO";
import HR from "./flags/HR";
import BG from "./flags/BG";
import MQ from "./flags/MQ";
import TO from "./flags/TO";
import LC from "./flags/LC";
import SG from "./flags/SG";
import PW from "./flags/PW";
import NU from "./flags/NU";
import MO from "./flags/MO";
import FJ from "./flags/FJ";
import FO from "./flags/FO";
import DM from "./flags/DM";
import VU from "./flags/VU";
import SL from "./flags/SL";
import SC from "./flags/SC";
import XK from "./flags/XK";
import GN from "./flags/GN";
import TC from "./flags/TC";
import TK from "./flags/TK";
import GM from "./flags/GM";
import VC from "./flags/VC";
import SS from "./flags/SS";
import SB from "./flags/SB";
import VN from "./flags/VN";
import SX from "./flags/SX";
import KN from "./flags/KN";
import PN from "./flags/PN";
import PS from "./flags/PS";
import MP from "./flags/MP";
import NR from "./flags/NR";
import PT from "./flags/PT";
import MS from "./flags/MS";
import MR from "./flags/MR";
import KW from "./flags/KW";
import GG from "./flags/GG";
import GU from "./flags/GU";
import GD from "./flags/GD";
import GL from "./flags/GL";
import TH from "./flags/TH";
import GI from "./flags/GI";
import GA from "./flags/GA";
import FK from "./flags/FK";
import VI from "./flags/VI";
import AT from "./flags/AT";
import VA from "./flags/VA";
import TV from "./flags/TV";
import TM from "./flags/TM";
import TG from "./flags/TG";
import BS from "./flags/BS";
import NL from "./flags/NL";
import SR from "./flags/SR";
import SO from "./flags/SO";
import ST from "./flags/ST";
import NF from "./flags/NF";
import NE from "./flags/NE";
import FM from "./flags/FM";
import MH from "./flags/MH";
import ML from "./flags/ML";
import KG from "./flags/KG";
import GW from "./flags/GW";
import ER from "./flags/ER";
import DJ from "./flags/DJ";
import CW from "./flags/CW";
import BL from "./flags/BL";
import SM from "./flags/SM";
import LI from "./flags/LI";
import IN from "./flags/IN";
import LR from "./flags/LR";
import YE from "./flags/YE";
import UZ from "./flags/UZ";
import SD from "./flags/SD";
import EH from "./flags/EH";
import MK from "./flags/MK";
import LY from "./flags/LY";
import TL from "./flags/TL";
import PG from "./flags/PG";
import ME from "./flags/ME";
import MD from "./flags/MD";
import MV from "./flags/MV";
import PF from "./flags/PF";
import TT from "./flags/TT";
import TJ from "./flags/TJ";
import LK from "./flags/LK";
import CG from "./flags/CG";
import GQ from "./flags/GQ";
import ZW from "./flags/ZW";
import RW from "./flags/RW";
import LS from "./flags/LS";
import CI from "./flags/CI";
import IM from "./flags/IM";
import HT from "./flags/HT";
import WS from "./flags/WS";
import NP from "./flags/NP";
import BR from "./flags/BR";
import MN from "./flags/MN";
import MW from "./flags/MW";
import MG from "./flags/MG";
import JE from "./flags/JE";
import GH from "./flags/GH";
import GE from "./flags/GE";
import EE from "./flags/EE";
import CD from "./flags/CD";
import SN from "./flags/SN";
import PY from "./flags/PY";
import CN from "./flags/CN";
import WF from "./flags/WF";
import SZ from "./flags/SZ";
import GY from "./flags/GY";
import GP from "./flags/GP";
import CS from "./flags/CS";
import BF from "./flags/BF";
import AN from "./flags/AN";

export default class Icon extends PureComponent {
  render() {
    const { type, size, color } = this.props;
    switch (type) {
      case "search":
        return <SearchIcon />;
      case "share":
        return <ShareIcon />;
      case "login":
        return <LoginIcon size={size} />;
      case "auctions":
        return <MyAuctionsIcon />;
      case "menu":
        return <MenuIcon />;
      case "register":
        return <RegisterIcon />;
      case "wishlist":
        return <WishlistIcon />;
      case "cart":
        return <CartIcon />;
      case "close":
        return <CloseIcon />;
      case "whatsapp":
        return <WhatsappIcon size={size} />;
      case "facebook":
        return <FacebookIcon size={size} />;
      case "instagram":
        return <InstagramIcon size={size} />;
      case "twitter":
        return <TwitterIcon size={size} />;
      case "copy":
        return <CopyIcon size={size} />;
      case "paymentAmex":
        return <Amex />;
      case "paymentApplePay":
        return <ApplePay />;
      case "paymentDiscover":
        return <Discover />;
      case "paymentDinersClub":
        return <DinersClub />;
      case "paymentElo":
        return <Elo />;
      case "paymentGooglePay":
        return <GooglePay />;
      case "paymentJCB":
        return <JCB />;
      case "paymentMastercard":
        return <Mastercard />;
      case "paymentPayPal":
        return <PayPal />;
      case "paymentShopPay":
        return <ShopPay />;
      case "paymentVenmo":
        return <Venmo />;
      case "paymentVisa":
        return <Visa />;
      case "paymentKlarna":
        return <Klarna />;
      case "volumeUp":
        return <VolumeUp size={size} />;
      case "volumeMute":
        return <VolumeMute size={size} />;
      case "noImage":
        return <NoImage size={size} />;
      case "trashIcon":
        return <TrashIcon size={size} />;
      case "deleteIcon":
        return <DeleteIcon size={size} />;
      case "uploadIcon":
        return <UploadIcon />;
      case "plusIcon":
        return <PlusIcon />;
      case "auction":
        return <AuctionIcon />;
      case "downloadIcon":
        return <DownloadIcon color={color} />;
      case "arrowLeftIcon":
        return <ArrowLeftIcon color={color} />;
      case "vendorRegisterIcon":
        return <VendorRegisterIcon />;
      case "questionMarkIcon":
        return <QuestionMarkIcon />;
      case "flagUA":
        return <UA />;
      case "flagUS":
        return <US />;
      case "flagAU":
        return <AU />;
      case "flagCA":
        return <CA />;
      case "flagEG":
        return <EG />;
      case "flagDE":
        return <DE />;
      case "flagGT":
        return <GT />;
      case "flagIE":
        return <IE />;
      case "flagMX":
        return <MX />;
      case "flagNO":
      case "flagSJ":
        return <NO />;
      case "flagPK":
        return <PK />;
      case "flagPA":
        return <PA />;
      case "flagPE":
        return <PE />;
      case "flagRU":
        return <RU />;
      case "flagSA":
        return <SA />;
      case "flagZA":
        return <ZA />;
      case "flagES":
        return <ES />;
      case "flagGB":
        return <GB />;
      case "flagET":
        return <ET />;
      case "flagOM":
        return <OM />;
      case "flagTZ":
        return <TZ />;
      case "flagSI":
        return <SI />;
      case "flagPR":
        return <PR />;
      case "flagMZ":
        return <MZ />;
      case "flagIQ":
        return <IQ />;
      case "flagLB":
        return <LB />;
      case "flagUG":
        return <UG />;
      case "flagNG":
        return <NG />;
      case "flagIT":
        return <IT />;
      case "flagMT":
        return <MT />;
      case "flagTN":
        return <TN />;
      case "flagNI":
        return <NI />;
      case "flagSV":
        return <SV />;
      case "flagZM":
        return <ZM />;
      case "flagDO":
        return <DO />;
      case "flagQA":
        return <QA />;
      case "flagNA":
        return <NA />;
      case "flagMU":
        return <MU />;
      case "flagLU":
        return <LU />;
      case "flagLT":
        return <LT />;
      case "flagJM":
        return <JM />;
      case "flagHN":
        return <HN />;
      case "flagMM":
        return <MM />;
      case "flagKE":
        return <KE />;
      case "flagCY":
        return <CY />;
      case "flagLV":
        return <LV />;
      case "flagJP":
        return <JP />;
      case "flagKZ":
        return <KZ />;
      case "flagRS":
        return <RS />;
      case "flagKP":
        return <KP />;
      case "flagUY":
        return <UY />;
      case "flagSY":
        return <SY />;
      case "flagIS":
        return <IS />;
      case "flagJO":
        return <JO />;
      case "flagMC":
        return <MC />;
      case "flagSK":
        return <SK />;
      case "flagNZ":
        return <NZ />;
      case "flagEC":
        return <EC />;
      case "flagRO":
        return <RO />;
      case "flagCL":
        return <CL />;
      case "flagFI":
        return <FI />;
      case "flagHU":
        return <HU />;
      case "flagBE":
        return <BE />;
      case "flagKR":
        return <KR />;
      case "flagMY":
        return <MY />;
      case "flagVE":
        return <VE />;
      case "flagIL":
        return <IL />;
      case "flagCZ":
        return <CZ />;
      case "flagCO":
        return <CO />;
      case "flagIR":
        return <IR />;
      case "flagAR":
        return <AR />;
      case "flagHK":
        return <HK />;
      case "flagAE":
        return <AE />;
      case "flagLA":
        return <LA />;
      case "flagGR":
        return <GR />;
      case "flagDK":
        return <DK />;
      case "flagSE":
        return <SE />;
      case "flagPH":
        return <PH />;
      case "flagFR":
      case "flagYT":
      case "flagRE":
      case "flagNC":
      case "flagMF":
      case "flagGF":
        return <FR />;
      case "flagID":
        return <ID />;
      case "flagTW":
        return <TW />;
      case "flagCK":
        return <CK />;
      case "flagCC":
        return <CC />;
      case "flagCX":
        return <CX />;
      case "flagAS":
        return <AS />;
      case "flagMA":
        return <MA />;
      case "flagAI":
        return <AI />;
      case "flagKM":
        return <KM />;
      case "flagCF":
        return <CF />;
      case "flagKY":
        return <KY />;
      case "flagIO":
        return <IO />;
      case "flagBT":
        return <BT />;
      case "flagTD":
        return <TD />;
      case "flagCV":
        return <CV />;
      case "flagCH":
        return <CH />;
      case "flagBJ":
        return <BJ />;
      case "flagAW":
        return <AW />;
      case "flagAD":
        return <AD />;
      case "flagBI":
        return <BI />;
      case "flagAG":
        return <AG />;
      case "flagAX":
        return <AX />;
      case "flagCM":
        return <CM />;
      case "flagBN":
        return <BN />;
      case "flagPL":
        return <PL />;
      case "flagBQ":
        return <BQ />;
      case "flagBY":
        return <BY />;
      case "flagBB":
        return <BB />;
      case "flagVG":
        return <VG />;
      case "flagBA":
        return <BA />;
      case "flagBM":
        return <BM />;
      case "flagBZ":
        return <BZ />;
      case "flagBH":
        return <BH />;
      case "flagAL":
        return <AL />;
      case "flagTR":
        return <TR />;
      case "flagAM":
        return <AM />;
      case "flagAF":
        return <AF />;
      case "flagAO":
        return <AO />;
      case "flagAZ":
        return <AZ />;
      case "flagDZ":
        return <DZ />;
      case "flagBW":
        return <BW />;
      case "flagBD":
        return <BD />;
      case "flagCU":
        return <CU />;
      case "flagCR":
        return <CR />;
      case "flagKH":
        return <KH />;
      case "flagBO":
        return <BO />;
      case "flagHR":
        return <HR />;
      case "flagBG":
        return <BG />;
      case "flagMQ":
        return <MQ />;
      case "flagTO":
        return <TO />;
      case "flagLC":
        return <LC />;
      case "flagSG":
        return <SG />;
      case "flagPW":
        return <PW />;
      case "flagNU":
        return <NU />;
      case "flagMO":
        return <MO />;
      case "flagFJ":
        return <FJ />;
      case "flagFO":
        return <FO />;
      case "flagDM":
        return <DM />;
      case "flagVU":
        return <VU />;
      case "flagSL":
        return <SL />;
      case "flagSC":
        return <SC />;
      case "flagXK":
        return <XK />;
      case "flagGN":
        return <GN />;
      case "flagTC":
        return <TC />;
      case "flagTK":
        return <TK />;
      case "flagGM":
        return <GM />;
      case "flagVC":
        return <VC />;
      case "flagSS":
        return <SS />;
      case "flagSB":
        return <SB />;
      case "flagVN":
        return <VN />;
      case "flagSX":
        return <SX />;
      case "flagKN":
        return <KN />;
      case "flagPN":
        return <PN />;
      case "flagPS":
        return <PS />;
      case "flagMP":
        return <MP />;
      case "flagNR":
        return <NR />;
      case "flagPT":
        return <PT />;
      case "flagMS":
        return <MS />;
      case "flagMR":
        return <MR />;
      case "flagKW":
        return <KW />;
      case "flagGG":
        return <GG />;
      case "flagGU":
        return <GU />;
      case "flagGD":
        return <GD />;
      case "flagGL":
        return <GL />;
      case "flagTH":
        return <TH />;
      case "flagGI":
        return <GI />;
      case "flagGA":
        return <GA />;
      case "flagFK":
        return <FK />;
      case "flagVI":
        return <VI />;
      case "flagAT":
        return <AT />;
      case "flagVA":
        return <VA />;
      case "flagTV":
        return <TV />;
      case "flagTM":
        return <TM />;
      case "flagTG":
        return <TG />;
      case "flagBS":
        return <BS />;
      case "flagNL":
        return <NL />;
      case "flagSR":
        return <SR />;
      case "flagSO":
        return <SO />;
      case "flagST":
        return <ST />;
      case "flagNF":
        return <NF />;
      case "flagNE":
        return <NE />;
      case "flagFM":
        return <FM />;
      case "flagMH":
        return <MH />;
      case "flagML":
        return <ML />;
      case "flagKG":
        return <KG />;
      case "flagGW":
        return <GW />;
      case "flagER":
        return <ER />;
      case "flagDJ":
        return <DJ />;
      case "flagCW":
        return <CW />;
      case "flagBL":
        return <BL />;
      case "flagSM":
        return <SM />;
      case "flagLI":
        return <LI />;
      case "flagIN":
        return <IN />;
      case "flagLR":
        return <LR />;
      case "flagYE":
        return <YE />;
      case "flagUZ":
        return <UZ />;
      case "flagSD":
        return <SD />;
      case "flagEH":
        return <EH />;
      case "flagMK":
        return <MK />;
      case "flagLY":
        return <LY />;
      case "flagTL":
        return <TL />;
      case "flagPG":
        return <PG />;
      case "flagME":
        return <ME />;
      case "flagMD":
        return <MD />;
      case "flagMV":
        return <MV />;
      case "flagPF":
        return <PF />;
      case "flagTT":
        return <TT />;
      case "flagTJ":
        return <TJ />;
      case "flagLK":
        return <LK />;
      case "flagCG":
        return <CG />;
      case "flagGQ":
        return <GQ />;
      case "flagZW":
        return <ZW />;
      case "flagRW":
        return <RW />;
      case "flagLS":
        return <LS />;
      case "flagCI":
        return <CI />;
      case "flagIM":
        return <IM />;
      case "flagHT":
        return <HT />;
      case "flagWS":
        return <WS />;
      case "flagNP":
        return <NP />;
      case "flagBR":
        return <BR />;
      case "flagMN":
        return <MN />;
      case "flagMW":
        return <MW />;
      case "flagMG":
        return <MG />;
      case "flagJE":
        return <JE />;
      case "flagGH":
        return <GH />;
      case "flagGE":
        return <GE />;
      case "flagEE":
        return <EE />;
      case "flagCD":
        return <CD />;
      case "flagSN":
        return <SN />;
      case "flagPY":
        return <PY />;
      case "flagCN":
        return <CN />;
      case "flagWF":
        return <WF />;
      case "flagSZ":
        return <SZ />;
      case "flagGY":
        return <GY />;
      case "flagGP":
        return <GP />;
      case "flagCS":
        return <CS />;
      case "flagBF":
        return <BF />;
      case "flagAN":
        return <AN />;
      default:
        return null;
    }
  }
}
