import React, { PureComponent } from "react";

export default class BL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M390.061,222.6H333.914a18.552,18.552,0,0,0-18.551-18.551l-14.84,29.682s15.831,44.522,15.336,44.522H334.41a18.551,18.551,0,0,0,18.551-18.551,18.551,18.551,0,0,0,18.551-18.551h-.618C381.136,241.154,390.061,232.849,390.061,222.6Z"
              fill="#acabb1"
            />
            <path
              d="M121.939,222.6h56.147a18.552,18.552,0,0,1,18.551-18.551l14.84,29.682s-15.831,44.522-15.336,44.522H177.59a18.551,18.551,0,0,1-18.551-18.551,18.551,18.551,0,0,1-18.551-18.551h.618C130.864,241.154,121.939,232.849,121.939,222.6Z"
              fill="#acabb1"
            />
          </g>
          <path
            d="M315.362,322.777V330.2H196.638v-7.421H166.957v29.682h29.681v7.419H315.362v-7.419h29.681V322.777Z"
            fill="#ffda44"
          />
          <path
            d="M196.638,204.052v74.2c0,45.44,59.362,59.363,59.362,59.363s59.362-13.923,59.362-59.363v-74.2L256,196.632Z"
            fill="#0052b4"
          />
          <rect
            width="118.728"
            height="44.522"
            transform="translate(196.641 233.734)"
            fill="#d80027"
          />
          <path
            d="M285.682,166.951v11.13l-7.421,3.71-7.421-7.419V152.111H241.16v22.261l-7.421,7.419-7.421-3.71v-11.13h-29.68v37.1H315.362v-37.1Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
