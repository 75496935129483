import { applyMiddleware, compose, createStore } from "redux";
import {
  connectRouter,
  routerMiddleware,
} from "connected-react-router/immutable";
import Immutable from "immutable";
import createSagaMiddleware from "redux-saga";
import { combineReducers } from "redux-immutable";

import {
  mergeProductAuctionData,
  createArrayOfProductsForHome,
} from "storefront/features/shared/utils";
import { appReducers, appSagas } from "./utils";

const sagaMiddleware = createSagaMiddleware();
const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...appReducers,
  });

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const mapInitialPropsToPreloadedState = (initialProps) => {
  const {
    top_menu,
    shipping_prices,
    document,
    products,
    bid_auctions,
    page_params,
    sorted_bid_auctions,
    product,
    bid_auction,
    vendor,
  } = initialProps;

  const preloadedState = {
    ssrReducer: {
      top_menu,
      shipping_prices,
    },
  };

  preloadedState.bidAuctionsReducer = { products: { page_params: {} } };
  if (products && sorted_bid_auctions) {
    preloadedState.bidAuctionsReducer.products = mergeProductAuctionData({
      bid_auctions: createArrayOfProductsForHome(sorted_bid_auctions),
      products,
      page_params,
    });
  } else if (vendor) {
    preloadedState.vendorReducer = { info: {}, settings: {} };
    preloadedState.bidAuctionsReducer.products = mergeProductAuctionData({
      bid_auctions,
      products,
      page_params,
    });
    preloadedState.vendorReducer.info = vendor;
  } else if (products && bid_auctions) {
    preloadedState.bidAuctionsReducer.products = mergeProductAuctionData({
      bid_auctions,
      products,
      page_params,
    });
  } else if (products) {
    preloadedState.bidAuctionsReducer.products = {
      products,
      page_params,
    };
  }

  if (product && bid_auction) {
    preloadedState.productReducer = {
      product: {
        ...bid_auction,
        ...product,
        bidAuctionId: bid_auction.id,
      },
    };
  } else if (product) {
    preloadedState.productReducer = { product };
  }

  if (document) {
    preloadedState.pagesReducer = { page: { ...document } };
  }

  return preloadedState;
};

export default function configureStore(initialProps = {}, history) {
  const store = createStore(
    createReducers(history),
    Immutable.fromJS(mapInitialPropsToPreloadedState(initialProps)),
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  );
  sagaMiddleware.run(appSagas);
  return store;
}
