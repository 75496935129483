import styled from "styled-components";
import { Container, Badge, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const DropDoownContainer = styled(Container)`
  z-index: 7;
  background-color: #fff;
  left: 0;
  min-height: 200px;
  font-size: 10px;
  &.dropdown-menu-enter {
    opacity: 0;
  }
  &.dropdown-menu-enter-active {
    opacity: 1;
    transition: all 300ms;
  }
  &.dropdown-menu-exit {
    opacity: 1;
  }
  &.dropdown-menu-exit-active {
    opacity: 0;
    transition: all 300ms;
  }
`;

export const MenuDrawer = styled.div`
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 7;

  &.mobile-menu-enter {
    left: -300px;
  }
  &.mobile-menu-enter-active {
    left: 0;
    transition: all 300ms;
  }
  &.mobile-menu-exit {
    left: 0;
  }
  &.mobile-menu-exit-active {
    left: -300px;
    transition: all 300ms;
  }
`;

export const MobileMenuOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 8;
  position: fixed;
`;

export const MenuContainer = styled(Container)`
  width: 300px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: 9;
  overflow-y: scroll;
  a.btn,
  button.btn {
    font-family: Futura, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
  }
  .nav-item {
    a.btn,
    button.btn {
      font-size: 13px;
    }
  }
`;

export const BadgeStyled = styled(Badge)`
  position: absolute;
  background: #ca002c;
  color: white;
  width: 16px;
  height: 16px;
  font-size: 9px;
  right: 3px;
  top: -8px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
`;

export const HeaderMenuStyledLink = styled(NavLink)`
  border-bottom: 3px solid transparent;
  padding: 7px 0;
  letter-spacing: 1px;
  font-size: 15px;
  color: #000;
  font-weight: 700;
  &:hover,
  &.active {
    text-decoration: none;
    color: #000;
    border-bottom: 3px solid
      ${(props) =>
        props.bottombordercolor ? props.bottombordercolor : "black"};
  }
`;

export const HeaderMenuStyledTagA = styled.a`
  border-bottom: 3px solid transparent;
  padding: 7px 0;
  letter-spacing: 1px;
  font-size: 15px;
  color: #000;
  font-weight: 700;
  &:hover,
  &.active {
    text-decoration: none;
    color: #000;
    border-bottom: 3px solid
      ${(props) =>
        props.bottombordercolor ? props.bottombordercolor : "black"};
  }
`;

export const HeaderIconStyledLink = styled(Link)`
  span:not(.badge) {
    color: #000;
    letter-spacing: 0px;
    font-size: 13px;
  }
  &:hover {
    text-decoration: none;
  }
`;
export const HeaderLogo = styled(Col)`
  img {
    @media ${device.laptop} {
      min-width: 130px;
      max-height: 97px;
    }
  }
`;
export const HeaderMenu = styled(Col)`
  margin-left: 50px;
  @media ${device.laptopL} {
    margin-left: 110px;
  }
`;
export const StyleIcons = styled(Col)`
  a {
    margin: 0 5px;
    @media ${device.laptop} {
      margin: 0 10px;
    }
  }
`;

export const VolumeIcon = styled.span`
  cursor: pointer;
`;
