import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { Row } from "react-bootstrap";

import {
  StyledPrismicBannerContainer,
  StyledColumn,
  MainTitle,
  MobileTitle,
} from "./PrismicStripeBanner.styled";
import { getStripeBannerInfo } from "../selectors";
import * as actions from "../actions";

class PrismicStripeBanner extends PureComponent {
  state = { stripeBannerInfo: {} };

  componentDidMount() {
    const { fetchStripeBannerRequest } = this.props;
    fetchStripeBannerRequest();
  }

  componentDidUpdate() {
    const { stripeBannerInfo } = this.props;
    if (stripeBannerInfo) {
      this.setState({ stripeBannerInfo });
    }
  }

  renderContent = (data) => {
    const {
      stripeBannerInfo: { link },
    } = this.state;
    const content = link ? (
      <a href={link} target="_blank" rel="noopener noreferrer nofollow">
        {data}
      </a>
    ) : (
      data
    );
    return content;
  };

  render() {
    const {
      stripeBannerInfo: {
        main_title,
        mobile_title,
        title_color,
        background_color,
      },
    } = this.state;

    return (
      <>
        {main_title && (
          <StyledPrismicBannerContainer
            fluid
            onClick={this.handleCloseBanner}
            backgroundcolor={background_color}
            contentcolor={title_color}
            className="mt-1"
          >
            <Row className="text-center h-100">
              <StyledColumn
                className="my-auto mx-auto p-0"
                contentcolor={title_color}
              >
                <MainTitle className="d-none d-sm-block">
                  {this.renderContent(main_title)}
                </MainTitle>
                <MobileTitle className="d-block d-sm-none">
                  {this.renderContent(mobile_title)}
                </MobileTitle>
              </StyledColumn>
            </Row>
          </StyledPrismicBannerContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  stripeBannerInfo: getStripeBannerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStripeBannerRequest: () => {
    dispatch(actions.fetchStripeBannerRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PrismicStripeBanner));
