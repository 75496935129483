import React, { PureComponent } from "react";

export default class ER extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#338af3"
          />
          <rect
            width="512"
            height="170.663"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <path
            d="M0,426.663c0,.027,512-170.669,512-170.669L0,85.337Z"
            fill="#d80027"
          />
          <path
            d="M140.986,191.994a53.955,53.955,0,0,0-53.9,53.9V266.1a53.9,53.9,0,0,0,107.79,0V245.889A53.957,53.957,0,0,0,140.986,191.994ZM174.67,266.1a33.743,33.743,0,0,1-23.579,32.134V276.206l14.3-14.3-14.29-14.29-.008-5.087v-6.745H130.881V256l-14.288,14.288,14.288,14.288v13.663A33.745,33.745,0,0,1,107.3,266.1V245.89a33.684,33.684,0,0,1,67.368,0V266.1h0Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
