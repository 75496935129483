import styled from "styled-components";

export const StyledHomepageBannersContainer = styled.div`
  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 576px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
    padding: 0 20px;
    object-fit: scale-down;

    @media (min-width: 575px) {
      width: 530px;
    }

    @media (min-width: 768px) {
      width: 710px;
    }

    @media (min-width: 992px) {
      width: 950px;
    }

    @media (min-width: 1200px) {
      width: 1125px;
    }
  }
`;
