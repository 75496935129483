import React, { PureComponent, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Dropdown } from "react-bootstrap";
import { isEmpty } from "lodash";

import ProductImage from "storefront/features/productGrid/components/ProductImage";
import { moneyFormat, getImageData } from "storefront/features/shared/utils";
import Countdown from "storefront/features/shared/components/Countdown";

class SearchDropdown extends PureComponent {
  constructor() {
    super();
    this.dropdownRef = createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideDropDown);
  }

  handleClickOutsideDropDown = (e) => {
    const { hideDropDown } = this.props;
    const targetElementDataCy = e.target.dataset.cy;
    if (targetElementDataCy === "searchInput") {
      return;
    }
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target)
    ) {
      hideDropDown();
      const searchInputFields = document.querySelectorAll(
        'input[data-cy="searchInput"]'
      );
      searchInputFields.forEach((input) => input.blur());
    }
  };

  handleClick = (handle) => {
    const { history } = this.props;
    history.push(`/products/${handle}`);
  };

  render() {
    const { products } = this.props;
    const FIRST_IMAGE_INDEX = 0;

    return (
      <Dropdown.Menu show ref={this.dropdownRef} className="py-0">
        {isEmpty(products) ? (
          <span className="p-3">No results found</span>
        ) : (
          products.map(
            (
              { id, handle, images, current_bid, price, end_at, title },
              index
            ) => (
              <Dropdown.Item
                key={id + index}
                className="pt-2"
                onClick={() => this.handleClick(handle)}
              >
                <Row>
                  <Col lg={4} md={4} xs={4}>
                    <ProductImage
                      {...getImageData(images, FIRST_IMAGE_INDEX)}
                    />
                  </Col>
                  <Col className="pl-0">
                    <p className="text-primary text-wrap">{title}</p>
                    <div className="d-flex justify-content-between">
                      {current_bid ? (
                        <span className="font-weight-bold">
                          {moneyFormat(current_bid, true)}
                        </span>
                      ) : (
                        <span className="font-weight-bold">
                          {moneyFormat(price, false)}
                        </span>
                      )}
                      {end_at && (
                        <span>
                          <Countdown endDate={end_at} />
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Dropdown.Item>
            )
          )
        )}
      </Dropdown.Menu>
    );
  }
}

export default withRouter(SearchDropdown);
