import styled, { keyframes } from "styled-components";

const bouncingAnimation = keyframes`
 0% { transform: scale(0); }
 40% { transform: scale(1.0); }
 80% { transform: scale(0); }
 100% { transform: scale(0); }
`;

export const SpinnerContainer = styled.div`
  display: block;
  text-align: center;
`;

export const SpinerSpan = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: currentColor;
  border-radius: 100%;
  animation-name: ${bouncingAnimation};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  &:first-child {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;
