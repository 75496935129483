import styled from "styled-components";
import { Button } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledBidButton = styled(Button)`
  transition: transform 0.3s ease-in;
  min-height: 52px;
  &.btn-primary {
    min-width: 100%;
    padding: 6px 10px;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    @media ${device.mobileL} {
      min-width: 140px;
      padding: 6px 30px;
    }
  }
`;

export const StyledShippingInfo = styled.p`
  width: 100%;
  font-size: 11px;
  margin: 0;
  font-weight: 400;
`;
