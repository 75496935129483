import React, { PureComponent } from "react";
import { Carousel, Image } from "react-bootstrap";
import { StyledCarousel } from "./QuickViewImageSlider.styled";

class QuickViewImageSlider extends PureComponent {
  render() {
    const { images } = this.props;

    return (
      <StyledCarousel nextIcon="" prevIcon="">
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <Image
              fluid
              src={image.src}
              alt={image.alt}
              className="shadow-sm p-3 bg-white rounded"
            />
          </Carousel.Item>
        ))}
      </StyledCarousel>
    );
  }
}

export default QuickViewImageSlider;
