import React, { PureComponent } from "react";

export default class US extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="42.663"
              transform="translate(0 127.994)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 213.331)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 298.657)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 383.994)"
              fill="#d80027"
            />
          </g>
          <rect
            width="256"
            height="183.797"
            transform="translate(0 85.331)"
            fill="#2e52b2"
          />
          <g>
            <path
              d="M99.822,160.624,95.7,173.308H82.363l10.791,7.835-4.123,12.683,10.791-7.835,10.784,7.835-4.122-12.683,10.791-7.835H103.938Z"
              fill="#f0f0f0"
            />
            <path
              d="M103.938,219.08,99.822,206.4,95.7,219.08H82.363l10.791,7.836L89.031,239.6l10.791-7.836,10.784,7.836-4.122-12.683,10.791-7.836Z"
              fill="#f0f0f0"
            />
            <path
              d="M47.577,219.08,43.46,206.4,39.337,219.08H26l10.791,7.836L32.669,239.6l10.791-7.836L54.245,239.6l-4.122-12.683,10.789-7.836Z"
              fill="#f0f0f0"
            />
            <path
              d="M43.46,160.624l-4.123,12.684H26l10.791,7.835-4.123,12.683,10.791-7.835,10.785,7.835-4.122-12.683,10.789-7.835H47.577Z"
              fill="#f0f0f0"
            />
            <path
              d="M99.822,114.85,95.7,127.535H82.363l10.791,7.836-4.123,12.683,10.791-7.836,10.784,7.836-4.122-12.683,10.791-7.836H103.938Z"
              fill="#f0f0f0"
            />
            <path
              d="M43.46,114.85l-4.123,12.685H26l10.791,7.836-4.123,12.683,10.791-7.836,10.785,7.836-4.122-12.683,10.789-7.836H47.577Z"
              fill="#f0f0f0"
            />
            <path
              d="M156.183,160.624l-4.122,12.684H138.725l10.79,7.835-4.121,12.683,10.789-7.835,10.786,7.835-4.123-12.683,10.791-7.835H160.3Z"
              fill="#f0f0f0"
            />
            <path
              d="M160.3,219.08,156.183,206.4l-4.122,12.683H138.725l10.79,7.836L145.394,239.6l10.789-7.836,10.786,7.836-4.123-12.683,10.791-7.836Z"
              fill="#f0f0f0"
            />
            <path
              d="M216.663,219.08,212.546,206.4l-4.123,12.683H195.088l10.789,7.836L201.755,239.6l10.791-7.836,10.785,7.836-4.123-12.683L230,219.08Z"
              fill="#f0f0f0"
            />
            <path
              d="M212.546,160.624l-4.123,12.684H195.088l10.789,7.835-4.122,12.683,10.791-7.835,10.785,7.835-4.123-12.683L230,173.308H216.663Z"
              fill="#f0f0f0"
            />
            <path
              d="M156.183,114.85l-4.122,12.685H138.725l10.79,7.836-4.121,12.683,10.789-7.836,10.786,7.836-4.123-12.683,10.791-7.836H160.3Z"
              fill="#f0f0f0"
            />
            <path
              d="M212.546,114.85l-4.123,12.685H195.088l10.789,7.836-4.122,12.683,10.791-7.836,10.785,7.836-4.123-12.683L230,127.535H216.663Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
