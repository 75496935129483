import React, { PureComponent } from "react";

export default class LR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 353.647"
      >
        <g transform="translate(0 -79.177)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 91.476)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="31.031"
              transform="translate(0 91.476)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="31.031"
              transform="translate(0 153.539)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="31.031"
              transform="translate(0 215.603)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="31.031"
              transform="translate(0 277.666)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="31.031"
              transform="translate(0 339.729)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="31.031"
              transform="translate(0 401.793)"
              fill="#d80027"
            />
          </g>
          <rect
            width="256"
            height="167.457"
            transform="translate(0 79.177)"
            fill="#0052b4"
          />
          <path
            d="M128,118.382l11.05,34.01h35.764l-28.932,21.021,11.05,34.012L128,186.4,99.066,207.425l11.053-34.012L81.187,152.392h35.762Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
