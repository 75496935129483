import React, { PureComponent } from "react";

export default class PF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#d80027"
            />
          </g>
          <path
            d="M293.991,256c0,20.982-17.01,33.243-37.992,33.243S218.008,276.982,218.008,256a37.992,37.992,0,1,1,75.983,0Z"
            fill="#ffda44"
          />
          <path d="M293.991,256a37.992,37.992,0,1,1-75.984,0" fill="#0052b4" />
          <g>
            <rect
              width="9.498"
              height="19"
              transform="translate(232.259 246.506)"
              fill="#d80027"
            />
            <rect
              width="9.498"
              height="19"
              transform="translate(270.247 246.506)"
              fill="#d80027"
            />
            <rect
              width="9.498"
              height="33.243"
              transform="translate(251.247 232.259)"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
