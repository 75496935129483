import React, { PureComponent } from "react";

export default class CV extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="22.261"
            transform="translate(0 322.777)"
            fill="#d80027"
          />
          <g>
            <rect
              width="512"
              height="22.261"
              transform="translate(0 300.516)"
              fill="#f0f0f0"
            />
            <rect
              width="512"
              height="22.261"
              transform="translate(0 345.038)"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M180.384,261.272l3,9.249h9.725l-7.867,5.715,3.005,9.248-7.867-5.715-7.867,5.715,3.005-9.248-7.866-5.715h9.724Z"
              fill="#ffda44"
            />
            <path
              d="M180.384,382.332l3,9.247h9.725l-7.867,5.715,3.005,9.248-7.867-5.715-7.867,5.715,3.005-9.248-7.866-5.715h9.724Z"
              fill="#ffda44"
            />
            <path
              d="M124.033,341.389l3,9.249h9.725l-7.866,5.715,3,9.248-7.867-5.715-7.867,5.715,3-9.248-7.867-5.715h9.725Z"
              fill="#ffda44"
            />
            <path
              d="M145.557,273.378l3,9.248h9.725l-7.866,5.716,3,9.248-7.867-5.716-7.867,5.716,3.005-9.248-7.867-5.716h9.725Z"
              fill="#ffda44"
            />
            <path
              d="M111.3,311.462h9.723l3-9.249,3.005,9.248h9.725l-7.867,5.716,3,9.25-7.868-5.716-7.866,5.713,3-9.247Z"
              fill="#ffda44"
            />
            <path
              d="M137.69,392.671l3.005-9.246-7.868-5.717h9.725l3.005-9.248,3,9.247h9.724l-7.867,5.716,3,9.249-7.867-5.716Z"
              fill="#ffda44"
            />
            <path
              d="M236.714,341.389l-3,9.249h-9.724l7.865,5.715-3,9.248,7.867-5.715,7.867,5.715-3-9.248,7.867-5.715h-9.725Z"
              fill="#ffda44"
            />
            <path
              d="M215.19,273.378l-3,9.248h-9.725l7.866,5.716-3,9.248,7.867-5.716,7.867,5.716-3.005-9.248,7.867-5.716h-9.725Z"
              fill="#ffda44"
            />
            <path
              d="M249.443,311.462h-9.722l-3.006-9.249-3,9.248h-9.724l7.867,5.716-3.006,9.25,7.868-5.716,7.866,5.713-3-9.247Z"
              fill="#ffda44"
            />
            <path
              d="M223.057,392.671l-3.005-9.246,7.867-5.717H218.2l-3.005-9.248-3.005,9.247h-9.724l7.867,5.716-3,9.249,7.867-5.716Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
