import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";

import ProductTableRow from "./ProductTableRow";
import ProductTableAuctionRow from "./ProductTableAuctionRow";

export class ProductTable extends PureComponent {
  render() {
    const {
      products,
      handleModalShow,
      deleteProduct,
      handleAuctionModalShow,
      deleteAuction,
    } = this.props;

    return (
      <div className="table-responsive">
        <Table
          striped
          hover
          responsive
          className="table"
          data-cy="product-table"
        >
          <thead>
            <tr>
              <th className="pl-3" data-cy="product-number">
                &#8470;
              </th>
              <th data-cy="product-image">Image</th>
              <th data-cy="product-name">Product name</th>
              <th data-cy="product-status">Status</th>
              <th data-cy="product-status">Buying Format</th>
              <th data-cy="product-btns">{""}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, i) =>
              product.shopify_product_id ? (
                <ProductTableAuctionRow
                  product={product}
                  positionNumber={i + 1}
                  key={product.shopify_product_id}
                  handleAuctionModalShow={handleAuctionModalShow}
                  deleteAuction={deleteAuction}
                />
              ) : (
                <ProductTableRow
                  product={product}
                  positionNumber={i + 1}
                  key={product.id}
                  handleModalShow={handleModalShow}
                  deleteProduct={deleteProduct}
                  handleAuctionModalShow={handleAuctionModalShow}
                />
              )
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ProductTable;
