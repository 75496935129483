export const navMenu = [
  {
    title: "Novelty",
    url: "Category_Novelty",
    badge: "new",
    type: "link",
  },
  {
    title: " Jewelry",
    url: "Product-Type_Jewelry",
    type: "link",
  },
  {
    title: "Watches",
    url: "Category_Watches",
    type: "link",
  },
  {
    title: "Clothing",
    url: "clothing",
  },
  {
    title: "Shoes",
    url: "shoes",
  },
  {
    title: "Bags",
    url: "bags",
  },
  {
    title: "Art",
    url: "Category_Arts",
  },
  {
    title: "Eyewear",
    url: "eyewear",
  },
  {
    title: "Accessories",
    url: "accessories",
  },
  {
    title: "Brands",
    url: "/pages/brands",
  },
];

export const mapTopMenuToNav = (topMenu) =>
  topMenu.map((menuItem) => {
    return {
      title: menuItem.category_title,
      url: menuItem.tag,
    };
  });

export const customerQuery = `query($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    acceptsMarketing
    createdAt
    displayName
    email
    firstName
    id
    lastName
    phone
    tags
    updatedAt
    orders(first: 250, reverse: true) {
      edges {
        node {
          id
          name
          processedAt
          financialStatus
          fulfillmentStatus
          statusUrl
          subtotalPriceV2{
            amount
          }
          totalShippingPriceV2{
            amount
          }
          totalTaxV2 {
            amount
          }
          totalPriceV2{
            amount
          }
          shippingAddress{
            name
            address1
            address2
            city
            zip
            country
          }
          lineItems(first: 50) {
            edges{
              node{
                title
                quantity
                discountedTotalPrice{
                  amount
                  currencyCode
                }
                originalTotalPrice{
                  amount
                }
                variant{
                  id
                  title
                  availableForSale
                  image{
                    transformedSrc(maxWidth: 240, maxHeight: 240, crop: CENTER)
                    altText
                  }
                  product{
                    onlineStoreUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    defaultAddress {
      id
      name
      firstName
      lastName
      address1
      address2
      company
      city
      phone
      province
      zip
      country
    }
    addresses(first: 5){
      edges {
        node {
          name
          firstName
          lastName
          id
          address1
          address2
          company
          city
          phone
          province
          zip
          country
        }
      }
    }
  }
  }`;

export const setSoundState = () => {
  const soundState = localStorage.getItem("Sound-State");
  if (soundState === "soundOn") {
    localStorage.setItem("Sound-State", "soundOff");
  } else {
    localStorage.setItem("Sound-State", "soundOn");
  }
};

export const handeleize = (string = "") => {
  return string
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const generatorUrl = (prefix, url) =>
  url.includes("/pages") ? url : `${prefix}/${url}`;
