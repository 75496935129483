import React, { PureComponent } from "react";

export default class NO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#d80027"
          />
          <path
            d="M512,295.883H202.2V426.666h-79.76V295.883H0V216.111H122.435V85.329H202.2V216.111H512v79.772Z"
            fill="#f0f0f0"
          />
          <path
            d="M512,234.666v42.663H183.652V426.666H140.978V277.329H0V234.666H140.978V85.329h42.674V234.666Z"
            fill="#2e52b2"
          />
        </g>
      </svg>
    );
  }
}
