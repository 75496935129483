import React, { PureComponent } from "react";

export default class PL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <g>
            <rect
              width="512"
              height="341.326"
              transform="translate(0 85.337)"
              fill="#f0f0f0"
            />
            <rect
              width="512"
              height="170.663"
              transform="translate(0 85.337)"
              fill="#f0f0f0"
            />
          </g>
          <rect
            width="512"
            height="170.663"
            transform="translate(0 256)"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
