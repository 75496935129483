import React, { PureComponent } from "react";

export default class GB extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#f0f0f0"
          />
          <path
            d="M288,85.33H224V224H0v64H224V426.662h64V288H512V224H288Z"
            fill="#d80027"
          />
          <g>
            <path d="M393.785,315.358,512,381.034V315.358Z" fill="#0052b4" />
            <path
              d="M311.652,315.358,512,426.662V395.188l-143.693-79.83Z"
              fill="#0052b4"
            />
            <path d="M458.634,426.662,311.652,345v81.664Z" fill="#0052b4" />
          </g>
          <path
            d="M311.652,315.358,512,426.662V395.188l-143.693-79.83Z"
            fill="#f0f0f0"
          />
          <path
            d="M311.652,315.358,512,426.662V395.188l-143.693-79.83Z"
            fill="#d80027"
          />
          <g>
            <path d="M90.341,315.356,0,365.546v-50.19Z" fill="#0052b4" />
            <path d="M200.348,329.51v97.151H25.491Z" fill="#0052b4" />
          </g>
          <path
            d="M143.693,315.358,0,395.188v31.474H0l200.348-111.3Z"
            fill="#d80027"
          />
          <g>
            <path d="M118.215,196.634,0,130.958v65.676Z" fill="#0052b4" />
            <path
              d="M200.348,196.634,0,85.33V116.8l143.693,79.83Z"
              fill="#0052b4"
            />
            <path d="M53.366,85.33l146.982,81.664V85.33Z" fill="#0052b4" />
          </g>
          <path
            d="M200.348,196.634,0,85.33V116.8l143.693,79.83Z"
            fill="#f0f0f0"
          />
          <path
            d="M200.348,196.634,0,85.33V116.8l143.693,79.83Z"
            fill="#d80027"
          />
          <g>
            <path d="M421.659,196.636,512,146.446v50.19Z" fill="#0052b4" />
            <path d="M311.652,182.482V85.331H486.509Z" fill="#0052b4" />
          </g>
          <path
            d="M368.307,196.634,512,116.8V85.33h0l-200.348,111.3Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
