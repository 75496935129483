import React, { PureComponent } from "react";

export default class GW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#6da544"
          />
          <rect
            width="512"
            height="170.663"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <path d="M98.32,195.127l15.107,46.5h48.894l-39.555,28.74,15.108,46.5L98.32,288.125,58.764,316.862l15.111-46.5-39.554-28.74H83.212Z" />
        </g>
      </svg>
    );
  }
}
