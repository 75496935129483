import { has, isEmpty, cloneDeep } from "lodash";

export const getSplicedArray = (arr, value, index) => {
  const splicedArr = [...arr];
  const method = splicedArr.length >= index ? 1 : 0;
  splicedArr.splice(index, method, value);
  return splicedArr;
};

export const splitOptionValues = (optionValues) => {
  const splittedOptionValues = [];
  optionValues.forEach((option) => {
    const splittedValue = option.split(",").map((value) => value.trim());
    splittedOptionValues.push(splittedValue);
  });
  return splittedOptionValues;
};

export const generateVariants = (optionValues) =>
  optionValues.reduce((a, b) => a.flatMap((x) => b.map((y) => x + y + "/")), [
    "",
  ]);

export const getValidVariants = (variantsData, defaultVariant) => {
  if (variantsData.length > 0) {
    const variantsDataArr = [...variantsData];
    const variantsDataArrSliced = variantsDataArr.slice(0, 99);
    const validVariantsData = variantsDataArrSliced.filter(
      (variant) => variant.deprecated === false || !has(variant, "deprecated")
    );
    validVariantsData.forEach((variant) => delete variant.deprecated);
    return validVariantsData;
  } else {
    return [defaultVariant];
  }
};

export const getSplittedVariant = (variant) => variant.split("/");

export const getVariantData = (variant) => ({
  options: variant.replace(/\/$/, "").split("/"),
  image: { src: "" },
  price: 0,
  compare_at_price: 0,
  quantity: 0,
  sku: "",
});

export const tagsList = {
  buyNow: "Buying Format_Buy Now",
  makeAnOffer: "Buying Format_Make an Offer",
};

export const getTagsValue = (tags) => {
  if (!isEmpty(tags)) {
    const { makeAnOffer, buyNow } = tagsList;
    const isMakeAnOffer = tags.includes(makeAnOffer);
    const tagsValue = isMakeAnOffer
      ? tags.replace(makeAnOffer, "")
      : tags.replace(buyNow, "");
    return tagsValue.replace(/^,/, "");
  } else {
    return "";
  }
};

export const getOptionsName = (options = []) =>
  options.map((option) => option.name);

export const getOptionsValues = (options = []) =>
  options.flatMap((option) => option.values.join());

export const getDefaultVariant = (
  options = [],
  variants = [{ selected_options: [] }]
) => {
  let defaultVariant = {};
  if (options.length > 0) {
    defaultVariant = {
      options: [],
      price: 0,
      compare_at_price: 0,
      quantity: 0,
      sku: "",
    };
  } else {
    defaultVariant = {
      ...variants[0],
      options: Object.values(variants[0].selected_options),
    };
    delete defaultVariant.selected_options;
  }
  return defaultVariant;
};

export const getVariantsData = (options, variants) => {
  const variantsArr = cloneDeep(variants);
  variantsArr.forEach((variant) => {
    variant.options = Object.values(variant.selected_options);
    delete variant.selected_options;
    if (!variant.image) {
      variant.image = { src: "" };
    }
  });
  return options.length > 0 ? variantsArr : [];
};

export const getVariantsImages = (options, variants) => {
  const imagesArr = [];
  variants.map((variant) =>
    variant.image ? imagesArr.push(variant.image) : imagesArr.push({ src: "" })
  );
  return options.length > 0 ? imagesArr : [];
};

export const getInitState = (product) => {
  const {
    tags,
    title,
    description_html,
    images,
    variants,
    options,
    video_src,
  } = product;
  const { makeAnOffer, buyNow } = tagsList;
  const isMakeAnOffer = tags.includes(makeAnOffer);
  const checkedTag = isMakeAnOffer ? makeAnOffer : buyNow;
  const tagsValue = getTagsValue(tags);
  const optionKeys = getOptionsName(options);
  const optionValues = getOptionsValues(options);
  const defaultVariant = getDefaultVariant(options, variants);
  const variantsData = getVariantsData(options, variants);
  const variantsImage = getVariantsImages(options, variants);
  const variantsImagesUrls = variantsImage.map(
    (variantImage) => variantImage.src
  );
  const productImages =
    images.length > 0
      ? images.filter((image) => !variantsImagesUrls.includes(image.src))
      : [];

  return {
    errorMsg: undefined,
    optionValues,
    optionKeys,
    variantsData,
    productImages,
    variantsImage,
    productData: { title, description_html, video_src },
    checkedTag,
    tagsValue,
    defaultVariant,
  };
};

export const getSelectedVariants = (selectedVariants = []) =>
  selectedVariants.map((variant) => {
    const isSelectedOptionsEmpty = isEmpty(variant.selected_options);
    return isSelectedOptionsEmpty
      ? []
      : Object.values(variant.selected_options).join("/") + "/";
  });

export const getProductsImageSources = (variants, productImages) => {
  const variantsImageSources =
    variants?.length > 0 ? variants.map((variant) => variant.image?.src) : [];
  const productImagesSources =
    productImages.length > 0 ? productImages.map((image) => image.src) : [];
  return productImagesSources.filter(
    (productImageSrc) => !variantsImageSources.includes(productImageSrc)
  );
};

export const getImagesSources = (filteredImagePreviewUrl) => {
  return filteredImagePreviewUrl?.length > 0
    ? filteredImagePreviewUrl.map((imagePreviewUrl) => ({
        src: imagePreviewUrl,
      }))
    : [];
};

export const filterImagesUrls = (imagePreviewUrl, e) => {
  return imagePreviewUrl.filter((item, index) => index !== e);
};

export const IMAGES_COUNT_LIMIT = 2;
export const IMAGES_SIZE_LIMIT = 2097152;
export const MAX_STRING_LENGTH = 100;
