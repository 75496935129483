import React, { PureComponent } from "react";

export default class NA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#496e2d"
          />
          <path d="M0,426.663V85.337H512" fill="#0052b4" />
          <path
            d="M512,152.222V85.337H411.67L0,359.778v66.885H100.33Z"
            fill="#f0f0f0"
          />
          <path
            d="M512,85.337v40.125L60.193,426.663H0V386.538l451.807-301.2Z"
            fill="#a2001d"
          />
          <path
            d="M187.737,189.212,165,199.908l12.11,22.024-24.693-4.724-3.129,24.945-17.2-18.347-17.2,18.347-3.129-24.945-24.693,4.723,12.109-22.023-22.739-10.7,22.74-10.7-12.11-22.022,24.693,4.722,3.13-24.944,17.2,18.347,17.2-18.347,3.128,24.944,24.693-4.722L165,178.517Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
