export const MENU_TOGGLE = "header/SHOW_MENU_TOGGLE";
export const MOBILE_MENU_TOGGLE = "header/SHOW_MOBILE_MENU_TOGGLE";
export const FETCH_CUSTOMER_REQUEST = "header/FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_REQUEST_RESULT =
  "header/FETCH_CUSTOMER_REQUEST_RESULT";
export const SET_REDIRECT_TO = "header/SET_REDIRECT_TO";
export const FETCH_USER_AUCTION_DATA_REQUEST =
  "header/FETCH_USER_AUCTION_DATA_REQUEST";
export const FETCH_USER_AUCTION_DATA_REQUEST_RESULT =
  "header/FETCH_USER_AUCTION_DATA_REQUEST_RESULT";
export const UPDATE_USER_AUCTION_DATA = "header/UPDATE_USER_AUCTION_DATA";
export const CREATE_CHECKOUT_REQUEST_RESULT =
  "header/CREATE_CHECKOUT_REQUEST_RESULT";
export const FETCH_CHECKOUT_REQUEST = "header/FETCH_CHECKOUT_REQUEST";
export const ADD_CHECKOUT_LINE_ITEMS_REQUEST =
  "header/ADD_CHECKOUT_LINE_ITEMS_REQUEST";
export const REMOVE_CHECKOUT_LINE_ITEMS_REQUEST =
  "header/REMOVE_CHECKOUT_LINE_ITEMS_REQUEST";
export const UPDATE_CHECKOUT_LINE_ITEMS_REQUEST =
  "header/UPDATE_CHECKOUT_LINE_ITEMS_REQUEST";

export function fetchCustomerRequest(params) {
  return { type: FETCH_CUSTOMER_REQUEST, payload: params };
}

export function fetchCustomerRequestResult(response) {
  return { type: FETCH_CUSTOMER_REQUEST_RESULT, payload: response };
}

export function menuToggle(value) {
  return { type: MENU_TOGGLE, payload: value };
}
export function mobileMenuToggle(value) {
  return { type: MOBILE_MENU_TOGGLE, payload: value };
}

export function setRedirectTo(value) {
  return { type: SET_REDIRECT_TO, payload: value };
}

export function fetchUserAuctionDataRequest() {
  return { type: FETCH_USER_AUCTION_DATA_REQUEST };
}

export function fetchUserAuctionDataRequestResult(response) {
  return { type: FETCH_USER_AUCTION_DATA_REQUEST_RESULT, payload: response };
}

export function updateUserAuctionData(params) {
  return { type: UPDATE_USER_AUCTION_DATA, payload: params };
}

export function createCheckoutRequestResult(response) {
  return { type: CREATE_CHECKOUT_REQUEST_RESULT, payload: response };
}

export function fetchCheckoutRequest(value) {
  return { type: FETCH_CHECKOUT_REQUEST, payload: value };
}

export function addCheckoutLineItemsRequest(value) {
  return { type: ADD_CHECKOUT_LINE_ITEMS_REQUEST, payload: value };
}

export function removeCheckoutLineItemsRequest(value) {
  return { type: REMOVE_CHECKOUT_LINE_ITEMS_REQUEST, payload: value };
}

export function updateCheckoutLineItemsRequest(value) {
  return { type: UPDATE_CHECKOUT_LINE_ITEMS_REQUEST, payload: value };
}
