import React, { PureComponent } from "react";
import { Button, Collapse, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "storefront/features/shared/icons";

export default class MobileSubMenuLinkList extends PureComponent {
  state = { open: false };

  handleMenuItemClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const { title, url, data } = this.props;

    const LinkList = () => {
      if (data) {
        return (
          <div className="ml-3">
            <Button
              variant="light"
              className="d-flex w-100 justify-content-between align-items-center my-2"
              onClick={this.handleMenuItemClick}
              aria-controls="sub-level-menu-2"
              aria-expanded={this.state.open}
            >
              {title}
              <Icon type="close" />
            </Button>
            <Collapse in={this.state.open}>
              <ListGroup variant="flush" className="sub-level-menu-2 ml-4">
                {data.map((item, index) => (
                  <Link key={title + index} to={item.url}>
                    {item.title}
                  </Link>
                ))}
              </ListGroup>
            </Collapse>
          </div>
        );
      } else {
        return (
          <>
            <Link to={url} className="ml-3">
              {title}
            </Link>
          </>
        );
      }
    };

    return <LinkList />;
  }
}
