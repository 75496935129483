import styled from "styled-components";
import { Card } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledImageAndTitle = styled(Card.Title)`
  line-height: 1.65;
  cursor: pointer;
  margin-bottom: 0;
  a {
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
    img {
      max-width: 100%;
      @media ${device.tabletS} {
        max-width: 213px;
      }
    }
  }
  .product-title {
    padding-top: 20px;
    margin-bottom: 25px;
    overflow: hidden;
    height: 65px;
    letter-spacing: 1px;
    color: #000;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .variant-title {
    margin-bottom: 0;
    min-height: 21px;
    height: 21px;
    font-style: italic;
    font-weight: 100;
    letter-spacing: 1px;
    font-size: 12px;
    color: #222222;
  }
`;
