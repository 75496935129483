import React, { PureComponent } from "react";

export default class RS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.882)"
            fill="#f0f0f0"
          />
          <path
            d="M129.468,181.793v85.136c0,48.429,63.267,63.267,63.267,63.267S256,315.356,256,266.929V181.793H129.468Z"
            fill="#d80027"
          />
          <g>
            <path
              d="M155.634,196.634h74.2V166.953l-14.841,7.42-22.261-22.26-22.259,22.26-14.84-7.42Z"
              fill="#ffda44"
            />
            <path
              d="M241.16,278.782l-48.231-48.231L144.7,278.782l15.741,15.74,32.49-32.49,32.49,32.49Z"
              fill="#ffda44"
            />
          </g>
          <path
            d="M241.16,233.734h-22.5a14.834,14.834,0,1,0-25.922-14.422,14.834,14.834,0,1,0-25.922,14.422H144.7c0,8.2,7.139,14.84,15.334,14.84h-.494a14.84,14.84,0,0,0,14.84,14.84,14.837,14.837,0,0,0,12.094,14.576l-11.694,26.4a48.243,48.243,0,0,0,36.3,0l-11.694-26.4a14.837,14.837,0,0,0,12.094-14.576,14.84,14.84,0,0,0,14.84-14.84h-.494C234.021,248.574,241.16,241.93,241.16,233.734Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
