import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { StyledReviewsCarouselContainer } from "./PrismicReviewsCarousel.styled";
import { getReviewsCarouselInfo } from "../selectors";
import * as actions from "../actions";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

class PrismicReviewsCarousel extends PureComponent {
  state = { reviewsCarouselInfo: {} };

  componentDidMount() {
    const { fetchReviewsCarouselRequest } = this.props;
    fetchReviewsCarouselRequest();
  }

  componentDidUpdate() {
    const { reviewsCarouselInfo } = this.props;
    if (reviewsCarouselInfo) {
      this.setState({ reviewsCarouselInfo });
    }
  }

  render() {
    const {
      reviewsCarouselInfo: { banners, enabled, autoplay_timeout },
    } = this.state;

    return (
      <>
        {enabled && (
          <StyledReviewsCarouselContainer className="mt-2 mt-lg-4">
            <Swiper
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: autoplay_timeout * 1000,
                disableOnInteraction: false,
              }}
            >
              {banners.map(function (banner, index) {
                return (
                  <SwiperSlide key={index}>
                    <img src={banner.banner} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </StyledReviewsCarouselContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  reviewsCarouselInfo: getReviewsCarouselInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchReviewsCarouselRequest: () => {
    dispatch(actions.fetchReviewsCarouselRequest());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PrismicReviewsCarousel));
