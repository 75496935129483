import React, { PureComponent } from "react";

export default class AddressLines extends PureComponent {
  render() {
    const {
      name,
      company,
      address1,
      address2,
      city,
      zip,
      country,
    } = this.props.data;
    return (
      <>
        <p>{name}</p>
        <p>{company}</p>
        <p>{address1}</p>
        {address2 && <p>{address2}</p>}
        <p>{city}</p>
        <p>{zip}</p>
        <p>{country}</p>
      </>
    );
  }
}
