import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";

import AuctionForm from "./AuctionForm";

class ModalAuction extends PureComponent {
  handleClose = (successMsg) => {
    const { handleModalClose } = this.props;
    handleModalClose(successMsg);
  };

  render() {
    const { show, product } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop="static"
        size="sm"
        data-cy="auction-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add auction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AuctionForm handleModalClose={this.handleClose} product={product} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalAuction;
