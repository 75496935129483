import React, { PureComponent } from "react";

export default class SK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="118.717"
            transform="translate(0 196.641)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="111.304"
            transform="translate(0 315.359)"
            fill="#d80027"
          />
          <path
            d="M129.468,181.8v85.136c0,48.429,63.267,63.267,63.267,63.267S256,315.362,256,266.935V181.8Z"
            fill="#f0f0f0"
          />
          <path
            d="M146.126,184.294v81.941a34.357,34.357,0,0,0,3.623,15.485h85.97a34.351,34.351,0,0,0,3.623-15.485V184.294Z"
            fill="#d80027"
          />
          <path
            d="M221.3,241.427H199.876V227.144H214.16V212.861H199.876V198.577H185.593v14.284H171.311v14.283h14.282v14.283H164.167v14.284h21.426v14.283h14.283V255.711H221.3Z"
            fill="#f0f0f0"
          />
          <path
            d="M169.232,301.658a102.619,102.619,0,0,0,23.5,10.636,102.619,102.619,0,0,0,23.5-10.636c9.282-5.833,15.79-12.506,19.484-19.939a24.951,24.951,0,0,0-20.1-3.926,25,25,0,0,0-45.768,0,24.951,24.951,0,0,0-20.1,3.926C153.443,289.152,159.95,295.825,169.232,301.658Z"
            fill="#0052b4"
          />
        </g>
      </svg>
    );
  }
}
