import { fromJS } from "immutable";
import { isEmpty } from "lodash";

import * as actions from "./actions";
import { isTagExist } from "storefront/features/shared/utils";

const initialState = fromJS({
  product: {},
  updateProductGridRequired: false,
  quickViewIsOpened: false,
});

const setProduct = (state, { bid_auction, product }) => {
  const isAuction = isTagExist(product.tags, "Buying Format_Auction");
  const endAt = isAuction ? bid_auction.end_at : product.end_at;
  const relatedId = isAuction ? bid_auction.id : product.id;

  if (isEmpty(bid_auction)) {
    return state.set("product", fromJS(product));
  } else {
    return state.set(
      "product",
      fromJS({
        ...bid_auction,
        ...product,
        id: relatedId,
        end_at: endAt,
        bidAuctionId: bid_auction.id,
      })
    );
  }
};

const updateProduct = (state, action) =>
  state.set("product", fromJS(action.payload));

const setFlagUpdateProductsGrid = (state, action) =>
  state.set("updateProductGridRequired", fromJS(action.payload));

const setFlagQuickViewIsOpened = (state, action) =>
  state.set("quickViewIsOpened", fromJS(action.payload));

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PRODUCT_REQUEST_RESULT:
      return setProduct(state, action.payload);
    case actions.UPDATE_QUICK_VIEW_PRODUCT:
      return updateProduct(state, action);
    case actions.SET_FLAG_UPDATE_PRODUCT_GRID:
      return setFlagUpdateProductsGrid(state, action);
    case actions.QUICK_VIEW_IS_OPENED:
      return setFlagQuickViewIsOpened(state, action);
    default:
      return state;
  }
};

export default productReducer;
