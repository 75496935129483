import React, { PureComponent } from "react";

export default class MW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect width="512" height="113.775" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#496e2d"
          />
          <path
            d="M307.01,166.957l-20.842-9.8,11.1-20.186L274.633,141.3l-2.868-22.864L256,135.248l-15.764-16.816L237.367,141.3l-22.633-4.329,11.1,20.186-20.843,9.8Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
