import React, { PureComponent } from "react";

export default class PY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#0052b4"
          />
          <path
            d="M289.579,216.738,276.987,229.33a29.68,29.68,0,1,1-41.974,0l-12.592-12.592a47.489,47.489,0,1,0,67.158,0Z"
            fill="#6da544"
          />
          <path
            d="M256,232.51l4.421,13.605h14.3l-11.573,8.408,4.421,13.6L256,259.719l-11.573,8.408,4.421-13.6-11.573-8.408h14.3Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
