import { createSelector } from "reselect";

export const getMenuState = (state) => state.getIn(["headerReducer", "open"]);

export const getMobileMenuState = (state) =>
  state.getIn(["headerReducer", "drawer"]);

export const getRedirectTo = (state) =>
  state.getIn(["headerReducer", "redirectTo"]);

export const getCustomer = (state) =>
  state.getIn(["headerReducer", "customer"]);

export const getAuctionData = (state) =>
  state.getIn(["headerReducer", "userAuctionData"]);

export const getCheckout = (state) =>
  state.getIn(["headerReducer", "checkout"]);

export const getUserAuctionData = createSelector(getAuctionData, (data) =>
  data.get("bid_auctions")
);

export const getCustomerId = createSelector(getCustomer, (customer) =>
  customer.get("id")
);

export const getCustomerAffiliateId = createSelector(
  getAuctionData,
  (customer) => customer.get("affiliate_id")
);

export const getCheckoutLineItems = createSelector(
  getCheckout,
  (checkout) => checkout.lineItems
);
