import { fromJS } from "immutable";

const initialState = fromJS({
  top_menu: {},
  shipping_prices: {},
});

const ssrReducer = (state = initialState) => state;

export default ssrReducer;
