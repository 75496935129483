import styled from "styled-components";

export const StyledSproutBannerContainer = styled.div`
  .sprout-banner-left,
  .sprout-banner-right {
    img {
      display: none;

      @media (min-width: 1750px) {
        display: block;
        width: 585px;
      }
    }
  }

  .sprout-banner-center {
    margin: 0 20px;

    #SproutTreeCountVintage {
      width: 100%;

      @media (min-width: 575px) {
        width: 500px;
      }

      @media (min-width: 768px) {
        width: 680px;
      }

      @media (min-width: 992px) {
        width: 920px;
      }

      @media (min-width: 1200px) {
        width: 1095px;
      }

      @media (min-width: 1750px) {
        width: 450px;
      }

      @media (min-width: 1920px) {
        width: 622px;
      }

      @media (min-width: 1970px) {
        width: 650px;
      }
    }
  }
`;
