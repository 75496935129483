import React, { PureComponent } from "react";

export default class BF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#6da544"
          />
          <rect
            width="512"
            height="170.663"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <path
            d="M256,196.632l12.891,39.679h41.726l-33.755,24.525,12.892,39.68L256,275.992l-33.754,24.524,12.894-39.68-33.755-24.525h41.722Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
