import React, { PureComponent } from "react";
import { Modal, Button, Form as BSForm, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import Field from "storefront/features/shared/components/field/Field";
import { connect } from "react-redux";
import { countriesList } from "../utils";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import * as actions from "../actions";
import {
  getCurrentAddress,
  getModalState,
  getCustomerDefaultAddressId,
} from "../selectors";

class AddAddressModal extends PureComponent {
  handleClose = () => {
    const { currentAddress = {} } = this.props;
    this.props.setModalAddresses(false, currentAddress);
  };

  handleCountryChange = (data) => {
    console.log(data);
  };

  handleSubmit = (data) => {
    const { createAddress, updateAddress, currentAddress = {} } = this.props;

    this.checkIsNewAddress(currentAddress)
      ? createAddress(data, currentAddress.id)
      : updateAddress(data, currentAddress.id);
    this.props.setModalAddresses(false, data);
  };

  checkIsNewAddress = (value) => {
    return !Object.keys(value).length;
  };

  render() {
    const { modalState, defaultAddressId, currentAddress = {} } = this.props;
    const {
      firstName,
      lastName,
      company,
      phone,
      address1,
      address2,
      city,
      country,
      province,
    } = currentAddress;
    const isNewAddress = this.checkIsNewAddress(currentAddress);
    const isDefaultAddress =
      !isNewAddress && defaultAddressId === currentAddress.id;
    return (
      <Modal
        show={modalState}
        onHide={this.handleClose}
        data-cy="ap-address-modal"
      >
        <div className="p-2 p-md-4">
          <Modal.Header closeButton>
            <Modal.Title>
              {isNewAddress ? (
                <p className="text-uppercase">Add a new Address</p>
              ) : (
                <p className="text-uppercase">Edit Address</p>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">
              {isNewAddress
                ? `Please fill in the information below:`
                : `Please change the information below:`}
            </p>
            <Form
              onSubmit={this.handleSubmit}
              initialValues={{
                firstName,
                lastName,
                company,
                phone,
                address1,
                address2,
                city,
                country,
                province,
              }}
              render={({ handleSubmit, submitting, pristine }) => (
                <BSForm onSubmit={handleSubmit}>
                  <BSForm.Group>
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      data-cy="address-modal-first-name"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      data-cy="address-modal-last-name"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="company"
                      type="text"
                      placeholder="Company"
                      data-cy="address-modal-company"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      data-cy="address-modal-phone"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="address1"
                      type="text"
                      placeholder="Address 1"
                      data-cy="address-modal-address1"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="address2"
                      type="text"
                      placeholder="Address 2"
                      data-cy="address-modal-address2"
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="city"
                      type="text"
                      placeholder="City"
                      data-cy="address-modal-city"
                    />
                  </BSForm.Group>
                  <BSForm.Row>
                    <BSForm.Group as={Col}>
                      <Field
                        name="country"
                        as="select"
                        onChange={this.handleCountryChange}
                        data-cy="address-modal-country"
                      >
                        {Object.keys(countriesList).map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Field>
                    </BSForm.Group>
                    <BSForm.Group as={Col}>
                      <Field
                        name="zip"
                        type="text"
                        placeholder="Zip code"
                        data-cy="address-modal-zip"
                      />
                    </BSForm.Group>
                  </BSForm.Row>
                  <BSForm.Group>
                    <Field
                      className="w-100"
                      name="province"
                      type="text"
                      placeholder="State"
                      data-cy="address-modal-province"
                    />
                  </BSForm.Group>
                  {!isDefaultAddress && (
                    <BSForm.Group>
                      <Field
                        name="defaultAddress"
                        type="checkbox"
                        label="Set as default address"
                        data-cy="address-modal-default-address"
                      />
                    </BSForm.Group>
                  )}
                  <BSForm.Group className="text-center">
                    <Button
                      className="w-50"
                      type="submit"
                      variant="primary"
                      data-cy="address-modal-submit"
                      disabled={submitting || pristine}
                    >
                      {isNewAddress ? "Save an address" : "Submit"}
                    </Button>
                  </BSForm.Group>
                </BSForm>
              )}
            />
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentAddress: getCurrentAddress,
  defaultAddressId: getCustomerDefaultAddressId,
  modalState: getModalState,
});

const mapDispatchToProps = (dispatch) => ({
  setModalAddresses: (open, data) => {
    dispatch(actions.setModalAddresses({ open, data }));
  },
  createAddress: (address) => {
    dispatch(actions.fetchCustomerAddressCreateRequest({ address }));
  },
  updateAddress: (address, id) => {
    dispatch(actions.fetchCustomerAddressUpdateRequest({ address, id }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(AddAddressModal));
