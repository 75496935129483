import styled from "styled-components";
import { Button, Row } from "react-bootstrap";

export const StyledRow = styled(Row)`
  background-color: #f2f2f2;
  margin: 10px 0px;
  padding: 10px;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
