import React, { PureComponent } from "react";

export default class AI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#0052b4"
          />
          <path
            d="M427.757,295.819a41.619,41.619,0,0,0,8.185-24.982v-74.2A37.1,37.1,0,0,1,384,189.216a37.108,37.108,0,0,1-51.943,7.421v74.2a41.615,41.615,0,0,0,8.185,24.982h87.516Z"
            fill="#f3f3f3"
          />
          <g>
            <path
              d="M412.32,257.071c2.362-5.763,4.2-14.188,4.2-18.382,0-6.737-8.783-12.2-8.783-12.2s-8.783,5.461-8.783,12.2c0,4.194,1.838,12.619,4.2,18.382l-5.07,11.448a25.659,25.659,0,0,0,19.306,0Z"
              fill="#ff9811"
            />
            <path
              d="M377.994,220c-6.171.836-14.385,3.459-18.017,5.556-5.835,3.369-6.173,13.7-6.173,13.7s9.121,4.875,14.955,1.507c3.632-2.1,10.008-7.9,13.816-12.828l12.451-1.333a25.663,25.663,0,0,0-9.653-16.72Z"
              fill="#ff9811"
            />
            <path
              d="M363.059,268.267c3.809,4.925,10.187,10.728,13.818,12.824,5.835,3.368,14.955-1.507,14.955-1.507s-.339-10.336-6.173-13.7c-3.633-2.1-11.846-4.717-18.019-5.552l-7.378-10.115a25.65,25.65,0,0,0-9.653,16.72Z"
              fill="#ff9811"
            />
          </g>
          <path
            d="M338.408,293.1C352.267,315.335,384,322.778,384,322.778s31.733-7.443,45.592-29.679Z"
            fill="#338af3"
          />
          <g>
            <path
              d="M256,186.665H144.006V256H160V207.078L233.372,256H256l-80.006-53.337h22.628L256,240.915V233.38l-46.069-30.72H256Z"
              fill="#f0f0f0"
            />
            <path
              d="M175.994,138.66,256,85.334H233.372L160,134.241V85.334H144.006v69.331H256V138.66H209.931L256,107.951v-7.535L198.623,138.66Z"
              fill="#f0f0f0"
            />
            <path
              d="M96,85.334v48.907L22.628,85.334H0L80.006,138.66H57.377L0,100.415v7.536L46.069,138.66H0v16.006H111.994V85.334Z"
              fill="#f0f0f0"
            />
            <path
              d="M0,186.665v15.994H46.069L0,233.38v7.535L57.377,202.66H80L0,256H22.628L96,207.078V256h15.994V186.665Z"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M144,85.329H112v69.334H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path
              d="M80,138.663,0,85.329v15.083l57.377,38.251Z"
              fill="#d80027"
            />
            <path d="M176,138.663h22.623L256,100.412V85.329Z" fill="#d80027" />
            <path d="M57.377,202.663,0,240.915V256l80-53.334Z" fill="#d80027" />
            <path
              d="M176,202.663,256,256V240.915l-57.377-38.252Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
