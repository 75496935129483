import React from "react";
import { Form } from "react-bootstrap";
import { Field as FinalFormField } from "react-final-form";

const FieldInput = ({ input, meta: { touched, error, warning }, ...props }) => (
  <>
    <Form.Control
      {...props}
      {...input}
      isValid={touched && !error}
      isInvalid={touched && error}
    />
    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {warning && <Form.Text>{warning}</Form.Text>}
  </>
);
const FieldInputCheck = ({
  input,
  meta: { touched, error, warning },
  label,
  ...props
}) => (
  <Form.Check>
    <Form.Check.Input
      {...props}
      {...input}
      isValid={touched && !error}
      isInvalid={touched && error}
    />
    <Form.Check.Label>{label}</Form.Check.Label>
    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {warning && <Form.Text>{warning}</Form.Text>}
  </Form.Check>
);

const Field = (props) => {
  const FieldComponent =
    props.type === "checkbox" ? FieldInputCheck : FieldInput;

  return <FinalFormField component={FieldComponent} {...props} />;
};

export default Field;
