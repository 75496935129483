import React, { PureComponent } from "react";

export default class SN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(330.207 85.331)"
            fill="#d80027"
          />
          <g>
            <rect
              width="181.793"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#496e2d"
            />
            <path
              d="M256,196.632l14.733,45.347h47.685l-38.576,28.029,14.734,45.348L256,287.33l-38.577,28.026,14.737-45.348-38.576-28.029h47.681Z"
              fill="#496e2d"
            />
          </g>
        </g>
      </svg>
    );
  }
}
