import { fromJS } from "immutable";

import * as actions from "./actions";
import { mergeProductAuctionData } from "storefront/features/shared/utils";
import { updateProduct } from "storefront/features/shared/bidAuctionsReducer";

const initialState = fromJS({
  products: {},
  dropDownProduct: {},
  error: null,
});

const NO_RESULT_MSG = "NO RESULTS FOUND";

const setSearchError = (state, action) =>
  state.set("error", action.payload.error ? NO_RESULT_MSG : null);

const setSearchProducts = (state, action) =>
  state.set("products", fromJS(mergeProductAuctionData(action.payload)));

const setDropDownSearchProducts = (state, action) =>
  state.set("dropDownProduct", fromJS(mergeProductAuctionData(action.payload)));

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SEARCH_REQUEST_RESULT:
      return setSearchProducts(state, action);
    case actions.FETCH_SEARCH_REQUEST_RESULT_ERROR:
      return setSearchError(state, action);
    case actions.FETCH_DROPDOWN_SEARCH_REQUEST_RESULT:
      return setDropDownSearchProducts(state, action);
    case actions.UPDATE_SEARCH_PRODUCT:
      return updateProduct(state, action);
    default:
      return state;
  }
};

export default searchReducer;
