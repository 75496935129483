export const FETCH_ADD_PRODUCT_IMAGE_REQUEST =
  "productsSettings/FETCH_ADD_PRODUCT_IMAGE_REQUEST";
export const FETCH_ADD_PRODUCT_REQUEST =
  "productsSettings/FETCH_ADD_PRODUCT_REQUEST";
export const FETCH_UPDATE_PRODUCT_REQUEST =
  "productsSettings/FETCH_UPDATE_PRODUCT_REQUEST";
export const FETCH_DELETE_PRODUCT_REQUEST =
  "productsSettings/FETCH_DELETE_PRODUCT_REQUEST";
export const FETCH_PRODUCT_REQUEST = "productsSettings/FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_REQUEST_RESULT =
  "productsSettings/FETCH_PRODUCT_REQUEST_RESULT";
export const FETCH_ADD_AUCTION_REQUEST =
  "productsSettings/FETCH_ADD_AUCTION_REQUEST";
export const FETCH_UPDATE_AUCTION_REQUEST =
  "productsSettings/FETCH_UPDATE_AUCTION_REQUEST";
export const FETCH_DELETE_AUCTION_REQUEST =
  "productsSettings/FETCH_DELETE_AUCTION_REQUEST";
export const FETCH_UPLOAD_FILE_REQUEST =
  "productsSettings/FETCH_UPLOAD_FILE_REQUEST";

export function fetchAddProductImageRequest(data) {
  return { type: FETCH_ADD_PRODUCT_IMAGE_REQUEST, payload: data };
}

export function fetchAddProductRequest(data) {
  return { type: FETCH_ADD_PRODUCT_REQUEST, payload: data };
}

export function fetchUpdateProductRequest(data) {
  return { type: FETCH_UPDATE_PRODUCT_REQUEST, payload: data };
}

export function fetchDeleteProductRequest(data) {
  return { type: FETCH_DELETE_PRODUCT_REQUEST, payload: data };
}

export function fetchProductRequest(data) {
  return { type: FETCH_PRODUCT_REQUEST, payload: data };
}

export function fetchProductRequestResult(data) {
  return { type: FETCH_PRODUCT_REQUEST_RESULT, payload: data };
}

export function fetchAddAuctionRequest(data) {
  return { type: FETCH_ADD_AUCTION_REQUEST, payload: data };
}

export function fetchUpdateAuctionRequest(data) {
  return { type: FETCH_UPDATE_AUCTION_REQUEST, payload: data };
}

export function fetchDeleteAuctionRequest(data) {
  return { type: FETCH_DELETE_AUCTION_REQUEST, payload: data };
}

export function fetchUploadFileRequest(data) {
  return { type: FETCH_UPLOAD_FILE_REQUEST, payload: data };
}
