import React, { PureComponent, createRef } from "react";
import { compose } from "redux";
import { Button, Row, Col, Form as BSForm, Container } from "react-bootstrap";
import { Form } from "react-final-form";
import { Field, BackButton } from "storefront/features/shared";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";

import * as actions from "../actions";
import {
  StyledImage,
  IconWrapper,
  DetailsButtonStyled,
} from "./AccountSettings.styled";
import Icon from "storefront/features/shared/icons";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import VendorSettings from "./VendorSettings";
import withRedirectUnregisteredUser from "storefront/features/shared/components/RedirectUnregisteredUser";

class AccountSettings extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  state = {
    avatar: "",
    imagePreviewUrl: "",
    successMsg: null,
    errorMsg: null,
    disabledBtn: true,
  };

  componentDidMount() {
    this.updateImagePreviewUrl();
  }

  componentDidUpdate(prevProps) {
    const {
      userAuctionData: { winning_customer_avatar },
    } = this.props;
    if (winning_customer_avatar !== prevProps.winning_customer_avatar) {
      this.updateImagePreviewUrl();
    }
  }

  updateImagePreviewUrl = () => {
    const {
      userAuctionData: { winning_customer_avatar },
    } = this.props;
    if (winning_customer_avatar) {
      this.setState({
        imagePreviewUrl: winning_customer_avatar,
      });
    }
  };

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleResponseSuccess = (successMsg) => {
    this.setState({ successMsg });
  };

  handleSubmitForm = () => {
    const { fetchChangeCustomerAvatar } = this.props;
    const { avatar } = this.state;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    fetchChangeCustomerAvatar({ avatar, handleErrors, handleSuccess });
    this.setState({
      disabledBtn: true,
    });
  };

  handleImageChange = (e) => {
    const reader = new FileReader();
    const avatar = e.target.files[0];

    if (avatar.size > 2097152) {
      this.setState({ errorMsg: "File is too big." });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        avatar,
        imagePreviewUrl: reader.result,
        disabledBtn: false,
      });
    };

    reader.readAsDataURL(avatar);
  };

  handleClick = () => {
    if (!this.ref.current) {
      return;
    }
    this.ref.current.click();
  };

  handleDelete = () => {
    const { fetchDeleteCustomerAvatar } = this.props;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    this.setState({
      imagePreviewUrl: "",
    });
    fetchDeleteCustomerAvatar({ handleErrors, handleSuccess });
  };

  render() {
    const acceptedStatuses = ["accepted", "pending"];
    const { imagePreviewUrl, disabledBtn, errorMsg, successMsg } = this.state;
    const {
      userAuctionData: { vendor, vendor_status, vendor_id },
    } = this.props;

    if (vendor && acceptedStatuses.includes(vendor_status)) {
      return (
        <Container className="my-5">
          <Row className="mb-3">
            <Col className="d-flex flex-row text-center text-md-left">
              <BackButton
                className="btn btn-primary text-uppercase"
                color="white"
              />
              <DetailsButtonStyled
                to={{ pathname: `/account/details/${vendor_id}` }}
                className="btn btn-primary text-uppercase"
                data-cy="ap-account-details-btn"
              >
                Account details
              </DetailsButtonStyled>
            </Col>
          </Row>
          <hr />
          <VendorSettings status={vendor_status} id={vendor_id} />
        </Container>
      );
    } else if (vendor === undefined) {
      return (
        <Container className="my-5">
          <Row className="mb-3">
            <Col>
              <BackButton
                className="btn btn-primary text-uppercase"
                color="white"
              />
            </Col>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container className="my-5">
          <Row className="mb-3">
            <Col>
              <BackButton
                className="btn btn-primary text-uppercase"
                color="white"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="text-uppercase mb-3">Account settings</h1>
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <Form
                noValidate
                onSubmit={this.handleSubmitForm}
                render={({ handleSubmit }) => (
                  <BSForm onSubmit={handleSubmit}>
                    <BSForm.Row className="justify-content-start">
                      <Col
                        xs={"auto"}
                        md={"auto"}
                        className="text-left my-auto mr-3"
                      >
                        <p>Please select avatar:</p>
                      </Col>
                      <Col
                        xs={"auto"}
                        md={"auto"}
                        className="text-left my-auto mr-3"
                      >
                        <BSForm.Group>
                          <Field name="avatar">
                            {({ input }) => (
                              <input
                                {...input}
                                onChange={this.handleImageChange}
                                type="file"
                                className="d-none"
                                ref={this.ref}
                                accept="image/*"
                              />
                            )}
                          </Field>
                          {imagePreviewUrl ? (
                            <StyledImage
                              src={imagePreviewUrl}
                              roundedCircle
                              onClick={this.handleClick}
                            />
                          ) : (
                            <IconWrapper
                              className="rounded-circle d-inline-flex justify-content-center"
                              onClick={this.handleClick}
                            >
                              <Icon type="login" size={25} />
                            </IconWrapper>
                          )}
                        </BSForm.Group>
                      </Col>
                      <Col
                        xs={12}
                        md={"auto"}
                        className="text-left my-auto p-0"
                      >
                        <BSForm.Group>
                          <Button
                            className="mr-3"
                            type="submit"
                            variant="primary"
                            disabled={disabledBtn}
                          >
                            Upload
                          </Button>
                          <Button variant="primary" onClick={this.handleDelete}>
                            Delete
                          </Button>
                        </BSForm.Group>
                      </Col>
                    </BSForm.Row>
                    <BSForm.Row className="justify-content-start">
                      <Col
                        xs={12}
                        md={3}
                        className="text-left my-auto mr-3 p-0"
                      >
                        {errorMsg && (
                          <p className="invalid-feedback d-block">{errorMsg}</p>
                        )}
                        {successMsg && (
                          <p className="valid-feedback d-block">{successMsg}</p>
                        )}
                      </Col>
                    </BSForm.Row>
                  </BSForm>
                )}
              />
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchChangeCustomerAvatar: (data) => {
    dispatch(actions.fetchChangeCustomerAvatarRequest(data));
  },
  fetchDeleteCustomerAvatar: (data) => {
    dispatch(actions.fetchDeleteCustomerAvatarRequest(data));
  },
});

export default compose(
  withRedirectUnregisteredUser,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(AccountSettings);
