import React from "react";
import { getPagination } from "../utils";
import PageItem from "./PageItem";
import { StyledPagination } from "./PaginationWrapper.styled";

export default class PaginationWrapper extends React.Component {
  render() {
    const { onClick } = this.props;
    const pagination = getPagination(this.props);
    return (
      <StyledPagination className="justify-content-center flex-wrap">
        {pagination.map((page, i) => (
          <PageItem
            key={`${page}-${i}`}
            text={page.text}
            page={page.page}
            className={page.class}
            onClick={onClick}
          />
        ))}
      </StyledPagination>
    );
  }
}

PaginationWrapper.defaultProps = {
  currentPage: 1,
  activeClass: "active",
  disabledClass: "disabled",
  prevText: "⟨",
  nextText: "⟩",
};
