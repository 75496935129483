import React, { PureComponent } from "react";

export default class KR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#fff"
          />
          <path
            d="M318.33,255.994c0,15.583-27.906,54.539-62.33,54.539s-62.33-38.957-62.33-54.539a62.33,62.33,0,0,1,124.66,0Z"
            fill="#d80027"
          />
          <path d="M318.33,255.994a62.33,62.33,0,0,1-124.66,0" fill="#0052b4" />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(322.114 311.096) rotate(-45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(294.566 338.631) rotate(-45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(355.17 344.132) rotate(-45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(327.621 371.689) rotate(-45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(338.641 327.614) rotate(-45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(311.094 355.159) rotate(-45)"
          />
          <rect
            width="62.33"
            height="15.582"
            transform="translate(355.177 200.896) rotate(-135)"
          />
          <rect
            width="23.374"
            height="15.581"
            transform="translate(311.099 189.881) rotate(-135)"
          />
          <rect
            width="23.374"
            height="15.581"
            transform="translate(338.635 217.431) rotate(-135)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(344.135 156.825) rotate(-135)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(371.694 184.374) rotate(-135)"
          />
          <rect
            width="62.33"
            height="15.582"
            transform="translate(140.309 327.611) rotate(45)"
          />
          <rect
            width="23.374"
            height="15.581"
            transform="translate(184.368 338.635) rotate(45)"
          />
          <rect
            width="23.374"
            height="15.582"
            transform="translate(156.832 311.087) rotate(45)"
          />
          <rect
            width="62.33"
            height="15.582"
            transform="translate(173.363 294.564) rotate(45)"
          />
          <rect
            width="62.33"
            height="15.582"
            transform="translate(184.384 140.287) rotate(135)"
          />
          <rect
            width="62.33"
            height="15.582"
            transform="translate(200.895 156.823) rotate(135)"
          />
          <rect
            width="62.33"
            height="15.581"
            transform="translate(217.424 173.351) rotate(135)"
          />
        </g>
      </svg>
    );
  }
}
