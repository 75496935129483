import React, { PureComponent } from "react";

export default class VA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="256"
            height="341.337"
            transform="translate(256 85.331)"
            fill="#f0f0f0"
          />
          <path
            d="M321.353,233.837l32.073,42.43a22.258,22.258,0,1,0,11.838-8.95l-49.988-66.129-11.838,8.949-17.759,13.424,17.9,23.677Zm46.175,48.78a7.421,7.421,0,1,1-1.444,10.394A7.421,7.421,0,0,1,367.528,282.617Z"
            fill="#acabb1"
          />
          <path
            d="M376.367,247.24l17.9-23.677-17.759-13.424-11.838-8.949-49.988,66.129a22.263,22.263,0,1,0,11.838,8.95l32.073-42.43Zm-62.5,45.771a7.42,7.42,0,1,1-1.444-10.394A7.421,7.421,0,0,1,313.863,293.011Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
