import { createSelector } from "reselect";

export const productSelector = (state) => state.getIn(["productReducer"]);

export const getProduct = createSelector(productSelector, (product) =>
  product.get("product")
);

export const getQuickViewIsOpened = createSelector(productSelector, (product) =>
  product.get("quickViewIsOpened")
);
