import React, { PureComponent, createRef } from "react";
import { Form as BSForm } from "react-bootstrap";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import { isEqual } from "lodash";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import { Field } from "storefront/features/shared";
import * as actions from "../actions";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import Icon from "storefront/features/shared/icons";
import { StyledButton } from "./ProductsSettings.styled";

class UploadFileButton extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  state = {
    file: "",
  };

  componentDidUpdate(prevProps, prevState) {
    const { file } = this.state;
    if (file.size > 0 && !isEqual(file, prevState.file)) {
      this.uploadFile();
    }
  }

  getResponseSuccessMsg = () => (
    <>
      <p>
        You&#39;re almost ready to sell your products on
        <Link to="/"> bids.com</Link> marketplace. Thanks for choosing the
        <Link to="/"> bids.com</Link> marketplace to sell your goods.
      </p>
      <p>
        This could take some time to complete. If you&#39;d like, you can close
        this page and continue working. Importing will overwrite any existing
        products that have the same product handle.
      </p>
    </>
  );

  handleErrors = (errorMsg) => {
    const { handleResponseErrors } = this.props;
    handleResponseErrors(errorMsg);
  };

  handleSuccess = () => {
    const { handleResponseSuccess } = this.props;
    const successMsg = this.getResponseSuccessMsg();
    const timeToShowMsg = 6000;
    handleResponseSuccess(successMsg, timeToShowMsg);
  };

  uploadFile = () => {
    const {
      fetchUploadFileRequest,
      userAuctionData: { vendor_id: id },
    } = this.props;
    const { file } = this.state;
    const handleErrors = this.handleErrors;
    const handleSuccess = this.handleSuccess;
    fetchUploadFileRequest({
      file,
      id,
      handleErrors,
      handleSuccess,
    });
  };

  handleFileChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file.size > 2097152) {
      this.setState({ errorMsg: "File is too big." });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        file,
      });
    };

    reader.readAsDataURL(file);
  };

  handleSubmitForm = () => {
    if (!this.ref.current) {
      return;
    }
    this.ref.current.click();
  };

  render() {
    return (
      <Form
        onSubmit={this.handleSubmitForm}
        render={({ handleSubmit, submitting }) => (
          <BSForm onSubmit={handleSubmit}>
            <BSForm.Group className="mb-0">
              <Field name="fileUpload">
                {({ input }) => (
                  <input
                    {...input}
                    onChange={this.handleFileChange}
                    type="file"
                    className="d-none"
                    ref={this.ref}
                    accept=".csv"
                  />
                )}
              </Field>
              <StyledButton
                className="mr-2"
                type="submit"
                variant="dark"
                disabled={submitting}
                data-cy="upload-file-btn"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Icon type="uploadIcon" />
                  <p className="m-0 ml-2">Import Products</p>
                </div>
              </StyledButton>
            </BSForm.Group>
          </BSForm>
        )}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUploadFileRequest: (data) => {
    dispatch(actions.fetchUploadFileRequest(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(UploadFileButton));
