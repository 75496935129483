import React, { PureComponent } from "react";

export default class FI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <path
            d="M512,234.666v42.663H183.652V426.666H140.978V277.329H0V234.666H140.978V85.329h42.674V234.666Z"
            fill="#2e52b2"
          />
        </g>
      </svg>
    );
  }
}
