import { fromJS } from "immutable";
import * as actions from "./actions";

const initialState = fromJS({
  auctions: [],
  open: false,
  data: {},
});

const returnActiveAuctions = (state, action) =>
  state.set("auctions", fromJS(action.reduce(
    (acc, elem) => ({ ...acc, [elem.id]: { ...elem, bidAuctionId: elem.id } }),
    {}
  )));

const setModalAddresses = (state, value) =>
  state.set("open", value.open).set("data", value.data);

const updateActiveAuctionData = (state, value) => {
  const currentMaxBid = state.getIn(["auctions", `${value.bidAuctionId}`, "max_bid"]);
  const updatedMaxBix = value.max_bid == null || value.max_bid < currentMaxBid ? currentMaxBid : value.max_bid;
  const result = state.get("auctions").set(`${value.bidAuctionId}`, fromJS({ ...value, max_bid: updatedMaxBix }));
  return state.set("auctions", result);
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST_RESULT:
      return returnActiveAuctions(state, action.payload);
    case actions.MODAL_WITH_DATA_TOGGLE:
      return setModalAddresses(state, action.payload);
    case actions.UPDATE_CUSTOMER_ACTIVE_AUCTIONS:
      return updateActiveAuctionData(state, action.payload);
    default:
      return state;
  }
};

export default accountReducer;
