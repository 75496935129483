import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as globalActions from "storefront/features/shared/actions";

import superagent from "superagent";

export function* fetchProductRequest(action) {
  const redirectLink = `/not-found`;
  const { handle, auctionId, loader } = action.payload;
  const request = () =>
    superagent
      .get(`/api/products/quick-view`)
      .query({ handle, auction_id: auctionId });
  try {
    const response = yield call(request);
    if (response.body.product.error) {
      yield window.location.replace(redirectLink);
    } else {
      yield put(actions.fetchProductRequestResult(response.body));
    }
    yield put(globalActions.removeLoader({ loader }));
  } catch (res) {
    console.log("no data from server");
  }
}

export default [takeLatest(actions.FETCH_PRODUCT_REQUEST, fetchProductRequest)];
