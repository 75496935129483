import React, { PureComponent } from "react";
import { Button, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import * as actions from "../actions";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import { Field } from "storefront/features/shared";

class AuctionForm extends PureComponent {
  state = { errorMsg: "" };

  handleResponseErrors = (error) => {
    this.setState((prevState) => ({ ...prevState, errorMsg: error }));
  };

  handleResponseSuccess = (successMsg) => {
    const { handleModalClose } = this.props;
    handleModalClose(successMsg);
  };

  handleSubmitForm = (values) => {
    const {
      fetchAddAuctionRequest,
      fetchUpdateAuctionRequest,
      product: { id, shopify_product_id = "", start_at = "", end_at = "" },
      userAuctionData: { vendor_id },
    } = this.props;
    const { price } = values;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    if (start_at) {
      const requestUpdateData = {
        vendor_id,
        product_id: shopify_product_id,
        start_time: start_at,
        end_time: end_at,
        price,
        request_action: "Update",
      };
      fetchUpdateAuctionRequest({
        requestUpdateData,
        id,
        handleErrors,
        handleSuccess,
      });
    } else {
      const requestData = { vendor_id, product_id: id, price };
      fetchAddAuctionRequest({ requestData, handleErrors, handleSuccess });
    }
  };

  render() {
    const { errorMsg } = this.state;
    return (
      <Form
        data-cy="auction-form"
        noValidate
        onSubmit={this.handleSubmitForm}
        render={({
          handleSubmit,
          submitting,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <BSForm onSubmit={handleSubmit}>
            <BSForm.Group>
              <Field
                name="price"
                type="number"
                placeholder="Price"
                min="0"
                step="0.1"
                data-cy="auction-price"
              />
            </BSForm.Group>
            <BSForm.Group>
              {errorMsg && !dirtySinceLastSubmit && (
                <p className="invalid-feedback d-block" data-cy="error-msg">
                  {errorMsg}
                </p>
              )}
              <Button
                className="w-100"
                type="submit"
                variant="primary"
                disabled={submitting || pristine}
                data-cy="auction-save-btn"
              >
                Save
              </Button>
            </BSForm.Group>
          </BSForm>
        )}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAddAuctionRequest: (values) => {
    dispatch(actions.fetchAddAuctionRequest(values));
  },
  fetchUpdateAuctionRequest: (values) => {
    dispatch(actions.fetchUpdateAuctionRequest(values));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(AuctionForm));
