import React, { PureComponent } from "react";
import { Col } from "react-bootstrap";
import { AddressEditButton } from "../Account.styled";
import { connect } from "react-redux";
import * as actions from "../actions";
import AddressLines from "./AddressLines";

class Addresses extends PureComponent {
  renderAddressesList = ({ node }, index) => {
    const { id } = node,
      { width, editButtons, setModalAddresses, defaultAddressId } = this.props;

    const handleDeleteAddress = () => {
      window.confirm("Are you sure you wish to delete this address?") &&
        this.props.deleteAddress(id);
    };

    return (
      <Col
        key={id}
        className="mb-5"
        xs={10}
        lg={width}
        data-cy="ap-address-card"
      >
        <p className="address-title border-bottom mb-4 text-uppercase pb-2">
          {defaultAddressId === id ? `Default address` : `Address ${index + 1}`}
        </p>
        <AddressLines data={node} />
        {editButtons && (
          <>
            <AddressEditButton
              variant="link"
              onClick={() => setModalAddresses(true, node)}
              data-cy="ap-address-edit-btn"
            >
              Edit
            </AddressEditButton>
            <AddressEditButton
              variant="link"
              onClick={handleDeleteAddress}
              data-cy="ap-address-delete-btn"
            >
              Delete
            </AddressEditButton>
          </>
        )}
      </Col>
    );
  };

  render() {
    const {
      defaultAddressId,
      addresses: { edges },
    } = this.props;
    return defaultAddressId ? (
      edges.map(this.renderAddressesList)
    ) : (
      <p className="text-uppercase">No addresses</p>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setModalAddresses: (open, data) => {
    dispatch(actions.setModalAddresses({ open, data }));
  },
  deleteAddress: (id) => {
    dispatch(actions.fetchCustomerAddressDeleteRequest({ id }));
  },
});

export default connect(undefined, mapDispatchToProps)(Addresses);
