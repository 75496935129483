import React, { PureComponent } from "react";
import { compose } from "redux";
import { Button, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import {
  validateEmail,
  validateRequired,
  composeValidators,
  validateMinLength,
  validateMaxLength,
  validatePhoneNumber,
} from "storefront/features/shared/components/field/utils";
import PaymentMethods from "./PaymentMethods";
import withRedirectRegisteredUser from "storefront/features/shared/components/RedirectRegisteredUser";
import { clearLocalStorage } from "../utils";
import { getCustomerAccessToken } from "../../shared/utils";

class RegisterVendorForm extends PureComponent {
  state = { errorMsg: "", paymentMethod: "paypal" };

  handleResponseErrors = (error) => {
    this.setState({ errorMsg: error });
  };

  logoutUser = () => {
    const { fetchCustomerAccessTokenDelete } = this.props;
    const customerAccessToken = getCustomerAccessToken();
    const redirectLink = "/account/success-account-creation";
    clearLocalStorage();
    fetchCustomerAccessTokenDelete(customerAccessToken, redirectLink);
  };

  handleSubmitForm = (values) => {
    const { paymentMethod } = this.state;
    const { fetchVendorRegisterRequest } = this.props;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.logoutUser;
    const isVendor = true;
    this.setState({ errorMsg: "" });
    fetchVendorRegisterRequest({
      isVendor,
      handleErrors,
      handleSuccess,
      paymentMethod,
      ...values,
    });
  };

  handlePaymentMethodChange = (e) => {
    this.setState({ paymentMethod: e.target.value });
  };

  render() {
    const { errorMsg, paymentMethod } = this.state;
    return (
      <Form
        noValidate
        onSubmit={this.handleSubmitForm}
        render={({
          handleSubmit,
          submitting,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <BSForm onSubmit={handleSubmit}>
            <BSForm.Group>
              <Field
                name="firstName"
                type="text"
                placeholder="First name"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="lastName"
                type="text"
                placeholder="Last name"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="phone"
                type="tel"
                placeholder="Phone"
                validate={composeValidators(
                  validateRequired,
                  validatePhoneNumber
                )}
                disabled={submitting}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                validate={composeValidators(
                  validateEmail,
                  validateRequired,
                  validateMaxLength(255)
                )}
                disabled={submitting}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="password"
                type="password"
                placeholder="Password"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMinLength(5),
                  validateMaxLength(40)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="store_name"
                type="text"
                placeholder="Store name (this field will be used as the public name of the store)"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="account_numbers"
                type="text"
                placeholder="How do you wish get payments from bids.com?"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="legal_name"
                type="text"
                placeholder="Legal Name/Trade Name"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="tax_id"
                type="text"
                placeholder="Tax ID"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="address"
                type="text"
                placeholder="Address"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="products_category"
                type="text"
                placeholder="Products category (clothes jewelry etc)"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="website_url"
                type="text"
                placeholder="Website"
                disabled={submitting}
                validate={composeValidators(validateMaxLength(100))}
              />
              <BSForm.Text className="text-muted">
                Please, add the link to your website (e.g., http://example.com
                or https://example.com)
              </BSForm.Text>
            </BSForm.Group>
            <BSForm.Group>
              <Field
                name="sales_channels"
                type="text"
                placeholder="Sales channels"
                disabled={submitting}
                validate={composeValidators(
                  validateRequired,
                  validateMaxLength(250)
                )}
              />
              <BSForm.Text className="text-muted">
                Your current sales channels (Please provide the links to your
                current pages and/or stores)
              </BSForm.Text>
            </BSForm.Group>
            <BSForm.Group>
              <Field
                as="textarea"
                rows={3}
                name="hear_about_bids"
                type="text"
                placeholder="How did you hear about Bids.com?"
                disabled={submitting}
                validate={validateMaxLength(250)}
              />
            </BSForm.Group>
            <BSForm.Group>
              <BSForm.Control
                as="select"
                name="payment_methods_select"
                onChange={this.handlePaymentMethodChange}
                value={paymentMethod}
              >
                <option value="paypal">PayPal</option>
                <option value="zelle">Zelle</option>
                <option value="wires">Wires</option>
                <option value="ach">ACH</option>
                <option value="payment_portal">Payment Portals</option>
              </BSForm.Control>
              <BSForm.Text className="text-muted">
                Choose how to receive payments from bids.com
              </BSForm.Text>
            </BSForm.Group>
            <PaymentMethods paymentMethod={paymentMethod} />
            <BSForm.Group className="d-flex align-items-center">
              <Field
                id="shipping-labels-checkbox"
                name="shippingLabels"
                type="checkbox"
                checked
                value={true}
                disabled={submitting}
                data-cy="shipping-labels-checkbox"
              />
              <small>
                Do you require shipping labels from
                <Link to="/" variant="link" id="home-page-link">
                  {" "}
                  bids.com{" "}
                </Link>
                ?
              </small>
            </BSForm.Group>
            <BSForm.Group>
              {errorMsg && !dirtySinceLastSubmit && (
                <p className="invalid-feedback d-block">{errorMsg}</p>
              )}
              <Button
                className="w-100"
                type="submit"
                variant="primary"
                disabled={submitting || pristine}
              >
                Register
              </Button>
            </BSForm.Group>
          </BSForm>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchVendorRegisterRequest: (values) => {
    dispatch(actions.fetchVendorRegisterRequest(values));
  },
  fetchCustomerAccessTokenDelete: (customerAccessToken, redirectLink) => {
    dispatch(
      actions.fetchCustomerAccessTokenDelete({
        customerAccessToken,
        redirectLink,
      })
    );
  },
});

export default compose(
  withRedirectRegisteredUser,
  connect(null, mapDispatchToProps)
)(RegisterVendorForm);
