import React, { PureComponent } from "react";

export default class HR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M300.522,204.059H270.84l4.948-37.1,19.787-14.841,19.787,14.841v29.681Z"
              fill="#338af3"
            />
            <path
              d="M211.478,204.059H241.16l-4.948-37.1-19.787-14.841-19.787,14.841v29.681Z"
              fill="#338af3"
            />
          </g>
          <path
            d="M275.788,204.059H236.212v-37.1L256,152.116l19.788,14.841Z"
            fill="#0052b4"
          />
          <path
            d="M196.638,196.638v81.623a59.315,59.315,0,0,0,23.868,47.555l10.388-2.6L244,336.4a59.667,59.667,0,0,0,23.93.014L282.9,323.959l8.6,1.834a59.289,59.289,0,0,0,23.868-47.531V196.639Z"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="23.745"
              height="23.745"
              transform="translate(196.641 196.641)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(244.124 196.641)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(291.617 196.641)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(220.383 220.383)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(267.876 220.383)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(196.641 244.124)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(220.383 267.865)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(244.124 244.124)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(291.617 244.124)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(267.876 267.865)"
              fill="#d80027"
            />
            <rect
              width="23.745"
              height="23.745"
              transform="translate(244.124 291.617)"
              fill="#d80027"
            />
            <path
              d="M220.383,291.613H198.171a59.168,59.168,0,0,0,11.536,23.745h10.676V291.613Z"
              fill="#d80027"
            />
            <path
              d="M291.617,315.357h10.677a59.188,59.188,0,0,0,11.536-23.745H291.617Z"
              fill="#d80027"
            />
            <path
              d="M220.383,315.357v10.361a59.112,59.112,0,0,0,23.745,10.71V315.357Z"
              fill="#d80027"
            />
            <path
              d="M267.873,315.357v21.071a59.1,59.1,0,0,0,23.745-10.71V315.357Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
