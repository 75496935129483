import React, { PureComponent } from "react";

export default class GY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 307.2"
      >
        <path d="M0,0H512V307.2H0Z" fill="#009e49" />
        <path d="M0,0,512,153.6,0,307.2Z" fill="#fff" />
        <path
          d="M0,26.1,466.944,166.337,0,306.574Z"
          transform="translate(0 -12.737)"
          fill="#fcd116"
        />
        <path d="M0,0,256,153.6,0,307.2Z" />
        <path
          d="M0,35,226.15,170.68,0,306.36Z"
          transform="translate(0 -17.08)"
          fill="#ce1126"
        />
      </svg>
    );
  }
}
