import styled from "styled-components";

export const StyledCountdown = styled.div`
  .blinking {
    color: red;
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
        opacity: 0;
  }
}
`;
