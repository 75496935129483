import React, { PureComponent } from "react";
import { Form } from "react-final-form";
import { Form as BSForm } from "react-bootstrap";
import { StyledInput, StyledInputGroup } from "./BidInput.styled";

import Field from "storefront/features/shared/components/field/Field";
class BidInput extends PureComponent {
  onSubmit = () => {};

  render() {
    const { handleChange, minValue } = this.props;
    return (
      <Form
        noValidate
        onSubmit
        render={({ handleSubmit, submitting }) => (
          <BSForm className="position-relative" onSubmit={handleSubmit}>
            <Field name="bidInput" type="number" disabled={submitting}>
              {({ input }) => (
                <StyledInputGroup>
                  <StyledInput
                    {...input}
                    placeholder="Place your highest bid"
                    onChange={handleChange}
                    value={undefined}
                    min={`${minValue}`}
                    step={1}
                  />
                </StyledInputGroup>
              )}
            </Field>
          </BSForm>
        )}
      />
    );
  }
}

export default BidInput;
