import React, { PureComponent } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { Container, Row, Col, Button } from "react-bootstrap";
import { isEqual } from "lodash";

import * as actions from "../actions";
import {
  addCheckoutLineItemsRequest,
  removeCheckoutLineItemsRequest,
  updateCheckoutLineItemsRequest,
} from "storefront/features/shared/components/Header/actions";
import { getWonAuctions, getShippingMessageData } from "../selectors";
import CartProduct from "./CartProduct";
import { StyledColSubtotal } from "./Cart.styled";
import {
  getSubtotal,
  formatWonAuctionsData,
  formatCheckoutLineItems,
  setCheckoutBuyNowData,
  setCheckoutWonAuctionsData,
  setCheckoutWonAuctionsRingSizingData,
  updateCheckoutWonAuctionsData,
  updateCheckoutWonAuctionsVariantData,
  updateCheckoutWonAuctionsRingSizeData,
} from "storefront/features/shared/utils";
import { getCheckoutLineItems } from "storefront/features/shared/components/Header/selectors";
import withRedirectUnregisteredUser from "storefront/features/shared/components/RedirectUnregisteredUser";
import { Helmet, PaymentIconsList } from "storefront/features/shared";
import { metaTags } from "../metaTags";
import { Discount } from "./Discount";
import SproutCartBadge from "./SproutCartBadge";
import guaranteeBadge from '../../../img/guarantee-badge.png';

class Cart extends PureComponent {
  state = {
    wonAuctionsData: [],
    errorMsg: "",
    discountValue: "",
  };

  componentDidMount() {
    const { fetchWonAuctions, fetchShippingMessageData } = this.props;
    fetchWonAuctions();
    fetchShippingMessageData();
    this.updateWonAuctionsData();
  }

  componentDidUpdate(prevProps) {
    const { wonAuctions } = this.props;
    if (!isEqual(wonAuctions, prevProps.wonAuctions)) {
      this.updateWonAuctionsData();
    }
  }

  updateWonAuctionsData = () => {
    const { wonAuctions } = this.props;
    const wonAuctionsData = formatWonAuctionsData(wonAuctions);
    this.setState({
      wonAuctionsData,
    });
  };

  addLineItems = (lineItemsData) => {
    const { addCheckoutLineItems } = this.props;
    addCheckoutLineItems(lineItemsData);
  };

  removeLineItems = (lineItemsData) => {
    const { removeCheckoutLineItems } = this.props;
    removeCheckoutLineItems(lineItemsData);
  };

  updateLineItems = (lineItemsData) => {
    const { updateCheckoutLineItems } = this.props;
    updateCheckoutLineItems(lineItemsData);
  };

  updateAuctionLineItems = (id, saveForLater) => {
    const { wonAuctionsData } = this.state;

    const updatedWonAuctionsData = updateCheckoutWonAuctionsData(
      wonAuctionsData,
      id,
      saveForLater
    );
    this.setState({
      wonAuctionsData: updatedWonAuctionsData,
    });
  };

  updateAuctionLineItemsVariant = (id, variantId, choosedOptions) => {
    const { wonAuctionsData } = this.state;

    const updatedWonAuctionsData = updateCheckoutWonAuctionsVariantData(
      wonAuctionsData,
      id,
      variantId,
      choosedOptions
    );
    this.setState({
      wonAuctionsData: updatedWonAuctionsData,
    });
  };

  updateAuctionLineItemsRingSizing = (id, ringSizingOption) => {
    const { wonAuctionsData } = this.state;

    const updatedWonAuctionsData = updateCheckoutWonAuctionsRingSizeData(
      wonAuctionsData,
      id,
      ringSizingOption
    );
    this.setState({
      wonAuctionsData: updatedWonAuctionsData,
    });
  };

  handleResponseErrors = (reason) => {
    this.setState({ errorMsg: reason });
  };

  handleDiscountChange = (discountValue) => {
    this.setState({ discountValue });
  };

  handleClickFetchCheckout = () => {
    const { fetchCheckout, checkoutLineItems } = this.props;
    const { wonAuctionsData, discountValue } = this.state;
    const handleErrors = this.handleResponseErrors;
    this.setState({ errorMsg: "" });
    const checkoutData = {
      handleErrors,
      auctions_data: setCheckoutWonAuctionsData(wonAuctionsData),
      buy_now_data: setCheckoutBuyNowData(checkoutLineItems),
      ring_sizing: setCheckoutWonAuctionsRingSizingData(wonAuctionsData),
      discount: discountValue,
    };
    fetchCheckout(checkoutData);
  };

  getCartProductData = () => {
    const { wonAuctionsData } = this.state;
    const { checkoutLineItems } = this.props;
    const checkoutLineItemsData = formatCheckoutLineItems(checkoutLineItems);
    return wonAuctionsData.concat(checkoutLineItemsData);
  };

  removeAuction = (auctionId) => {
    const { removeAuctionRequest } = this.props;
    removeAuctionRequest({ auctionId });
  };

  render() {
    const { isShippingMessageEnabled } = this.props;
    const cartProductData = this.getCartProductData();
    const { errorMsg } = this.state;
    return (
      <>
        <Helmet metaTags={metaTags} />
        <Container className="my-5">
          <Row>
            <Col>
              <h1 className="text-center mb-5">Cart</h1>
            </Col>
          </Row>
          {cartProductData.length > 0 ? (
            <div>
              <Row>
                <Col lg={8}>
                  <div className="d-flex justify-content-between w-100">
                    <span className="text-uppercase">Product</span>
                    <span className="text-uppercase">Price</span>
                  </div>
                  <hr />
                  {cartProductData.map((cartProduct) => (
                    <CartProduct
                      key={cartProduct.id}
                      cartProduct={cartProduct}
                      addLineItems={this.addLineItems}
                      removeLineItems={this.removeLineItems}
                      updateLineItems={this.updateLineItems}
                      updateAuctionLineItems={this.updateAuctionLineItems}
                      updateAuctionLineItemsVariant={
                        this.updateAuctionLineItemsVariant
                      }
                      updateAuctionLineItemsRingSizing={
                        this.updateAuctionLineItemsRingSizing
                      }
                      removeAuctionFromCart={this.removeAuction}
                    />
                  ))}
                </Col>
                <StyledColSubtotal lg={4}>
                  <div className="border content p-3">
                    <div className="d-flex align-items-end justify-content-between w-100">
                      <span className="subtotal-title">Subtotal</span>
                      <span
                        className="subtotal-price text-right"
                        data-cy="cart-subtotal"
                      >
                        {getSubtotal(cartProductData)}
                      </span>
                    </div>
                    <hr className="mb-1" />
                    {isShippingMessageEnabled && (
                      <p className="text-right mb-2 mt-3">
                        <small>Shipping will be calculated at next step</small>
                      </p>
                    )}
                    <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                      <Discount
                        handleDiscountChange={this.handleDiscountChange}
                      />
                      <Button
                        id="checkout-button"
                        variant="primary"
                        className="text-uppercase ml-3"
                        onClick={this.handleClickFetchCheckout}
                      >
                        CHECKOUT
                      </Button>
                    </div>
                    {errorMsg && (
                      <p className="mt-2 mb-1 invalid-feedback d-block">
                        {errorMsg}
                      </p>
                    )}
                    <PaymentIconsList />
                  </div>
                  <div className="mt-3">
                    <img className="w-100" src={guaranteeBadge} />
                  </div>
                </StyledColSubtotal>
              </Row>
              {process.env.SHOW_SPROUT === "true" && <SproutCartBadge />}
            </div>
          ) : (
            <Row>
              <Col sm={12}>
                <span className="text-uppercase">Cart is Empty</span>
                <hr />
                <p>You do not have any items on your cart.</p>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  wonAuctions: getWonAuctions,
  checkoutLineItems: getCheckoutLineItems,
  isShippingMessageEnabled: getShippingMessageData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWonAuctions: () => {
    dispatch(actions.fetchWonAuctionsRequest());
  },
  addCheckoutLineItems: (value) => {
    dispatch(addCheckoutLineItemsRequest(value));
  },
  removeCheckoutLineItems: (value) => {
    dispatch(removeCheckoutLineItemsRequest(value));
  },
  updateCheckoutLineItems: (value) => {
    dispatch(updateCheckoutLineItemsRequest(value));
  },
  fetchCheckout: (value) => {
    dispatch(actions.fetchCheckout(value));
  },
  removeAuctionRequest: (value) => {
    dispatch(actions.removeAuctionRequest(value));
  },
  fetchShippingMessageData: () => {
    dispatch(actions.fetchShippingMessageDataRequest());
  },
});

export { Cart };

export default compose(
  withRedirectUnregisteredUser,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(Cart);
