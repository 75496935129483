import React, { PureComponent } from "react";

export default class CW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="32"
            transform="translate(0 325.331)"
            fill="#ffda44"
          />
          <g>
            <path
              d="M145.786,148.41l9.209,28.341h29.8l-24.11,17.518,9.208,28.343L145.786,205.1l-24.11,17.516,9.21-28.343-24.11-17.518h29.8Z"
              fill="#f0f0f0"
            />
            <path
              d="M94.608,118.728l5.526,17.005h17.881L103.55,146.244l5.525,17.006L94.608,152.74,80.142,163.25l5.526-17.006L71.2,135.733H89.084Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
