import React, { PureComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";

import ContactUsInfo from "./ContactUsInfo";
import { ContactForm, Helmet } from "storefront/features/shared";
import { metaTagsContactUs } from "../metaTags";

export default class ContactUs extends PureComponent {
  render() {
    return (
      <>
        <Helmet metaTags={metaTagsContactUs} />
        <Container className="my-5 ">
          <Row>
            <Col xs={12} lg={4}>
              <ContactUsInfo />
            </Col>
            <Col xs={12} lg={8}>
              <h1 className="text-uppercase text-center mb-5">Contact us</h1>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
