import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const StyledContainer = styled(Container)`
  .form-footer-info {
    color: #9d9d9d;
  }
`;

export const StyledLinkLogin = styled(Link)`
  color: #454545;
  text-decoration: none;
  &:hover,
  &:active {
    color: #1e1e1e;
    text-decoration: none;
  }
`;
