import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "storefront/features/shared";
import { metaTags } from "../utils";

export default class FourOFour extends PureComponent {
  render() {
    return (
      <div className="text-center four-o-four-container">
        <Helmet metaTags={metaTags} />
        <p className="four-o-four-title">404</p>
        <h2>Ooops... looks like you make a wrong Bid.</h2>
        <h3>
          Please use the following <Link to="/"> LINK </Link> to find the best
          product at the best price.
        </h3>
      </div>
    );
  }
}
