import React, { PureComponent } from "react";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getPageTitle, getPageContent } from "../selectors";
import { withRouter } from "react-router-dom";
import * as actions from "storefront/features/shared/actions";
import { STATIC_PAGE_LOADER } from "storefront/features/shared/utils";
import StaticPageContent from "./StaticPageContent";
import { Helmet } from "storefront/features/shared";
import { metaTagsStaticPage } from "../metaTags";

class StaticPage extends PureComponent {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  getPageUID = () => location.pathname.split("/pages/")[1];

  fetchData = () => {
    const { fetchPageData } = this.props;
    fetchPageData(this.getPageUID(), STATIC_PAGE_LOADER);
  };

  render() {
    const { pageTitle, pageContent } = this.props;
    const metaTags = metaTagsStaticPage(pageTitle, pageContent);
    return (
      <>
        <Helmet metaTags={metaTags} />
        <WithLoader loader={STATIC_PAGE_LOADER}>
          <StaticPageContent title={pageTitle} content={pageContent} />
        </WithLoader>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  pageTitle: getPageTitle,
  pageContent: getPageContent,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPageData: (uid, loader) => {
    dispatch(actions.fetchStaticPageFromPrismic({ uid, loader }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StaticPage));
