import React, { PureComponent } from "react";

export default class VI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M284.986,204.557a28.986,28.986,0,0,0-57.972,0H163.246c0,15.761,13.729,28.538,29.49,28.538h-.949a28.54,28.54,0,0,0,28.54,28.541,28.534,28.534,0,0,0,23.259,28.031l-.075.167h24.977l-.073-.167a28.534,28.534,0,0,0,23.26-28.031,28.54,28.54,0,0,0,28.54-28.541h-.951c15.761,0,29.49-12.778,29.49-28.538Z"
              fill="#ffda44"
            />
            <path
              d="M243.258,286.877l-17.972,40.575a81.648,81.648,0,0,0,61.427,0l-17.971-40.575Z"
              fill="#ffda44"
            />
          </g>
          <path
            d="M218.9,218.811v49.928c0,28.4,37.1,37.1,37.1,37.1s37.1-8.7,37.1-37.1V218.811Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M126.719,257.2l20.066,56.123L166.642,257.2H181.8l-28.95,74.2H140.723l-29.158-74.2Z"
              fill="#338af3"
            />
            <path d="M359.884,331.4V257.2h14.423v74.2Z" fill="#338af3" />
          </g>
          <g>
            <path
              d="M233.739,234.935v60.5a65.292,65.292,0,0,0,14.84,7.989V234.935Z"
              fill="#d80027"
            />
            <path
              d="M278.261,234.935v60.5a65.293,65.293,0,0,1-14.84,7.989V234.935Z"
              fill="#d80027"
            />
          </g>
          <rect
            width="74.207"
            height="23.541"
            transform="translate(218.902 218.808)"
            fill="#0052b4"
          />
        </g>
      </svg>
    );
  }
}
