import React, { PureComponent } from "react";

export default class NZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M425.3,233.745l3.388,10.428h10.963l-8.87,6.444,3.388,10.427L425.3,254.6l-8.871,6.444,3.388-10.427-8.87-6.444h10.963Z"
              fill="#d80027"
            />
            <path
              d="M386.107,308.817l5.083,15.642h16.445l-13.305,9.667,5.082,15.64-13.3-9.667-13.3,9.667,5.083-15.64-13.305-9.667h16.445Z"
              fill="#d80027"
            />
            <path
              d="M387.588,185.971l4.236,13.036h13.7l-11.088,8.054,4.235,13.034-11.087-8.056L376.5,220.1l4.235-13.034-11.087-8.054h13.7Z"
              fill="#d80027"
            />
            <path
              d="M349.876,233.291l5.082,15.641H371.4L358.1,258.6l5.084,15.641-13.306-9.666-13.3,9.666,5.082-15.641-13.305-9.666h16.445Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M256,85.329v30.564l-45.178,25.088H256V200.34H196.89L256,233.186v22.806h-26.69l-73.484-40.826v40.826H100.177V207.419L12.748,255.992H0V225.439l45.168-25.1H0V140.981h59.1L0,108.147V85.329h26.68l73.494,40.838V85.329h55.652V133.9l87.43-48.573Z"
            fill="#f0f0f0"
          />
          <path
            d="M144,85.33H112v69.334H0v32H112V256h32V186.664H256v-32H144Z"
            fill="#d80027"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#0052b4"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#d80027"
            />
            <path
              d="M71.846,200.344,0,240.259V256H0l100.174-55.654Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M100.174,140.983,0,85.33v15.738l71.847,39.915Z"
            fill="#0052b4"
          />
          <path
            d="M100.174,140.983,0,85.33v15.738l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M100.174,140.983,0,85.33v15.738l71.847,39.915Z"
              fill="#d80027"
            />
            <path
              d="M184.154,140.983,256,101.068V85.33h0L155.826,140.983Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
