import React, { PureComponent } from "react";

export default class CC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#6da544"
          />
          <g>
            <path
              d="M347.323,330.2l4.683,9.792,10.576-2.444-4.736,9.767,8.5,6.745-10.588,2.385.029,10.856-8.468-6.791-8.468,6.791.029-10.856L328.3,354.058l8.5-6.745-4.736-9.767,10.575,2.444Z"
              fill="#ffda44"
            />
            <path
              d="M303.755,226.313l4.684,9.794,10.576-2.446-4.737,9.768,8.5,6.744-10.589,2.387.029,10.855-8.468-6.792-8.468,6.792.029-10.855-10.588-2.387,8.5-6.744-4.737-9.768,10.576,2.446Z"
              fill="#ffda44"
            />
            <path
              d="M347.323,166.951l4.683,9.792,10.576-2.445-4.735,9.769,8.5,6.744L355.762,193.2l.029,10.854-8.468-6.791-8.468,6.791.029-10.854L328.3,190.811l8.5-6.744-4.736-9.769,10.575,2.445Z"
              fill="#ffda44"
            />
            <path
              d="M385.378,211.473l4.684,9.792,10.576-2.444-4.736,9.768,8.5,6.744-10.588,2.386.029,10.855-8.468-6.791-8.468,6.791.029-10.855-10.589-2.386,8.505-6.744-4.736-9.768,10.576,2.444Z"
              fill="#ffda44"
            />
            <path
              d="M358.166,263.415l3.684,11.338h11.921l-9.644,7.006,3.684,11.336-9.645-7.006-9.643,7.006,3.684-11.336-9.645-7.006h11.921Z"
              fill="#ffda44"
            />
            <path
              d="M274.551,304.226a48.232,48.232,0,1,1,22.943-90.667,59.362,59.362,0,1,0,0,84.87A48.012,48.012,0,0,1,274.551,304.226Z"
              fill="#ffda44"
            />
            <path
              d="M192.657,152.111a29.674,29.674,0,0,0-51.4,0Z"
              fill="#ffda44"
            />
            <path
              d="M192.756,152.282l-25.8,25.8-25.8-25.8a29.683,29.683,0,1,0,51.6,0Z"
              fill="#ffda44"
            />
          </g>
          <path
            d="M159.536,170.661V195.7a29.917,29.917,0,0,0,14.842,0V170.661Z"
            fill="#a2001d"
          />
        </g>
      </svg>
    );
  }
}
