import styled from "styled-components";
import { Pagination } from "react-bootstrap";

export const StyledPagination = styled(Pagination)`
  .page-item {
    &.active {
      .page-link {
        background: #00509d;
        color: #fff;
      }
    }
    .page-link {
      color: #00509d;
    }
  }
`;
