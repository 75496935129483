import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";
import Client from "shopify-buy";
import { isEmpty } from "lodash";
import { push } from "connected-react-router/immutable";

import * as actions from "./actions";
import * as globalActions from "storefront/features/shared/actions";
import { fetchAffiliatesRequest } from "storefront/features/account/actions";
import { customerQuery } from "./utils";
import {
  getHeaders,
  getCustomerAccessToken,
} from "storefront/features/shared/utils";
import {
  removeCustomerAccessData,
  customerErrorCode,
} from "storefront/features/account/utils";
import { STOREFRONT_API_URL } from "../../utils";

const client = Client.buildClient({
  domain: process.env.SHOP,
  storefrontAccessToken: process.env.STOREFRONT_ACCESS_TOKEN,
});

export function* fetchUserAuctionDataRequest() {
  try {
    const multipassToken = localStorage.getItem("Multipass-Token");
    const request = () =>
      superagent
        .get(`/api/my_account/get_customer_auctions_data`)
        .query({ token: multipassToken });
    const response = yield call(request);
    const { checkout_id } = response.body;
    yield put(actions.fetchUserAuctionDataRequestResult(response.body));
    yield call([localStorage, "setItem"], "Checkout-Id", checkout_id);
    yield put(actions.fetchCheckoutRequest(checkout_id));
  } catch (res) {
    console.log(res);
  }
}

export function* fetchCustomerRequest(action) {
  const { loader, password } = action.payload;
  try {
    const vars = {
      customerAccessToken: getCustomerAccessToken(),
    };
    const graphBody = { query: customerQuery, variables: vars },
      headers = getHeaders();
    const request = () =>
      superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
    const response = yield call(request);

    const customer = response.body.data.customer;
    yield put(globalActions.removeLoader({ loader }));
    if (customer) {
      const isUpdateCustomer =
        isEmpty(customer.firstName) && isEmpty(customer.lastName);
      const redirectLink = "/account/update_first_last_name";
      yield put(actions.fetchCustomerRequestResult(customer));
      if (!isEmpty(password)) {
        isUpdateCustomer
          ? yield put(
              push({
                pathname: redirectLink,
                state: { password, email: customer.email },
              })
            )
          : yield put(fetchAffiliatesRequest({ password, ...customer }));
      }
    } else {
      yield call(removeCustomerAccessData, customerErrorCode[1]);
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchCheckoutRequest(action) {
  const checkoutId = action.payload;
  const request = () => client.checkout.fetch(checkoutId);
  try {
    const response = yield call(request);
    response !== null
      ? yield put(actions.createCheckoutRequestResult(response))
      : yield put(actions.fetchUserAuctionDataRequest(response));
  } catch (res) {
    console.log(res);
  }
}

export function* addCheckoutLineItemsRequest(action) {
  const { checkoutId, lineItemsToAdd } = action.payload;
  const redirectLink = `/cart`;

  const request = () =>
    client.checkout.addLineItems(checkoutId, lineItemsToAdd);
  try {
    const response = yield call(request);
    yield put(actions.createCheckoutRequestResult(response));
    yield put(push(redirectLink));
  } catch (res) {
    console.log(res);
  }
}

export function* removeCheckoutLineItemsRequest(action) {
  const { checkoutId, lineItemIdsToRemove } = action.payload;
  const request = () =>
    client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove);
  try {
    const response = yield call(request);
    yield put(actions.createCheckoutRequestResult(response));
  } catch (res) {
    console.log(res);
  }
}

export function* updateCheckoutLineItemsRequest(action) {
  const { checkoutId, lineItemsToUpdate } = action.payload;
  const request = () =>
    client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
  try {
    const response = yield call(request);
    yield put(actions.createCheckoutRequestResult(response));
  } catch (res) {
    console.log(res);
  }
}

export default [
  takeLatest(actions.FETCH_CUSTOMER_REQUEST, fetchCustomerRequest),
  takeLatest(
    actions.FETCH_USER_AUCTION_DATA_REQUEST,
    fetchUserAuctionDataRequest
  ),
  takeLatest(actions.FETCH_CHECKOUT_REQUEST, fetchCheckoutRequest),
  takeLatest(
    actions.ADD_CHECKOUT_LINE_ITEMS_REQUEST,
    addCheckoutLineItemsRequest
  ),
  takeLatest(
    actions.REMOVE_CHECKOUT_LINE_ITEMS_REQUEST,
    removeCheckoutLineItemsRequest
  ),
  takeLatest(
    actions.UPDATE_CHECKOUT_LINE_ITEMS_REQUEST,
    updateCheckoutLineItemsRequest
  ),
];
