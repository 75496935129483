import React, { PureComponent } from "react";

export default class AW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 362.023 241.34"
      >
        <g transform="translate(0 -60.341)">
          <rect
            width="362.023"
            height="241.34"
            transform="translate(0 60.341)"
            fill="#338af3"
          />
          <path
            d="M34.315,118.089,10.736,107.683l23.579-10.4L44.718,73.7,55.123,97.278l23.578,10.4L55.123,118.089,44.718,141.664Z"
            fill="#f0f0f0"
          />
          <path
            d="M44.718,86.7l6.426,14.561,14.562,6.426-14.562,6.426L44.718,128.67l-6.424-14.561-14.562-6.426,14.562-6.426Z"
            fill="#d80027"
          />
          <g>
            <rect
              width="362.023"
              height="15.743"
              transform="translate(0 212.496)"
              fill="#ffda44"
            />
            <rect
              width="362.023"
              height="15.743"
              transform="translate(0 243.974)"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
