import React, { PureComponent } from "react";
import { Form as BSForm } from "react-bootstrap";

import { Field } from "storefront/features/shared";
import {
  validateRequired,
  validateEmail,
  validateMaxLength,
  composeValidators,
} from "storefront/features/shared/components/field/utils";
const MAX_EMAIL_LENGTH = 255;

const paymentMethods = {
  paypal: (
    <BSForm.Group>
      <Field
        name="paypal_account_name"
        data-cy="paypal-account-name"
        type="text"
        placeholder="PayPal Account Name"
        validate={composeValidators(validateRequired)}
      />
    </BSForm.Group>
  ),
  zelle: (
    <>
      <BSForm.Group>
        <Field
          name="zelle_email_address"
          data-cy="zelle-email-address"
          type="email"
          placeholder="Zelle Email Address"
          validate={composeValidators(
            validateEmail,
            validateMaxLength(MAX_EMAIL_LENGTH)
          )}
        />
      </BSForm.Group>
      <BSForm.Group>
        <Field
          name="zelle_phone_number"
          data-cy="zelle-phone-number"
          type="text"
          placeholder="Zelle Phone Number"
        />
      </BSForm.Group>
    </>
  ),
  wires: (
    <>
      <BSForm.Group>
        <Field
          name="wires_account_numbers"
          data-cy="wires-account-numbers"
          type="text"
          placeholder="Wires Account Numbers"
          validate={composeValidators(validateRequired)}
        />
      </BSForm.Group>
      <BSForm.Group>
        <Field
          name="wires_routing_number"
          data-cy="wires-routing-number"
          type="text"
          placeholder="Wires Routing Number"
        />
      </BSForm.Group>
      <BSForm.Group>
        <Field name="aba" data-cy="aba" type="text" placeholder="ABA" />
      </BSForm.Group>
      <BSForm.Group>
        <Field
          name="bank_account_name"
          data-cy="bank-account-name"
          type="text"
          placeholder="Bank Account Name"
          validate={composeValidators(validateRequired)}
        />
      </BSForm.Group>
      <BSForm.Group>
        <Field name="bank_account_type" data-cy="bank-account-type" as="select">
          <option value="business">Business</option>
          <option value="personal">Personal</option>
          <option value="checking">Checking</option>
          <option value="savings">Savings</option>
        </Field>
      </BSForm.Group>
      <BSForm.Group>
        <Field name="swift" data-cy="swift" type="text" placeholder="SWIFT" />
      </BSForm.Group>
      <BSForm.Group>
        <Field name="bic" data-cy="bic" type="text" placeholder="BIC" />
      </BSForm.Group>
    </>
  ),
  ach: (
    <BSForm.Group>
      <Field
        name="ach_account_numbers"
        data-cy="ach_account_numbers"
        type="text"
        placeholder="ACH Account Numbers"
        validate={composeValidators(validateRequired)}
      />
    </BSForm.Group>
  ),
  payment_portal: (
    <BSForm.Group>
      <Field
        name="website_address"
        data-cy="website_address"
        type="text"
        placeholder="Website Address"
        validate={composeValidators(validateRequired)}
      />
    </BSForm.Group>
  ),
};

class PaymentMethods extends PureComponent {
  render() {
    const { paymentMethod } = this.props;
    return paymentMethods[paymentMethod];
  }
}

export default PaymentMethods;
