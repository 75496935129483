import styled from "styled-components";
import { Row, Col, Tooltip } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledRow = styled(Row)`
  &.save-for-late {
    opacity: 0.4;
  }
`;

export const InformIcon = styled.span`
  padding: 5px;
  border: 1px solid #00509d;
  border-radius: 50%;
  margin-right: 10px;
  text-transform: lowercase;
  color: #00509d;
  font-size: 1.1rem;
  width: 26px;
  display: inline-block;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
`;

export const StyledColWithPrice = styled(Col)`
  .cart-line-item-price {
    font-weight: bold;
    font-size: 1.4rem;
    @media ${device.tabletS} {
      font-size: 1rem;
    }
  }
`;

export const StyledColWithTitle = styled(Col)`
  .cart-line-item-title {
    margin-bottom: 0;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    @media ${device.tabletS} {
      font-size: 1rem;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: red;
  }
  .arrow {
    &:before {
      border-bottom-color: red;
    }
  }
`;
