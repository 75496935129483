import React, { PureComponent } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  atobImplementation,
  moneyFormat,
  isLogin,
} from "storefront/features/shared/utils";
import * as actions from "storefront/features/shared/components/Header/actions";
import { StyledShippingInfo, StyledBidButton } from "./BuyNowButton.styled";
import RestrictVendorModal from "./RestrictVendorModal";

class BuyNowButton extends PureComponent {
  state = {
    show: false,
  };

  handleClick = () => {
    const {
      location,
      setRedirectTo,
      history,
      variantId,
      addCheckoutLineItems,
    } = this.props;
    const isRegistered = isLogin();

    if (!isRegistered) {
      setRedirectTo(location.pathname);
      history.push("/account/login");
    } else {
      const checkoutId = localStorage.getItem("Checkout-Id");
      const lineItemsToAdd = [
        {
          variantId: atobImplementation(`gid://shopify/ProductVariant/${variantId}`),
          quantity: 1,
        },
      ];
      addCheckoutLineItems({ checkoutId, lineItemsToAdd });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const { amount, disabledButton, freeShippingLabel, vendor } = this.props;
    const buyNowAmount = amount ? moneyFormat(amount) : "";
    return (
      <div className="mb-2">
        <StyledBidButton
          variant="primary"
          data-cy="buy-now-btn"
          onClick={vendor ? this.handleShow : this.handleClick}
          disabled={disabledButton}
        >
          BUY {buyNowAmount}
          <StyledShippingInfo>
            {freeShippingLabel && "+ Free shipping"}
          </StyledShippingInfo>
        </StyledBidButton>
        {vendor && (
          <RestrictVendorModal
            show={this.state.show}
            onHide={this.handleClose}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addCheckoutLineItems: (value) => {
    dispatch(actions.addCheckoutLineItemsRequest(value));
  },
  setRedirectTo: (link) => {
    dispatch(actions.setRedirectTo(link));
  },
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter
)(BuyNowButton);
