import React, { PureComponent } from "react";
import { StyledCountdown } from "./Countdown.styled";
import { getTimerValues } from "storefront/features/shared/utils";

class Countdown extends PureComponent {
  state = getTimerValues(this.props.endDate);

  componentDidMount() {
    this.createTimer();
  }

  componentDidUpdate(prevProps) {
    const { endDate } = this.props;
    if (prevProps.endDate !== endDate) {
      this.setState(getTimerValues(endDate));
      clearInterval(this.interval);
      this.createTimer();
    }
  }

  createTimer = () => {
    const { endDate, onClose } = this.props;
    if (!this.state.expired) {
      this.interval = setInterval(() => {
        if (this.state.expired) {
          clearInterval(this.interval);
          if (onClose && typeof onClose === "function") {
            onClose();
          }
        } else {
          this.setState(getTimerValues(endDate));
        }
      }, 1000);
    }
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { expired, timer, blinking } = this.state;
    return <StyledCountdown>
      { expired ? <span>Closed</span> : <span className={`${blinking === true ? "blinking" : ""}`}>{timer}</span> }
    </StyledCountdown>;
  }
}

export default Countdown;
