import { flatten } from "lodash";
import { all } from "redux-saga/effects";
import { routes, productsRoutes } from "./routes";
import sagas from "./sagas";
import reducers from "./reducers";

const getAppRoutes = () => {
  return routes;
};
const getQuickViewRoutes = () => {
  return productsRoutes;
};
const getAppReducers = () => {
  return reducers;
};
const getAppSagas = () => {
  return flatten(Object.values(sagas));
};

export const appRoutes = getAppRoutes();

export const appReducers = getAppReducers();

export function* appSagas() {
  yield all(getAppSagas());
}
export const quickViewRoutes = getQuickViewRoutes();
