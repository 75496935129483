import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { setRedirectTo } from "storefront/features/shared/components/Header/actions";

import { isLogin } from "../utils";

const withRedirectUnregisteredUser = (WrappedComponent) => {
  class RedirectUnregisteredUser extends PureComponent {
    state = { renderComponent: false };
    componentDidMount() {
      const { history, redirectTo } = this.props;
      const isUserLogged = isLogin();
      if (!isUserLogged) {
        redirectTo(history.location.pathname);
        history.push("/account/login");
      } else {
        this.setState({ renderComponent: true });
      }
    }

    render() {
      return this.state.renderComponent ? (
        <WrappedComponent {...this.props} />
      ) : null;
    }
  }

  return RedirectUnregisteredUser;
};

const mapDispatchToProps = (dispatch) => ({
  redirectTo: (link) => {
    dispatch(setRedirectTo(link));
  },
});

export { withRedirectUnregisteredUser };

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withRedirectUnregisteredUser
);
