import { call, takeLatest, put, select } from "redux-saga/effects";
import superagent from "superagent";
import { push } from "connected-react-router/immutable";
import { get, isEmpty, has } from "lodash";

import * as actions from "./actions";
import {
  customerAccessTokenCreateQuery,
  customerRecover,
  customerCreateQuery,
  customerResetByUrl,
  customerActivateQuery,
  customerAccessTokenDelete,
  customerUpdate,
  removeCustomerAccessData,
  customerAddressDelete,
  customerAddressCreate,
  customerDefaultAddressUpdate,
  customerAddressUpdate,
} from "./utils";
import {
  getHeaders,
  getCustomerAccessToken,
} from "storefront/features/shared/utils";
import {
  fetchCustomerRequest,
  setRedirectTo,
  fetchUserAuctionDataRequest,
} from "storefront/features/shared/components/Header/actions";
import { getRedirectTo } from "storefront/features/shared/components/Header/selectors";
import { ACCOUNT_PAGE_LOADER } from "storefront/features/shared/utils";
import { fetchWonAuctionsCountRequest } from "storefront/features/cart/actions";
import { STOREFRONT_API_URL } from "../shared/utils";

export function* getMultipassToken(customerAccessToken) {
  let response;
  const request = () =>
    superagent
      .get(`/api/my_account/generate_multipass_token`)
      .query({ access_token: customerAccessToken });
  try {
    response = yield call(request);
    const responseData = response.body.multipass_token;
    localStorage.setItem("Multipass-Token", responseData);
    yield put(fetchUserAuctionDataRequest());
    yield put(fetchWonAuctionsCountRequest());
  } catch (res) {
    const responseErrorCode =
      response.body.data.customerAccessTokenCreate.customerUserErrors[0].code;
    yield call(removeCustomerAccessData, responseErrorCode);
    console.log(
      response.body.data.customerAccessTokenCreate.customerUserErrors[0].message
    );
  }
}

export function* signInRequest(action) {
  let response;
  const { callback, email, password } = action.payload;
  const vars = {
      input: { email, password },
    },
    graphBody = { query: customerAccessTokenCreateQuery, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const {
      customerUserErrors,
      customerAccessToken,
    } = response.body.data.customerAccessTokenCreate;
    if (customerUserErrors.length > 0) {
      callback();
    } else {
      yield call(setLocalStorageData, customerAccessToken);
      const redirectTo = yield select(getRedirectTo);
      const redirectLink = isEmpty(redirectTo) ? "/" : redirectTo;
      yield put(
        fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER, password })
      );
      yield put(push(redirectLink));
      yield put(setRedirectTo(""));
    }
  } catch (res) {
    const responseErrorCode =
      response.body.data.customerAccessTokenCreate.customerUserErrors[0].code;
    responseErrorCode &&
      (yield call(removeCustomerAccessData, responseErrorCode));
    console.log(
      response.body.data.customerAccessTokenCreate.customerUserErrors[0].message
    );
  }
}

export function* fetchCustomerActiveAuctionsRequest() {
  const token = localStorage.getItem("Multipass-Token");
  const request = () =>
    superagent
      .get(`/api/my_account/get_customer_active_auctions`)
      .query({ token: token });
  const response = yield call(request);
  yield put(actions.fetchCustomerActiveAuctionsRequestResult(response.body));
}

export function* fetchCustomerCreateRequest(action) {
  let response;
  const redirectLink = `/account/success-account-creation`;
  const { email, password, firstName, lastName, acceptsMarketing, callback } = action.payload;
  const vars = { input: { email, password, firstName, lastName, acceptsMarketing } },
    graphBody = { query: customerCreateQuery, variables: vars },
    headers = getHeaders();

  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = response.body.data.customerCreate;
    if (responseData.customerUserErrors.length === 0) {
      yield put(
        push({
          pathname: redirectLink,
        })
      );
      yield put(
        actions.fetchAffiliatesRequest({ email, password, firstName, lastName })
      );
    } else {
      callback(responseData.customerUserErrors[0].message);
    }
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* fetchCustomerRecoverRequest(action) {
  let response;
  const redirectLink = `/account/success-recover-request`;
  const { email, callback } = action.payload;
  const vars = {
      email: email,
    },
    graphBody = { query: customerRecover, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = response.body.data.customerRecover;
    responseData.customerUserErrors.length === 0
      ? yield put(push(redirectLink))
      : callback(responseData.customerUserErrors[0].message);
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* fetchCustomerResetPasswordRequest(action) {
  let response;
  const redirectLink = `/account/success-password-reset`;
  const { resetUrl, password, callback } = action.payload;
  const vars = {
      resetUrl: resetUrl,
      password: password,
    },
    graphBody = { query: customerResetByUrl, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = response.body.data.customerResetByUrl;
    responseData.customerUserErrors.length === 0
      ? yield put(push(redirectLink))
      : callback(responseData.customerUserErrors[0].message);
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* fetchCustomerActivateRequest(action) {
  let response;
  const redirectLink = `/account/my-account`;
  const { activationUrl, password, callback } = action.payload;
  const vars = { activationUrl, password },
    graphBody = { query: customerActivateQuery, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = get(response, "body.data.customerActivateByUrl", {});
    if (get(responseData, "customerUserErrors", []).length === 0) {
      yield call(
        setLocalStorageData,
        get(responseData, "customerAccessToken", {})
      );
      yield put(
        fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER, password })
      );
      yield put(push(redirectLink));
    } else {
      callback(responseData.customerUserErrors[0].message);
    }
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* fetchCustomerAccessTokenDelete(action) {
  let response;
  const { customerAccessToken, redirectLink } = action.payload;
  const vars = { customerAccessToken },
    graphBody = { query: customerAccessTokenDelete, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    yield window.location.replace(`${redirectLink}?vendor=true`);
  } catch (res) {
    console.log(response.body.errors[0].message);
  }
}

export function* fetchCustomerAddressDeleteRequest(action) {
  let response;
  const { id } = action.payload;
  const customerAccessToken = getCustomerAccessToken();
  const vars = { id, customerAccessToken },
    graphBody = { query: customerAddressDelete, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    yield put(fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER }));
  } catch (res) {
    console.log(response.body.errors[0].message);
  }
}

export function* fetchCustomerAddressCreateRequest(action) {
  let response;
  const {
    address: {
      address1,
      address2,
      city,
      company,
      country,
      firstName,
      lastName,
      phone,
      province,
      zip,
      defaultAddress,
    },
  } = action.payload;
  const address = {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    lastName,
    phone,
    province,
    zip,
  };
  const customerAccessToken = getCustomerAccessToken();
  const vars = { address, customerAccessToken },
    graphBody = { query: customerAddressCreate, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    defaultAddress
      ? yield fetchCustomerDefaultAddressUpdate(
          response.body.data.customerAddressCreate.customerAddress.id
        )
      : yield put(fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER }));
  } catch (res) {
    console.log(response.body.errors[0].message);
  }
}

export function* fetchCustomerAddressUpdateRequest(action) {
  let response;
  const {
    address: {
      address1,
      address2,
      city,
      company,
      country,
      firstName,
      lastName,
      phone,
      province,
      zip,
      defaultAddress,
    },
    id,
  } = action.payload;
  const address = {
    address1,
    address2,
    city,
    company,
    country,
    firstName,
    lastName,
    phone,
    province,
    zip,
  };
  const customerAccessToken = getCustomerAccessToken();
  const vars = { address, id, customerAccessToken },
    graphBody = { query: customerAddressUpdate, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);

    defaultAddress
      ? yield fetchCustomerDefaultAddressUpdate(
          response.body.data.customerAddressUpdate.customerAddress.id
        )
      : yield put(fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER }));
  } catch (res) {
    console.log(response.body.errors[0].message);
  }
}

function* fetchCustomerDefaultAddressUpdate(id) {
  let response;
  const customerAccessToken = getCustomerAccessToken();
  const vars = {
      customerAccessToken,
      addressId: id,
    },
    graphBody = { query: customerDefaultAddressUpdate, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    yield put(fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER }));
  } catch (res) {
    console.log(response.body.errors[0].message);
  }
}

export function* fetchCustomerUpdateRequest(action) {
  let response;
  const redirectLink = `/account/success-password-reset`;
  const { password, callback } = action.payload;
  const customerAccessToken = getCustomerAccessToken();
  const vars = {
      customerAccessToken,
      customer: {
        password,
      },
    },
    graphBody = { query: customerUpdate, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = response.body.data.customerUpdate;
    yield call(setLocalStorageData, responseData);
    responseData.customerUserErrors.length === 0
      ? yield put(push(redirectLink))
      : callback(responseData.customerUserErrors[0].message);
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* fetchCustomerUpdateFirstLastNameRequest(action) {
  let response;
  const { firstName, lastName, password, email, callback } = action.payload;
  const customerAccessToken = getCustomerAccessToken();
  const vars = {
      customerAccessToken,
      customer: {
        firstName,
        lastName,
      },
    },
    graphBody = { query: customerUpdate, variables: vars },
    headers = getHeaders();
  const request = () =>
    superagent.post(STOREFRONT_API_URL).set(headers).send(graphBody);
  try {
    response = yield call(request);
    const responseData = response.body.data.customerUpdate;

    if (responseData.customerUserErrors.length === 0) {
      const redirectTo = yield select(getRedirectTo);
      const redirectLink = isEmpty(redirectTo) ? "/" : redirectTo;
      yield put(
        actions.fetchAffiliatesRequest({ email, password, firstName, lastName })
      );
      yield put(fetchCustomerRequest({ loader: ACCOUNT_PAGE_LOADER }));
      yield put(fetchUserAuctionDataRequest());
      yield put(push(redirectLink));
      yield put(setRedirectTo(""));
    } else {
      callback(responseData.customerUserErrors[0].message);
    }
  } catch (res) {
    callback(response.body.errors[0].message);
  }
}

export function* setLocalStorageData(customerAccessToken) {
  const customerAccessTokenData = customerAccessToken.accessToken;
  const expiresAtData = customerAccessToken.expiresAt;
  yield call(getMultipassToken, customerAccessTokenData);
  yield call(
    [localStorage, "setItem"],
    "Customer-Access-Token",
    customerAccessTokenData
  );
  yield call(
    [localStorage, "setItem"],
    "Customer-Access-Token-ExpiresAt",
    expiresAtData
  );
}

export function* fetchAffiliatesRequest(action) {
  const { email, firstName, lastName, password } = action.payload;
  const parent_id = localStorage.getItem("parent-Id");
  const affiliatesData = {
    email,
    firstname: firstName,
    lastname: lastName,
    password,
    parent_id,
  };
  const request = () => superagent.post(`/api/affiliates`).send(affiliatesData);
  try {
    if (!isEmpty(password) && !isEmpty(firstName) && !isEmpty(lastName)) {
      yield call(request);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* fetchChangeCustomerAvatarRequest(action) {
  const { avatar, handleErrors, handleSuccess } = action.payload;
  const token = localStorage.getItem("Multipass-Token");
  const headers = getHeaders();
  const request = () =>
    superagent
      .post(`/api/my_account/change_customer_avatar`)
      .set(headers)
      .field("token", token)
      .attach("avatar", avatar);
  try {
    yield call(request);
    handleSuccess("Your profile image has been updated");
  } catch (res) {
    handleErrors("Your profile image has not been updated");
  }
}

export function* fetchDeleteCustomerAvatarRequest(action) {
  const { handleErrors, handleSuccess } = action.payload;
  const token = localStorage.getItem("Multipass-Token");
  const deleteData = {
    token,
    delete_avatar: true,
  };
  const headers = getHeaders();
  const request = () =>
    superagent
      .post(`/api/my_account/change_customer_avatar`)
      .set(headers)
      .send(deleteData);
  try {
    yield call(request);
    handleSuccess("Your profile image has been deleted");
  } catch (res) {
    handleErrors("Your profile image has not been deleted");
  }
}

export function* fetchVendorRegisterRequest(action) {
  const {
    password,
    phone,
    email,
    firstName,
    lastName,
    products_category,
    hear_about_bids,
    sales_channels,
    address,
    store_name,
    handleErrors,
    handleSuccess,
    account_numbers,
    legal_name,
    tax_id,
    website_url,
    shippingLabels,
    paymentMethod,
    paypal_account_name,
    zelle_email_address,
    zelle_phone_number,
    wires_account_numbers,
    wires_routing_number,
    aba,
    bank_account_name,
    bank_account_type,
    swift,
    bic,
    ach_account_numbers,
    website_address,
  } = action.payload;
  const headers = getHeaders();
  const vendorData = {
    vendor: {
      phone,
      email,
      first_name: firstName,
      last_name: lastName,
      products_category,
      hear_about_bids,
      sales_channels,
      address,
      store_name,
      account_numbers,
      legal_name,
      tax_id,
      website_url,
      shipping_labels: shippingLabels?.length > 0 ? true : false,
      payment_method: paymentMethod,
      payment_method_details: {
        paypal_account_name,
        zelle_email_address,
        zelle_phone_number,
        wires_account_numbers,
        wires_routing_number,
        aba,
        bank_account_name,
        bank_account_type,
        swift,
        bic,
        ach_account_numbers,
        website_address,
      },
    },
    storefront: {
      password,
    },
  };

  const request = () =>
    superagent.post(`/api/vendors`).set(headers).send(vendorData);
  try {
    const response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess();
      yield put(
        actions.fetchAffiliatesRequest({ email, password, firstName, lastName })
      );
    }
  } catch (res) {
    console.log(res);
  }
}

export default [
  takeLatest(actions.SIGN_IN_REQUEST, signInRequest),
  takeLatest(
    actions.FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST,
    fetchCustomerActiveAuctionsRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_REGISTER_REQUEST,
    fetchCustomerCreateRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_RECOVER_REQUEST,
    fetchCustomerRecoverRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_RESET_PASSWORD_REQUEST,
    fetchCustomerResetPasswordRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_ACTIVATE_REQUEST,
    fetchCustomerActivateRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_ACCESS_TOKEN_DELETE,
    fetchCustomerAccessTokenDelete
  ),
  takeLatest(actions.FETCH_CUSTOMER_UPDATE_REQUEST, fetchCustomerUpdateRequest),
  takeLatest(
    actions.FETCH_CUSTOMER_UPDATE_FIRST_LAST_NAME_REQUEST,
    fetchCustomerUpdateFirstLastNameRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_ADDRESS_DELETE_REQUEST,
    fetchCustomerAddressDeleteRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_ADDRESS_CREATE_REQUEST,
    fetchCustomerAddressCreateRequest
  ),
  takeLatest(
    actions.FETCH_CUSTOMER_ADDRESS_UPDATE_REQUEST,
    fetchCustomerAddressUpdateRequest
  ),
  takeLatest(actions.FETCH_AFFILIATES_REQUEST, fetchAffiliatesRequest),
  takeLatest(
    actions.FETCH_CHANGE_CUSTOMER_AVATAR_REQUEST,
    fetchChangeCustomerAvatarRequest
  ),
  takeLatest(
    actions.FETCH_DELETE_CUSTOMER_AVATAR_REQUEST,
    fetchDeleteCustomerAvatarRequest
  ),
  takeLatest(actions.FETCH_VENDOR_REGISTER_REQUEST, fetchVendorRegisterRequest),
];
