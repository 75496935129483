import { fromJS } from "immutable";
import * as actions from "storefront/features/shared/actions";
import * as productGridActions from "storefront/features/productGrid/actions";
import * as accountActions from "storefront/features/shared/components/Header/actions";
import { FETCH_SEARCH_REQUEST } from "../../../search/actions";

const initialState = fromJS({
  loaders: [],
});

const setLoader = (state, value) => {
  const { loader } = value;
  return state.update(
    "loaders",
    fromJS((loaders) => loaders.push(loader))
  );
};

const removeLoader = (state, value) => {
  const { loader } = value;
  return state.update(
    "loaders",
    fromJS((loaders) => loaders.filter((e) => e !== loader))
  );
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_STATIC_PAGE_FROM_PRISMIC:
      return setLoader(state, action.payload);
    case productGridActions.FETCH_PRODUCT_REQUEST:
      return setLoader(state, action.payload);
    case accountActions.FETCH_CUSTOMER_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_ALL_PRODUCTS_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_PRODUCTS_FOR_HOME_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_BUY_IT_NOW_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_MAKE_AN_OFFER_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_FILTERED_AUCTIONS_REQUEST:
      return setLoader(state, action.payload);
    case actions.FETCH_VENDOR_PRODUCTS_REQUEST:
      return setLoader(state, action.payload);
    case FETCH_SEARCH_REQUEST:
      return setLoader(state, action.payload);
    case actions.REMOVE_LOADER:
      return removeLoader(state, action.payload);
    default:
      return state;
  }
};

export default loaderReducer;
