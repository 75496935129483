import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

export default class BidsAgreements extends PureComponent {
  render() {
    const { termsAndConditionsLink, privacyPolicy } = this.props;
    return (
      <small className="text-center mb-5">
        By creating an account, you agree to the
        <Link
          to={termsAndConditionsLink}
          variant="link"
          id="terms-and-conditions-page-link"
        >
          {" "}
          Terms and Conditions{" "}
        </Link>
        and
        <Link
          to={privacyPolicy}
          variant="link"
          data-cy="privacy-policy-page-link"
        >
          {" "}
          Privacy Policy
        </Link>
      </small>
    );
  }
}
