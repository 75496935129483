import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";

class ModalAboutAutoBidding extends PureComponent {
  render() {
    const { onClose, show } = this.props;
    return (
      <Modal show={show} onHide={() => onClose(false)} size="md" centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <p>
            Enter your maximum bid amount and our system will automatically
            outbid challengers, $1 at a time, until your maximum bid price is
            met.
          </p>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalAboutAutoBidding;
