import React, { PureComponent } from "react";

export default class Venmo extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 38 24"
        width="38"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-labelledby="pi-venmo"
      >
        <title id="pi-venmo">Venmo</title>
        <rect
          fillOpacity=".07"
          fill="#000"
          width="38"
          height="24"
          rx="3"
        ></rect>
        <path
          fill="#3D95CE"
          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
        ></path>
        <path
          d="M24.675 8.36c0 3.064-2.557 7.045-4.633 9.84h-4.74L13.4 6.57l4.151-.402 1.005 8.275c.94-1.566 2.099-4.025 2.099-5.702 0-.918-.154-1.543-.394-2.058l3.78-.783c.437.738.634 1.499.634 2.46z"
          fill="#FFF"
        ></path>
      </svg>
    );
  }
}
