import moment from "moment";
import { isEmpty, flatten, zip, get } from "lodash";

import { pusher } from "storefront/config/pusher";

const ONE_HUNDRED = 100;

export const STOREFRONT_API_URL = `https://${process.env.SHOP}/api/2024-04/graphql`;

export const atobImplementation = (str) => {
  try {
    return atob(str);
  } catch (err) {
    return str;
  }
};

export const getCustomerData = (data) => {
  const decodeCustomerId = atobImplementation(data.id);
  const customerId = decodeCustomerId.replace(/(.*\/)*/, "");
  return Object.assign(data, { id: customerId });
};

export const mergeProductAuctionData = ({
  bid_auctions,
  products,
  page_params,
}) => {
  const productAuctionArr =
    bid_auctions.length >= products.length
      ? filterDataByAuctions(bid_auctions, products)
      : filterDataByProducts(bid_auctions, products);
  return {
    products: productAuctionArr,
    page_params,
  };
};

export const filterDataByAuctions = (bid_auctions, products) => {
  const mergedProducts = [];
  bid_auctions.forEach((bidAuction) => {
    const filteredProductsById = filterDataArrById(products, bidAuction, null);
    filteredProductsById.length > 0 &&
      filteredProductsById.map((product, i) => {
        return mergedProducts.push(getMergedObj(bidAuction, product, i));
      });
  });

  return mergedProducts;
};

export const filterDataByProducts = (bid_auctions, products) => {
  const mergedProducts = [];
  products.forEach((product, i) => {
    const filteredBidAuctionsById = filterDataArrById(
      bid_auctions,
      null,
      product
    );

    if (filteredBidAuctionsById.length > 0) {
      filteredBidAuctionsById.map((bidAuction, index) => {
        return mergedProducts.push(getMergedObj(bidAuction, product, index));
      });
    } else {
      mergedProducts.push({ ...product, bidAuctionId: i });
    }
  });
  return mergedProducts;
};

export const filterDataArrById = (dataArr, bidAuction, product) =>
  dataArr.filter((data) =>
    product
      ? product?.id === data.shopify_product_id
      : data?.id === bidAuction.shopify_product_id
  );

const getMergedObj = (bidAuction, product, i) => {
  const isAuction = isTagExist(product.tags, "Buying Format_Auction");
  const productId = isAuction ? bidAuction.shopify_product_id : product.id;

  return {
    ...product,
    ...bidAuction,
    id: productId,
    bidAuctionId: bidAuction.id ? bidAuction.id : i - ONE_HUNDRED,
  };
};

const formatInt = (int) => {
  if (int < 10) {
    return `0${int}`;
  }
  return `${int}`;
};

export const formatDuration = (duration) => {
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();

  if (days > 0) {
    return `${days}d ${formatInt(hours)}:${formatInt(minutes)}:${formatInt(
      seconds
    )}`;
  }
  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  if (minutes > 0) {
    return `${formatInt(minutes)}:${formatInt(seconds)}`;
  }
  return `00:${formatInt(seconds)}`;
};

export const getTimerValues = (endDate) => {
  const then = moment(endDate);
  const now = moment.utc();

  const duration = moment.duration(then.diff(now));

  return {
    timer: formatDuration(duration),
    expired: duration.seconds() < 0,
    blinking: duration.asSeconds() < 15
  };
};

export const isExpired = (then) => moment(then) < moment();

export const getImageData = (images, i) => get(images, `[${i}]`, null);

export const getHeaders = () => ({
  Accept: "application/json",
  "X-Shopify-Storefront-Access-Token": process.env.STOREFRONT_ACCESS_TOKEN,
});

export const getCustomerAccessToken = () => {
  try {
    return localStorage.getItem("Customer-Access-Token");
  } catch (err) {
    console.log(err);
  }
};

export const isLogin = () => {
  try {
    const expiresAt = localStorage.getItem("Customer-Access-Token-ExpiresAt");
    return !isExpired(expiresAt) && getCustomerAccessToken();
  } catch (err) {
    console.log(err);
  }
};

export const XS_WIDTH = 480;
export const SM_WIDTH = 567;
export const MD_WIDTH = 768;
export const LG_WIDTH = 992;
export const XL_WIDTH = 1200;
export const XS_MAX_WIDTH = SM_WIDTH - 1;
export const SM_MAX_WIDTH = MD_WIDTH - 1;
export const MD_MAX_WIDTH = LG_WIDTH - 1;
export const LG_MAX_WIDTH = XL_WIDTH - 1;

export const STATIC_PAGE_LOADER = `page_loader`;
export const QUICK_VIEW_LOADER = `quick_view_loader`;
export const PRODUCT_PAGE_VIEW_LOADER = `product_page_view_loader`;
export const ACCOUNT_PAGE_LOADER = `account_loader`;
export const ALL_AUCTIONS_PAGE_LOADER = "all_auctions_loader";
export const HOME_PAGE_LOADER = "home_loader";
export const BUY_IT_NOW_PAGE_LOADER = "buy_it_now_loader";
export const MAKE_AN_OFFER_PAGE_LOADER = "make_an_offer_loader";
export const VENDOR_PAGE_LOADER = "vendor_loader";
export const SEARCH_PAGE_LOADER = "search_loader";

export const subscribePusher = ({
  product,
  updateProduct,
  updateQuickView,
  updateAuctionData,
  userAuctionData,
  updateSearchProduct,
  activeAuctions,
  updateCustomerActiveAuction,
  customerId
}) => {
  const channel = pusher.subscribe(`auction-${product.bidAuctionId}`);
  channel.bind("new-bid", (data) => {
    const {
      current_bid_cents,
      max_bid,
      winner_id,
      customer_country,
      count,
      end_at,
      winner,
      avatar,
    } = data;
    const { bidAuctionId } = product;
    const userAuctionBidValue = getUserAuctionBidValue(
      userAuctionData,
      bidAuctionId
    );
    const updatedData = {
      bids_count: count,
      end_at: moment.unix(end_at).toISOString(),
      winner_bid_id: winner_id,
      current_bid: current_bid_cents,
      winning_customer_country: customer_country,
      winner,
      winning_customer_avatar: avatar,
    };
    const updatedProduct = { ...product, ...updatedData };
    updateProduct(updatedProduct);

    if (ifUpdateQuickView(product.handle, bidAuctionId)) {
      updateQuickView(updatedProduct);
    }

    updateSearchProduct(updatedProduct);
    if (userAuctionBidValue && current_bid_cents >= userAuctionBidValue) {
      delete userAuctionData[bidAuctionId].auto_bid;
      updateAuctionData({
        userAuctionData,
        [bidAuctionId]: {
          ...userAuctionData[bidAuctionId],
          auto_bid: undefined,
        },
      });
    }

    if (activeAuctions[bidAuctionId] !== undefined) {
      const updated_max_bid = customerId === winner_id && max_bid > activeAuctions[bidAuctionId].max_bid
        ? max_bid
        : activeAuctions[bidAuctionId].max_bid;

      const updatedActiveAuctions = {
        ...activeAuctions[bidAuctionId],
        winning_customer: winner,
        winning_customer_id: winner_id,
        current_bid: current_bid_cents,
        bids_count: count,
        max_bid: updated_max_bid,
        end_at: moment.unix(end_at).toISOString()
      };

      updateCustomerActiveAuction(updatedActiveAuctions);
    }
  });
};

const ifUpdateQuickView = (productHandle, bidAuctionId) => {
  let result = false;

  const quickViewAuction = Number(
    getSearchParams(location?.search, "auction_id")
  );
  const quickViewHandle = location?.pathname?.split("/").pop();

  if (
    (quickViewAuction !== 0 && quickViewAuction === bidAuctionId) ||
    (quickViewAuction === 0 && quickViewHandle === productHandle)
  ) {
    result = true;
  }

  return result;
};

export const getUserAuctionBidValue = (userAuctionData, bidAuctionId) => {
  const userAutoBidValue =
    userAuctionData && !isEmpty(userAuctionData[bidAuctionId])
      ? userAuctionData[bidAuctionId].auto_bid
      : null;
  return userAutoBidValue;
};

export const bidButtonCasesTypes = {
  UNREGISTERED_USER: "unregistered-user",
  UNREGISTERED_USER_WITH_EMAIL: "unregistered-user-with-email",
  REGISTERED_AND_LOGGED: "registered-and-logged",
};

export const bidEventName = {
  NEW_BID: "new_bid",
  NEW_MAX_BID: "new_max_bid",
  NEW_ANONYMOUS_BID: "new_anonymous_bid",
};

export const getBidButtonCaseType = () => {
  const unregisteredUserEmail = localStorage.getItem("Unregistered-User-Email");
  const isRegistered = isLogin();
  if (isEmpty(unregisteredUserEmail) && !isRegistered) {
    return bidButtonCasesTypes.UNREGISTERED_USER;
  } else if (!isEmpty(unregisteredUserEmail) && !isRegistered) {
    return bidButtonCasesTypes.UNREGISTERED_USER_WITH_EMAIL;
  } else if (isRegistered) {
    return bidButtonCasesTypes.REGISTERED_AND_LOGGED;
  }
};

export const getBidDataObj = (
  { shopify_product_id, current_bid, bids_count, bidAuctionId },
  event,
  email,
  customer_id
) => {
  const data = {
    product_id: shopify_product_id,
    bid: calculateNextBid(current_bid, bids_count) / ONE_HUNDRED,
    shop: `${process.env.SHOP}`,
    event: event,
    auction_id: bidAuctionId,
  };
  if (email) {
    Object.assign(data, { email });
  }
  if (customer_id) {
    Object.assign(data, { customer_id });
  }

  return data;
};

export const updateAuction = (products, dispatchActionCallBack) => {
  const closedAuction = products.filter((product) => isExpired(product.end_at));
  closedAuction.length > 0 && dispatchActionCallBack();
};

export const moneyFormat = (value, isCents) => {
  const amount = isCents ? value / ONE_HUNDRED : value;
  const style = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    useGrouping: true,
  };
  return new Intl.NumberFormat("en-US", style).format(amount);
};

export const mergeUserAuctionData = ({
  bid_auctions,
  won_auctions_count,
  affiliate_id,
  vendor,
  vendor_status,
  vendor_id,
  store_name,
}) => {
  const mergedAuctionData = bid_auctions.reduce(
    (acc, elem) => ({ ...acc, [elem.id]: elem }),
    {}
  );

  return {
    bid_auctions: mergedAuctionData,
    won_auctions_count,
    affiliate_id,
    vendor,
    vendor_status,
    vendor_id,
    store_name,
    winning_customer_avatar: isEmpty(bid_auctions)
      ? ""
      : bid_auctions[0].winning_customer_avatar,
  };
};

export const getSearchParams = (url, name) => {
  name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line no-useless-escape
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getVendorId = (pathname) => {
  return pathname.replace("/vendors/", "").split("/")[0];
};

export const getTags = (pathName) =>
  pathName.split("/quick-view/")[0].split("/").pop();

export const createArrayOfProductsForHome = (data) => {
  const smallestArray = data.reduce((prev, next) =>
    prev.length > next.length ? next : prev
  );

  data.map((el) => el.splice(smallestArray.length));
  return flatten(zip(...data));
};

export const isTagExist = (tags, tagName) =>
  tags ? tags.includes(tagName) : false;
export const FREE_SHIPPING = "free-shipping";
export const RING_SIZING = "Ring Sizing";
export const RING_SIZING_COST = 25;

export const getSubtotal = (cartProductData) => {
  const priceData = [];
  if (cartProductData && cartProductData.length) {
    cartProductData.forEach(
      ({ price, saveForLater, quantity, notAvailable, ringSizing }) => {
        if (checkWonAuctionDataBeforeCheckout(saveForLater, notAvailable)) {
          const productPrice = Number(price.replace(/[$,]/g, ""));
          priceData.push(productPrice * (quantity || 1));
          if (ringSizing) {
            priceData.push(RING_SIZING_COST);
          }
        }
      }
    );
  }

  const subtotal = priceData.reduce((acc, price) => {
    return acc + price;
  }, 0);
  return moneyFormat(subtotal);
};

export const subscribeWonAuctionsPusher = (
  customerId,
  fetchWonAuctionsCountRequestResult,
  wonAuctionsCount,
  showFireworks,
  playHymn
) => {
  const chanel = pusher.subscribe(`customer-${customerId}`);
  const winnerCount = [];
  chanel.bind("closed", (data) => {
    if (data.reason === "winner") {
      winnerCount.push(data);
      const updateWonAuctionsCount = wonAuctionsCount + winnerCount.length;
      fetchWonAuctionsCountRequestResult(updateWonAuctionsCount);
      showFireworks();
      playHymn(data.winning_customer_country);
    }
  });
};
const MAX_DELAY_BEFORE_REMOVE_CLOSED_AUCTION = 10000;

export const getRandomInt = () =>
  Math.floor(Math.random() * MAX_DELAY_BEFORE_REMOVE_CLOSED_AUCTION);

export const mergeCartProductData = (wonAuctions, checkoutLineItems) => {
  const cartProductData = [];

  wonAuctions.map((wonAuction) => {
    const { product_image, product_title, current_bid, id } = wonAuction;
    const wonAuctionData = {
      id,
      imageSrc: product_image,
      productTitle: product_title,
      price: current_bid,
    };
    return cartProductData.push(wonAuctionData);
  });

  checkoutLineItems.map((liteItem) => {
    const {
      variant: { image, price },
      title,
      id,
    } = liteItem;

    const wonAuctionData = {
      id,
      imageSrc: image.src,
      productTitle: title,
      price,
    };
    return cartProductData.push(wonAuctionData);
  });
  return cartProductData;
};

export const formatWonAuctionsData = (wonAuctions) => {
  const cartProductData = [];
  if (wonAuctions && wonAuctions.length) {
    wonAuctions.map((wonAuction) => {
      const {
        product_title,
        current_bid,
        id,
        product: { image_url, options, tags_array, variants },
      } = wonAuction;

      const getFirstAvailableVariant = () => {
        return variants.find(({ quantity }) => quantity > 0) || variants[0];
      };
      const firstAvailableVariant = getFirstAvailableVariant();
      const wonAuctionData = {
        id,
        imageSrc: image_url,
        productTitle: product_title,
        price: moneyFormat(current_bid, true),
        options,
        variantId: firstAvailableVariant.shopify_id,
        variantImageUrl: firstAvailableVariant.image?.src
          ? firstAvailableVariant.image.src
          : image_url,
        activeOptions: firstAvailableVariant.selected_options,
        variants,
        notAvailable: firstAvailableVariant.quantity > 0 ? false : true,
        product_tags: tags_array,
      };
      return cartProductData.push(wonAuctionData);
    });
  }

  return cartProductData;
};

export const formatCheckoutLineItems = (checkoutLineItems) => {
  const cartProductData = [];
  if (checkoutLineItems && checkoutLineItems.length > 0) {
    checkoutLineItems.map((lineItem) => {
      const {
        variant: { image, price, id: variantId },
        title,
        quantity,
        id,
      } = lineItem;

      const lineItemData = {
        id,
        variantId,
        imageSrc: image.src,
        productTitle: title,
        price: moneyFormat(price.amount, false),
        quantity,
      };
      return cartProductData.push(lineItemData);
    });
  }
  return cartProductData;
};

export const getCheckoutLineItemsCount = (checkoutLineItems) => {
  const quantityData = [];
  checkoutLineItems.map((checkoutLineItem) =>
    quantityData.push(checkoutLineItem.quantity)
  );

  const quantityCount = quantityData.reduce((acc, quantity) => {
    return acc + quantity;
  });
  return quantityCount;
};

export const setCheckoutBuyNowData = (checkoutLineItems) => {
  const checkoutBuyNow = [];
  if (checkoutLineItems) {
    checkoutLineItems.forEach((lineItem) => {
      const {
        variant: { id },
        quantity,
      } = lineItem;
      checkoutBuyNow.push({
        variant_id: id.split("/ProductVariant/")[1],
        quantity,
      });
    });
  }

  return checkoutBuyNow;
};

export const getCountActiveAuctions = (userAuctionData) => {
  return isEmpty(userAuctionData) ? 0 : Object.values(userAuctionData).filter(x => !isExpired(x.end_at)).length;
};

export const setCheckoutWonAuctionsData = (wonAuctions) => {
  const checkoutWonAuctions = [];
  if (wonAuctions) {
    wonAuctions.forEach(({ id, saveForLater, variantId, notAvailable }) => {
      checkWonAuctionDataBeforeCheckout(saveForLater, notAvailable) &&
        checkoutWonAuctions.push({
          auction_id: id,
          variant_id: variantId,
        });
    });
  }
  return checkoutWonAuctions;
};

export const setCheckoutWonAuctionsRingSizingData = (wonAuctions) => {
  const checkoutWonAuctionsRingSizing = [];
  if (wonAuctions) {
    wonAuctions.forEach(
      ({ saveForLater, variantId, notAvailable, ringSizing }) => {
        checkWonAuctionDataBeforeCheckout(saveForLater, notAvailable) &&
          ringSizing &&
          checkoutWonAuctionsRingSizing.push({
            variant_id: variantId,
            value: ringSizing,
          });
      }
    );
  }
  return checkoutWonAuctionsRingSizing;
};

const checkWonAuctionDataBeforeCheckout = (saveForLater, notAvailable) => {
  if (!saveForLater) {
    if (!notAvailable) {
      return true;
    }
  }
  return false;
};

export const updateCheckoutWonAuctionsData = (
  wonAuctions,
  id,
  saveForLater
) => {
  const modifiedWonAuction = wonAuctions.map((wonAuction) => {
    if (wonAuction.id === id) {
      return { ...wonAuction, saveForLater };
    } else {
      return { ...wonAuction };
    }
  });
  return modifiedWonAuction;
};

export const updateCheckoutWonAuctionsVariantData = (
  wonAuctions,
  id,
  variantId,
  choosedOptions
) => {
  const modifiedWonAuction = wonAuctions.map((wonAuction) => {
    if (wonAuction.id === id) {
      const variantData = wonAuction.variants.find(
        (variant) => variant.shopify_id === variantId
      );
      const notAvailable =
        variantData && variantData.quantity > 0 ? false : true;
      const activeOptionsUpdated = variantData
        ? variantData.selected_options
        : choosedOptions;
      wonAuction.variantId = variantId;
      wonAuction.variantImageUrl = variantData?.image?.src
        ? variantData.image.src
        : wonAuctions.imageSrc;
      wonAuction.activeOptions = activeOptionsUpdated;
      return { ...wonAuction, notAvailable };
    } else {
      return { ...wonAuction };
    }
  });
  return modifiedWonAuction;
};

export const updateCheckoutWonAuctionsRingSizeData = (
  wonAuctions,
  id,
  ringSizingOption
) => {
  const modifiedWonAuction = wonAuctions.map((wonAuction) => {
    if (wonAuction.id === id) {
      const ringSizing = ringSizingOption !== "default" ? ringSizingOption : "";
      return { ...wonAuction, ringSizing };
    } else {
      return { ...wonAuction };
    }
  });
  return modifiedWonAuction;
};

export const EXISTS = "exists";
export const STATUS_BAD_REQUEST = 400;

export const calculateNextBid = (currentBid, bidsCount) => {
  const ONE_DOLLAR = 100;
  if (currentBid === ONE_DOLLAR && bidsCount === 0) {
    return ONE_DOLLAR;
  }
  return currentBid + ONE_DOLLAR;
};

export const getCurrentUserBidValues = ({
  product: { bidAuctionId, current_bid, bids_count },
  userAuctionData,
}) => {
  const userAutoBidValue = getUserAuctionBidValue(
    userAuctionData,
    bidAuctionId
  );
  return current_bid < userAutoBidValue
    ? [calculateNextBid(userAutoBidValue, bids_count), true]
    : [calculateNextBid(current_bid, bids_count), false];
};

export const mapShippingPriceToVendor = (vendor, shippingPrices) =>
  shippingPrices[vendor] || shippingPrices.default;

export const setCookie = (cookieName, cookieValue, exmin) => {
  const date = new Date();
  date.setTime(date.getTime() + exmin * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
};

export const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const splittedCookie = decodedCookie.split(";");
  for (let i = 0; i < splittedCookie.length; i++) {
    let cookie = splittedCookie[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

export const checkCookie = (cookieName) => {
  return getCookie(cookieName) !== "" ? true : false;
};

export const isVendorAccepted = (vendor, vendor_status) =>
  vendor && vendor_status === "accepted";

export const getBidButtonLabel = (showBidValue, bidValue) =>
  showBidValue ? `BID ${bidValue}` : "BID";
