import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { WithLoader } from "storefront/features/shared/components/Loader";
import { VENDOR_PAGE_LOADER } from "storefront/features/shared/utils";

import withRemoveAuction from "storefront/features/shared/components/RemoveAuction";
import withRedirectBlockedVendor from "storefront/features/shared/components/RedirectBlockedVendor";
import * as actions from "../actions";
import * as productActions from "storefront/features/shared/actions";
import {
  getFlagUpdateProductGrid,
  getPageParams,
  getProducts,
  getVendorInfo,
} from "storefront/features/shared/selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams, getVendorId } from "storefront/features/shared/utils";
import VendorFilter from "./VendorFilter";

class Vendor extends PureComponent {
  state = {
    filter: "",
  };

  componentDidMount() {
    const {
      location,
      fetchVendorData,
      history,
      updateProductGrid,
    } = this.props;
    const pageNumber = getSearchParams(location.search, "page");
    history.action === "PUSH" &&
      fetchVendorData({
        vendor_id: this.getVendorSlug(),
        page: pageNumber,
        buying_format: this.state.filter,
        loader: VENDOR_PAGE_LOADER,
        withDelay: updateProductGrid,
      });
    document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  }

  getVendorSlug = () => {
    const { location } = this.props;
    return getVendorId(location.pathname);
  };

  handleVisibilityChange = () => {
    if (!document.hidden) {
      const isLoader = true;
      const isVisibilityChange = true;
      this.handleUpdateGrid(isLoader, isVisibilityChange);
    }
  };

  pageItemHandleClick = (page) => {
    const buyingFormat = this.state.filter;
    this.filterItemHandleChange(buyingFormat, page);
  };

  handleUpdateGrid = (isLoader, isVisibilityChange) => {
    const {
      location: { search, pathname },
      fetchVendorData,
      updateProductGrid,
    } = this.props;
    const withDelay = isVisibilityChange ? false : updateProductGrid;
    const pageNumber = getSearchParams(search, "page");
    const buyingFormat = this.state.filter;
    const loader = isLoader ? VENDOR_PAGE_LOADER : null;
    fetchVendorData({
      vendor_id: getVendorId(pathname),
      page: pageNumber,
      buying_format: buyingFormat,
      loader,
      withDelay: withDelay,
    });
  };

  filterItemHandleChange = (filter, page) => {
    const buyingFormat = filter;
    this.setState({ filter: buyingFormat });
    const {
      fetchVendorFilter,
      vendorInfo: { slug },
    } = this.props;
    fetchVendorFilter({
      page,
      vendor_id: slug,
      buying_format: buyingFormat,
      loader: VENDOR_PAGE_LOADER,
    });
  };

  render() {
    const { filter } = this.state;
    const {
      products,
      pageParams,
      vendorInfo: { store_name = "", description = "", logo },
    } = this.props;
    const metaTags = {
      metaTitle: store_name,
      metaDescription: description,
      image: logo,
    };

    return (
      <>
        <Helmet metaTags={metaTags} />
        <VendorFilter
          filterItemHandleChange={this.filterItemHandleChange}
          filter={filter}
        />
        <WithLoader loader={VENDOR_PAGE_LOADER}>
          <ProductGrid products={products} />
        </WithLoader>
        <Pagination
          pageItemHandleClick={this.pageItemHandleClick}
          pageParams={pageParams}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorInfo: getVendorInfo,
  products: getProducts,
  updateProductGrid: getFlagUpdateProductGrid,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorData: (values) => {
    dispatch(actions.fetchVendorDataRequest(values.vendor_id));
    dispatch(productActions.fetchVendorProductsRequest(values));
  },
  fetchVendorFilter: (values) => {
    dispatch(productActions.fetchVendorProductsRequest(values));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withRedirectBlockedVendor,
  withRemoveAuction
)(Vendor);
