import React, { PureComponent } from "react";

export default class MY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="42.663"
              transform="translate(0 127.994)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 213.331)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 298.657)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 383.994)"
              fill="#d80027"
            />
          </g>
          <rect
            width="256"
            height="183.797"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M140.919,219.429A42.2,42.2,0,1,1,160.994,140.1a51.941,51.941,0,1,0,0,74.26A42.021,42.021,0,0,1,140.919,219.429Z"
              fill="#ffda44"
            />
            <path
              d="M152.811,147.545l7.493,15.668,16.92-3.91-7.577,15.626,13.608,10.792-16.943,3.819.048,17.367-13.549-10.866-13.549,10.866.047-17.367-16.943-3.819,13.608-10.792L128.4,159.3l16.922,3.91Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
