import React, { PureComponent } from "react";

export default class BT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#ff9811"
          />
          <path d="M512,85.33H0V426.662" fill="#ffda44" />
          <path
            d="M352.546,181.793V152.111H295.655l-4.346,4.346c-15.459,15.459-12.859,33.494-10.96,46.662,1.761,12.216,1.972,16.894-2.57,21.437s-9.223,4.331-21.437,2.57c-13.167-1.9-31.2-4.5-46.661,10.959s-12.857,33.493-10.957,46.66c1.762,12.213,1.973,16.893-2.568,21.434s-9.223,4.329-21.434,2.568a111.55,111.55,0,0,0-14.94-1.455l-.326,29.679a88.713,88.713,0,0,1,11.029,1.152,108.4,108.4,0,0,0,15.663,1.45,44.777,44.777,0,0,0,17.993-3.442v23.747h44.522V337.617H226.4v-14.84h14.84V300.516H229.38c.563-7.2-.426-14.1-1.28-20.009-1.762-12.213-1.973-16.893,2.568-21.434s9.222-4.332,21.437-2.57c9.594,1.384,21.774,3.131,33.658-2v23.751h44.522V255.994H308.024v-14.84h14.84V218.893H311c.563-7.2-.425-14.1-1.278-20.011-1.23-8.534-1.7-13.39-.329-17.091h43.15Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
