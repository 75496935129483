import React, { PureComponent } from "react";

export default class RW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <rect
            width="512"
            height="81.619"
            transform="translate(0 255.994)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="89.043"
            transform="translate(0 337.614)"
            fill="#496e2d"
          />
          <path
            d="M278.261,185.209l20.844,9.8-11.1,20.186,22.632-4.33,2.868,22.865,15.765-16.816,15.766,16.816,2.867-22.865,22.633,4.33-11.1-20.186,20.843-9.8-20.844-9.8,11.1-20.185L347.9,159.548l-2.868-22.864L329.271,153.5l-15.766-16.816-2.867,22.864L288,155.219l11.1,20.187Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
