import React, { PureComponent } from "react";

export default class KN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#d80027"
          />
          <path d="M512,85.33H0V426.663" fill="#6da544" />
          <path
            d="M446.679,85.33H417.834L0,363.887v62.776H94.165L512,148.1V85.33Z"
            fill="#ffda44"
          />
          <path d="M512,85.33H446.679L0,383.117v43.546H65.32L512,128.876Z" />
          <g>
            <path
              d="M186.241,265.94l18.9,14.53L224.8,266.983l-7.977,22.467,18.9,14.53-23.832-.644L203.916,325.8l-6.751-22.867-23.833-.644,19.66-13.487Z"
              fill="#f0f0f0"
            />
            <path
              d="M289.182,195.323l18.9,14.53,19.659-13.487-7.977,22.467,18.9,14.53-23.833-.645-7.978,22.468-6.752-22.867-23.832-.644,19.66-13.487Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
