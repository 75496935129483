import { createSelector } from "reselect";

export const cartSelector = (state) => state.getIn(["cartReducer"]);

export const getWonAuctions = createSelector(cartSelector, (cart) =>
  cart.get("wonAuctions")
);

export const getWonAuctionsCount = createSelector(cartSelector, (cart) =>
  cart.get("wonAuctionsCount")
);

export const getShippingMessageData = createSelector(cartSelector, (cart) =>
  cart.get("shippingMessageEnabled")
);
