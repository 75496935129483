import { call, put, takeLatest, delay } from "redux-saga/effects";
import superagent from "superagent";

import { setFlagUpdateProductGrid } from "storefront/features/productGrid/actions";
import * as actions from "./actions";
import { getRandomInt } from "storefront/features/shared/utils";
import { removeLoader } from "../shared/actions";

export function* fetchSearchRequest(action) {
  const { title, buyingFormat, page, loader, withDelay } = action.payload;
  withDelay && (yield delay(15000 + getRandomInt()));
  const request = () =>
    superagent
      .get(`/api/products/search`)
      .query({ title, buying_format: buyingFormat, page });
  try {
    const response = yield call(request);
    if (response.body.error) {
      yield put(actions.fetchSearchRequestResultError(response.body));
    }
    yield put(actions.fetchSearchRequestResult(response.body));
    yield put(setFlagUpdateProductGrid(false));
    yield put(removeLoader({ loader }));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchDropDownSearchRequest(action) {
  const { title, limit, buyingFormat, loader } = action.payload;

  const request = () =>
    superagent
      .get(`/api/products/search`)
      .query({ title, limit, buying_format: buyingFormat });
  try {
    const response = yield call(request);
    yield put(actions.fetchDropDownSearchRequestResult(response.body));
    yield put(setFlagUpdateProductGrid(false));
    yield put(removeLoader({ loader }));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export default [
  takeLatest(actions.FETCH_SEARCH_REQUEST, fetchSearchRequest),
  takeLatest(actions.FETCH_DROPDOWN_SEARCH_REQUEST, fetchDropDownSearchRequest),
];
