import React, { PureComponent } from "react";

export default class QA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 339.86"
      >
        <g transform="translate(0 -86.07)">
          <rect
            width="509.784"
            height="339.848"
            transform="translate(2.216 86.076)"
            fill="#751a46"
          />
          <path
            d="M209.455,154.969l-50.236,25.267,50.236,25.257L159.219,230.76l50.236,25.257-50.236,25.266,50.236,25.257-50.236,25.268,50.236,25.257-50.236,25.267L209.455,407.6l-36.45,18.33H0V86.07H172.939l36.516,18.364L159.219,129.7Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
