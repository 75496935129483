import React, { PureComponent } from "react";
import { Button, Col, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import Field from "storefront/features/shared/components/field/Field";
import {
  validateEmail,
  validateRequired,
  composeValidators,
} from "storefront/features/shared/components/field/utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "storefront/features/shared/actions";

class ContactForm extends PureComponent {
  state = { alert: false };

  handleResponse = () => {
    this.setState({ alert: true });
  };

  handleSubmitForm = (values) => {
    const { fetchCustomerContactFormSubmit } = this.props,
      callback = this.handleResponse;
    fetchCustomerContactFormSubmit({ callback, ...values });
  };

  render() {
    const { alert } = this.state,
      thankYouMsg = `Your complain or suggestion will be read by the upper management of Bids.com. Thank you for your help.`;
    return (
      <>
        {alert ? (
          <h4 className="text-center py-4">{thankYouMsg}</h4>
        ) : (
          <Form
            onSubmit={this.handleSubmitForm}
            render={({ handleSubmit, submitting }) => (
              <BSForm onSubmit={handleSubmit}>
                <BSForm.Row>
                  <BSForm.Group as={Col}>
                    <Field name="name" type="text" placeholder="Your name" />
                  </BSForm.Group>
                  <BSForm.Group as={Col}>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Your email"
                      validate={composeValidators(
                        validateEmail,
                        validateRequired
                      )}
                      disabled={submitting}
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Group>
                  <Field
                    name="phone"
                    type="phone"
                    placeholder="Your phone (optional)"
                  />
                </BSForm.Group>
                <BSForm.Row>
                  <BSForm.Group as={Col}>
                    <Field
                      name="message"
                      component="textarea"
                      rows="8"
                      className="w-100"
                      placeholder="Your message"
                      validate={validateRequired}
                      disabled={submitting}
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Group>
                  <Button
                    className="text-uppercase"
                    type="submit"
                    variant="primary"
                    disabled={submitting}
                  >
                    Send message
                  </Button>
                </BSForm.Group>
              </BSForm>
            )}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerContactFormSubmit: (values) => {
    dispatch(actions.fetchCustomerContactFormSubmit(values));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(ContactForm));
