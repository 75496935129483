import React, { PureComponent } from "react";

export default class JM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <path d="M215.864,256.006,0,399.911V112.089Z" />
          <path d="M512,112.089V399.911l-215.864-143.9Z" />
          <path
            d="M512,112.089,296.136,256.006,512,399.911v26.757H471.875L256,282.752,40.125,426.668H0V399.911l215.864-143.9L0,112.089V85.331H40.125L256,229.248,471.875,85.331H512Z"
            fill="#0052b4"
          />
          <path
            d="M512,112.089,296.136,256.006,512,399.911v26.757H471.875L256,282.752,40.125,426.668H0V399.911l215.864-143.9L0,112.089V85.331H40.125L256,229.248,471.875,85.331H512Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
