import { isEmpty } from "lodash";

export const validateRequired = (value) => (value ? undefined : "Required");

export const validateNumberOrEmpty = (value) => {
  if (!isEmpty(value)) {
    return validateNumber(value);
  }
  return undefined;
};

export const validateNumber = (value) =>
  isNaN(value) ? "Must be a number" : undefined;

export const validateMinLength = (min) => (value) =>
  value && value.length >= min ? undefined : `Should be greater than ${min}`;

export const validateMaxLength = (max) => (value) => {
  if (!isEmpty(value)) {
    return value.length <= max ? undefined : `Should be less than ${max}`;
  }
};

export const validateEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const validatePhoneNumber = (value) =>
  value &&
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(value)
    ? undefined
    : "Invalid phone number";

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
