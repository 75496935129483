import React, { PureComponent } from "react";

export default class BR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#6da544"
          />
          <path
            d="M256,161.678l128,94.316L256,350.31,128,255.994Z"
            fill="#ffda44"
          />
          <circle
            cx="53.894"
            cy="53.894"
            r="53.894"
            transform="translate(202.106 202.1)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M229.052,252.626a90.659,90.659,0,0,0-26.93,4.07,53.877,53.877,0,0,0,98.011,30.212A90.815,90.815,0,0,0,229.052,252.626Z"
              fill="#0052b4"
            />
            <path
              d="M308.895,266.32a53.9,53.9,0,0,0-102.422-31.6,111.1,111.1,0,0,1,102.422,31.6Z"
              fill="#0052b4"
            />
          </g>
        </g>
      </svg>
    );
  }
}
