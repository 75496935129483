import { fromJS } from "immutable";
import * as actions from "./actions";

const initialState = fromJS({
  banner: {},
  stripeBanner: {},
  homepageBanners: {},
  reviewsCarousel: {},
});

const setBannerContent = (state, action) =>
  state.set("banner", fromJS(action.payload));

const setStripeBannerContent = (state, action) =>
  state.set("stripeBanner", fromJS(action.payload));

const setHomepageBannersContent = (state, action) =>
  state.set("homepageBanners", fromJS(action.payload));

const setReviewsCarouselContent = (state, action) =>
  state.set("reviewsCarousel", fromJS(action.payload));

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_BANNER_REQUEST_RESULT:
      return setBannerContent(state, action);
    case actions.FETCH_STRIPE_BANNER_REQUEST_RESULT:
      return setStripeBannerContent(state, action);
    case actions.FETCH_REVIEWS_CAROUSEL_REQUEST_RESULT:
      return setReviewsCarouselContent(state, action);
    case actions.FETCH_HOMEPAGE_BANNERS_REQUEST_RESULT:
      return setHomepageBannersContent(state, action);
    default:
      return state;
  }
};

export default bannerReducer;
