export const getCanonicalPath = (pathName) => {
  if (pathName === "/") {
    return `https://${process.env.SHOP_URL}`;
  } else {
    const regex = /^.*(?=(\/quick-view))/;
    return pathName.match(regex)
      ? `https://${process.env.SHOP_URL}${pathName.match(regex)[0]}`
      : `https://${process.env.SHOP_URL}${pathName}`;
  }
};
