import React, { PureComponent } from "react";

export default class FJ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#2e52b2"
          />
          <path
            d="M256,85.334v22.617L209.931,138.66H256v64H209.931L256,233.38V256H233.372L160,207.078V256H96V207.078L22.628,256H0V233.38l46.069-30.72H0v-64H46.069L0,107.951V85.334H22.628L96,134.241V85.334h64v48.907l73.372-48.907Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.329H112v69.334H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
            <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
            <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
            <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
            <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          </g>
          <path
            d="M332.058,207.765v59.362c0,39.76,51.942,51.942,51.942,51.942s51.942-12.182,51.942-51.942V207.765L384,192.924Z"
            fill="#f3f3f3"
          />
          <path
            d="M435.942,211.475V189.214H332.059v22.261H376.58V256H332.059v14.839H376.58V320.5c4.479,1.586,7.421,2.277,7.421,2.277s2.942-.691,7.42-2.276V270.838h44.523V256H391.42V211.475Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
