import React, { PureComponent } from "react";

export default class CA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#d80027"
            />
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#d80027"
            />
            <path
              d="M288,279.7l32-16-16-8v-16l-32,16,16-32H272l-16-24-16,24H224l16,32-32-16v16l-16,8,32,16-8,16h32v24h16v-24h32Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
