import React, { PureComponent } from "react";

export default class TN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <circle
            cx="96"
            cy="96"
            r="96"
            transform="translate(160 159.994)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M267.826,219.291l16.47,22.695,26.673-8.649-16.5,22.676,16.468,22.7-26.664-8.681L267.782,292.7l.017-28.04-26.664-8.682,26.674-8.648Z"
              fill="#d80027"
            />
            <path
              d="M277.818,312.724A56.727,56.727,0,1,1,304.8,206.089a69.818,69.818,0,1,0,0,99.818A56.488,56.488,0,0,1,277.818,312.724Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
