import React, { PureComponent } from "react";

export default class MyAuctionsIcon extends PureComponent {
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 32 32">
        <path
          d="M17.7,26.5V32H0v-5.5 M13.5,23.3H4.1c-0.8,0-1.4,0.6-1.4,1.4v3.2c0,0.8,0.6,1.4,1.4,1.4h9.4c0.8,0,1.4-0.6,1.4-1.4v-3.2
            C14.9,24,14.3,23.3,13.5,23.3z"
        ></path>
        <path
          d="M25.9,17l5.7,5.7c0.5,0.5,0.5,1.4,0,1.9l-3.1,3.1c-0.5,0.5-1.4,0.5-1.9,0l-5.7-5.7 M28.8,24.7c0,0,0.5-0.5-0.4-1.3
            c-0.8-0.8-4.5-4.5-4.5-4.5c0,0,2-2,2-2c-1.4,1.4-2.4,0.7-2.6,0.5c-1.8-1.8-4.7-4.7-6.2-6.2c-0.8-0.7-1.8,0-1.8,0l0,0
            c-0.8,1-0.3,1.7-0.3,1.7s4.6,4.6,6.4,6.4c0.2,0.2,0.9,1.3-0.4,2.6c0,0,2-2,2-2c0,0,4.5,4.5,4.5,4.5c0.8,0.8,1.2,0.4,1.2,0.4"
        ></path>
        <path
          d="M13.9,3l-1-1c0,0,0-0.1,0-0.1l2-2c0,0,0,0,0.1,0c0.5,0.5,5.9,5.8,6.3,6.3c0,0,0,0,0,0.1l-2.1,2.1c0,0-0.9-0.9-0.9-0.9
            L11,14.8c0,0,0.9,0.9,0.9,0.9c-0.5,0.5-1.6,1.6-2.1,2.1c0,0,0,0-0.1,0l-6.3-6.3c0,0,0-0.1,0-0.1l2-2.1c0,0,0.1,0,0.1,0l1,1
            M14.4,5.4L9,10.7c0,0,0,0.1,0,0.1l1.6,1.6c0,0,0.1,0,0.1,0L16,7c0,0,0-0.1,0-0.1L14.4,5.4C14.4,5.3,14.4,5.3,14.4,5.4z"
        ></path>
      </svg>
    );
  }
}
