import React, { PureComponent } from "react";

export default class IN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#ff9811"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#6da544"
          />
          <circle
            cx="43.896"
            cy="43.896"
            r="43.896"
            transform="translate(212.104 212.104)"
            fill="#0052b4"
          />
          <circle
            cx="27.434"
            cy="27.434"
            r="27.434"
            transform="translate(228.566 228.566)"
            fill="#f0f0f0"
          />
          <path
            d="M256,222.146l8.464,19.2,20.855-2.268L272.927,256l12.392,16.927-20.855-2.268L256,289.854l-8.464-19.2-20.855,2.268L239.073,256l-12.392-16.927,20.855,2.268Z"
            fill="#0052b4"
          />
        </g>
      </svg>
    );
  }
}
