import React, { PureComponent } from "react";

export default class MC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.332"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.34)"
            fill="#f0f0f0"
          />
          <path d="M512,85.334v166.69L0,256.175V85.334Z" fill="#d80027" />
        </g>
      </svg>
    );
  }
}
