import React, { PureComponent } from "react";

export default class CI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(330.207 85.331)"
            fill="#6da544"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#ff9811"
          />
        </g>
      </svg>
    );
  }
}
