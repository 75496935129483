import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import { moneyFormat } from "storefront/features/shared/utils";

class SingleOrderDataTableRows extends PureComponent {
  render() {
    const {
      title,
      discountedTotalPrice,
      originalTotalPrice,
      quantity,
      variant,
    } = this.props.item;
    return (
      <tr>
        <td>
          <Row>
            <Col xs={3} className="CartItem__ImageWrapper">
              <img
                className="img-fluid"
                src={variant && variant.image.transformedSrc}
                alt={variant && variant.image.altText}
              />
            </Col>
            <Col xs={9}>
              <h4>
                {variant ? (
                  <a href={variant.product.onlineStoreUrl}>{title}</a>
                ) : (
                  <span>{title}</span>
                )}
              </h4>
              <div>
                <p>{variant && variant.title}</p>
                <div>
                  <span>{moneyFormat(discountedTotalPrice.amount)}</span>
                  <span className="ml-5">
                    <s>
                      {discountedTotalPrice.amount !==
                        originalTotalPrice.amount &&
                        moneyFormat(originalTotalPrice.amount)}
                    </s>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </td>
        <td className="text-center d-none d-md-table-cell">{quantity}</td>
        <td className="text-right">
          {moneyFormat(discountedTotalPrice.amount)}
        </td>
      </tr>
    );
  }
}

export default SingleOrderDataTableRows;
