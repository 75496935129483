import React, { PureComponent } from "react";

export default class DO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="211.478"
            height="126.141"
            transform="translate(0 300.522)"
            fill="#d80027"
          />
          <rect
            width="211.478"
            height="126.152"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <rect
            width="211.478"
            height="126.152"
            transform="translate(300.522 85.337)"
            fill="#d80027"
          />
          <rect
            width="211.478"
            height="126.141"
            transform="translate(300.522 300.522)"
            fill="#0052b4"
          />
          <path
            d="M300.521,256.006a44.521,44.521,0,0,1-89.042,0c0-24.589,44.521-44.521,44.521-44.521S300.521,231.417,300.521,256.006Z"
            fill="#496e2d"
          />
          <path
            d="M211.479,256.006a44.521,44.521,0,1,1,89.042,0"
            fill="#0052b4"
          />
          <path
            d="M230.957,233.745V261.57a25.043,25.043,0,0,0,50.086,0V233.745H230.957Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
<svg xmlns="http://www.w3.org/2000/svg" height="13" viewBox="0 0 512 341.326">
  <g transform="translate(0 -85.337)">
    <rect
      width="512"
      height="341.326"
      transform="translate(0 85.337)"
      fill="#f0f0f0"
    />
    <rect
      width="211.478"
      height="126.141"
      transform="translate(0 300.522)"
      fill="#d80027"
    />
    <rect
      width="211.478"
      height="126.152"
      transform="translate(0 85.337)"
      fill="#0052b4"
    />
    <rect
      width="211.478"
      height="126.152"
      transform="translate(300.522 85.337)"
      fill="#d80027"
    />
    <rect
      width="211.478"
      height="126.141"
      transform="translate(300.522 300.522)"
      fill="#0052b4"
    />
    <path
      d="M300.521,256.006a44.521,44.521,0,0,1-89.042,0c0-24.589,44.521-44.521,44.521-44.521S300.521,231.417,300.521,256.006Z"
      fill="#496e2d"
    />
    <path d="M211.479,256.006a44.521,44.521,0,1,1,89.042,0" fill="#0052b4" />
    <path
      d="M230.957,233.745V261.57a25.043,25.043,0,0,0,50.086,0V233.745H230.957Z"
      fill="#d80027"
    />
  </g>
</svg>;
