export const FETCH_VENDOR_DATA_REQUEST = "vendor/FETCH_VENDOR_DATA_REQUEST";

export const FETCH_VENDOR_DATA_REQUEST_RESULT =
  "vendor/FETCH_VENDOR_DATA_REQUEST_RESULT";

export const FETCH_VENDOR_SETTINGS_REQUEST =
  "vendor/FETCH_VENDOR_SETTINGS_REQUEST";

export const FETCH_VENDOR_SETTINGS_REQUEST_RESULT =
  "vendor/FETCH_VENDOR_SETTINGS_REQUEST_RESULT";

export const FETCH_CHANGE_VENDOR_LOGO_REQUEST =
  "vendor/FETCH_CHANGE_VENDOR_LOGO_REQUEST";

export const FETCH_DELETE_VENDOR_LOGO_REQUEST =
  "vendor/FETCH_DELETE_VENDOR_LOGO_REQUEST";

export const FETCH_VENDOR_SETTINGS_SAVE_REQUEST =
  "vendor/FETCH_VENDOR_SETTINGS_SAVE_REQUEST";

export const FETCH_VENDOR_NAMES_REQUEST_RESULT =
  "vendor/FETCH_VENDOR_NAMES_REQUEST_RESULT";

export const FETCH_VENDOR_NAMES_REQUEST = "vendor/FETCH_VENDOR_NAMES_REQUEST";

export const fetchVendorNamesRequest = () => {
  return { type: FETCH_VENDOR_NAMES_REQUEST, payload: {} };
};

export const fetchVendorNamesRequestResult = (response) => {
  return { type: FETCH_VENDOR_NAMES_REQUEST_RESULT, payload: response };
};

export const fetchVendorDataRequest = (params) => {
  return { type: FETCH_VENDOR_DATA_REQUEST, payload: params };
};

export const fetchVendorDataRequestResult = (response) => {
  return { type: FETCH_VENDOR_DATA_REQUEST_RESULT, payload: response };
};

export const fetchVendorSettingsRequest = (params) => {
  return { type: FETCH_VENDOR_SETTINGS_REQUEST, payload: params };
};
export const fetchVendorSettingsRequestResult = (response) => {
  return { type: FETCH_VENDOR_SETTINGS_REQUEST_RESULT, payload: response };
};

export function fetchChangeVendorLogoRequest(data) {
  return { type: FETCH_CHANGE_VENDOR_LOGO_REQUEST, payload: data };
}

export function fetchDeleteVendorLogoRequest(data) {
  return { type: FETCH_DELETE_VENDOR_LOGO_REQUEST, payload: data };
}

export function fetchVendorSettingsSaveRequest(data) {
  return { type: FETCH_VENDOR_SETTINGS_SAVE_REQUEST, payload: data };
}
