import React, { PureComponent } from "react";

export default class BA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#0052b4"
          />
          <path d="M99.9,85.329H441.238V426.663" fill="#ffda44" />
          <g>
            <path
              d="M36.561,100.645,51.4,89.838l14.867,10.794-4.979-15.3H41.525Z"
              fill="#f0f0f0"
            />
            <path
              d="M88.332,103.632,82.649,86.16l-5.672,17.472-18.365.012,14.867,10.8L67.818,131.9,82.661,121.1,97.527,131.89l-5.684-17.474L106.7,103.621Z"
              fill="#f0f0f0"
            />
            <path
              d="M119.59,134.89l-5.684-17.474-5.673,17.474-18.365.011L104.735,145.7l-5.661,17.462,14.844-10.807,14.866,10.8L123.1,145.673l14.856-10.794Z"
              fill="#f0f0f0"
            />
            <path
              d="M150.846,166.146l-5.683-17.473-5.673,17.473-18.364.011,14.866,10.8-5.661,17.461,14.843-10.806L160.04,194.4l-5.683-17.474,14.855-10.794Z"
              fill="#f0f0f0"
            />
            <path
              d="M182.1,197.4l-5.683-17.474L170.748,197.4l-18.366.011,14.866,10.795-5.66,17.462,14.843-10.807,14.866,10.8-5.684-17.473,14.855-10.795Z"
              fill="#f0f0f0"
            />
            <path
              d="M213.359,228.659l-5.683-17.472L202,228.659l-18.365.012,14.866,10.795-5.66,17.462,14.842-10.806,14.866,10.794-5.683-17.473,14.855-10.795Z"
              fill="#f0f0f0"
            />
            <path
              d="M244.616,259.916l-5.683-17.473-5.672,17.473-18.366.012,14.867,10.795L224.1,288.185l14.844-10.807,14.866,10.794L248.126,270.7l14.855-10.794Z"
              fill="#f0f0f0"
            />
            <path
              d="M275.872,291.173,270.19,273.7l-5.673,17.473-18.365.011,14.867,10.8-5.661,17.461L270.2,308.635l14.866,10.795-5.683-17.474,14.853-10.794Z"
              fill="#f0f0f0"
            />
            <path
              d="M307.13,322.43l-5.683-17.474-5.674,17.474-18.364.011,14.866,10.795L286.614,350.7l14.844-10.807,14.866,10.8-5.684-17.474,14.855-10.794Z"
              fill="#f0f0f0"
            />
            <path
              d="M338.386,353.686,332.7,336.214l-5.672,17.472-18.365.012,14.866,10.8-5.661,17.462,14.843-10.807,14.866,10.795L341.9,364.471l14.855-10.8Z"
              fill="#f0f0f0"
            />
            <path
              d="M369.643,384.943,363.96,367.47l-5.672,17.473-18.366.012,14.866,10.794-5.66,17.463L363.971,402.4l14.866,10.8-5.684-17.473,14.855-10.795Z"
              fill="#f0f0f0"
            />
            <path
              d="M395.216,398.727,389.544,416.2l-18.365.012,14.394,10.453h19.278l14.414-10.476L400.9,416.2Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
