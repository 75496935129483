import React, { PureComponent } from "react";
import { Helmet as HelmetComponent } from "react-helmet";
import { withRouter } from "react-router-dom";

import { getCanonicalPath } from "../utils";

class Helmet extends PureComponent {
  render() {
    const {
      metaTags: { metaTitle, metaDescription, image },
      location,
    } = this.props;
    const canonicalPathName = getCanonicalPath(location.pathname);
    return (
      <HelmetComponent>
        <meta
          name="google-site-verification"
          content={process.env.GOOGLE_SITE_VERIFICATION}
        />
        <meta charset="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="bids, bid, auction, auctions, ring, earrings, jewelry, jewellery, gold, diamond, engagement rings"
        />
        <title>{`${metaTitle} - Bids.com`}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={`${canonicalPathName}${location.search}`} />
        <link
          rel="shortcut icon"
          href="//cdn.shopify.com/s/files/1/0071/2081/4195/files/Shopify_Favicon2_32x32.png?v=1582043844"
          type="image/png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        {image && <meta property="og:image" content={image} />}
        {image && <meta property="og:image:secure_url" content={image} />}
        <meta
          property="og:url"
          content={`https://${process.env.SHOP_URL}${location.pathname}${location.search}`}
        />
        <meta property="og:site_name" content="Bids.com" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        {image && <meta name="twitter:image" content={image} />}

        <meta name="p:domain_verify" content={process.env.P_DOMAIN_VERIFY} />
      </HelmetComponent>
    );
  }
}

export default withRouter(Helmet);
