import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";

import ActivateForm from "./ActivateForm";
import { BidsAgreements } from "storefront/features/shared";

export default class Activate extends PureComponent {
  render() {
    return (
      <Container>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>ACTIVATE ACCOUNT</h1>
            <p>Please enter a password to activate your account:</p>
            <ActivateForm />
            <BidsAgreements
              termsAndConditionsLink="/pages/terms-and-conditions"
              privacyPolicy="/pages/privacy-policy"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
