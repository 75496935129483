export const FETCH_SEARCH_REQUEST = "search/FETCH_SEARCH_REQUEST";
export const FETCH_SEARCH_REQUEST_RESULT = "search/FETCH_SEARCH_REQUEST_RESULT";
export const FETCH_SEARCH_REQUEST_RESULT_ERROR =
  "search/FETCH_SEARCH_REQUEST_RESULT_ERROR";
export const FETCH_DROPDOWN_SEARCH_REQUEST =
  "search/FETCH_DROPDOWN_SEARCH_REQUEST";
export const FETCH_DROPDOWN_SEARCH_REQUEST_RESULT =
  "search/FETCH_DROPDOWN_SEARCH_REQUEST_RESULT";
export const UPDATE_SEARCH_PRODUCT = "search/UPDATE_SEARCH_PRODUCT";

export function fetchSearchRequest(params) {
  return { type: FETCH_SEARCH_REQUEST, payload: params };
}
export function fetchSearchRequestResult(params) {
  return { type: FETCH_SEARCH_REQUEST_RESULT, payload: params };
}
export function fetchSearchRequestResultError(params) {
  return { type: FETCH_SEARCH_REQUEST_RESULT_ERROR, payload: params };
}

export function fetchDropDownSearchRequest(params) {
  return { type: FETCH_DROPDOWN_SEARCH_REQUEST, payload: params };
}
export function fetchDropDownSearchRequestResult(params) {
  return { type: FETCH_DROPDOWN_SEARCH_REQUEST_RESULT, payload: params };
}

export function updateSearchProduct(params) {
  return { type: UPDATE_SEARCH_PRODUCT, payload: params };
}
