import React, { PureComponent } from "react";

export default class CM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#496e2d"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#ffda44"
            />
            <path
              d="M256,208.5l11.787,36.278h38.147L275.073,267.2l11.787,36.28L256,281.062l-30.861,22.423,11.789-36.28-30.861-22.423h38.146Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
