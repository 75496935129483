import React, { PureComponent } from "react";
import { compose } from "redux";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { isEmpty } from "lodash";

import { getMetaTags } from "storefront/features/productGrid/metaTags";

import { Helmet } from "storefront/features/shared";
import { QuickViewProduct } from "storefront/features/productGrid";

import * as actions from "storefront/features/productGrid/actions";
import { fetchVendorDataRequest } from "storefront/features/vendor/actions";
import { getProduct } from "storefront/features/productGrid/selectors";
import withPusherSubscription from "storefront/features/shared/components/SubscriptionPusher";
import { WithLoader } from "storefront/features/shared/components/Loader";
import {
  PRODUCT_PAGE_VIEW_LOADER,
  getSearchParams,
} from "storefront/features/shared/utils";
import { getVendorInfo } from "storefront/features/shared/selectors";

class Product extends PureComponent {
  componentDidMount() {
    const {
      location,
      fetchProduct,
      product,
      product: { vendor_slug },
      fetchVendorData,
    } = this.props;
    const handle = location.pathname.split("/").pop();
    const auctionId = getSearchParams(location.search, "auction_id");
    handle !== product.handle &&
      fetchProduct(handle, auctionId, PRODUCT_PAGE_VIEW_LOADER);
    vendor_slug && fetchVendorData(vendor_slug);
  }

  componentDidUpdate(prevProps) {
    const { location, fetchProduct } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      const handle = location.pathname.split("/").pop();
      const auctionId = getSearchParams(location.search, "auction_id");
      fetchProduct(handle, auctionId, PRODUCT_PAGE_VIEW_LOADER);
    }
  }

  render() {
    const { product } = this.props;
    const metaTags = getMetaTags(product);
    return (
      <Container className="my-5">
        <Helmet metaTags={metaTags} />
        <WithLoader
          loader={PRODUCT_PAGE_VIEW_LOADER}
          forceLoading={isEmpty(product) || product.error}
        >
          <QuickViewProduct product={product} />
        </WithLoader>
      </Container>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  product: getProduct,
  vendorInfo: getVendorInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProduct: (handle, auctionId, loader) => {
    dispatch(actions.fetchProductRequest({ handle, auctionId, loader }));
  },
  fetchVendorData: (vendor_id) => {
    dispatch(fetchVendorDataRequest(vendor_id));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withPusherSubscription
)(Product);
