import React, { PureComponent } from "react";

export default class JO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.338"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.339)"
            fill="#f0f0f0"
          />
          <rect width="512" height="113.775" transform="translate(0 85.339)" />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.89)"
            fill="#6da544"
          />
          <path d="M256,256.006,0,426.669V85.331Z" fill="#d80027" />
          <path
            d="M77.913,224.8l7.876,16.469,17.786-4.11L95.61,253.587l14.3,11.343-17.808,4.014.049,18.255L77.913,275.778,63.672,287.2l.05-18.255L45.913,264.93l14.3-11.343-7.965-16.426,17.786,4.11Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
