import React, { PureComponent } from "react";

export default class KH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#0052b4"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#0052b4"
            />
          </g>
          <path
            d="M303.49,281.971V264.162H291.617V240.417l-11.872-11.872-11.872,11.872V216.673L256,204.8l-11.873,11.873v23.744l-11.872-11.872-11.872,11.872v23.745H208.51v17.809H196.638v17.808H315.362V281.971Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
