import React, { PureComponent } from "react";
import { moneyFormat } from "storefront/features/shared/utils";
import { StyledProductRetailPrice } from "./ProductRetailPrice.styled";

export default class ProductRetailPrice extends PureComponent {
  render() {
    const { retailPrice } = this.props;
    return (
      <StyledProductRetailPrice>
        {retailPrice ? (
          <span>
            Retail:&nbsp;&nbsp;{moneyFormat(retailPrice)}
            &nbsp;&nbsp;
          </span>
        ) : (
          <span className="d-block"> </span>
        )}
      </StyledProductRetailPrice>
    );
  }
}
