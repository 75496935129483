import React, { PureComponent } from "react";

export default class SY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect width="512" height="113.775" transform="translate(0 312.882)" />
          <g>
            <path
              d="M187.31,215.184l9.208,28.341h29.8l-24.11,17.518,9.209,28.342L187.31,271.869,163.2,289.385l9.209-28.342L148.3,243.525h29.8Z"
              fill="#6da544"
            />
            <path
              d="M324.69,215.184l9.209,28.341h29.8l-24.109,17.518,9.209,28.342-24.11-17.516-24.109,17.516,9.209-28.342-24.11-17.518h29.8Z"
              fill="#6da544"
            />
          </g>
        </g>
      </svg>
    );
  }
}
