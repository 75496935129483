import React, { PureComponent } from "react";

export default class FM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <g>
            <path
              d="M256,159.53l7.367,22.674h23.843L267.921,196.22l7.367,22.673L256,204.88l-19.288,14.013,7.368-22.673L224.791,182.2h23.842Z"
              fill="#f0f0f0"
            />
            <path
              d="M159.536,256l22.674-7.368V224.786l14.015,19.288,22.674-7.368L204.886,256,218.9,275.282l-22.674-7.367L182.21,287.2V263.362Z"
              fill="#f0f0f0"
            />
            <path
              d="M256,352.459l-7.367-22.674H224.791l19.288-14.016L236.712,293.1,256,307.109,275.288,293.1l-7.367,22.674,19.288,14.016H263.367Z"
              fill="#f0f0f0"
            />
            <path
              d="M352.464,255.993l-22.674,7.368V287.2l-14.015-19.288L293.1,275.282l14.013-19.289L293.1,236.706l22.674,7.368,14.015-19.288v23.841Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
