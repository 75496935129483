import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { GlobalStyles } from "./GlobalStyles";
import { getSchema } from "./schemes";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { getVendorInfo } from "storefront/features/shared/selectors";

class GlobalStylesWrapper extends PureComponent {
  render() {
    const {
      location,
      vendorInfo: { color_scheme },
    } = this.props;
    const VENDORS_ULR_MARK = "/vendors/";
    const isVendorPage = () => {
      return location.pathname.includes(VENDORS_ULR_MARK);
    };
    const themeProps = isVendorPage()
      ? getSchema(
          color_scheme?.background_color,
          color_scheme?.color_scheme_title
        )
      : getSchema();
    return (
      <GlobalStyles themeProps={themeProps} isVendorPage={isVendorPage()} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorInfo: getVendorInfo,
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  withImmutablePropsToJS
)(GlobalStylesWrapper);
