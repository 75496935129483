import React, { PureComponent } from "react";

export default class UG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect width="512" height="341.326" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="56.888"
            transform="translate(0 142.225)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="56.888"
            transform="translate(0 199.112)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="56.888"
            transform="translate(0 312.888)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="56.888"
            transform="translate(0 369.775)"
            fill="#d80027"
          />
          <circle
            cx="56.954"
            cy="56.954"
            r="56.954"
            transform="translate(199.046 199.046)"
            fill="#f0f0f0"
          />
          <path d="M276.863,258.9,256,249.862s4.881-16.2,5.141-17.31a11.111,11.111,0,0,0-2.968-10.393l5.247-5.247a18.551,18.551,0,0,0-26.234,0l5.247,5.247a11.124,11.124,0,0,0-2.78,11.1l-7.9,7.9H246.11a133.276,133.276,0,0,0-9.176,16.237,14.972,14.972,0,0,0,7.534,19.588l4.33,1.924,7.2,6.771V293.1l-7.421,7.421h14.84v-14.84l6.771-6.771h14c.068-.139.138-.275.2-.417A14.837,14.837,0,0,0,276.863,258.9Z" />
        </g>
      </svg>
    );
  }
}
