export const SIGN_IN_REQUEST = "account/SIGN_IN_REQUEST";
export const SIGN_IN_REQUEST_SUCCESS = "account/SIGN_IN_REQUEST_SUCCESS";
export const SIGN_IN_REQUEST_FAILED = "account/SIGN_IN_REQUEST_FAILED";
export const FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST =
  "account/FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST";
export const FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST_RESULT =
  "account/FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST_RESULT";
  export const UPDATE_CUSTOMER_ACTIVE_AUCTIONS =
  "account/UPDATE_CUSTOMER_ACTIVE_AUCTIONS";
export const MODAL_WITH_DATA_TOGGLE = "account/MODAL_WITH_DATA_TOGGLE";
export const FETCH_CUSTOMER_REGISTER_REQUEST =
  "account/FETCH_CUSTOMER_REGISTER_REQUEST";
export const FETCH_CUSTOMER_RECOVER_REQUEST =
  "account/FETCH_CUSTOMER_RECOVER_REQUEST";
export const FETCH_CUSTOMER_RESET_PASSWORD_REQUEST =
  "account/FETCH_CUSTOMER_RESET_PASSWORD_REQUEST";
export const FETCH_CUSTOMER_ACTIVATE_REQUEST =
  "account/FETCH_CUSTOMER_ACTIVATE_REQUEST";
export const FETCH_CUSTOMER_ACCESS_TOKEN_DELETE =
  "account/FETCH_CUSTOMER_ACCESS_TOKEN_DELETE";
export const FETCH_CUSTOMER_UPDATE_REQUEST =
  "account/FETCH_CUSTOMER_UPDATE_REQUEST";
export const FETCH_CUSTOMER_ADDRESS_DELETE_REQUEST =
  "account/FETCH_CUSTOMER_ADDRESS_DELETE_REQUEST";
export const FETCH_CUSTOMER_ADDRESS_CREATE_REQUEST =
  "account/FETCH_CUSTOMER_ADDRESS_CREATE_REQUEST";
export const FETCH_CUSTOMER_ADDRESS_UPDATE_REQUEST =
  "account/FETCH_CUSTOMER_ADDRESS_UPDATE_REQUEST";
export const FETCH_AFFILIATES_REQUEST = "account/FETCH_AFFILIATES_REQUEST";
export const FETCH_CUSTOMER_UPDATE_FIRST_LAST_NAME_REQUEST =
  "account/FETCH_CUSTOMER_UPDATE_FIRST_LAST_NAME_REQUEST";
export const FETCH_CHANGE_CUSTOMER_AVATAR_REQUEST =
  "account/FETCH_CHANGE_CUSTOMER_AVATAR_REQUEST";
export const FETCH_DELETE_CUSTOMER_AVATAR_REQUEST =
  "account/FETCH_DELETE_CUSTOMER_AVATAR_REQUEST";
export const FETCH_VENDOR_REGISTER_REQUEST =
  "account/FETCH_VENDOR_REGISTER_REQUEST";

export function signInRequest(params) {
  return { type: SIGN_IN_REQUEST, payload: params };
}

export function signInRequestSuccess() {
  return { type: SIGN_IN_REQUEST_SUCCESS };
}

export function signInRequestFailed() {
  return { type: SIGN_IN_REQUEST_FAILED };
}

export function fetchCustomerActiveAuctionsRequest() {
  return { type: FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST };
}

export function fetchCustomerActiveAuctionsRequestResult(response) {
  return { type: FETCH_CUSTOMER_ACTIVE_AUCTIONS_REQUEST_RESULT, payload: response };
}

export function updateCustomerActiveAuction(params) {
  return { type: UPDATE_CUSTOMER_ACTIVE_AUCTIONS, payload: params };
}

export function fetchCustomerRegisterRequest(data) {
  return { type: FETCH_CUSTOMER_REGISTER_REQUEST, payload: data };
}

export function fetchCustomerActivateRequest(data) {
  return { type: FETCH_CUSTOMER_ACTIVATE_REQUEST, payload: data };
}

export function setModalAddresses(data) {
  return { type: MODAL_WITH_DATA_TOGGLE, payload: data };
}

export function fetchCustomerRecoverRequest(data) {
  return { type: FETCH_CUSTOMER_RECOVER_REQUEST, payload: data };
}

export function fetchCustomerResetPasswordRequest(data) {
  return { type: FETCH_CUSTOMER_RESET_PASSWORD_REQUEST, payload: data };
}

export function fetchCustomerAccessTokenDelete(data) {
  return { type: FETCH_CUSTOMER_ACCESS_TOKEN_DELETE, payload: data };
}

export function fetchCustomerUpdateRequest(data) {
  return { type: FETCH_CUSTOMER_UPDATE_REQUEST, payload: data };
}

export function fetchCustomerUpdateFirstLastNameRequest(data) {
  return { type: FETCH_CUSTOMER_UPDATE_FIRST_LAST_NAME_REQUEST, payload: data };
}

export function fetchCustomerAddressDeleteRequest(data) {
  return { type: FETCH_CUSTOMER_ADDRESS_DELETE_REQUEST, payload: data };
}

export function fetchCustomerAddressCreateRequest(data) {
  return { type: FETCH_CUSTOMER_ADDRESS_CREATE_REQUEST, payload: data };
}

export function fetchCustomerAddressUpdateRequest(data) {
  return { type: FETCH_CUSTOMER_ADDRESS_UPDATE_REQUEST, payload: data };
}

export function fetchAffiliatesRequest(data) {
  return { type: FETCH_AFFILIATES_REQUEST, payload: data };
}

export function fetchChangeCustomerAvatarRequest(data) {
  return { type: FETCH_CHANGE_CUSTOMER_AVATAR_REQUEST, payload: data };
}

export function fetchDeleteCustomerAvatarRequest(data) {
  return { type: FETCH_DELETE_CUSTOMER_AVATAR_REQUEST, payload: data };
}

export function fetchVendorRegisterRequest(data) {
  return { type: FETCH_VENDOR_REGISTER_REQUEST, payload: data };
}
