import React, { PureComponent } from "react";

export default class TJ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#6da544"
          />
          <g>
            <path
              d="M226.318,300.522h59.364v-25.23l-11.873,5.937L256,263.421l-17.809,17.808-11.873-5.937Z"
              fill="#ffda44"
            />
            <path
              d="M192.355,270.84l2.763,8.5h8.94l-7.233,5.255,2.763,8.5-7.233-5.255-7.234,5.255,2.763-8.5-7.233-5.255h8.94Z"
              fill="#ffda44"
            />
            <path
              d="M200.921,241.16l2.763,8.5h8.94l-7.233,5.255,2.763,8.5-7.233-5.256-7.234,5.256,2.763-8.5-7.233-5.255h8.94Z"
              fill="#ffda44"
            />
            <path
              d="M225.409,218.9l2.763,8.5h8.94l-7.233,5.255,2.763,8.5-7.233-5.256-7.234,5.256,2.763-8.5-7.233-5.255h8.94Z"
              fill="#ffda44"
            />
            <path
              d="M319.645,270.84l-2.763,8.5h-8.94l7.233,5.255-2.763,8.5,7.233-5.255,7.234,5.255-2.763-8.5,7.233-5.255h-8.94Z"
              fill="#ffda44"
            />
            <path
              d="M311.079,241.16l-2.763,8.5h-8.94l7.233,5.255-2.763,8.5,7.233-5.256,7.234,5.256-2.763-8.5,7.233-5.255h-8.94Z"
              fill="#ffda44"
            />
            <path
              d="M286.591,218.9l-2.763,8.5h-8.94l7.233,5.255-2.763,8.5,7.233-5.256,7.234,5.256-2.763-8.5L298.3,227.4h-8.94Z"
              fill="#ffda44"
            />
            <path
              d="M256,207.767l2.763,8.5H267.7l-7.234,5.256,2.763,8.5L256,224.774l-7.233,5.254,2.763-8.5L244.3,216.27h8.941Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
