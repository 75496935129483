import React, { PureComponent } from "react";

export default class XK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M160.3,189.212l3.685,11.337H175.9l-9.643,7.007,3.684,11.336-9.645-7.006-9.644,7.006,3.684-11.336-9.643-7.007h11.919Z"
              fill="#f0f0f0"
            />
            <path
              d="M197.4,178.081l3.685,11.338H213l-9.643,7.006,3.684,11.337-9.645-7.007-9.644,7.007,3.684-11.337-9.643-7.006h11.919Z"
              fill="#f0f0f0"
            />
            <path
              d="M234.5,166.951l3.683,11.337H250.1l-9.643,7.007,3.684,11.336-9.643-7.006-9.645,7.006,3.684-11.336-9.643-7.007h11.919Z"
              fill="#f0f0f0"
            />
            <path
              d="M351.7,189.212l-3.685,11.337H336.1l9.643,7.007-3.684,11.336,9.645-7.006,9.644,7.006-3.684-11.336,9.643-7.007H355.385Z"
              fill="#f0f0f0"
            />
            <path
              d="M314.6,178.081l-3.685,11.338H299l9.643,7.006-3.684,11.337,9.645-7.007,9.644,7.007-3.684-11.337,9.643-7.006H318.284Z"
              fill="#f0f0f0"
            />
            <path
              d="M277.5,166.951l-3.683,11.337H261.895l9.643,7.007-3.684,11.336,9.643-7.006,9.645,7.006-3.684-11.336,9.643-7.007h-11.92Z"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M285.682,263.414,256,226.312l-14.84,7.421v14.84L218.9,263.413h-14.84v19.172a59.368,59.368,0,0,1,37.1,55.03H256v-14.84l14.84-14.84,14.84,14.84,14.841-14.839V293.1l14.84-22.261Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
