import React, { PureComponent } from "react";

export default class IQ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#a2001d"
          />
          <rect width="512" height="113.775" transform="translate(0 312.888)" />
          <g>
            <path
              d="M219.186,246.069c-.083,0-.165.006-.248.007v-.007H189.83a13.253,13.253,0,0,1,12.806-9.931V216.275a33.14,33.14,0,0,0-33.1,33.1v16.551h49.655a3.314,3.314,0,0,1,3.31,3.31v6.62H156.29v19.862h86.069V269.24A23.2,23.2,0,0,0,219.186,246.069Z"
              fill="#496e2d"
            />
            <path
              d="M268.841,275.862V216.275H248.98v79.449h33.1V275.862Z"
              fill="#496e2d"
            />
            <path
              d="M335.048,275.862V216.275H315.186v59.587h-6.62V256H288.7v39.723H348.29V275.862Z"
              fill="#496e2d"
            />
          </g>
        </g>
      </svg>
    );
  }
}
