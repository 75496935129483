import React, { PureComponent } from "react";

export default class JP extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <circle
            cx="96"
            cy="96"
            r="96"
            transform="translate(160 159.994)"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
