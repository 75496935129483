import React, { PureComponent } from "react";

export default class ZM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#496e2d"
          />
          <path
            d="M490.668,195.476h-48a16,16,0,0,0-32,0h-48c0,8.836,7.7,16,16.533,16h-.533a16,16,0,0,0,16,16,16,16,0,0,0,16,16h32a16,16,0,0,0,16-16,16,16,0,0,0,16-16h-.533C482.972,211.476,490.668,204.312,490.668,195.476Z"
            fill="#ff9811"
          />
          <rect
            width="56.888"
            height="170.663"
            transform="translate(341.337 255.994)"
            fill="#d80027"
          />
          <rect
            width="56.888"
            height="170.663"
            transform="translate(455.112 255.994)"
            fill="#ff9811"
          />
          <rect
            width="56.888"
            height="170.663"
            transform="translate(398.225 255.994)"
          />
        </g>
      </svg>
    );
  }
}
