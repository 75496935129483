import React, { PureComponent } from "react";

class StaticPageContent extends PureComponent {
  render() {
    const { title, content } = this.props;
    return (
      <div className="my-5">
        <h1 className="text-center mb-5">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }
}

export default StaticPageContent;
