import React, { PureComponent } from "react";

export default class KP extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#91dc5a"
          />
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="57.188"
              transform="translate(0 85.331)"
              fill="#0052b4"
            />
            <rect
              width="512"
              height="57.177"
              transform="translate(0 369.48)"
              fill="#0052b4"
            />
          </g>
          <rect
            width="512"
            height="181.582"
            transform="translate(0 165.215)"
            fill="#d80027"
          />
          <circle
            cx="65.668"
            cy="65.668"
            r="65.668"
            transform="translate(124.662 190.326)"
            fill="#f0f0f0"
          />
          <path
            d="M190.332,190.332l14.734,45.346h47.757l-38.65,28.028,14.849,45.481-38.69-28.16L151.7,309.134l14.79-45.428-38.616-28.028H175.6Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
