import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";

export default class ContactUsInfo extends PureComponent {
  render() {
    return (
      <Row>
        <Col xs={12} sm={6} lg={12} className="mb-3">
          <p>
            <strong>By Department:</strong>
          </p>
          <p>
            Customer Service:{" "}
            <strong>
              <a
                href="mailto:support@bids.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@bids.com
              </a>
            </strong>
          </p>
          <p>
            Marketing:{" "}
            <a
              href="mailto:Marketing@bids.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>marketing@bids.com</strong>
            </a>
          </p>
          <p>
            Information:{" "}
            <a
              href="mailto:Info@bids.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>info@bids.com</strong>
            </a>
          </p>
          <p>
            Legal:{" "}
            <a
              href="mailto:Legal@bids.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>legal@bids.com</strong>
            </a>
          </p>
          <p>
            Billing &amp; Invoices:{" "}
            <a
              href="mailto:Billing@bids.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>billing@bids.com</strong>
            </a>
          </p>
          <p>
            Vendor Inquiries:{" "}
            <a
              href="mailto:Vendor@bids.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>vendor@bids.com</strong>
            </a>
          </p>
        </Col>
        <Col xs={12} sm={6} lg={12} className="mb-3">
          <p>
            <strong>Customer Service:</strong>
            <br />
            <span>+1-310-855-3525</span>
          </p>
          <p>
            <strong>Operating Hours:</strong>
            <br />
            Monday - Friday
            <br />
            8am - 5pm EST
          </p>
          <p>
            <strong>Address:</strong>
            <span>&nbsp;</span>
            <br />
            <span>27240 Turnberry Lane 200</span>
            <br />
            <span>Valencia, CA 91355</span>
          </p>
        </Col>
      </Row>
    );
  }
}
