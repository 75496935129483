import styled from "styled-components";
import { Image } from "react-bootstrap";

import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledImage = styled(Image)`
  width: ${(props) => (props.isvariants ? "38px" : "213px")};
  height: ${(props) => (props.isvariants ? "38px" : "213px")};
  cursor: pointer;
  @media ${device.tabletS} {
    width: ${(props) => (props.isvariants ? "38px" : "140px")};
    height: ${(props) => (props.isvariants ? "38px" : "140px")};
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const DeleteIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${(props) => (props.isvariants ? "-10px" : "-13px")};
  right: -10px;
`;
