import React, { PureComponent } from "react";

export default class MQ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M512,233.734v44.521H278.261v148.4H233.739v-148.4H0V233.734H233.739V85.331h44.522v148.4Z"
              fill="#f0f0f0"
            />
            <path
              d="M386.607,175.885a4.119,4.119,0,0,1,4.115-4.114h8.816a25.273,25.273,0,1,0,0-50.546h-8.372a17.423,17.423,0,0,0-10.443-3.318c-8.436,0-15.273,5.471-15.273,12.219,0,6.683,6.707,12.1,15.029,12.209l0,.049h19.062a4.115,4.115,0,1,1,0,8.229h-8.816a25.273,25.273,0,1,0,0,50.546h34.09V180h-34.09A4.12,4.12,0,0,1,386.607,175.885Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.346,175.885a4.119,4.119,0,0,1,4.115-4.114h8.816a25.273,25.273,0,1,0,0-50.546H112.9a17.423,17.423,0,0,0-10.443-3.318c-8.436,0-15.273,5.471-15.273,12.219,0,6.683,6.707,12.1,15.029,12.209l0,.049h19.062a4.115,4.115,0,1,1,0,8.229h-8.816a25.273,25.273,0,1,0,0,50.546h34.09V180h-34.09A4.12,4.12,0,0,1,108.346,175.885Z"
              fill="#f0f0f0"
            />
            <path
              d="M386.607,368.809a4.119,4.119,0,0,1,4.115-4.114h8.816a25.273,25.273,0,1,0,0-50.546h-8.372a17.423,17.423,0,0,0-10.443-3.318c-8.436,0-15.273,5.471-15.273,12.219,0,6.683,6.707,12.1,15.029,12.209l0,.049h19.062a4.115,4.115,0,1,1,0,8.229h-8.816a25.273,25.273,0,1,0,0,50.546h34.09V372.924h-34.09A4.119,4.119,0,0,1,386.607,368.809Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.346,368.809a4.119,4.119,0,0,1,4.115-4.114h8.816a25.273,25.273,0,1,0,0-50.546H112.9a17.423,17.423,0,0,0-10.443-3.318c-8.436,0-15.273,5.471-15.273,12.219,0,6.683,6.707,12.1,15.029,12.209l0,.049h19.062a4.115,4.115,0,1,1,0,8.229h-8.816a25.273,25.273,0,0,0,0,50.546h34.09V372.924h-34.09A4.119,4.119,0,0,1,108.346,368.809Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
