import React, { PureComponent } from "react";

export default class AF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(330.207 85.331)"
            fill="#496e2d"
          />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(0 85.331)"
          />
          <g>
            <path
              d="M256,196.632a59.362,59.362,0,1,0,59.362,59.362A59.362,59.362,0,0,0,256,196.632Zm0,96.463a37.1,37.1,0,1,1,37.1-37.1A37.1,37.1,0,0,1,256,293.1Z"
              fill="#ffda44"
            />
            <path
              d="M256,233.734a14.84,14.84,0,0,0-14.84,14.84v22.261h29.682V248.574A14.842,14.842,0,0,0,256,233.734Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
