import React, { PureComponent } from "react";
import { Loader } from "storefront/features/shared/components/Loader";

class Collection extends PureComponent {
  render() {
    return (
      <div>
        Collection
        <Loader />
      </div>
    );
  }
}

export default Collection;
