import bidAuctionsReducer from "../features/shared/bidAuctionsReducer";
import pagesReducer from "../features/shared/pagesReducer";
import headerReducer from "../features/shared/components/Header/headerReducer";
import productReducer from "../features/productGrid/productReducer";
import accountReducer from "../features/account/accountReducer";
import loaderReducer from "../features/shared/components/Loader/loaderReducer";
import ssrReducer from "../features/shared/ssrReducer";
import cartReducer from "../features/cart/cartReducer";
import searchReducer from "../features/search/searchReducer";
import bannerReducer from "../features/prismicBanner/bannerReducer";
import sproutBannerReducer from "../features/sproutBanner/sproutBannerReducer";
import productsSettingsReducer from "../features/productsSettings/productsSettingsReducer";
import vendorReducer from "../features/vendor/vendorReducer";

export default {
  bidAuctionsReducer,
  headerReducer,
  productReducer,
  accountReducer,
  loaderReducer,
  pagesReducer,
  cartReducer,
  ssrReducer,
  searchReducer,
  bannerReducer,
  sproutBannerReducer,
  productsSettingsReducer,
  vendorReducer,
};
