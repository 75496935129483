import React, { PureComponent, createRef } from "react";
import { Col, InputGroup, Overlay, Form } from "react-bootstrap";
import { StyledTooltip } from "./ProductVariantPicker.styled";

class ProductVariantPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.form = createRef();
  }

  handleVariantChange = (e) => {
    const { name, value } = e.target;
    const { activeOptions, handleOptionsChange } = this.props;
    const pickedOptions = { ...activeOptions };
    pickedOptions[name] = value;
    handleOptionsChange(pickedOptions);
  };

  render() {
    const { options, variantAvailable, activeOptions } = this.props;
    if (!activeOptions || activeOptions.length === 0) return null;
    return (
      <Col>
        <Form ref={this.form}>
          {options.map(({ name, values }) => (
            <InputGroup className="w-auto mb-2 align-items-center" key={name}>
              <Form.Label className="mr-2 mb-0">{name}</Form.Label>

              <Form.Control
                as="select"
                size="sm"
                onChange={this.handleVariantChange}
                name={name}
                value={activeOptions[name]}
              >
                {values.map((value) => (
                  <option key={value}>{value}</option>
                ))}
              </Form.Control>
            </InputGroup>
          ))}
          <Overlay
            target={this.form}
            show={!variantAvailable}
            placement="bottom"
          >
            {(props) => (
              <StyledTooltip {...props}>
                This option is not available!
              </StyledTooltip>
            )}
          </Overlay>
        </Form>
      </Col>
    );
  }
}

export default ProductVariantPicker;
