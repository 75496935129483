import React, { PureComponent } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import MobileSubMenuItem from "./MobileSubMenuItem";
import { handeleize, generatorUrl } from "../utils";

export default class MobileMenu extends PureComponent {
  render() {
    const { menuItems, prefix } = this.props;
    return menuItems.map(({ url, title, children }, index) => (
      <Nav.Item key={title + index}>
        {children ? (
          <MobileSubMenuItem title={title} data={children} />
        ) : (
          <Link
            id={`mobile-menu-link-${handeleize(title)}`}
            className="btn btn-outlined-dark my-2"
            to={generatorUrl(prefix, url)}
          >
            {title}
          </Link>
        )}
      </Nav.Item>
    ));
  }
}
