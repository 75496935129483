import React, { PureComponent } from "react";

export default class SI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 199.112)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#d80027"
          />
          <path
            d="M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522V196.639l-.145-44.188,89.043-.266Z"
            fill="#f0f0f0"
          />
          <path
            d="M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522V196.639l-.145-44.188,89.043-.266Z"
            fill="#0052b4"
          />
          <path
            d="M233.606,196.639v14.837c0,34.081-44.522,44.522-44.522,44.522s-44.522-10.44-44.522-44.522V196.639l14.848,14.837,29.674-22.261,29.685,22.261Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
