import React, { PureComponent } from "react";

export default class GU extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="489.739"
            height="319.076"
            transform="translate(11.13 96.462)"
            fill="#0052b4"
          />
          <path
            d="M246.342,363.726c-2.333-2-57.125-49.632-57.125-107.731s54.792-105.732,57.125-107.731L256,139.985l9.658,8.279c2.333,2,57.125,49.632,57.125,107.731s-54.792,105.732-57.125,107.731L256,372.005Z"
            fill="#d80027"
          />
          <path
            d="M307.942,255.994c0-51.942-51.942-96.464-51.942-96.464s-51.942,44.524-51.942,96.464c0,15.89,4.865,31.083,11.613,44.522h80.658C303.077,287.077,307.942,271.884,307.942,255.994Z"
            fill="#338af3"
          />
          <path
            d="M307.942,255.994c0,15.89-4.865,31.083-11.613,44.522L256,307.937l-40.329-7.421c-6.748-13.439-11.613-28.632-11.613-44.522H307.942Z"
            fill="#0052b4"
          />
          <path
            d="M256,352.459s25.019-21.452,40.329-51.942H215.671C230.981,331.007,256,352.459,256,352.459Z"
            fill="#ffda44"
          />
          <path d="M222.609,226.313h66.782L256,259.7Z" fill="#6da544" />
          <rect
            width="22.261"
            height="66.783"
            transform="translate(244.87 248.57)"
            fill="#a2001d"
          />
        </g>
      </svg>
    );
  }
}
