import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import { moneyFormat } from "storefront/features/shared/utils";
import SingleOrderDataTableRows from "./SingleOrderDataTableRows";

class SingleOrderData extends PureComponent {
  render() {
    const {
      orderItems,
      subtotal,
      totalTax,
      totalShipping,
      totalPrice,
    } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>Product</th>
            <th className="text-center d-none d-md-table-cell">Quantity</th>
            <th className="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map(({ node }) => (
            <SingleOrderDataTableRows item={node} key={node.title} />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="d-none d-md-table-cell border-bottom-0"></td>
            <td>Subtotal</td>
            <td className="text-right">{moneyFormat(subtotal)}</td>
          </tr>
          <tr>
            <td className="d-none d-md-table-cell border-0"></td>
            <td className="text-nowrap">
              <span>Shipping</span>
            </td>
            <td className="text-right">{moneyFormat(totalShipping)}</td>
          </tr>
          {totalTax !== "0.0" && (
            <tr>
              <td className="d-none d-md-table-cell border-0"></td>
              <td className="text-nowrap">
                <span>Tax</span>
                <span className="mr-5">(VAT 20.0%)</span>
              </td>
              <td className="text-right">{moneyFormat(totalTax)}</td>
            </tr>
          )}
          <tr>
            <td className="d-none d-md-table-cell border-0"></td>
            <td>Total</td>
            <td className="text-right">{moneyFormat(totalPrice)}</td>
          </tr>
        </tfoot>
      </Table>
    );
  }
}

export default SingleOrderData;
