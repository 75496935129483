import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import { size, device } from "storefront/features/shared/styles/mediaQuery";

export const StyledPrismicBannerContainer = styled(Container)`
  background-color: ${(props) =>
    props.backgroundcolor ? `#${props.backgroundcolor}` : "#fff"};
  position: fixed;
  top: -500px;
  left: 0;
  min-height: 135px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 15px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-out;
  z-index: 3;
  &.show {
    top: 0px;
  }
`;

export const StyledColumn = styled(Col)`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 135px;
  overflow: hidden;
  @media ${device.laptop} {
    background-image: url(${(props) => props.desktopbackgroundimage};);
  }
  @media ${device.tabletS} and (max-width: ${size.laptop}) {
    background-image: url(${(props) => props.tabletbackgroundimage});
  }
  @media ${device.mobileS} and (max-width: ${size.tabletS}) {
    min-height: ${(props) => (props.mobilebackgroundimage ? "394px" : "auto")};
    background-image: url(${(props) => props.mobilebackgroundimage});
  }
`;

export const StyledPrismicBannerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.25;
  z-index: 2;
`;

export const HeaderLogoImage = styled.img`
  margin: 0 auto;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none;
  vertical-align: top;
  @media ${device.mobileS} and (max-width: ${size.tabletS}) {
    margin-top: 15px;
  }
`;

export const BannerContent = styled.span`
  color: ${(props) => (props.contentcolor ? `#${props.contentcolor}` : "#000")};
  pre {
    color: ${(props) =>
      props.contentcolor ? `#${props.contentcolor}` : "#000"};
  }
  ol,
  ul,
  dl {
    list-style-type: none;
    display: contents;
  }
`;

export const TitleWrapper = styled.span`
  color: ${(props) => (props.titlecolor ? `#${props.titlecolor}` : "#000")};
`;

export const RoundButton = styled.button`
  width: 35px;
  height: 33px;
  -webkit-box-shadow: 0 1px 5px rgba(54, 54, 54, 0.7);
  box-shadow: 0 1px 5px rgba(54, 54, 54, 0.7);
  color: rgba(0, 80, 157, 0.5);
  position: relative;
  border: none;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  text-align: center;
  z-index: 5;
  vertical-align: middle;
  line-height: 0;
  transform: scale(1.001);
  transition: background 0.15s ease-in-out, opacity 0.15s ease-in-out,
    transform 0.2s ease-in-out, color 0.2s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: scale(1.15);
    color: #00509d;
  }
`;

export const Icon = styled.svg`
  height: 15px !important;
  width: 15px !important;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  stroke-width: 1px;
  background: none;
  pointer-events: none;
  &:not(:root) {
    overflow: hidden;
  }
`;
