import { fromJS } from "immutable";
import * as actions from "./actions";

const initialState = fromJS({
  sproutBanner: {},
});

const setSproutBannerContent = (state, action) =>
  state.set("sproutBanner", fromJS(action.payload));

const sproutBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SPROUT_BANNER_REQUEST_RESULT:
      return setSproutBannerContent(state, action);
    default:
      return state;
  }
};

export default sproutBannerReducer;
