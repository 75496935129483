import React, { PureComponent } from "react";

export default class KM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="85.337"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="85.337"
            transform="translate(0 170.663)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="85.337"
            transform="translate(0 256)"
            fill="#d80027"
          />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#6da544" />
          <g>
            <path
              d="M68.638,256a51.951,51.951,0,0,1,40.812-50.734,51.942,51.942,0,1,0,0,101.468A51.95,51.95,0,0,1,68.638,256Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.877,211.478l2.762,8.5h8.94l-7.232,5.256,2.762,8.5-7.232-5.255-7.234,5.255,2.764-8.5-7.234-5.256h8.94Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.877,233.739l2.762,8.5h8.94l-7.232,5.255,2.762,8.5-7.232-5.255L101.643,256l2.764-8.5-7.234-5.255h8.94Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.877,256l2.762,8.5h8.94l-7.232,5.255,2.762,8.5-7.232-5.255-7.234,5.255,2.764-8.5L97.173,264.5h8.94Z"
              fill="#f0f0f0"
            />
            <path
              d="M108.877,278.261l2.762,8.5h8.94l-7.232,5.256,2.762,8.5-7.232-5.255-7.234,5.255,2.764-8.5-7.234-5.256h8.94Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
