import React, { PureComponent } from "react";

export default class BM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#d80027"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.33H112v69.333H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
          </g>
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
          <g>
            <path d="M0,233.376V202.663H46.069Z" fill="#2e52b2" />
            <path d="M96,255.994V207.082L22.628,255.994Z" fill="#2e52b2" />
          </g>
          <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          <g>
            <path d="M256,233.376V202.663H209.931Z" fill="#2e52b2" />
            <path d="M160,255.994V207.082l73.372,48.912Z" fill="#2e52b2" />
          </g>
          <path
            d="M332.058,189.214v81.623c0,39.76,103.884,39.76,103.884,0V189.214Z"
            fill="#f3f3f3"
          />
          <path
            d="M332.058,270.837h0c0,39.76,51.942,51.942,51.942,51.942s51.942-12.182,51.942-51.942H332.058Z"
            fill="#6da544"
          />
          <path
            d="M384,238.188l-24.116,10.388v22.261L384,285.678l24.116-14.841V248.576Z"
            fill="#a2001d"
          />
          <rect
            width="48.228"
            height="22.261"
            transform="translate(359.88 226.312)"
            fill="#338af3"
          />
        </g>
      </svg>
    );
  }
}
