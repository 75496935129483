import React, { PureComponent } from "react";

export default class SS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="103.88"
            transform="translate(0 322.783)"
            fill="#496e2d"
          />
          <rect width="512" height="104.515" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="89.656"
            transform="translate(0 210.877)"
            fill="#a2001d"
          />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#0052b4" />
          <path
            d="M73.178,209.188l20.831,29.067,34.084-10.83L106.886,256.22l20.83,29.069L93.777,274.018,72.569,302.812,72.8,267.05l-33.94-11.273,34.083-10.83Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
