import styled from "styled-components";
import { Button } from "react-bootstrap";

export const AddressEditButton = styled(Button)`
  padding: 0;
  text-decoration: none;
  color: var(--gray-dark);
  border-bottom: 1px solid var(--gray-dark);
  border-radius: 0;
  margin-right: 20px;
  font-size: 0.8rem;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;
