import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";

import * as actions from "storefront/features/shared/actions";
import { getCustomerId } from "storefront/features/shared/components/Header/selectors";
import { shippingPricesSelector } from "storefront/features/shared/selectors";
import {
  calculateNextBid,
  getBidButtonCaseType,
  bidButtonCasesTypes,
  getBidDataObj,
  bidEventName,
  moneyFormat,
  FREE_SHIPPING,
  isTagExist,
  mapShippingPriceToVendor,
  getBidButtonLabel,
} from "storefront/features/shared/utils";
import { StyledBidButton, StyledShippingInfo } from "./BidButton.styled";
import RestrictVendorModal from "../../shared/components/buyNowButton/components/RestrictVendorModal";

class BidButton extends PureComponent {
  state = { animate: false, show: false };

  componentDidUpdate(prevProps) {
    const {
      product: { current_bid, bids_count },
    } = this.props;
    if (prevProps.product.bids_count !== bids_count) {
      this.setState((prevState) => ({
        animate: !prevState.animate,
        bid: current_bid,
      }));
      setTimeout(() => {
        this.setState((prevState) => ({ animate: !prevState.animate }));
      }, 500);
    }
  }

  handleClickBid = () => {
    const type = getBidButtonCaseType();
    const {
      product,
      postBidRequest,
      customerId,
      onModalOpen,
      location,
    } = this.props;
    const redirectLink = location.pathname;

    switch (type) {
      case bidButtonCasesTypes.UNREGISTERED_USER:
        onModalOpen();
        break;
      case bidButtonCasesTypes.UNREGISTERED_USER_WITH_EMAIL: {
        const unregisteredUserEmail = localStorage.getItem(
          "Unregistered-User-Email"
        );
        const data = getBidDataObj(
          product,
          bidEventName.NEW_ANONYMOUS_BID,
          unregisteredUserEmail
        );
        postBidRequest(data, redirectLink);
        break;
      }
      case bidButtonCasesTypes.REGISTERED_AND_LOGGED: {
        const data = getBidDataObj(
          product,
          bidEventName.NEW_BID,
          null,
          customerId
        );
        postBidRequest(data);
        break;
      }
      default:
        break;
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const {
      product: { current_bid, bids_count, tags, vendor },
      closed,
      shippingPrices,
      vendorPresent,
      showBidValue,
      width,
      padding,
    } = this.props;

    const bidValue = moneyFormat(
      calculateNextBid(current_bid, bids_count),
      true
    );
    const bidButtonLabel = getBidButtonLabel(showBidValue, bidValue);
    const freeShippingTagExist = isTagExist(tags, FREE_SHIPPING);
    const shippingPrice = mapShippingPriceToVendor(vendor, shippingPrices)
      ? `+ $${mapShippingPriceToVendor(vendor, shippingPrices)} shipping`
      : null;

    const rotatingBidButtonStates = [bidButtonLabel, bidButtonLabel];
    return (
      <>
        <StyledBidButton
          className={`${this.state.animate && "animate"} ml-1 rotate-btn`}
          variant="primary"
          disabled={closed}
          onClick={vendorPresent ? this.handleShow : this.handleClickBid}
          width={width}
          padding={padding}
        >
          {rotatingBidButtonStates.map((data, i) => (
            <span className="d-flex flex-column justify-content-center" key={i}>
              {data}
              <StyledShippingInfo>
                {freeShippingTagExist ? "+ Free shipping" : shippingPrice}
              </StyledShippingInfo>
            </span>
          ))}
        </StyledBidButton>
        {vendorPresent && (
          <RestrictVendorModal
            show={this.state.show}
            onHide={this.handleClose}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerId: getCustomerId,
  shippingPrices: shippingPricesSelector,
});

const mapDispatchToProps = (dispatch) => ({
  postBidRequest: (data, redirectLink) => {
    dispatch(actions.postBidRequest({ data, redirectLink }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(withRouter(BidButton)));
