import styled from "styled-components";
import { Button } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledModalContent = styled.div`
  max-width: 280px;
  background-color: #fff;
  z-index: 10;
  text-align: center;
  padding: 30px;
  border-radius: 5px;
  @media ${device.mobileL} {
    max-width: 320px;
  }
`;

export const StyledModalCloseBtn = styled(Button)`
  font-family: Futura;
  position: relative;
  font-size: 1.3rem;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  background: white;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  line-height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    outline: none;
  }
`;
