import React, { PureComponent } from "react";
import { Modal, Button } from "react-bootstrap";

class ModalDeleteTableRow extends PureComponent {
  handleClose = () => {
    const { handleModalClose } = this.props;
    handleModalClose();
  };

  handleDelete = () => {
    const { deleteTableRow } = this.props;
    deleteTableRow();
  };

  render() {
    const { show, title } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop="static"
        size="sm"
        data-cy="confirm-delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure you want delete {title} ?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            data-cy="cancel-delete-btn"
            onClick={this.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            data-cy="confirm-delete-btn"
            onClick={this.handleDelete}
          >
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalDeleteTableRow;
