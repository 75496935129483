import React, { PureComponent } from "react";

export default class AN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.333"
      >
        <g transform="translate(27 14)">
          <path
            d="M-27-14H485V327.333H-27Z"
            transform="translate(0 0)"
            fill="#fff"
          />
          <path
            d="M-6-14H107.778V327.333H-6Z"
            transform="translate(178.111 0)"
            fill="#dc171d"
          />
          <path
            d="M-27-2H485V111.778H-27Z"
            transform="translate(0 101.778)"
            fill="#012a87"
          />
          <g transform="translate(213.384 104.519)">
            <g transform="translate(8.853)">
              <path
                d="M0,0V14.222H7.111Z"
                transform="translate(6.763 0) rotate(18)"
                fill="#fff"
              />
              <path
                d="M0,14.222V0H7.111Z"
                transform="translate(11.158 13.526) rotate(162)"
                fill="#fff"
              />
            </g>
            <g transform="translate(27.052 3.395) rotate(72)">
              <path
                d="M0,0V14.222H7.111Z"
                transform="translate(6.763 0) rotate(18)"
                fill="#fff"
              />
              <path
                d="M0,14.222V0H7.111Z"
                transform="translate(11.158 13.526) rotate(162)"
                fill="#fff"
              />
            </g>
            <g transform="translate(0 16.259) rotate(-72)">
              <path
                d="M0,0V14.222H7.111Z"
                transform="translate(6.763 0) rotate(18)"
                fill="#fff"
              />
              <path
                d="M0,14.222V0H7.111Z"
                transform="translate(11.158 13.526) rotate(162)"
                fill="#fff"
              />
            </g>
            <g transform="matrix(-0.809, 0.588, -0.588, -0.809, 29.447, 21.753)">
              <path
                d="M0,0V14.222H7.111Z"
                transform="translate(6.763 0) rotate(18)"
                fill="#fff"
              />
              <path
                d="M0,14.222V0H7.111Z"
                transform="translate(11.158 13.526) rotate(162)"
                fill="#fff"
              />
            </g>
            <g transform="matrix(-0.809, -0.588, 0.588, -0.809, 12.728, 29.704)">
              <path
                d="M0,0V14.222H7.111Z"
                transform="translate(6.763 0) rotate(18)"
                fill="#fff"
              />
              <path
                d="M0,14.222V0H7.111Z"
                transform="translate(11.158 13.526) rotate(162)"
                fill="#fff"
              />
            </g>
          </g>
          <g transform="translate(244.803 135.937)">
            <g transform="translate(44.433)">
              <g transform="translate(8.853)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(27.052 3.395) rotate(72)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(0 16.259) rotate(-72)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, 0.588, -0.588, -0.809, 29.447, 21.753)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, -0.588, 0.588, -0.809, 12.728, 29.704)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
            </g>
            <g transform="translate(0 44.433)">
              <g transform="translate(8.853)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(27.052 3.395) rotate(72)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(0 16.259) rotate(-72)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, 0.588, -0.588, -0.809, 29.447, 21.753)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, -0.588, 0.588, -0.809, 12.728, 29.704)">
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 0) rotate(18)"
                  fill="#fff"
                />
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(11.158 13.526) rotate(162)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
          <g transform="translate(213.197 210.074) rotate(180)">
            <g transform="translate(44.433 44.433)">
              <g id="b-4" data-name="b" transform="translate(8.853 13.98)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(12.098 21.449) rotate(-72)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(14.954 8.585) rotate(72)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, -0.588, 0.588, -0.809, 20.205, 20.671)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, 0.588, -0.588, -0.809, 21.97, 12.721)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
            </g>
            <g transform="translate(0 0)">
              <g id="b-5" data-name="b" transform="translate(8.853 13.98)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(12.098 21.449) rotate(-72)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="translate(14.954 8.585) rotate(72)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, -0.588, 0.588, -0.809, 20.205, 20.671)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
              <g transform="matrix(-0.809, 0.588, -0.588, -0.809, 21.97, 12.721)">
                <path
                  d="M0,14.222V0H7.111Z"
                  transform="translate(2.368 2.197) rotate(-18)"
                  fill="#fff"
                />
                <path
                  d="M0,0V14.222H7.111Z"
                  transform="translate(6.763 15.724) rotate(-162)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
