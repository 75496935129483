import React, { PureComponent } from "react";

export default class CF extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#d80027"
          />
          <g>
            <rect
              width="211.478"
              height="85.337"
              transform="translate(0 255.996)"
              fill="#6da544"
            />
            <rect
              width="211.478"
              height="85.337"
              transform="translate(300.522 255.996)"
              fill="#6da544"
            />
          </g>
          <path
            d="M0,85.33v85.332H211.478V85.333h89.044v85.329H512V85.33Z"
            fill="#0052b4"
          />
          <g>
            <rect
              width="211.478"
              height="85.337"
              transform="translate(300.522 170.659)"
              fill="#f0f0f0"
            />
            <rect
              width="211.478"
              height="85.337"
              transform="translate(0 170.659)"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M300.522,426.659H211.478V341.33H0v85.332H512V341.33H300.522Z"
              fill="#ffda44"
            />
            <path
              d="M119.678,111.3l4.6,14.172h14.9l-12.054,8.759,4.6,14.171-12.056-8.758L107.624,148.4l4.6-14.171-12.055-8.759h14.9Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
