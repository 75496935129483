import React, { PureComponent } from "react";

export default class VG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#0052b4"
          />
          <path
            d="M256,85.334V202.66H209.931L256,233.38V256H233.372L160,207.078V256H96V207.078L22.628,256H0V233.38l46.069-30.72H0v-64H46.069L0,107.951V85.334H22.628L96,134.241V85.334h64v48.907l73.372-48.907Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.33H112v69.333H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
            <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          </g>
          <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
          <g>
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
            <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          </g>
          <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
          <g>
            <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
            <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          </g>
          <path
            d="M384,259.706l-46.129,46.129a51.935,51.935,0,0,0,92.258,0Z"
            fill="#ffda44"
          />
          <path
            d="M332.058,178.084v81.623h0c0,39.759,51.942,51.941,51.942,51.941s51.941-12.182,51.942-51.942h0V178.084H332.058Z"
            fill="#6da544"
          />
          <rect
            width="22.261"
            height="59.359"
            transform="translate(372.87 215.181)"
            fill="#f0f0f0"
          />
          <circle
            cx="11.13"
            cy="11.13"
            r="11.13"
            transform="translate(372.87 204.051)"
            fill="#a2001d"
          />
          <g>
            <rect
              width="14.84"
              height="14.84"
              transform="translate(346.902 192.92)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="14.84"
              transform="translate(346.902 226.312)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="14.84"
              transform="translate(346.902 259.703)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="14.84"
              transform="translate(406.261 192.92)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="14.84"
              transform="translate(406.261 226.312)"
              fill="#ffda44"
            />
            <rect
              width="14.84"
              height="14.84"
              transform="translate(406.261 259.703)"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
