import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { updateAuction } from "../utils";
import { createStructuredSelector } from "reselect";
import { getFlagUpdateProductGrid } from "../selectors";

const withRemoveAuction = (WrappedComponent) => {
  class RemoveAuction extends PureComponent {
    componentDidUpdate(prevProps) {
      const { products, updateProductGrid } = this.props;
      if (updateProductGrid && !prevProps.updateProductGrid) {
        updateAuction(products, this.cmp.handleUpdateGrid);
      }
    }

    render() {
      return (
        <WrappedComponent ref={(ref) => (this.cmp = ref)} {...this.props} />
      );
    }
  }
  return RemoveAuction;
};

const mapStateToProps = createStructuredSelector({
  updateProductGrid: getFlagUpdateProductGrid,
});

export default compose(connect(mapStateToProps), withRemoveAuction);
