import React, { PureComponent } from "react";

export default class AnnouncementBar extends PureComponent {
  render() {
    return (
      <div className="announcement-container text-center bg-dark">
        <p className="announcement-container__text mb-0">
          Free worldwide shipping on order $29+
        </p>
      </div>
    );
  }
}
