import React, { PureComponent } from "react";

export default class VE extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="148.413"
            transform="translate(0 181.793)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M367.3,285.852l-8.033,6.276,3.488,9.577-8.453-5.7-8.032,6.275,2.809-9.8-8.451-5.7,10.188-.355,2.811-9.8,3.487,9.579Z"
              fill="#f0f0f0"
            />
            <path
              d="M346.735,250.227l-5.4,8.645,6.552,7.808L338,264.216l-5.4,8.643-.712-10.169-9.891-2.466,9.452-3.819-.71-10.17,6.553,7.81Z"
              fill="#f0f0f0"
            />
            <path
              d="M315.223,223.785l-2.118,9.971,8.827,5.1L311.8,239.921l-2.121,9.968-4.146-9.311-10.137,1.064,7.575-6.821-4.146-9.311,8.829,5.1Z"
              fill="#f0f0f0"
            />
            <path
              d="M276.568,209.716l1.419,10.094,10.039,1.77-9.162,4.47,1.418,10.094-7.081-7.332-9.163,4.467,4.786-9-7.081-7.33,10.04,1.768Z"
              fill="#f0f0f0"
            />
            <path
              d="M235.432,209.716l4.787,9,10.037-1.77-7.081,7.334,4.785,9-9.161-4.469-7.082,7.331,1.42-10.093-9.163-4.468,10.04-1.772Z"
              fill="#f0f0f0"
            />
            <path
              d="M196.776,223.785l7.578,6.821,8.825-5.1-4.144,9.314,7.574,6.82-10.137-1.066-4.147,9.311-2.117-9.97-10.139-1.064,8.829-5.1Z"
              fill="#f0f0f0"
            />
            <path
              d="M165.265,250.227l9.453,3.818,6.55-7.808-.709,10.171,9.45,3.819-9.891,2.465-.712,10.167-5.4-8.643-9.892,2.465,6.552-7.809Z"
              fill="#f0f0f0"
            />
            <path
              d="M144.7,285.852l10.19.356,3.484-9.578,2.813,9.8,10.186.357-8.452,5.7,2.81,9.8-8.032-6.276-8.45,5.7,3.486-9.579Z"
              fill="#f0f0f0"
            />
          </g>
          <rect
            width="512"
            height="96.456"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
