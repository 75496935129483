import React, { PureComponent } from "react";
import { Col, Form as BSForm } from "react-bootstrap";
import { kebabCase, camelCase, startCase } from "lodash";

import { Field } from "storefront/features/shared";
import {
  validateNumberOrEmpty,
  composeValidators,
} from "storefront/features/shared/components/field/utils";

export default class ShippingField extends PureComponent {
  render() {
    const { data } = this.props;
    return data.map(({ name, placeholder }) => (
      <BSForm.Row
        className="justify-content-center justify-content-sm-start"
        key={name}
      >
        <BSForm.Group as={Col} xs={10} md={4} xl={6}>
          <label htmlFor={`${camelCase(name)}`}>
            Shipping Rate {startCase(name)}
          </label>
          <Field
            size="sm"
            name={name}
            type="text"
            placeholder={placeholder}
            id={camelCase(name)}
            data-cy={`vendor-settings-${kebabCase(name)}`}
            validate={composeValidators(validateNumberOrEmpty)}
          />
        </BSForm.Group>
      </BSForm.Row>
    ));
  }
}
