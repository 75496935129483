import React, { PureComponent } from "react";

export default class TK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M210.715,174.377l3.684,11.338h11.919l-9.643,7.006,3.684,11.337-9.644-7.007-9.644,7.007,3.684-11.337-9.645-7.006h11.921Z"
              fill="#f0f0f0"
            />
            <path
              d="M116.816,281.971l4.6,14.172h14.9L124.266,304.9l4.606,14.173-12.056-8.76-12.055,8.76,4.6-14.173-12.054-8.757h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M144.7,119.679l4.6,14.172h14.9l-12.056,8.758,4.606,14.171L144.7,148.021,132.64,156.78l4.606-14.171-12.055-8.758h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M69.539,175.331l4.6,14.172h14.9l-12.055,8.758,4.606,14.171-12.055-8.758-12.056,8.758,4.606-14.171L50.034,189.5h14.9Z"
              fill="#f0f0f0"
            />
          </g>
          <g>
            <path
              d="M483.386,354.5H117.8s109.234-88.562,310.593-220.938C428.395,133.565,339.951,262.5,483.386,354.5Z"
              fill="#ffda44"
            />
            <path
              d="M117.8,366.089c-11.177,0-11.195,17.37,0,17.37H483.386c11.177,0,11.2-17.37,0-17.37Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
