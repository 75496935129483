import React, { PureComponent } from "react";

export default class MO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#496e2d"
          />
          <path
            d="M285.682,248.878a34.981,34.981,0,0,0-11.861,2.164,36.334,36.334,0,0,0,3.379-14.885c0-21.2-21.2-37.1-21.2-37.1s-21.2,15.9-21.2,37.1a36.342,36.342,0,0,0,3.379,14.885,34.972,34.972,0,0,0-11.861-2.164c-21.2,0-37.1,21.2-37.1,21.2s15.9,21.2,37.1,21.2c12.429,0,23.031-7.286,29.682-13.315,6.65,6.03,17.251,13.315,29.682,13.315,21.2,0,37.1-21.2,37.1-21.2S306.882,248.878,285.682,248.878Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M256,152.111l4.38,13.476h14.171l-11.464,8.332,4.376,13.476L256,179.065l-11.463,8.33,4.376-13.476-11.464-8.332H251.62Z"
              fill="#ffda44"
            />
            <path
              d="M202.112,175.683l8.425,7.585,9.818-5.667-4.61,10.355,8.423,7.588-11.274-1.185-4.611,10.355-2.358-11.088-11.275-1.185,9.818-5.669Z"
              fill="#ffda44"
            />
            <path
              d="M162.662,215.132l11.09,2.358,5.668-9.818,1.186,11.274L191.7,221.3l-10.357,4.61,1.186,11.274-7.587-8.423-10.357,4.61,5.669-9.818Z"
              fill="#ffda44"
            />
            <path
              d="M309.889,175.683l-8.425,7.585-9.818-5.667,4.611,10.355-8.425,7.588,11.275-1.185,4.61,10.355,2.359-11.088,11.275-1.185-9.818-5.669Z"
              fill="#ffda44"
            />
            <path
              d="M349.338,215.132l-11.089,2.358-5.669-9.818-1.186,11.274L320.306,221.3l10.357,4.61-1.185,11.274,7.586-8.423,10.357,4.61-5.669-9.818Z"
              fill="#ffda44"
            />
          </g>
          <g>
            <path
              d="M256,350.642a73.858,73.858,0,0,0,44.816-15.073h-89.63A73.847,73.847,0,0,0,256,350.642Z"
              fill="#f0f0f0"
            />
            <path
              d="M187.977,306.12a74.138,74.138,0,0,0,8.85,15.073H315.172a74.131,74.131,0,0,0,8.85-15.073Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
