export const customerAccessTokenCreateQuery = `
mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;
export const customerCreateQuery = `
mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;
export const customerActivateQuery = `
mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
  customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const customerRecover = `
mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const customerResetByUrl = `
mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $resetUrl, password: $password) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const customerAccessTokenDelete = `
mutation customerAccessTokenDelete($customerAccessToken: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
    deletedAccessToken
    deletedCustomerAccessTokenId
    userErrors {
      field
      message
    }
  }
}`;

export const customerAddressDelete = `
mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
  customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
    customerUserErrors {
      code
      field
      message
    }
    deletedCustomerAddressId
  }
}
`;

export const customerAddressCreate = `
mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
  customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
    customerAddress {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;

export const customerDefaultAddressUpdate = `
mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
  customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;

export const customerAddressUpdate = `
mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
  customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
    customerAddress {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;

export const countriesList = {
  " ": {},
  Afghanistan: {},
  "Aland Islands": {},
  Albania: {},
  Algeria: {},
  Andorra: {},
  Angola: {},
  Anguilla: {},
  "Antigua And Barbuda": {},
  Argentina: {
    "Buenos Aires": { name: "Buenos Aires Province" },
    Catamarca: { name: "Catamarca" },
    Chaco: { name: "Chaco" },
    Chubut: { name: "Chubut" },
    "Ciudad Autónoma de Buenos Aires": {
      name: "Buenos Aires (Autonomous City)",
    },
    Corrientes: { name: "Corrientes" },
    Córdoba: { name: "Córdoba" },
    "Entre Ríos": { name: "Entre Ríos" },
    Formosa: { name: "Formosa" },
    Jujuy: { name: "Jujuy" },
    "La Pampa": { name: "La Pampa" },
    "La Rioja": { name: "La Rioja" },
    Mendoza: { name: "Mendoza" },
    Misiones: { name: "Misiones" },
    Neuquén: { name: "Neuquén" },
    "Río Negro": { name: "Río Negro" },
    Salta: { name: "Salta" },
    "San Juan": { name: "San Juan" },
    "San Luis": { name: "San Luis" },
    "Santa Cruz": { name: "Santa Cruz" },
    "Santa Fe": { name: "Santa Fe" },
    "Santiago Del Estero": { name: "Santiago del Estero" },
    "Tierra Del Fuego": { name: "Tierra del Fuego" },
    Tucumán: { name: "Tucumán" },
  },
  Armenia: {},
  Aruba: {},
  Australia: {},
  Austria: {
    "Australian Capital Territory": { name: "Australian Capital Territory" },
    "New South Wales": { name: "New South Wales" },
    "Northern Territory": { name: "Northern Territory" },
    Queensland: { name: "Queensland" },
    "South Australia": { name: "South Australia" },
    Tasmania: { name: "Tasmania" },
    Victoria: { name: "Victoria" },
    "Western Australia": { name: "Western Australia" },
  },
  Azerbaijan: {},
  Bahamas: {},
  Bahrain: {},
  Bangladesh: {},
  Barbados: {},
  Belarus: {},
  Belgium: {},
  Belize: {},
  Benin: {},
  Bermuda: {},
  Bhutan: {},
  Bolivia: {},
  "Bosnia And Herzegovina": {},
  Botswana: {},
  "Bouvet Island": {},
  Brazil: {
    Acre: { name: "Acre" },
    Alagoas: { name: "Alagoas" },
    Amapá: { name: "Amapá" },
    Amazonas: { name: "Amazonas" },
    Bahia: { name: "Bahia" },
    Ceará: { name: "Ceará" },
    "Distrito Federal": { name: "Federal District" },
    "Espírito Santo": { name: "Espírito Santo" },
    Goiás: { name: "Goiás" },
    Maranhão: { name: "Maranhão" },
    "Mato Grosso": { name: "Mato Grosso" },
    "Mato Grosso do Sul": { name: "Mato Grosso do Sul" },
    "Minas Gerais": { name: "Minas Gerais" },
    Paraná: { name: "Paraná" },
    Paraíba: { name: "Paraíba" },
    Pará: { name: "Pará" },
    Pernambuco: { name: "Pernambuco" },
    Piauí: { name: "Piauí" },
    "Rio Grande do Norte": { name: "Rio Grande do Norte" },
    "Rio Grande do Sul": { name: "Rio Grande do Sul" },
    "Rio de Janeiro": { name: "Rio de Janeiro" },
    Rondônia: { name: "Rondônia" },
    Roraima: { name: "Roraima" },
    "Santa Catarina": { name: "Santa Catarina" },
    Sergipe: { name: "Sergipe" },
    "São Paulo": { name: "São Paulo" },
    Tocantins: { name: "Tocantins" },
  },
  "British Indian Ocean Territory": {},
  "Virgin Islands, British": {},
  Brunei: {},
  Bulgaria: {},
  "Burkina Faso": {},
  Burundi: {},
  Cambodia: {},
  "Republic of Cameroon": {},
  Canada: {
    Alberta: { name: "Alberta" },
    "British Columbia": { name: "British Columbia" },
    Manitoba: { name: "Manitoba" },
    "New Brunswick": { name: "New Brunswick" },
    "Newfoundland and Labrador": { name: "Newfoundland and Labrador" },
    "Northwest Territories": { name: "Northwest Territories" },
    "Nova Scotia": { name: "Nova Scotia" },
    Nunavut: { name: "Nunavut" },
    Ontario: { name: "Ontario" },
    "Prince Edward Island": { name: "Prince Edward Island" },
    Quebec: { name: "Quebec" },
    Saskatchewan: { name: "Saskatchewan" },
    Yukon: { name: "Yukon" },
  },
  "Cape Verde": {},
  "Caribbean Netherlands": {},
  "Cayman Islands": {},
  "Central African Republic": {},
  Chad: {},
  Chile: {
    Antofagasta: { name: "Antofagasta" },
    Araucanía: { name: "Araucanía" },
    "Arica and Parinacota": { name: "Arica y Parinacota" },
    Atacama: { name: "Atacama" },
    Aysén: { name: "Aysén" },
    Biobío: { name: "Bío Bío" },
    Coquimbo: { name: "Coquimbo" },
    "Los Lagos": { name: "Los Lagos" },
    "Los Ríos": { name: "Los Ríos" },
    Magallanes: { name: "Magallanes Region" },
    Maule: { name: "Maule" },
    "O'Higgins": { name: "Libertador General Bernardo O’Higgins" },
    Santiago: { name: "Santiago Metropolitan" },
    Tarapacá: { name: "Tarapacá" },
    Valparaíso: { name: "Valparaíso" },
    Ñuble: { name: "Ñuble" },
  },
  China: {
    Anhui: { name: "Anhui" },
    Beijing: { name: "Beijing" },
    Chongqing: { name: "Chongqing" },
    Fujian: { name: "Fujian" },
    Gansu: { name: "Gansu" },
    Guangdong: { name: "Guangdong" },
    Guangxi: { name: "Guangxi" },
    Guizhou: { name: "Guizhou" },
    Hainan: { name: "Hainan" },
    Hebei: { name: "Hebei" },
    Heilongjiang: { name: "Heilongjiang" },
    Henan: { name: "Henan" },
    Hubei: { name: "Hubei" },
    Hunan: { name: "Hunan" },
    "Inner Mongolia": { name: "Inner Mongolia" },
    Jiangsu: { name: "Jiangsu" },
    Jiangxi: { name: "Jiangxi" },
    Jilin: { name: "Jilin" },
    Liaoning: { name: "Liaoning" },
    Ningxia: { name: "Ningxia" },
    Qinghai: { name: "Qinghai" },
    Shaanxi: { name: "Shaanxi" },
    Shandong: { name: "Shandong" },
    Shanghai: { name: "Shanghai" },
    Shanxi: { name: "Shanxi" },
    Sichuan: { name: "Sichuan" },
    Tianjin: { name: "Tianjin" },
    Xinjiang: { name: "Xinjiang" },
    Xizang: { name: "Tibet" },
    Yunnan: { name: "Yunnan" },
    Zhejiang: { name: "Zhejiang" },
  },
  "Christmas Island": {},
  "Cocos (Keeling) Islands": {},
  Colombia: {
    Amazonas: { name: "Amazonas" },
    Antioquia: { name: "Antioquia" },
    Arauca: { name: "Arauca" },
    Atlántico: { name: "Atlántico" },
    "Bogotá, D.C.": { name: "Capital District" },
    Bolívar: { name: "Bolívar" },
    Boyacá: { name: "Boyacá" },
    Caldas: { name: "Caldas" },
    Caquetá: { name: "Caquetá" },
    Casanare: { name: "Casanare" },
    Cauca: { name: "Cauca" },
    Cesar: { name: "Cesar" },
    Chocó: { name: "Chocó" },
    Cundinamarca: { name: "Cundinamarca" },
    Córdoba: { name: "Córdoba" },
    Guainía: { name: "Guainía" },
    Guaviare: { name: "Guaviare" },
    Huila: { name: "Huila" },
    "La Guajira": { name: "La Guajira" },
    Magdalena: { name: "Magdalena" },
    Meta: { name: "Meta" },
    Nariño: { name: "Nariño" },
    "Norte de Santander": { name: "Norte de Santander" },
    Putumayo: { name: "Putumayo" },
    Quindío: { name: "Quindío" },
    Risaralda: { name: "Risaralda" },
    "San Andrés, Providencia y Santa Catalina": {
      name: "San Andrés & Providencia",
    },
    Santander: { name: "Santander" },
    Sucre: { name: "Sucre" },
    Tolima: { name: "Tolima" },
    "Valle del Cauca": { name: "Valle del Cauca" },
    Vaupés: { name: "Vaupés" },
    Vichada: { name: "Vichada" },
  },
  Comoros: {},
  Congo: {},
  "Congo, The Democratic Republic Of The": {},
  "Cook Islands": {},
  "Costa Rica": {},
  Croatia: {},
  Cuba: {},
  Curaçao: {},
  Cyprus: {},
  "Czech Republic": {},
  "Côte d'Ivoire": {},
  Denmark: {},
  Djibouti: {},
  Dominica: {},
  "Dominican Republic": {},
  Ecuador: {},
  Egypt: {
    "6th of October": { name: "6th of October" },
    "Al Sharqia": { name: "Al Sharqia" },
    Alexandria: { name: "Alexandria" },
    Aswan: { name: "Aswan" },
    Asyut: { name: "Asyut" },
    Beheira: { name: "Beheira" },
    "Beni Suef": { name: "Beni Suef" },
    Cairo: { name: "Cairo" },
    Dakahlia: { name: "Dakahlia" },
    Damietta: { name: "Damietta" },
    Faiyum: { name: "Faiyum" },
    Gharbia: { name: "Gharbia" },
    Giza: { name: "Giza" },
    Helwan: { name: "Helwan" },
    Ismailia: { name: "Ismailia" },
    "Kafr el-Sheikh": { name: "Kafr el-Sheikh" },
    Luxor: { name: "Luxor" },
    Matrouh: { name: "Matrouh" },
    Minya: { name: "Minya" },
    Monufia: { name: "Monufia" },
    "New Valley": { name: "New Valley" },
    "North Sinai": { name: "North Sinai" },
    "Port Said": { name: "Port Said" },
    Qalyubia: { name: "Qalyubia" },
    Qena: { name: "Qena" },
    "Red Sea": { name: "Red Sea" },
    Sohag: { name: "Sohag" },
    "South Sinai": { name: "South Sinai" },
    Suez: { name: "Suez" },
  },
  "El Salvador": {},
  "Equatorial Guinea": {},
  Eritrea: {},
  Estonia: {},
  Eswatini: {},
  Ethiopia: {},
  "Falkland Islands (Malvinas)": {},
  "Faroe Islands": {},
  Fiji: {},
  Finland: {},
  France: {},
  "French Guiana": {},
  "French Polynesia": {},
  "French Southern Territories": {},
  Gabon: {},
  Gambia: {},
  Georgia: {},
  Germany: {},
  Ghana: {},
  Gibraltar: {},
  Greece: {},
  Greenland: {},
  Grenada: {},
  Guadeloupe: {},
  Guatemala: {
    "Alta Verapaz": { name: "Alta Verapaz" },
    "Baja Verapaz": { name: "Baja Verapaz" },
    Chimaltenango: { name: "Chimaltenango" },
    Chiquimula: { name: "Chiquimula" },
    "El Progreso": { name: "El Progreso" },
    Escuintla: { name: "Escuintla" },
    Guatemala: { name: "Guatemala" },
    Huehuetenango: { name: "Huehuetenango" },
    Izabal: { name: "Izabal" },
    Jalapa: { name: "Jalapa" },
    Jutiapa: { name: "Jutiapa" },
    Petén: { name: "Petén" },
    Quetzaltenango: { name: "Quetzaltenango" },
    Quiché: { name: "Quiché" },
    Retalhuleu: { name: "Retalhuleu" },
    Sacatepéquez: { name: "Sacatepéquez" },
    "San Marcos": { name: "San Marcos" },
    "Santa Rosa": { name: "Santa Rosa" },
    Sololá: { name: "Sololá" },
    Suchitepéquez: { name: "Suchitepéquez" },
    Totonicapán: { name: "Totonicapán" },
    Zacapa: { name: "Zacapa" },
  },
  Guernsey: {},
  Guinea: {},
  "Guinea Bissau": {},
  Guyana: {},
  Haiti: {},
  "Heard Island And Mcdonald Islands": {},
  Honduras: {},
  "Hong Kong": {
    "Hong Kong Island": { name: "Hong Kong Island" },
    Kowloon: { name: "Kowloon" },
    "New Territories": { name: "New Territories" },
  },
  Hungary: {},
  Iceland: {},
  India: {
    "Andaman and Nicobar Islands": { name: "Andaman and Nicobar Islands" },
    "Andhra Pradesh": { name: "Andhra Pradesh" },
    "Arunachal Pradesh": { name: "Arunachal Pradesh" },
    Assam: { name: "Assam" },
    Bihar: { name: "Bihar" },
    Chandigarh: { name: "Chandigarh" },
    Chhattisgarh: { name: "Chhattisgarh" },
    "Dadra and Nagar Haveli": { name: "Dadra and Nagar Haveli" },
    "Daman and Diu": { name: "Daman and Diu" },
    Delhi: { name: "Delhi" },
    Goa: { name: "Goa" },
    Gujarat: { name: "Gujarat" },
    Haryana: { name: "Haryana" },
    "Himachal Pradesh": { name: "Himachal Pradesh" },
    "Jammu and Kashmir": { name: "Jammu and Kashmir" },
    Jharkhand: { name: "Jharkhand" },
    Karnataka: { name: "Karnataka" },
    Kerala: { name: "Kerala" },
    Ladakh: { name: "Ladakh" },
    Lakshadweep: { name: "Lakshadweep" },
    "Madhya Pradesh": { name: "Madhya Pradesh" },
    Maharashtra: { name: "Maharashtra" },
    Manipur: { name: "Manipur" },
    Meghalaya: { name: "Meghalaya" },
    Mizoram: { name: "Mizoram" },
    Nagaland: { name: "Nagaland" },
    Odisha: { name: "Odisha" },
    Puducherry: { name: "Puducherry" },
    Punjab: { name: "Punjab" },
    Rajasthan: { name: "Rajasthan" },
    Sikkim: { name: "Sikkim" },
    "Tamil Nadu": { name: "Tamil Nadu" },
    Telangana: { name: "Telangana" },
    Tripura: { name: "Tripura" },
    "Uttar Pradesh": { name: "Uttar Pradesh" },
    Uttarakhand: { name: "Uttarakhand" },
    "West Bengal": { name: "West Bengal" },
  },
  Indonesia: {
    Aceh: { name: "Aceh" },
    Bali: { name: "Bali" },
    "Bangka Belitung": { name: "Bangka–Belitung Islands" },
    Banten: { name: "Banten" },
    Bengkulu: { name: "Bengkulu" },
    Gorontalo: { name: "Gorontalo" },
    Jakarta: { name: "Jakarta" },
    Jambi: { name: "Jambi" },
    "Jawa Barat": { name: "West Java" },
    "Jawa Tengah": { name: "Central Java" },
    "Jawa Timur": { name: "East Java" },
    "Kalimantan Barat": { name: "West Kalimantan" },
    "Kalimantan Selatan": { name: "South Kalimantan" },
    "Kalimantan Tengah": { name: "Central Kalimantan" },
    "Kalimantan Timur": { name: "East Kalimantan" },
    "Kalimantan Utara": { name: "North Kalimantan" },
    "Kepulauan Riau": { name: "Riau Islands" },
    Lampung: { name: "Lampung" },
    Maluku: { name: "Maluku" },
    "Maluku Utara": { name: "North Maluku" },
    "North Sumatra": { name: "North Sumatra" },
    "Nusa Tenggara Barat": { name: "West Nusa Tenggara" },
    "Nusa Tenggara Timur": { name: "East Nusa Tenggara" },
    Papua: { name: "Papua" },
    "Papua Barat": { name: "West Papua" },
    Riau: { name: "Riau" },
    "South Sumatra": { name: "South Sumatra" },
    "Sulawesi Barat": { name: "West Sulawesi" },
    "Sulawesi Selatan": { name: "South Sulawesi" },
    "Sulawesi Tengah": { name: "Central Sulawesi" },
    "Sulawesi Tenggara": { name: "Southeast Sulawesi" },
    "Sulawesi Utara": { name: "North Sulawesi" },
    "West Sumatra": { name: "West Sumatra" },
    Yogyakarta: { name: "Yogyakarta" },
  },
  "Iran, Islamic Republic Of": {},
  Iraq: {},
  Ireland: {
    Carlow: { name: "Carlow" },
    Cavan: { name: "Cavan" },
    Clare: { name: "Clare" },
    Cork: { name: "Cork" },
    Donegal: { name: "Donegal" },
    Dublin: { name: "Dublin" },
    Galway: { name: "Galway" },
    Kerry: { name: "Kerry" },
    Kildare: { name: "Kildare" },
    Kilkenny: { name: "Kilkenny" },
    Laois: { name: "Laois" },
    Leitrim: { name: "Leitrim" },
    Limerick: { name: "Limerick" },
    Longford: { name: "Longford" },
    Louth: { name: "Louth" },
    Mayo: { name: "Mayo" },
    Meath: { name: "Meath" },
    Monaghan: { name: "Monaghan" },
    Offaly: { name: "Offaly" },
    Roscommon: { name: "Roscommon" },
    Sligo: { name: "Sligo" },
    Tipperary: { name: "Tipperary" },
    Waterford: { name: "Waterford" },
    Westmeath: { name: "Westmeath" },
    Wexford: { name: "Wexford" },
    Wicklow: { name: "Wicklow" },
  },
  "Isle Of Man": {},
  Israel: {},
  Italy: {
    Agrigento: { name: "Agrigento" },
    Alessandria: { name: "Alessandria" },
    Ancona: { name: "Ancona" },
    Aosta: { name: "Aosta" },
    Arezzo: { name: "Arezzo" },
    "Ascoli Piceno": { name: "Ascoli Piceno" },
    Asti: { name: "Asti" },
    Avellino: { name: "Avellino" },
    Bari: { name: "Bari" },
    "Barletta-Andria-Trani": { name: "Barletta-Andria-Trani" },
    Belluno: { name: "Belluno" },
    Benevento: { name: "Benevento" },
    Bergamo: { name: "Bergamo" },
    Biella: { name: "Biella" },
    Bologna: { name: "Bologna" },
    Bolzano: { name: "South Tyrol" },
    Brescia: { name: "Brescia" },
    Brindisi: { name: "Brindisi" },
    Cagliari: { name: "Cagliari" },
    Caltanissetta: { name: "Caltanissetta" },
    Campobasso: { name: "Campobasso" },
    "Carbonia-Iglesias": { name: "Carbonia-Iglesias" },
    Caserta: { name: "Caserta" },
    Catania: { name: "Catania" },
    Catanzaro: { name: "Catanzaro" },
    Chieti: { name: "Chieti" },
    Como: { name: "Como" },
    Cosenza: { name: "Cosenza" },
    Cremona: { name: "Cremona" },
    Crotone: { name: "Crotone" },
    Cuneo: { name: "Cuneo" },
    Enna: { name: "Enna" },
    Fermo: { name: "Fermo" },
    Ferrara: { name: "Ferrara" },
    Firenze: { name: "Florence" },
    Foggia: { name: "Foggia" },
    "Forlì-Cesena": { name: "Forlì-Cesena" },
    Frosinone: { name: "Frosinone" },
    Genova: { name: "Genoa" },
    Gorizia: { name: "Gorizia" },
    Grosseto: { name: "Grosseto" },
    Imperia: { name: "Imperia" },
    Isernia: { name: "Isernia" },
    "L'Aquila": { name: "L’Aquila" },
    "La Spezia": { name: "La Spezia" },
    Latina: { name: "Latina" },
    Lecce: { name: "Lecce" },
    Lecco: { name: "Lecco" },
    Livorno: { name: "Livorno" },
    Lodi: { name: "Lodi" },
    Lucca: { name: "Lucca" },
    Macerata: { name: "Macerata" },
    Mantova: { name: "Mantua" },
    "Massa-Carrara": { name: "Massa and Carrara" },
    Matera: { name: "Matera" },
    "Medio Campidano": { name: "Medio Campidano" },
    Messina: { name: "Messina" },
    Milano: { name: "Milan" },
    Modena: { name: "Modena" },
    "Monza e Brianza": { name: "Monza and Brianza" },
    Napoli: { name: "Naples" },
    Novara: { name: "Novara" },
    Nuoro: { name: "Nuoro" },
    Ogliastra: { name: "Ogliastra" },
    "Olbia-Tempio": { name: "Olbia-Tempio" },
    Oristano: { name: "Oristano" },
    Padova: { name: "Padua" },
    Palermo: { name: "Palermo" },
    Parma: { name: "Parma" },
    Pavia: { name: "Pavia" },
    Perugia: { name: "Perugia" },
    "Pesaro e Urbino": { name: "Pesaro and Urbino" },
    Pescara: { name: "Pescara" },
    Piacenza: { name: "Piacenza" },
    Pisa: { name: "Pisa" },
    Pistoia: { name: "Pistoia" },
    Pordenone: { name: "Pordenone" },
    Potenza: { name: "Potenza" },
    Prato: { name: "Prato" },
    Ragusa: { name: "Ragusa" },
    Ravenna: { name: "Ravenna" },
    "Reggio Calabria": { name: "Reggio Calabria" },
    "Reggio Emilia": { name: "Reggio Emilia" },
    Rieti: { name: "Rieti" },
    Rimini: { name: "Rimini" },
    Roma: { name: "Rome" },
    Rovigo: { name: "Rovigo" },
    Salerno: { name: "Salerno" },
    Sassari: { name: "Sassari" },
    Savona: { name: "Savona" },
    Siena: { name: "Siena" },
    Siracusa: { name: "Syracuse" },
    Sondrio: { name: "Sondrio" },
    Taranto: { name: "Taranto" },
    Teramo: { name: "Teramo" },
    Terni: { name: "Terni" },
    Torino: { name: "Turin" },
    Trapani: { name: "Trapani" },
    Trento: { name: "Trentino" },
    Treviso: { name: "Treviso" },
    Trieste: { name: "Trieste" },
    Udine: { name: "Udine" },
    Varese: { name: "Varese" },
    Venezia: { name: "Venice" },
    "Verbano-Cusio-Ossola": { name: "Verbano-Cusio-Ossola" },
    Vercelli: { name: "Vercelli" },
    Verona: { name: "Verona" },
    "Vibo Valentia": { name: "Vibo Valentia" },
    Vicenza: { name: "Vicenza" },
    Viterbo: { name: "Viterbo" },
  },
  Jamaica: {},
  Japan: {
    Aichi: { name: "Aichi" },
    Akita: { name: "Akita" },
    Aomori: { name: "Aomori" },
    Chiba: { name: "Chiba" },
    Ehime: { name: "Ehime" },
    Fukui: { name: "Fukui" },
    Fukuoka: { name: "Fukuoka" },
    Fukushima: { name: "Fukushima" },
    Gifu: { name: "Gifu" },
    Gunma: { name: "Gunma" },
    Hiroshima: { name: "Hiroshima" },
    Hokkaidō: { name: "Hokkaidō" },
    Hyōgo: { name: "Hyōgo" },
    Ibaraki: { name: "Ibaraki" },
    Ishikawa: { name: "Ishikawa" },
    Iwate: { name: "Iwate" },
    Kagawa: { name: "Kagawa" },
    Kagoshima: { name: "Kagoshima" },
    Kanagawa: { name: "Kanagawa" },
    Kumamoto: { name: "Kumamoto" },
    Kyōto: { name: "Kyōto" },
    Kōchi: { name: "Kōchi" },
    Mie: { name: "Mie" },
    Miyagi: { name: "Miyagi" },
    Miyazaki: { name: "Miyazaki" },
    Nagano: { name: "Nagano" },
    Nagasaki: { name: "Nagasaki" },
    Nara: { name: "Nara" },
    Niigata: { name: "Niigata" },
    Okayama: { name: "Okayama" },
    Okinawa: { name: "Okinawa" },
    Saga: { name: "Saga" },
    Saitama: { name: "Saitama" },
    Shiga: { name: "Shiga" },
    Shimane: { name: "Shimane" },
    Shizuoka: { name: "Shizuoka" },
    Tochigi: { name: "Tochigi" },
    Tokushima: { name: "Tokushima" },
    Tottori: { name: "Tottori" },
    Toyama: { name: "Toyama" },
    Tōkyō: { name: "Tokyo" },
    Wakayama: { name: "Wakayama" },
    Yamagata: { name: "Yamagata" },
    Yamaguchi: { name: "Yamaguchi" },
    Yamanashi: { name: "Yamanashi" },
    Ōita: { name: "Ōita" },
    Ōsaka: { name: "Ōsaka" },
  },
  Jersey: {},
  Jordan: {},
  Kazakhstan: {},
  Kenya: {},
  Kiribati: {},
  Kosovo: {},
  Kuwait: {},
  Kyrgyzstan: {},
  "Lao People's Democratic Republic": {},
  Latvia: {},
  Lebanon: {},
  Lesotho: {},
  Liberia: {},
  "Libyan Arab Jamahiriya": {},
  Liechtenstein: {},
  Lithuania: {},
  Luxembourg: {},
  Macao: {},
  Madagascar: {},
  Malawi: {},
  Malaysia: {
    Johor: { name: "Johor" },
    Kedah: { name: "Kedah" },
    Kelantan: { name: "Kelantan" },
    "Kuala Lumpur": { name: "Kuala Lumpur" },
    Labuan: { name: "Labuan" },
    Melaka: { name: "Malacca" },
    "Negeri Sembilan": { name: "Negeri Sembilan" },
    Pahang: { name: "Pahang" },
    Penang: { name: "Penang" },
    Perak: { name: "Perak" },
    Perlis: { name: "Perlis" },
    Putrajaya: { name: "Putrajaya" },
    Sabah: { name: "Sabah" },
    Sarawak: { name: "Sarawak" },
    Selangor: { name: "Selangor" },
    Terengganu: { name: "Terengganu" },
  },
  Maldives: {},
  Mali: {},
  Malta: {},
  Martinique: {},
  Mauritania: {},
  Mauritius: {},
  Mayotte: {},
  Mexico: {
    Aguascalientes: { name: "Aguascalientes" },
    "Baja California": { name: "Baja California" },
    "Baja California Sur": { name: "Baja California Sur" },
    Campeche: { name: "Campeche" },
    Chiapas: { name: "Chiapas" },
    Chihuahua: { name: "Chihuahua" },
    "Ciudad de México": { name: "Ciudad de Mexico" },
    Coahuila: { name: "Coahuila" },
    Colima: { name: "Colima" },
    Durango: { name: "Durango" },
    Guanajuato: { name: "Guanajuato" },
    Guerrero: { name: "Guerrero" },
    Hidalgo: { name: "Hidalgo" },
    Jalisco: { name: "Jalisco" },
    Michoacán: { name: "Michoacán" },
    Morelos: { name: "Morelos" },
    México: { name: "Mexico State" },
    Nayarit: { name: "Nayarit" },
    "Nuevo León": { name: "Nuevo León" },
    Oaxaca: { name: "Oaxaca" },
    Puebla: { name: "Puebla" },
    Querétaro: { name: "Querétaro" },
    "Quintana Roo": { name: "Quintana Roo" },
    "San Luis Potosí": { name: "San Luis Potosí" },
    Sinaloa: { name: "Sinaloa" },
    Sonora: { name: "Sonora" },
    Tabasco: { name: "Tabasco" },
    Tamaulipas: { name: "Tamaulipas" },
    Tlaxcala: { name: "Tlaxcala" },
    Veracruz: { name: "Veracruz" },
    Yucatán: { name: "Yucatán" },
    Zacatecas: { name: "Zacatecas" },
  },
  "Moldova, Republic of": {},
  Monaco: {},
  Mongolia: {},
  Montenegro: {},
  Montserrat: {},
  Morocco: {},
  Mozambique: {},
  Myanmar: {},
  Namibia: {},
  Nauru: {},
  Nepal: {},
  Netherlands: {},
  "Netherlands Antilles": {},
  "New Caledonia": {},
  "New Zealand": {
    Auckland: { name: "Auckland" },
    "Bay of Plenty": { name: "Bay of Plenty" },
    Canterbury: { name: "Canterbury" },
    Gisborne: { name: "Gisborne" },
    "Hawke's Bay": { name: "Hawke’s Bay" },
    "Manawatu-Wanganui": { name: "Manawatu-Wanganui" },
    Marlborough: { name: "Marlborough" },
    Nelson: { name: "Nelson" },
    Northland: { name: "Northland" },
    Otago: { name: "Otago" },
    Southland: { name: "Southland" },
    Taranaki: { name: "Taranaki" },
    Tasman: { name: "Tasman" },
    Waikato: { name: "Waikato" },
    Wellington: { name: "Wellington" },
    "West Coast": { name: "West Coast" },
  },
  Nicaragua: {},
  Niger: {},
  Nigeria: {
    Abia: { name: "Abia" },
    "Abuja Federal Capital Territory": { name: "Federal Capital Territory" },
    Adamawa: { name: "Adamawa" },
    "Akwa Ibom": { name: "Akwa Ibom" },
    Anambra: { name: "Anambra" },
    Bauchi: { name: "Bauchi" },
    Bayelsa: { name: "Bayelsa" },
    Benue: { name: "Benue" },
    Borno: { name: "Borno" },
    "Cross River": { name: "Cross River" },
    Delta: { name: "Delta" },
    Ebonyi: { name: "Ebonyi" },
    Edo: { name: "Edo" },
    Ekiti: { name: "Ekiti" },
    Enugu: { name: "Enugu" },
    Gombe: { name: "Gombe" },
    Imo: { name: "Imo" },
    Jigawa: { name: "Jigawa" },
    Kaduna: { name: "Kaduna" },
    Kano: { name: "Kano" },
    Katsina: { name: "Katsina" },
    Kebbi: { name: "Kebbi" },
    Kogi: { name: "Kogi" },
    Kwara: { name: "Kwara" },
    Lagos: { name: "Lagos" },
    Nasarawa: { name: "Nasarawa" },
    Niger: { name: "Niger" },
    Ogun: { name: "Ogun" },
    Ondo: { name: "Ondo" },
    Osun: { name: "Osun" },
    Oyo: { name: "Oyo" },
    Plateau: { name: "Plateau" },
    Rivers: { name: "Rivers" },
    Sokoto: { name: "Sokoto" },
    Taraba: { name: "Taraba" },
    Yobe: { name: "Yobe" },
    Zamfara: { name: "Zamfara" },
  },
  Niue: {},
  "Norfolk Island": {},
  "Korea, Democratic People's Republic Of": {},
  "North Macedonia": {},
  Norway: {},
  Oman: {},
  Pakistan: {},
  "Palestinian Territory, Occupied": {},
  Panama: {
    "Bocas del Toro": { name: "Bocas del Toro" },
    Chiriquí: { name: "Chiriquí" },
    Coclé: { name: "Coclé" },
    Colón: { name: "Colón" },
    Darién: { name: "Darién" },
    Emberá: { name: "Emberá" },
    Herrera: { name: "Herrera" },
    "Kuna Yala": { name: "Guna Yala" },
    "Los Santos": { name: "Los Santos" },
    "Ngöbe-Buglé": { name: "Ngöbe-Buglé" },
    Panamá: { name: "Panamá" },
    "Panamá Oeste": { name: "West Panamá" },
    Veraguas: { name: "Veraguas" },
  },
  "Papua New Guinea": {},
  Paraguay: {},
  Peru: {
    Amazonas: { name: "Amazonas" },
    Apurímac: { name: "Apurímac" },
    Arequipa: { name: "Arequipa" },
    Ayacucho: { name: "Ayacucho" },
    Cajamarca: { name: "Cajamarca" },
    Callao: { name: "El Callao" },
    Cuzco: { name: "Cusco" },
    Huancavelica: { name: "Huancavelica" },
    Huánuco: { name: "Huánuco" },
    Ica: { name: "Ica" },
    Junín: { name: "Junín" },
    "La Libertad": { name: "La Libertad" },
    Lambayeque: { name: "Lambayeque" },
    "Lima (departamento)": { name: "Lima Region" },
    "Lima (provincia)": { name: "Lima" },
    Loreto: { name: "Loreto" },
    "Madre de Dios": { name: "Madre de Dios" },
    Moquegua: { name: "Moquegua" },
    Pasco: { name: "Pasco" },
    Piura: { name: "Piura" },
    Puno: { name: "Puno" },
    "San Martín": { name: "San Martín" },
    Tacna: { name: "Tacna" },
    Tumbes: { name: "Tumbes" },
    Ucayali: { name: "Ucayali" },
    Áncash: { name: "Ancash" },
  },
  Philippines: {},
  Pitcairn: {},
  Poland: {},
  Portugal: {
    Aveiro: { name: "Aveiro" },
    Açores: { name: "Azores" },
    Beja: { name: "Beja" },
    Braga: { name: "Braga" },
    Bragança: { name: "Bragança" },
    "Castelo Branco": { name: "Castelo Branco" },
    Coimbra: { name: "Coimbra" },
    Faro: { name: "Faro" },
    Guarda: { name: "Guarda" },
    Leiria: { name: "Leiria" },
    Lisboa: { name: "Lisbon" },
    Madeira: { name: "Madeira" },
    Portalegre: { name: "Portalegre" },
    Porto: { name: "Porto" },
    Santarém: { name: "Santarém" },
    Setúbal: { name: "Setúbal" },
    "Viana do Castelo": { name: "Viana do Castelo" },
    "Vila Real": { name: "Vila Real" },
    Viseu: { name: "Viseu" },
    Évora: { name: "Évora" },
  },
  Qatar: {},
  Reunion: {},
  Romania: {
    Alba: { name: "Alba" },
    Arad: { name: "Arad" },
    Argeș: { name: "Argeș" },
    Bacău: { name: "Bacău" },
    Bihor: { name: "Bihor" },
    "Bistrița-Năsăud": { name: "Bistriţa-Năsăud" },
    Botoșani: { name: "Botoşani" },
    Brașov: { name: "Braşov" },
    Brăila: { name: "Brăila" },
    București: { name: "Bucharest" },
    Buzău: { name: "Buzău" },
    "Caraș-Severin": { name: "Caraș-Severin" },
    Cluj: { name: "Cluj" },
    Constanța: { name: "Constanța" },
    Covasna: { name: "Covasna" },
    Călărași: { name: "Călărași" },
    Dolj: { name: "Dolj" },
    Dâmbovița: { name: "Dâmbovița" },
    Galați: { name: "Galați" },
    Giurgiu: { name: "Giurgiu" },
    Gorj: { name: "Gorj" },
    Harghita: { name: "Harghita" },
    Hunedoara: { name: "Hunedoara" },
    Ialomița: { name: "Ialomița" },
    Iași: { name: "Iași" },
    Ilfov: { name: "Ilfov" },
    Maramureș: { name: "Maramureş" },
    Mehedinți: { name: "Mehedinți" },
    Mureș: { name: "Mureş" },
    Neamț: { name: "Neamţ" },
    Olt: { name: "Olt" },
    Prahova: { name: "Prahova" },
    "Satu Mare": { name: "Satu Mare" },
    Sibiu: { name: "Sibiu" },
    Suceava: { name: "Suceava" },
    Sălaj: { name: "Sălaj" },
    Teleorman: { name: "Teleorman" },
    Timiș: { name: "Timiș" },
    Tulcea: { name: "Tulcea" },
    Vaslui: { name: "Vaslui" },
    Vrancea: { name: "Vrancea" },
    Vâlcea: { name: "Vâlcea" },
  },
  Russia: {
    "Altai Krai": { name: "Altai Krai" },
    "Altai Republic": { name: "Altai" },
    "Amur Oblast": { name: "Amur" },
    "Arkhangelsk Oblast": { name: "Arkhangelsk" },
    "Astrakhan Oblast": { name: "Astrakhan" },
    "Belgorod Oblast": { name: "Belgorod" },
    "Bryansk Oblast": { name: "Bryansk" },
    "Chechen Republic": { name: "Chechen" },
    "Chelyabinsk Oblast": { name: "Chelyabinsk" },
    "Chukotka Autonomous Okrug": { name: "Chukotka Okrug" },
    "Chuvash Republic": { name: "Chuvash" },
    "Irkutsk Oblast": { name: "Irkutsk" },
    "Ivanovo Oblast": { name: "Ivanovo" },
    "Jewish Autonomous Oblast": { name: "Jewish" },
    "Kabardino-Balkarian Republic": { name: "Kabardino-Balkar" },
    "Kaliningrad Oblast": { name: "Kaliningrad" },
    "Kaluga Oblast": { name: "Kaluga" },
    "Kamchatka Krai": { name: "Kamchatka Krai" },
    "Karachay–Cherkess Republic": { name: "Karachay-Cherkess" },
    "Kemerovo Oblast": { name: "Kemerovo" },
    "Khabarovsk Krai": { name: "Khabarovsk Krai" },
    "Khanty-Mansi Autonomous Okrug": { name: "Khanty-Mansi" },
    "Kirov Oblast": { name: "Kirov" },
    "Komi Republic": { name: "Komi" },
    "Kostroma Oblast": { name: "Kostroma" },
    "Krasnodar Krai": { name: "Krasnodar Krai" },
    "Krasnoyarsk Krai": { name: "Krasnoyarsk Krai" },
    "Kurgan Oblast": { name: "Kurgan" },
    "Kursk Oblast": { name: "Kursk" },
    "Leningrad Oblast": { name: "Leningrad" },
    "Lipetsk Oblast": { name: "Lipetsk" },
    "Magadan Oblast": { name: "Magadan" },
    "Mari El Republic": { name: "Mari El" },
    Moscow: { name: "Moscow" },
    "Moscow Oblast": { name: "Moscow Province" },
    "Murmansk Oblast": { name: "Murmansk" },
    "Nizhny Novgorod Oblast": { name: "Nizhny Novgorod" },
    "Novgorod Oblast": { name: "Novgorod" },
    "Novosibirsk Oblast": { name: "Novosibirsk" },
    "Omsk Oblast": { name: "Omsk" },
    "Orenburg Oblast": { name: "Orenburg" },
    "Oryol Oblast": { name: "Oryol" },
    "Penza Oblast": { name: "Penza" },
    "Perm Krai": { name: "Perm Krai" },
    "Primorsky Krai": { name: "Primorsky Krai" },
    "Pskov Oblast": { name: "Pskov" },
    "Republic of Adygeya": { name: "Adygea" },
    "Republic of Bashkortostan": { name: "Bashkortostan" },
    "Republic of Buryatia": { name: "Buryat" },
    "Republic of Dagestan": { name: "Dagestan" },
    "Republic of Ingushetia": { name: "Ingushetia" },
    "Republic of Kalmykia": { name: "Kalmykia" },
    "Republic of Karelia": { name: "Karelia" },
    "Republic of Khakassia": { name: "Khakassia" },
    "Republic of Mordovia": { name: "Mordovia" },
    "Republic of North Ossetia–Alania": { name: "North Ossetia-Alania" },
    "Republic of Tatarstan": { name: "Tatarstan" },
    "Rostov Oblast": { name: "Rostov" },
    "Ryazan Oblast": { name: "Ryazan" },
    "Saint Petersburg": { name: "Saint Petersburg" },
    "Sakha Republic (Yakutia)": { name: "Sakha" },
    "Sakhalin Oblast": { name: "Sakhalin" },
    "Samara Oblast": { name: "Samara" },
    "Saratov Oblast": { name: "Saratov" },
    "Smolensk Oblast": { name: "Smolensk" },
    "Stavropol Krai": { name: "Stavropol Krai" },
    "Sverdlovsk Oblast": { name: "Sverdlovsk" },
    "Tambov Oblast": { name: "Tambov" },
    "Tomsk Oblast": { name: "Tomsk" },
    "Tula Oblast": { name: "Tula" },
    "Tver Oblast": { name: "Tver" },
    "Tyumen Oblast": { name: "Tyumen" },
    "Tyva Republic": { name: "Tuva" },
    Udmurtia: { name: "Udmurt" },
    "Ulyanovsk Oblast": { name: "Ulyanovsk" },
    "Vladimir Oblast": { name: "Vladimir" },
    "Volgograd Oblast": { name: "Volgograd" },
    "Vologda Oblast": { name: "Vologda" },
    "Voronezh Oblast": { name: "Voronezh" },
    "Yamalo-Nenets Autonomous Okrug": { name: "Yamalo-Nenets Okrug" },
    "Yaroslavl Oblast": { name: "Yaroslavl" },
    "Zabaykalsky Krai": { name: "Zabaykalsky Krai" },
  },
  Rwanda: {},
  Samoa: {},
  "San Marino": {},
  "Sao Tome And Principe": {},
  "Saudi Arabia": {},
  Senegal: {},
  Serbia: {},
  Seychelles: {},
  "Sierra Leone": {},
  Singapore: {},
  "Sint Maarten": {},
  Slovakia: {},
  Slovenia: {},
  "Solomon Islands": {},
  Somalia: {},
  "South Africa": {
    "Eastern Cape": { name: "Eastern Cape" },
    "Free State": { name: "Free State" },
    Gauteng: { name: "Gauteng" },
    "KwaZulu-Natal": { name: "KwaZulu-Natal" },
    Limpopo: { name: "Limpopo" },
    Mpumalanga: { name: "Mpumalanga" },
    "North West": { name: "North West" },
    "Northern Cape": { name: "Northern Cape" },
    "Western Cape": { name: "Western Cape" },
  },
  "South Georgia And The South Sandwich Islands": {},
  "South Korea": {
    Busan: { name: "Busan" },
    Chungbuk: { name: "North Chungcheong" },
    Chungnam: { name: "South Chungcheong" },
    Daegu: { name: "Daegu" },
    Daejeon: { name: "Daejeon" },
    Gangwon: { name: "Gangwon" },
    Gwangju: { name: "Gwangju City" },
    Gyeongbuk: { name: "North Gyeongsang" },
    Gyeonggi: { name: "Gyeonggi" },
    Gyeongnam: { name: "South Gyeongsang" },
    Incheon: { name: "Incheon" },
    Jeju: { name: "Jeju" },
    Jeonbuk: { name: "North Jeolla" },
    Jeonnam: { name: "South Jeolla" },
    Sejong: { name: "Sejong" },
    Seoul: { name: "Seoul" },
    Ulsan: { name: "Ulsan" },
  },
  "South Sudan": {},
  Spain: {
    "A Coruña": { name: "A Coruña" },
    Albacete: { name: "Albacete" },
    Alicante: { name: "Alicante" },
    Almería: { name: "Almería" },
    Asturias: { name: "Asturias Province" },
    Badajoz: { name: "Badajoz" },
    Balears: { name: "Balears Province" },
    Barcelona: { name: "Barcelona" },
    Burgos: { name: "Burgos" },
    Cantabria: { name: "Cantabria Province" },
    Castellón: { name: "Castellón" },
    Ceuta: { name: "Ceuta" },
    "Ciudad Real": { name: "Ciudad Real" },
    Cuenca: { name: "Cuenca" },
    Cáceres: { name: "Cáceres" },
    Cádiz: { name: "Cádiz" },
    Córdoba: { name: "Córdoba" },
    Girona: { name: "Girona" },
    Granada: { name: "Granada" },
    Guadalajara: { name: "Guadalajara" },
    Guipúzcoa: { name: "Gipuzkoa" },
    Huelva: { name: "Huelva" },
    Huesca: { name: "Huesca" },
    Jaén: { name: "Jaén" },
    "La Rioja": { name: "La Rioja Province" },
    "Las Palmas": { name: "Las Palmas" },
    León: { name: "León" },
    Lleida: { name: "Lleida" },
    Lugo: { name: "Lugo" },
    Madrid: { name: "Madrid Province" },
    Melilla: { name: "Melilla" },
    Murcia: { name: "Murcia" },
    Málaga: { name: "Málaga" },
    Navarra: { name: "Navarra" },
    Ourense: { name: "Ourense" },
    Palencia: { name: "Palencia" },
    Pontevedra: { name: "Pontevedra" },
    Salamanca: { name: "Salamanca" },
    "Santa Cruz de Tenerife": { name: "Santa Cruz de Tenerife" },
    Segovia: { name: "Segovia" },
    Sevilla: { name: "Seville" },
    Soria: { name: "Soria" },
    Tarragona: { name: "Tarragona" },
    Teruel: { name: "Teruel" },
    Toledo: { name: "Toledo" },
    Valencia: { name: "Valencia" },
    Valladolid: { name: "Valladolid" },
    Vizcaya: { name: "Biscay" },
    Zamora: { name: "Zamora" },
    Zaragoza: { name: "Zaragoza" },
    Álava: { name: "Álava" },
    Ávila: { name: "Ávila" },
  },
  "Sri Lanka": {},
  "Saint Barthélemy": {},
  "Saint Helena": {},
  "Saint Kitts And Nevis": {},
  "Saint Lucia": {},
  "Saint Martin": {},
  "Saint Pierre And Miquelon": {},
  "St. Vincent": {},
  Sudan: {},
  Suriname: {},
  "Svalbard And Jan Mayen": {},
  Sweden: {},
  Switzerland: {},
  Syria: {},
  Taiwan: {},
  Tajikistan: {},
  "Tanzania, United Republic Of": {},
  Thailand: {
    "Amnat Charoen": { name: "Amnat Charoen" },
    "Ang Thong": { name: "Ang Thong" },
    Bangkok: { name: "Bangkok" },
    "Bueng Kan": { name: "Bueng Kan" },
    Buriram: { name: "Buri Ram" },
    Chachoengsao: { name: "Chachoengsao" },
    "Chai Nat": { name: "Chai Nat" },
    Chaiyaphum: { name: "Chaiyaphum" },
    Chanthaburi: { name: "Chanthaburi" },
    "Chiang Mai": { name: "Chiang Mai" },
    "Chiang Rai": { name: "Chiang Rai" },
    "Chon Buri": { name: "Chon Buri" },
    Chumphon: { name: "Chumphon" },
    Kalasin: { name: "Kalasin" },
    "Kamphaeng Phet": { name: "Kamphaeng Phet" },
    Kanchanaburi: { name: "Kanchanaburi" },
    "Khon Kaen": { name: "Khon Kaen" },
    Krabi: { name: "Krabi" },
    Lampang: { name: "Lampang" },
    Lamphun: { name: "Lamphun" },
    Loei: { name: "Loei" },
    Lopburi: { name: "Lopburi" },
    "Mae Hong Son": { name: "Mae Hong Son" },
    "Maha Sarakham": { name: "Maha Sarakham" },
    Mukdahan: { name: "Mukdahan" },
    "Nakhon Nayok": { name: "Nakhon Nayok" },
    "Nakhon Pathom": { name: "Nakhon Pathom" },
    "Nakhon Phanom": { name: "Nakhon Phanom" },
    "Nakhon Ratchasima": { name: "Nakhon Ratchasima" },
    "Nakhon Sawan": { name: "Nakhon Sawan" },
    "Nakhon Si Thammarat": { name: "Nakhon Si Thammarat" },
    Nan: { name: "Nan" },
    Narathiwat: { name: "Narathiwat" },
    "Nong Bua Lam Phu": { name: "Nong Bua Lam Phu" },
    "Nong Khai": { name: "Nong Khai" },
    Nonthaburi: { name: "Nonthaburi" },
    "Pathum Thani": { name: "Pathum Thani" },
    Pattani: { name: "Pattani" },
    Pattaya: { name: "Pattaya" },
    Phangnga: { name: "Phang Nga" },
    Phatthalung: { name: "Phatthalung" },
    Phayao: { name: "Phayao" },
    Phetchabun: { name: "Phetchabun" },
    Phetchaburi: { name: "Phetchaburi" },
    Phichit: { name: "Phichit" },
    Phitsanulok: { name: "Phitsanulok" },
    "Phra Nakhon Si Ayutthaya": { name: "Phra Nakhon Si Ayutthaya" },
    Phrae: { name: "Phrae" },
    Phuket: { name: "Phuket" },
    "Prachin Buri": { name: "Prachin Buri" },
    "Prachuap Khiri Khan": { name: "Prachuap Khiri Khan" },
    Ranong: { name: "Ranong" },
    Ratchaburi: { name: "Ratchaburi" },
    Rayong: { name: "Rayong" },
    "Roi Et": { name: "Roi Et" },
    "Sa Kaeo": { name: "Sa Kaeo" },
    "Sakon Nakhon": { name: "Sakon Nakhon" },
    "Samut Prakan": { name: "Samut Prakan" },
    "Samut Sakhon": { name: "Samut Sakhon" },
    "Samut Songkhram": { name: "Samut Songkhram" },
    Saraburi: { name: "Saraburi" },
    Satun: { name: "Satun" },
    "Sing Buri": { name: "Sing Buri" },
    Sisaket: { name: "Si Sa Ket" },
    Songkhla: { name: "Songkhla" },
    Sukhothai: { name: "Sukhothai" },
    "Suphan Buri": { name: "Suphanburi" },
    "Surat Thani": { name: "Surat Thani" },
    Surin: { name: "Surin" },
    Tak: { name: "Tak" },
    Trang: { name: "Trang" },
    Trat: { name: "Trat" },
    "Ubon Ratchathani": { name: "Ubon Ratchathani" },
    "Udon Thani": { name: "Udon Thani" },
    "Uthai Thani": { name: "Uthai Thani" },
    Uttaradit: { name: "Uttaradit" },
    Yala: { name: "Yala" },
    Yasothon: { name: "Yasothon" },
  },
  "Timor Leste": {},
  Togo: {},
  Tokelau: {},
  Tonga: {},
  "Trinidad and Tobago": {},
  Tunisia: {},
  Turkey: {},
  Turkmenistan: {},
  "Turks and Caicos Islands": {},
  Tuvalu: {},
  "United States Minor Outlying Islands": {},
  Uganda: {},
  Ukraine: {},
  "United Arab Emirates": {
    "Abu Dhabi": { name: "Abu Dhabi" },
    Ajman: { name: "Ajman" },
    Dubai: { name: "Dubai" },
    Fujairah: { name: "Fujairah" },
    "Ras al-Khaimah": { name: "Ras al-Khaimah" },
    Sharjah: { name: "Sharjah" },
    "Umm al-Quwain": { name: "Umm al-Quwain" },
  },
  "United Kingdom": {},
  "United States": {
    Alabama: { name: "Alabama" },
    Alaska: { name: "Alaska" },
    "American Samoa": { name: "American Samoa" },
    Arizona: { name: "Arizona" },
    Arkansas: { name: "Arkansas" },
    "Armed Forces Americas": { name: "Armed Forces Americas" },
    "Armed Forces Europe": { name: "Armed Forces Europe" },
    "Armed Forces Pacific": { name: "Armed Forces Pacific" },
    California: { name: "California" },
    Colorado: { name: "Colorado" },
    Connecticut: { name: "Connecticut" },
    Delaware: { name: "Delaware" },
    "District of Columbia": { name: "Washington DC" },
    "Federated States of Micronesia": { name: "Micronesia" },
    Florida: { name: "Florida" },
    Georgia: { name: "Georgia" },
    Guam: { name: "Guam" },
    Hawaii: { name: "Hawaii" },
    Idaho: { name: "Idaho" },
    Illinois: { name: "Illinois" },
    Indiana: { name: "Indiana" },
    Iowa: { name: "Iowa" },
    Kansas: { name: "Kansas" },
    Kentucky: { name: "Kentucky" },
    Louisiana: { name: "Louisiana" },
    Maine: { name: "Maine" },
    "Marshall Islands": { name: "Marshall Islands" },
    Maryland: { name: "Maryland" },
    Massachusetts: { name: "Massachusetts" },
    Michigan: { name: "Michigan" },
    Minnesota: { name: "Minnesota" },
    Mississippi: { name: "Mississippi" },
    Missouri: { name: "Missouri" },
    Montana: { name: "Montana" },
    Nebraska: { name: "Nebraska" },
    Nevada: { name: "Nevada" },
    "New Hampshire": { name: "New Hampshire" },
    "New Jersey": { name: "New Jersey" },
    "New Mexico": { name: "New Mexico" },
    "New York": { name: "New York" },
    "North Carolina": { name: "North Carolina" },
    "North Dakota": { name: "North Dakota" },
    "Northern Mariana Islands": { name: "Northern Mariana Islands" },
    Ohio: { name: "Ohio" },
    Oklahoma: { name: "Oklahoma" },
    Oregon: { name: "Oregon" },
    Palau: { name: "Palau" },
    Pennsylvania: { name: "Pennsylvania" },
    "Puerto Rico": { name: "Puerto Rico" },
    "Rhode Island": { name: "Rhode Island" },
    "South Carolina": { name: "South Carolina" },
    "South Dakota": { name: "South Dakota" },
    Tennessee: { name: "Tennessee" },
    Texas: { name: "Texas" },
    Utah: { name: "Utah" },
    Vermont: { name: "Vermont" },
    "Virgin Islands": { name: "U.S. Virgin Islands" },
    Virginia: { name: "Virginia" },
    Washington: { name: "Washington" },
    "West Virginia": { name: "West Virginia" },
    Wisconsin: { name: "Wisconsin" },
    Wyoming: { name: "Wyoming" },
  },
  Uruguay: {},
  Uzbekistan: {},
  Vanuatu: {},
  "Holy See (Vatican City State)": {},
  Venezuela: {},
  Vietnam: {},
  "Wallis And Futuna": {},
  "Western Sahara": {},
  Yemen: {},
  Zambia: {},
  Zimbabwe: {},
};

export const customerUpdate = `
mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
  customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;

export const customerErrorCode = [
  "UNIDENTIFIED_CUSTOMER",
  "CUSTOMER_DISABLED",
  "INVALID_MULTIPASS_REQUEST",
  "TOKEN_INVALID",
];

export const SHIPPING_RATES = {
  USA: 15.9,
  CANADA: 20.0,
  EUROPE: 5.1,
  AUSTRALIA: 32.25,
  REST_WORLD: 35.0,
};

export const WEB_URL = "https://website.com";

export const vendorShippingProperties = [
  { name: "usa", placeholder: "USA shipping rate in US dollars" },
  { name: "canada", placeholder: "Canada shipping rate in US dollars" },
  { name: "europe", placeholder: "Europe shipping rate in US dollars" },
  { name: "australia", placeholder: "Australia shipping rate in US dollars" },
  {
    name: "rest_of_the_world",
    placeholder: "Rest of the world shipping rate in US dollars",
  },
];

export const removeCustomerAccessData = (responseErrorCode) => {
  const isTokenInvalid = customerErrorCode.includes(responseErrorCode);
  if (isTokenInvalid) {
    localStorage.removeItem("Customer-Access-Token");
    localStorage.removeItem("Customer-Access-Token-ExpiresAt");
    localStorage.removeItem("Multipass-Token");
    localStorage.removeItem("Checkout-Id");
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem("Customer-Access-Token");
  localStorage.removeItem("Customer-Access-Token-ExpiresAt");
  localStorage.removeItem("Multipass-Token");
  localStorage.removeItem("Checkout-Id");
};

export const reactSelectStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
    paddingTop: 0,
  }),
  input: (base) => ({
    ...base,
    paddingTop: 0,
  }),
};
