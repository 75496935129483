import React, { PureComponent } from "react";

export default class EG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect width="512" height="113.775" transform="translate(0 312.888)" />
          <path
            d="M320,236.29H272a16,16,0,0,0-32,0H192c0,8.836,7.7,16,16.533,16H208a16,16,0,0,0,16,16,16,16,0,0,0,16,16h32a16,16,0,0,0,16-16,16,16,0,0,0,16-16h-.533C312.3,252.29,320,245.127,320,236.29Z"
            fill="#ff9811"
          />
        </g>
      </svg>
    );
  }
}
