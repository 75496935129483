import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Form as BSForm } from "react-bootstrap";
import { connect } from "react-redux";

import { Form } from "react-final-form";

import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import { isLogin } from "storefront/features/shared/utils";
import {
  composeValidators,
  validateMinLength,
  validateMaxLength,
} from "storefront/features/shared/components/field/utils";
import { StyledContainer, StyledLinkLogin } from "./ResetPassword.styled";

class ResetPassword extends PureComponent {
  state = { errorMsg: "" };

  handleResponsErrors = (error) => {
    this.setState({ errorMsg: error });
  };

  getResetUrl = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("reset_url");
  };

  handleSubmitForm = (values) => {
    const {
        location,
        fetchCustomerUpdateRequest,
        fetchCustomerResetPasswordRequest,
      } = this.props,
      callback = this.handleResponsErrors,
      resetUrl = this.getResetUrl(location);
    isLogin()
      ? fetchCustomerUpdateRequest({ callback, ...values })
      : fetchCustomerResetPasswordRequest({ resetUrl, callback, ...values });
  };

  render() {
    let submitDisabled = true;
    return (
      <StyledContainer>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>NEW PASSWORD</h1>
            <p>Please enter your new password:</p>
            <Form
              validate={(values) => {
                values.password && values.confirm === values.password
                  ? (submitDisabled = false)
                  : (submitDisabled = true);
                const errors = {};
                if (values.confirm !== values.password) {
                  errors.confirm = "Must match";
                }
                return errors;
              }}
              onSubmit={this.handleSubmitForm}
              render={({ handleSubmit, dirtySinceLastSubmit }) => (
                <BSForm onSubmit={handleSubmit}>
                  <BSForm.Group>
                    <Field
                      name="password"
                      type="password"
                      placeholder="Password"
                      validate={composeValidators(
                        validateMinLength(5),
                        validateMaxLength(40)
                      )}
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="confirm"
                      type="password"
                      placeholder="Confirm"
                      validate={composeValidators(
                        validateMinLength(5),
                        validateMaxLength(40)
                      )}
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    {this.state.errorMsg && !dirtySinceLastSubmit && (
                      <p className="invalid-feedback d-block">
                        {this.state.errorMsg}
                      </p>
                    )}
                    <Button
                      className="w-100"
                      type="submit"
                      variant="primary"
                      disabled={submitDisabled}
                    >
                      Save
                    </Button>
                  </BSForm.Group>
                </BSForm>
              )}
            />
            <p className="form-footer-info">
              Remember your password?{" "}
              <StyledLinkLogin to="/account/login">
                Back to login
              </StyledLinkLogin>
            </p>
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  fetchCustomerResetPasswordRequest: (values) => {
    dispatch(actions.fetchCustomerResetPasswordRequest(values));
  },
  fetchCustomerUpdateRequest: (values) => {
    dispatch(actions.fetchCustomerUpdateRequest(values));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));
