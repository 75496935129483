import { createSelector } from "reselect";

export const productSelector = (state) => {
  return state.getIn(["productsSettingsReducer", "products"]);
};

export const getProducts = createSelector(productSelector, (products) => {
  return products.get("products");
});

export const getPageParams = createSelector(productSelector, (products) =>
  products.get("page_params")
);
