import React, { PureComponent } from "react";

export default class TM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#496e2d"
          />
          <g>
            <path
              d="M357.208,183.679a51.951,51.951,0,0,0-21.281-61.536,51.941,51.941,0,1,1-34.266,95.507A51.95,51.95,0,0,0,357.208,183.679Z"
              fill="#f0f0f0"
            />
            <path
              d="M310.637,115.729l-7.3,9.427-11.22-4.026,6.712,9.852-7.3,9.427,11.443-3.338,6.712,9.852.361-11.914L321.5,131.67l-11.221-4.025Z"
              fill="#f0f0f0"
            />
            <path
              d="M330.338,146.448l-7.3,9.427-11.221-4.026,6.712,9.852-7.3,9.427,11.443-3.339,6.712,9.853.36-11.915,11.445-3.34-11.221-4.024Z"
              fill="#f0f0f0"
            />
            <path
              d="M275.9,126.916l-7.3,9.427-11.219-4.024,6.711,9.851-7.3,9.426,11.443-3.338,6.712,9.852.361-11.915,11.444-3.337-11.221-4.027Z"
              fill="#f0f0f0"
            />
            <path
              d="M275.1,162.1l-7.3,9.427-11.221-4.026,6.712,9.852-7.3,9.427,11.444-3.338,6.711,9.852.362-11.915,11.443-3.338-11.22-4.026Z"
              fill="#f0f0f0"
            />
            <path
              d="M308.585,174.118l-7.3,9.427-11.22-4.026,6.712,9.853-7.3,9.426,11.444-3.338,6.712,9.852L308,193.4l11.443-3.338-11.219-4.025Z"
              fill="#f0f0f0"
            />
          </g>
          <rect
            width="89.043"
            height="341.326"
            transform="translate(83.478 85.337)"
            fill="#d80027"
          />
          <path
            d="M117.458,175.191l-14.908-11.1V148.381l14.908-11.1h21.084l14.908,11.1v15.705l-14.908,11.1Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M128,137.276H117.458l-14.908,11.1v7.678H128Z"
              fill="#ff9811"
            />
            <path
              d="M128,175.191h10.542l14.908-11.1v-8.012H128Z"
              fill="#ff9811"
            />
          </g>
          <path
            d="M117.458,374.725,102.55,363.619v-15.7l14.908-11.105h21.084l14.908,11.105v15.7l-14.908,11.106Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M128,336.81H117.458L102.55,347.915v7.678H128Z"
              fill="#ff9811"
            />
            <path
              d="M128,374.725h10.542l14.908-11.106v-8.011H128Z"
              fill="#ff9811"
            />
          </g>
          <g>
            <path
              d="M117.458,274.957,102.55,263.852v-15.7l14.908-11.106h21.084l14.908,11.106v15.7l-14.908,11.105Z"
              fill="#496e2d"
            />
            <path
              d="M153.971,299.391h-6.493V292.9H136.067L128,284.83l-8.067,8.068h-11.41v6.493h-6.493v12.986h6.493v6.492h11.41L128,326.938l8.068-8.069h11.41v-6.492h6.493Z"
              fill="#496e2d"
            />
            <path
              d="M153.971,199.623h-6.493v-6.492H136.067L128,185.062l-8.067,8.069h-11.41v6.492h-6.493v12.986h6.493V219.1h11.41l8.068,8.07,8.068-8.07h11.41v-6.492h6.493Z"
              fill="#496e2d"
            />
          </g>
          <g>
            <rect
              width="14.84"
              height="14.84"
              transform="translate(120.576 248.576)"
              fill="#d80027"
            />
            <circle
              cx="7.421"
              cy="7.421"
              r="7.421"
              transform="translate(120.579 198.692)"
              fill="#d80027"
            />
            <circle
              cx="7.421"
              cy="7.421"
              r="7.421"
              transform="translate(120.579 298.466)"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
