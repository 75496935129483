import { isEmpty } from "lodash";
import { getSearchParams } from "storefront/features/shared/utils";

const getPagination = (props) => {
  const { currentPage, totalPages, activeClass, defaultClass } = props;
  const showMax = 5;
  const startAt = setStartAt(currentPage, showMax);
  const endAt = setEndAt(currentPage, totalPages);
  let arr = [];

  for (let i = startAt; i <= endAt; i++) {
    arr.push({
      page: i,
      text: i,
      isCurrent: isCurrent(i, currentPage),
      class: isCurrent(i, currentPage) ? activeClass : defaultClass,
    });
  }

  arr = addEllipsis(arr, props);
  arr = addNext(arr, props);
  arr = addPrev(arr, props);

  return arr;
};
const setStartAt = (currentPage, showMax) => {
  let startAt;
  if (currentPage < showMax) {
    startAt = 1;
  } else {
    startAt = currentPage - Math.floor(showMax / 2);
  }
  return startAt;
};

const setEndAt = (currentPage, totalPages) => {
  let endAt;
  if (totalPages - currentPage >= 2) {
    endAt = currentPage + 2;
  } else {
    endAt = totalPages;
  }
  return endAt;
};

export const isCurrent = (page, currentPage) => currentPage === page;

const addLeftEllipsis = (arr, props, threeDotsObj) => {
  const { defaultClass } = props;
  const isFirstDots = arr[0].page > 2;
  if (isFirstDots) {
    arr.unshift(threeDotsObj);
    arr.unshift({
      page: 1,
      text: 1,
      isCurrent: false,
      class: defaultClass,
    });
  }
  return arr;
};
const addRightEllipsis = (arr, props, threeDotsObj) => {
  const lastPageNumber = arr[arr.length - 1].page;
  const { totalPages, defaultClass } = props;
  const pageItemObj = {
    page: totalPages,
    text: totalPages,
    isCurrent: false,
    class: defaultClass,
  };
  const isRightSibling = totalPages - lastPageNumber <= 1;
  const isLastTotalNumber = lastPageNumber !== totalPages;
  if (!isRightSibling) {
    arr.push.apply(arr, [threeDotsObj, pageItemObj]); // eslint-disable-line no-useless-call
  }
  if (isRightSibling && isLastTotalNumber) {
    arr.push(pageItemObj);
  }
  return arr;
};

const addEllipsis = (arr, props) => {
  const { disabledClass } = props;
  const threeDotsObj = {
    page: false,
    text: "...",
    isCurrent: false,
    class: disabledClass,
  };
  arr = addLeftEllipsis(arr, props, threeDotsObj);
  arr = addRightEllipsis(arr, props, threeDotsObj);
  return arr;
};

const addNext = (arr, props) => {
  const { currentPage, totalPages, disabledClass, nextText } = props;
  const nextObj = {
    page: currentPage + 1,
    text: nextText,
    isCurrent: false,
    class: currentPage + 1 > totalPages && disabledClass,
  };
  arr.push(nextObj);
  return arr;
};

const addPrev = (arr, props) => {
  const { currentPage, disabledClass, prevText } = props;
  const prevObj = {
    page: currentPage - 1,
    text: prevText,
    isCurrent: false,
    class: currentPage - 1 < 1 && disabledClass,
  };
  arr.unshift(prevObj);
  return arr;
};

export const getSearchValue = (locationSearch, currentPage) => {
  const pageNumber = getSearchParams(locationSearch, "page");
  if (!isEmpty(locationSearch) && !isEmpty(pageNumber)) {
    return locationSearch.replace(`page=${pageNumber}`, `page=${currentPage}`);
  } else if (!isEmpty(locationSearch) && isEmpty(pageNumber)) {
    return `${locationSearch}&page=${currentPage}`;
  } else {
    return `?page=${currentPage}`;
  }
};

export { getPagination };
