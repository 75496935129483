import React, { PureComponent } from "react";

export default class CZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <path d="M512,256V426.663H0L215.185,256Z" fill="#d80027" />
          <path d="M512,85.337V256H215.185L0,85.337Z" fill="#f0f0f0" />
        </g>
      </svg>
    );
  }
}
