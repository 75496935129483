import React, { PureComponent } from "react";

export default class MK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#ffda44"
          />
          <g>
            <path
              d="M383.875,426.662,256,252.286l26.827,174.376Z"
              fill="#d80027"
            />
            <path
              d="M133.565,85.33,256,252.286,230.314,85.33Z"
              fill="#d80027"
            />
            <path
              d="M229.171,426.662,256,252.286,128.124,426.662Z"
              fill="#d80027"
            />
            <path d="M0,85.33V212.9l256,39.386L28.333,85.33Z" fill="#d80027" />
            <path d="M0,426.662H18.212L256,252.286,0,291.67Z" fill="#d80027" />
            <path d="M256,252.286,512,212.9V85.33H483.669Z" fill="#d80027" />
            <path
              d="M281.686,85.33,256,252.286,378.434,85.33Z"
              fill="#d80027"
            />
            <path
              d="M512,426.662V291.671L256,252.286,493.787,426.662Z"
              fill="#d80027"
            />
            <circle
              cx="59.359"
              cy="59.359"
              r="59.359"
              transform="translate(196.641 192.931)"
              fill="#d80027"
            />
          </g>
          <circle
            cx="44.522"
            cy="44.522"
            r="44.522"
            transform="translate(211.478 207.768)"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
