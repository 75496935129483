import React, { PureComponent } from "react";

export default class KZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <path
            d="M352,265.768H160c0,13.255,11.544,24,24.8,24H184a24,24,0,0,0,24,24,24,24,0,0,0,24,24h48a24,24,0,0,0,24-24,24,24,0,0,0,24-24h-.8C340.454,289.767,352,279.023,352,265.768Z"
            fill="#ffda44"
          />
          <path
            d="M322.462,234.384a66.462,66.462,0,0,1-132.924,0"
            fill="#338af3"
          />
          <path
            d="M321.882,228.163l-26.92,10.9L309.3,261.5l-29.232-4.814-3.7,25.415L256,263.412,235.638,282.1l-3.7-25.415L202.7,261.5l14.334-22.44-26.919-10.9,26.92-10.9L202.7,194.825l29.231,4.813,3.705-25.417L256,192.915l20.362-18.694,3.7,25.417,29.233-4.813-14.335,22.44Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
