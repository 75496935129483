import React, { PureComponent } from "react";

export default class HN extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#338af3"
            />
            <path
              d="M203.491,208.511l4.421,13.6h14.3l-11.574,8.408,4.421,13.6-11.573-8.408-11.572,8.408,4.421-13.6-11.574-8.408h14.3Z"
              fill="#338af3"
            />
            <path
              d="M203.491,267.872l4.421,13.606h14.3l-11.574,8.406,4.421,13.605-11.573-8.408-11.572,8.408,4.421-13.605-11.574-8.406h14.3Z"
              fill="#338af3"
            />
            <path
              d="M308.509,208.511l4.42,13.6h14.305l-11.574,8.408,4.421,13.6-11.572-8.408-11.573,8.408,4.421-13.6-11.574-8.408h14.305Z"
              fill="#338af3"
            />
            <path
              d="M308.509,267.872l4.42,13.606h14.305l-11.574,8.406,4.421,13.605-11.572-8.408-11.573,8.408,4.421-13.605-11.574-8.406h14.305Z"
              fill="#338af3"
            />
            <path
              d="M256,238.191l4.421,13.605h14.3L263.152,260.2l4.421,13.6L256,265.4l-11.573,8.408,4.421-13.6L237.275,251.8h14.3Z"
              fill="#338af3"
            />
          </g>
        </g>
      </svg>
    );
  }
}
