import React, { PureComponent } from "react";

export default class DJ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <path d="M512,85.331v166.69L0,256.173V85.331Z" fill="#338af3" />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#f0f0f0" />
          <path
            d="M94.608,211.478l11.05,34.01h35.764L112.49,266.51l11.05,34.012L94.608,279.5l-28.933,21.02L76.728,266.51,47.795,245.488H83.557Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
