import React, { PureComponent } from "react";

export default class GG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#f0f0f0"
          />
          <path
            d="M288,85.33H224V224H0v64H224V426.662h64V288H512V224H288Z"
            fill="#d80027"
          />
          <path
            d="M319.3,265.735l14.609,9.739V236.518L319.3,246.257H265.739V192.692l9.739-14.609H236.522l9.739,14.609v53.565H192.7l-14.609-9.739v38.956l14.609-9.739h53.565V319.3l-9.739,14.609h38.956L265.739,319.3V265.735Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
