import React, { PureComponent } from "react";
import { Form } from "react-bootstrap";

export class Discount extends PureComponent {
  handleSubmitForm = () => {};

  handleChange = (e) => {
    const { handleDiscountChange } = this.props;
    handleDiscountChange(e.target.value);
  };

  render() {
    return (
      <Form>
        <Form.Group className="m-0">
          <Form.Control
            name="discount"
            type="text"
            placeholder="Enter discount code"
            onChange={this.handleChange}
            data-cy="cart-discount-input"
          />
        </Form.Group>
      </Form>
    );
  }
}

export default Discount;
