import styled from "styled-components";
import { Image } from "react-bootstrap";
import { device } from "../../shared/styles/mediaQuery";
import { Link } from "react-router-dom";

export const StyledImage = styled(Image)`
  width: 45px;
  height: 45px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  border: 1px solid black;
  width: 45px;
  height: 45px;
  padding-top: 8px;
  cursor: pointer;
`;

export const DetailsButtonStyled = styled(Link)`
  margin-left: none;
  @media ${device.mobileL} {
    margin-left: 5px;
  }
`;
