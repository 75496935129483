import React, { PureComponent } from "react";
import { compose } from "redux";
import { flattenDeep } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import withImmutablePropsToJS from "with-immutable-props-to-js";

import ModalAboutAutoBidding from "./ModalAboutAutoBidding";
import ProductImage from "./ProductImage";
import {
  calculateNextBid,
  getImageData,
  isExpired,
  getCurrentUserBidValues,
  moneyFormat,
  RING_SIZING,
  isTagExist,
} from "storefront/features/shared/utils";
import {
  Countdown,
  UnregisteredUserModal,
  RingSizingModal,
  ProductVariantPicker,
} from "storefront/features/shared";
import BidInput from "./BidInput";
import {
  StyledTitle,
  StyledCurrentBidInfo,
  StyledSpan,
  StyledP,
  StyledImage,
  IconWrapper,
  StyledFlag,
  StyledVideoColumn,
  StyledLabel,
} from "./QuickViewProduct.styled";

import Icon from "storefront/features/shared/icons";
import {
  getWinnerNickname,
  amountWithCents,
  amountWithoutCents,
  getFirstAvailableVariant,
  getVariantDataByOptions,
  getProductType,
  AUCTION_FORMAT,
  BUY_NOW_FORMAT,
} from "../utils";
import Buttons from "./Buttons";
import BidButtons from "./BidButtons";
import ProductRetailPrice from "./ProductRetailPrice";
import SoldByLink from "./SoldByLink";
import withPusherSubscription from "storefront/features/shared/components/SubscriptionPusher";
import * as actions from "../actions";
import withRedirectBlockedVendor from "storefront/features/shared/components/RedirectBlockedVendor";
import ProductVideo from "./ProductVideo";

class QuickViewProduct extends PureComponent {
  state = {
    modalOpen: false,
    modalResizeOpen: false,
    bidValue: 0,
    showAutoBidBtn: false,
    currentBid: 0,
    activeOptions: [],
    isShowModal: false,
  };

  componentDidMount() {
    const currentUserBidValues = getCurrentUserBidValues(this.props);
    const {
      product: { current_bid, variants },
      setFlagQuickViewIsOpened,
    } = this.props;
    setFlagQuickViewIsOpened(true);
    const { activeOptions } = getFirstAvailableVariant(variants);
    this.setState({
      bidValue: currentUserBidValues[0],
      showAutoBidBtn: currentUserBidValues[1],
      currentBid: current_bid,
      activeOptions,
    });
  }

  componentDidUpdate(prevProps) {
    const [bidValue, showAutoBidBtn] = getCurrentUserBidValues(this.props);
    const {
      product: { current_bid },
    } = this.props;
    const [prevBidValue, prevShowAutoBidBtn] = getCurrentUserBidValues(
      prevProps
    );

    if (bidValue !== prevBidValue || showAutoBidBtn !== prevShowAutoBidBtn) {
      this.setState({ bidValue, showAutoBidBtn, currentBid: current_bid });
    }
  }

  componentWillUnmount() {
    const { setFlagQuickViewIsOpened } = this.props;
    setFlagQuickViewIsOpened(false);
  }

  onModalOpen = () => this.setState({ modalOpen: true });

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleModalResizeOpen = () => {
    this.setState({ modalResizeOpen: true });
  };

  handleModalResizeClose = () => {
    this.setState({ modalResizeOpen: false });
  };

  createMarkup = () => {
    const {
      product: { description_html },
    } = this.props;
    return { __html: description_html };
  };

  handleChange = (e) => {
    const {
      product: { current_bid, bids_count },
    } = this.props;
    const bidValue = amountWithCents(e.target.value);

    if (bidValue <= calculateNextBid(current_bid, bids_count)) {
      this.setState({ bidValue, showAutoBidBtn: false });
    } else {
      this.setState({ bidValue, showAutoBidBtn: true });
    }
  };

  productOptions = (options, classes = "") => (
    <div className={`${classes} options-list`}>
      <small className="text-secondary">Available variants</small>
      {options.map(({ name, values }) => (
        <p key={name} className="mb-0">
          <b>{name}: </b>
          {values?.join(", ")}
        </p>
      ))}
      <p className="text-secondary mt-2">
        Win an auction, select available options in the shopping cart.
      </p>
    </div>
  );

  getAvailableOptions(variants, options) {
    const result = [];
    const availableVariants = variants?.filter(
      (x) => x.quantity > 0 && x.selected_options
    );

    options.forEach((option) => {
      const name = option?.name;
      const values = this.getAvailableOptionValues(option, availableVariants);
      if (name && values?.length) {
        result.push({ name, values });
      }
    });
    return result;
  }

  getAvailableOptionValues(option, availableVariants) {
    const result = [];

    if (!option.values) {
      return result;
    }

    option.values.forEach((value) => {
      const valueHasVariant = availableVariants?.find(
        (x) => x.selected_options[option?.name] === value
      );
      if (valueHasVariant) {
        result.push(value);
      }
    });

    return result;
  }

  handleOptionsChange = (choosedOptions) => {
    const {
      product: { variants },
    } = this.props;
    const { selectedOptions } = getVariantDataByOptions(
      variants,
      choosedOptions
    );
    this.setState({
      activeOptions: selectedOptions,
    });
  };

  handleModalAboutBidding = (status) => {
    this.setState({
      isShowModal: status,
    });
  };

  checkOptions = () => {
    const {
      product: { options, variants },
    } = this.props;
    const isOptionsContainsDefault = (array) => {
      return flattenDeep(array).find((item) => item.includes("Default"))
        ? true
        : false;
    };
    const optionsValues = options.map(({ values }) => values);
    if (variants.length === 1 || isOptionsContainsDefault(optionsValues)) {
      return false;
    }
    return true;
  };

  render() {
    const {
      vendor,
      product,
      product: {
        images,
        title,
        end_at,
        shopify_product_id,
        variants,
        options,
        tags,
        winning_customer_avatar,
        vendor_name,
        vendor_slug,
        video_src,
        bids_count,
      },
    } = this.props;
    const {
      modalOpen,
      modalResizeOpen,
      showAutoBidBtn,
      bidValue,
      currentBid,
      activeOptions,
    } = this.state;
    const {
      id,
      productPrice,
      productRetailPrice,
      variantAvailable,
    } = getVariantDataByOptions(variants, activeOptions);
    const FIRST_IMAGE_INDEX = 0;
    const closed = isExpired(end_at);
    const { countryCode, winnerName } = getWinnerNickname(product);
    const isAuction = getProductType(product) === AUCTION_FORMAT;
    const isRingSizingAvailable = isAuction && isTagExist(tags, RING_SIZING);
    const showOptionsOnAuction = options && isAuction && this.checkOptions();
    const showOptionsDropdown =
      this.checkOptions() && getProductType(product) === BUY_NOW_FORMAT;
    const availableOptions = this.getAvailableOptions(variants, options);

    return (
      <>
        <Container className="p-0 p-sm-2" data-cy="modal-quick-view-body">
          {video_src && (
            <Row>
              <Col className="text-center">
                <StyledTitle>{title}</StyledTitle>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={5} className="text-center">
              <ProductImage
                shadow={true}
                {...getImageData(images, FIRST_IMAGE_INDEX)}
                images={images}
              />
            </Col>
            {video_src ? (
              <StyledVideoColumn xs={12} md={7}>
                <ProductVideo videoSrc={video_src} />
              </StyledVideoColumn>
            ) : (
              <Col xs={12} md={7}>
                <StyledTitle>{title}</StyledTitle>
                {showOptionsOnAuction &&
                  this.productOptions(availableOptions, "d-md-block d-none")}
                {isRingSizingAvailable && (
                  <Button
                    onClick={this.handleModalResizeOpen}
                    variant="link"
                    className="px-0 mt-3 d-md-block d-none"
                  >
                    Ring resizing available.
                  </Button>
                )}
              </Col>
            )}
          </Row>
          <Row className="d-flex justify-content-between align-items-center mt-3">
            <Col xs={12} md={9}>
              {showOptionsOnAuction &&
                video_src &&
                this.productOptions(availableOptions, "d-md-block d-none")}
            </Col>
            <Col xs={12} md={3}>
              {isRingSizingAvailable && video_src && (
                <Button
                  onClick={this.handleModalResizeOpen}
                  variant="link"
                  className="px-0 mt-3 d-md-block d-none"
                >
                  Ring resizing available.
                </Button>
              )}
            </Col>
          </Row>
          {end_at && (
            <Row>
              <Col className="w-100 col-12 m-0 p-o">
                <hr className="mt-2 mb-2 mt-md-3 mb-md-3" />
              </Col>
              <Col>
                <StyledP className="counter">
                  <Countdown endDate={end_at} />
                </StyledP>
              </Col>
              <Col>
                {winnerName && (
                  <StyledP className="text-right">
                    <span className="mr-1">Winning bidder:</span>
                    {winning_customer_avatar ? (
                      <StyledImage
                        src={winning_customer_avatar}
                        roundedCircle
                      />
                    ) : (
                      <IconWrapper className="rounded-circle d-inline-flex justify-content-center">
                        <Icon type="login" size={10} />
                      </IconWrapper>
                    )}
                    {winnerName}
                    {countryCode && (
                      <StyledFlag>
                        <Icon type={`flag${countryCode}`} />
                      </StyledFlag>
                    )}
                  </StyledP>
                )}
              </Col>
            </Row>
          )}
          <hr className="mt-2 mb-3 mt-md-3" />
          <Row className="justify-content-between m-3">
            {isAuction && (
              <StyledCurrentBidInfo>
                Current Bid: <b>{moneyFormat(currentBid, true)}</b>
                {bids_count > 0 && (
                  <div>
                    <span>
                      Bids placed: <b>{bids_count}</b>
                    </span>
                  </div>
                )}
              </StyledCurrentBidInfo>
            )}
            <StyledSpan>
              <ProductRetailPrice retailPrice={productRetailPrice} />
            </StyledSpan>
            <div>
              <StyledSpan>
                <b>Sold by</b>
              </StyledSpan>
              <SoldByLink vendorSlug={vendor_slug} vendorName={vendor_name} />
            </div>
          </Row>
          {isAuction && (
            <Row className="d-flex flex-column flex-lg-row justify-content-between ml-1 mr-1">
              <div
                className="
                  d-flex align-items-center
                  text-white order-2 order-lg-1
                  justify-content-around
                "
              >
                <StyledLabel className="bg-secondary">NO RESERVE</StyledLabel>
                <StyledLabel className="bg-secondary">
                  HIGHEST BID WINS
                </StyledLabel>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between order-1 order-lg-2">
                <BidInput
                  handleChange={this.handleChange}
                  autoBidValue={amountWithoutCents(bidValue)}
                  minValue={amountWithoutCents(
                    getCurrentUserBidValues(this.props)?.[0]
                  )}
                />
                <div className="d-flex justify-content-between mt-2 mt-md-0">
                  <BidButtons
                    vendor={vendor}
                    showAutoBidBtn={showAutoBidBtn}
                    closed={closed}
                    shopifyProductId={shopify_product_id}
                    autoBidValue={amountWithoutCents(bidValue)}
                    product={product}
                    onModalOpen={this.onModalOpen}
                    width="100%"
                    padding="0"
                  />
                  <Button
                    variant="link"
                    onClick={() => this.handleModalAboutBidding(true)}
                  >
                    <Icon type="questionMarkIcon" />
                  </Button>
                </div>
              </div>
            </Row>
          )}
          <Row className="align-items-center">
            {!isAuction && (
              <>
                {showOptionsDropdown && (
                  <ProductVariantPicker
                    options={options}
                    variantAvailable={variantAvailable}
                    activeOptions={activeOptions}
                    handleOptionsChange={this.handleOptionsChange}
                  />
                )}
                <Col className="text-right">
                  <Buttons
                    vendor={vendor}
                    product={product}
                    variantId={id}
                    productPrice={productPrice}
                    buyNowDisabled={!variantAvailable}
                    buttonType={getProductType(product)}
                  />
                </Col>
              </>
            )}
          </Row>
          <hr className="mt-3 mb-2 d-block d-md-none" />
          {showOptionsOnAuction && (
            <Row className="d-block d-md-none">
              <Col>{this.productOptions(availableOptions)}</Col>
            </Row>
          )}
          {isRingSizingAvailable && (
            <Button
              onClick={this.handleModalResizeOpen}
              variant="link"
              className="px-0 my-1 d-block d-md-none"
            >
              Ring resizing available.
            </Button>
          )}
          <hr className="mt-2 mb-2 mt-md-3 mb-md-3" />
          <Row>
            <Col xs={12}>
              <div dangerouslySetInnerHTML={this.createMarkup()} />
            </Col>
          </Row>
        </Container>
        {modalOpen && (
          <UnregisteredUserModal
            product={product}
            onClose={this.handleModalClose}
          />
        )}
        {modalResizeOpen && (
          <RingSizingModal onClose={this.handleModalResizeClose} />
        )}
        <ModalAboutAutoBidding
          show={this.state.isShowModal}
          onClose={this.handleModalAboutBidding}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFlagQuickViewIsOpened: (value) => {
    dispatch(actions.setFlagQuickViewIsOpened(value));
  },
});

export { QuickViewProduct };

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS,
  withPusherSubscription,
  withRedirectBlockedVendor
)(QuickViewProduct);
