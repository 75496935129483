import React, { PureComponent } from "react";

export default class AR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#338af3"
            />
          </g>
          <path
            d="M296.809,256l-16.676,7.844,8.88,16.149-18.107-3.464-2.294,18.291L256,281.367,243.388,294.82l-2.294-18.291-18.107,3.463,8.879-16.15L215.191,256l16.676-7.844-8.88-16.148,18.106,3.463,2.295-18.291L256,230.633l12.612-13.453,2.294,18.291,18.107-3.463-8.879,16.149Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
