export const FETCH_PRODUCT_REQUEST = "quick_view/FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_REQUEST_RESULT =
  "quick_view/FETCH_PRODUCT_REQUEST_RESULT";

export const UPDATE_QUICK_VIEW_PRODUCT = "quick_view/UPDATE_QUICK_VIEW_PRODUCT";
export const SET_FLAG_UPDATE_PRODUCT_GRID =
  "product_grid/SET_FLAG_UPDATE_PRODUCT_GRID";

export const QUICK_VIEW_IS_OPENED = "quick_view/QUICK_VIEW_IS_OPENED";

export function fetchProductRequest(params) {
  return { type: FETCH_PRODUCT_REQUEST, payload: params };
}

export function fetchProductRequestResult(response) {
  return { type: FETCH_PRODUCT_REQUEST_RESULT, payload: response };
}

export function updateQuickViewProduct(params) {
  return { type: UPDATE_QUICK_VIEW_PRODUCT, payload: params };
}

export function setFlagQuickViewIsOpened(value) {
  return { type: QUICK_VIEW_IS_OPENED, payload: value };
}

export function setFlagUpdateProductGrid(value) {
  return { type: SET_FLAG_UPDATE_PRODUCT_GRID, payload: value };
}
