import React, { PureComponent } from "react";

export default class FK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#0052b4"
          />
          <path
            d="M413.681,293.1v7.421H354.318V293.1h-22.26v29.682H346.9V330.2h74.2v-7.419h14.841V293.1Z"
            fill="#ffda44"
          />
          <path
            d="M332.058,181.793v81.623c0,39.759,51.942,51.942,51.942,51.942s51.942-12.182,51.942-51.942V181.793Z"
            fill="#338af3"
          />
          <g>
            <path
              d="M384,242.675c-12.985,0-12.985,11.873-25.97,11.873s-12.985-11.873-25.97-11.873v20.777c12.985,0,12.985,11.873,25.97,11.873s12.985-11.873,25.97-11.873,12.986,11.873,25.973,11.873,12.986-11.873,25.973-11.873V242.675c-12.986,0-12.986,11.873-25.973,11.873S396.984,242.675,384,242.675Z"
              fill="#f3f3f3"
            />
            <path
              d="M384,201.085c-12.985,0-12.985,11.873-25.97,11.873s-12.985-11.873-25.97-11.873v20.777c12.985,0,12.985,11.873,25.97,11.873s12.985-11.873,25.97-11.873,12.986,11.873,25.973,11.873,12.986-11.873,25.973-11.873V201.085c-12.986,0-12.986,11.873-25.973,11.873S396.984,201.085,384,201.085Z"
              fill="#f3f3f3"
            />
          </g>
          <path
            d="M256,85.333v30.553l-45.167,25.1H256v59.359H196.9L256,233.179V256H229.32l-73.494-40.827V256H100.174V207.423L12.744,256H0V225.442l45.167-25.1H0V140.985H59.1L0,108.139V85.333H26.68l73.494,40.827V85.333h55.652v48.573l87.43-48.573Z"
            fill="#f0f0f0"
          />
          <path
            d="M144,85.33H112v69.332H0v32H112V256h32V186.662H256v-32H144Z"
            fill="#d80027"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#0052b4"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#d80027"
            />
            <path
              d="M71.846,200.344,0,240.259V256H0l100.174-55.652Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#0052b4"
          />
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
              fill="#d80027"
            />
            <path
              d="M184.154,140.982,256,101.067V85.33h0L155.826,140.982Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
