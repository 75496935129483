import React, { PureComponent } from "react";

export default class GP extends PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" height="13" viewBox="0 0 512 307">
        <rect width="512" height="307" fill="#078930" />
        <rect
          width="512"
          height="231"
          transform="translate(0 38)"
          fill="#fff"
        />
        <rect
          width="512"
          height="201"
          transform="translate(0 53)"
          fill="#da121a"
        />
        <path
          d="M45.677-7.186,78.13,92.7-6.835,30.965H98.188L13.223,92.7Z"
          transform="translate(64.75 110.91)"
          fill="#fcdd09"
        />
      </svg>
    );
  }
}
