import React, { PureComponent } from "react";

export default class MR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#496e2d"
          />
          <g>
            <path
              d="M256,298.851a96.016,96.016,0,0,1-93.767-75.429,96,96,0,1,0,187.534,0A96.016,96.016,0,0,1,256,298.851Z"
              fill="#ffda44"
            />
            <path
              d="M256,171.994l8.935,27.5h28.918l-23.394,17,8.936,27.5L256,227l-23.394,17,8.937-27.5-23.394-17h28.915Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
