import { createSelector } from "reselect";

export const productSelector = (state) => {
  return state.getIn(["searchReducer", "products"]);
};

export const getSearchProducts = createSelector(productSelector, (products) => {
  return products.get("products");
});

export const searchSelector = (state) => state.getIn(["searchReducer"]);

export const getSearchError = createSelector(searchSelector, (searchSelector) =>
  searchSelector.get("error")
);

export const getSearchPageParams = createSelector(searchSelector, (products) =>
  products.getIn(["products", "page_params"])
);

export const dropDownProductSelector = (state) => {
  return state.getIn(["searchReducer", "dropDownProduct"]);
};

export const getDropDownSearchProducts = createSelector(
  dropDownProductSelector,
  (dropDownProducts) => {
    return dropDownProducts.get("products");
  }
);
