import React, { PureComponent } from "react";
import { Button, Col, Form as BSForm } from "react-bootstrap";
import { OnChange } from "react-final-form-listeners";

import { Field } from "storefront/features/shared";
import { validateRequired } from "storefront/features/shared/components/field/utils";

export class ProductOptions extends PureComponent {
  state = {
    availableOptions: ["Size", "Color", "Material", "Style", "Title"],
    option: "",
  };

  addOptionValue = (value) => {
    const { addOptionValues, positionNumber } = this.props;
    const optionValue = value.replace(/,\s*$/, "");
    addOptionValues(optionValue, positionNumber);
  };

  removeProductOption = (e) => {
    const { removeOption, positionNumber } = this.props;
    removeOption(positionNumber);
  };

  handleChangeOption = (e) => {
    const { addOptionKeys, positionNumber } = this.props;
    const option = e.target.value;
    this.setState({
      option,
    });
    addOptionKeys(option, positionNumber);
  };

  render() {
    const { availableOptions, option } = this.state;
    const { optionValue, optionKey, index } = this.props;

    return (
      <BSForm.Row className="mx-0 mb-1" key={index} data-cy="option-row">
        <Col className="col-3 pl-0 mt-1">
          <BSForm.Group className="mb-0">
            <BSForm.Control
              as="select"
              value={option || optionKey}
              onChange={this.handleChangeOption}
              name={`optionKey-${index}`}
              data-cy="option-name"
            >
              {availableOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </BSForm.Control>
          </BSForm.Group>
        </Col>
        <Col className="col-6 mt-1">
          <BSForm.Group className="mb-0">
            <Field
              name={`optionValue-${index}`}
              type="text"
              placeholder="Separate options with a comma"
              initialValue={optionValue}
              validate={validateRequired}
              data-cy="option-value"
            />
            <OnChange name={`optionValue-${index}`}>
              {(value) => {
                this.addOptionValue(value);
              }}
            </OnChange>
          </BSForm.Group>
        </Col>
        <Col className="col-3 pr-0 text-right mb-1">
          <Button
            variant="primary"
            onClick={this.removeProductOption}
            data-cy="remove-option-btn"
          >
            Remove
          </Button>
        </Col>
      </BSForm.Row>
    );
  }
}

export default ProductOptions;
