import React, { PureComponent } from "react";
import FooterNav from "./FooterNav";
import { FooterStyled } from "./Footer.style";
import FooterSocialIcons from "./FooterSocialIcons";
import FooterPromo from "./FooterPromo";
import BuiltBy from "./BuiltBy";

export default class Footer extends PureComponent {
  render() {
    return (
      <FooterStyled>
        <FooterSocialIcons />
        <FooterNav />
        <FooterPromo />
        <BuiltBy />
      </FooterStyled>
    );
  }
}
