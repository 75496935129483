import React, { PureComponent } from "react";

export default class NU extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#ffda44"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.33H112v69.333H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
          </g>
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,85.329v15.083l57.377,38.251H80Z" fill="#d80027" />
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.083l-57.377,38.251H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
          <g>
            <path d="M0,233.376V202.663H46.069Z" fill="#2e52b2" />
            <path d="M96,255.994V207.082L22.628,255.994Z" fill="#2e52b2" />
          </g>
          <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          <g>
            <path d="M256,233.376V202.663H209.931Z" fill="#2e52b2" />
            <path d="M160,255.994V207.082l73.372,48.912Z" fill="#2e52b2" />
            <circle
              cx="22.627"
              cy="22.627"
              r="22.627"
              transform="translate(105.373 148.033)"
              fill="#2e52b2"
            />
          </g>
          <g>
            <path
              d="M128,154.663l3.97,12.222h12.854l-10.4,7.556,3.971,12.222L128,179.109l-10.4,7.554,3.972-12.222-10.4-7.556h12.853Z"
              fill="#ffda44"
            />
            <path
              d="M128,219.8l2.364,7.278h7.653l-6.19,4.5,2.364,7.278-6.191-4.5-6.191,4.5,2.364-7.278-6.19-4.5h7.652Z"
              fill="#ffda44"
            />
            <path
              d="M128,102.469l2.364,7.278h7.653l-6.19,4.5,2.364,7.278-6.191-4.5-6.191,4.5,2.364-7.278-6.19-4.5h7.652Z"
              fill="#ffda44"
            />
            <path
              d="M197.931,161.136l2.365,7.277h7.653l-6.192,4.5,2.365,7.279-6.191-4.5-6.191,4.5,2.365-7.279-6.191-4.5h7.652Z"
              fill="#ffda44"
            />
            <path
              d="M58.069,161.136l2.364,7.277h7.653l-6.191,4.5,2.364,7.279-6.19-4.5-6.191,4.5,2.365-7.279-6.192-4.5H55.7Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
