import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  StyledPrismicBannerContainer,
  StyledPrismicBannerOverlay,
  HeaderLogoImage,
  BannerContent,
  RoundButton,
  Icon,
  StyledColumn,
  TitleWrapper,
} from "./PrismicBanner.styled";
import { setCookie, checkCookie } from "storefront/features/shared/utils";
import { getBannerInfo } from "../selectors";
import * as actions from "../actions";

class PrismicBanner extends PureComponent {
  state = { isShowBanner: true, visible: false, bannerInfo: {} };

  componentDidMount() {
    const { fetchBannerRequest } = this.props;
    if (!checkCookie("PrismicBanner-hidden")) {
      fetchBannerRequest();
    }
  }

  componentDidUpdate() {
    const { bannerInfo } = this.props;
    const { isShowBanner } = this.state;
    if (isShowBanner && bannerInfo && !isEmpty(bannerInfo)) {
      this.setState({ bannerInfo, visible: true });
    }
  }

  handleCloseBanner = () => {
    this.setState({ visible: false, isShowBanner: false });
    setCookie("PrismicBanner-hidden", true, 60);
  };

  render() {
    const {
      visible,
      bannerInfo: {
        background_color,
        logo,
        title,
        content,
        button_link,
        button_title,
        image_for_content,
        desktop_background_image,
        tablet_background_image,
        mobile_background_image,
        content_text_color,
        title_color,
      },
    } = this.state;
    const buttonLink = button_link ? button_link : "/";

    return (
      <>
        <StyledPrismicBannerContainer
          fluid
          className={`${visible && "show"}`}
          onClick={this.handleCloseBanner}
          backgroundcolor={background_color}
        >
          <Row className="text-center">
            {logo && (
              <Col className="col-md-1 col-12 my-auto mx-auto">
                <Link to="/">
                  <HeaderLogoImage src={logo} width="100" alt="Bids.com" />
                </Link>
              </Col>
            )}
            <StyledColumn
              desktopbackgroundimage={desktop_background_image}
              tabletbackgroundimage={tablet_background_image}
              mobilebackgroundimage={mobile_background_image}
              className="col-md-10 col-12 my-auto mx-auto"
            >
              <Row>
                <Col className="col-md-auto col-12 my-auto mx-auto">
                  {title_color && (
                    <TitleWrapper
                      titlecolor={title_color}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                  {button_title && (
                    <Link
                      to={buttonLink}
                      className="text-uppercase btn btn-primary"
                    >
                      {button_title}
                    </Link>
                  )}
                </Col>
                {content && (
                  <Col className="col-md-auto col-12 my-auto mx-auto">
                    <BannerContent
                      contentcolor={content_text_color}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </Col>
                )}
                {image_for_content && (
                  <Col className="col-md-auto col-12 my-auto mx-auto">
                    <Image src={image_for_content} thumbnail />
                  </Col>
                )}
              </Row>
            </StyledColumn>
            <Col className="d-none d-sm-block my-auto pl-0">
              <RoundButton
                onClick={this.handleCloseBanner}
                data-animate-bottom=""
                aria-expanded="false"
              >
                <Icon role="presentation" viewBox="0 0 16 14">
                  <path
                    d="M15 0L1 14m14 0L1 0"
                    stroke="currentColor"
                    fill="none"
                    fillRule="evenodd"
                  ></path>
                </Icon>
              </RoundButton>
            </Col>
          </Row>
        </StyledPrismicBannerContainer>
        <StyledPrismicBannerOverlay
          className={`${!visible && "d-none"}`}
          onClick={this.handleCloseBanner}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  bannerInfo: getBannerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBannerRequest: () => {
    dispatch(actions.fetchBannerRequest());
  },
});

export { PrismicBanner };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PrismicBanner));
