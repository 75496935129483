import React, { PureComponent } from "react";

export default class AD extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#d80027"
            />
            <path
              d="M256,315.356V255.994h44.522v22.261c0,3.868-7.421,18.038-25.735,29.682C267.849,312.348,260.669,313.766,256,315.356Z"
              fill="#d80027"
            />
            <rect
              width="44.522"
              height="44.522"
              transform="translate(211.478 211.473)"
              fill="#d80027"
            />
          </g>
          <path
            d="M278.261,196.632A14.823,14.823,0,0,0,256,183.793a14.823,14.823,0,0,0-22.261,12.839h-37.1v74.2c0,27.624,21.938,43.6,39.14,51.88A14.822,14.822,0,0,0,256,343.036a14.821,14.821,0,0,0,20.221-20.322c17.2-8.281,39.141-24.257,39.141-51.88v-74.2Zm14.84,74.2c0,3.868,0,15.635-18.314,27.279A89.88,89.88,0,0,1,256,306.975a89.928,89.928,0,0,1-18.787-8.861C218.9,286.47,218.9,274.7,218.9,270.836V218.895h74.2v51.941h0Z"
            fill="#ff9811"
          />
        </g>
      </svg>
    );
  }
}
