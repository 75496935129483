import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import SubMenuImage from "./SubMenuImage";
import SubMenuLinkList from "./SubmenuLinkList";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as actions from "../actions";
import { getMenuState } from "../selectors";

import { DropDoownContainer } from "./Header.styled";

class SubMenuItem extends PureComponent {
  checkIsMenuOpen = () => {
    const { menuState, title } = this.props;
    return menuState === title;
  };

  handleDropdownTriggerClick = () => {
    const { menuToggle, title } = this.props;
    menuToggle(this.checkIsMenuOpen() ? undefined : title);
  };

  render() {
    const { title, data } = this.props;
    return (
      <div>
        <div className="nav-item">
          <a
            aria-haspopup="true"
            aria-expanded="false"
            className="dropdown-toggle nav-link"
            role="button"
            onClick={this.handleDropdownTriggerClick}
          >
            {title}
          </a>
        </div>
        <CSSTransition
          in={this.checkIsMenuOpen()}
          classNames="dropdown-menu"
          unmountOnExit
          timeout={300}
        >
          <DropDoownContainer className="dropdown-menu-container align-items-start position-absolute mt-2 ">
            <Row className="w-100">
              {data.map((item, index) => (
                <Col lg={2} key={title + index}>
                  {item.type === "image" ? (
                    <SubMenuImage url={item.imgUrl} alt={item.title} />
                  ) : (
                    <SubMenuLinkList
                      title={item.title}
                      url={item.url}
                      data={item.children}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </DropDoownContainer>
        </CSSTransition>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  menuState: getMenuState,
});

const mapDispatchToProps = (dispatch) => ({
  menuToggle: (value) => {
    dispatch(actions.menuToggle(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuItem);
