import React, { PureComponent } from "react";

export default class GT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#338af3"
            />
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#338af3"
            />
          </g>
          <path
            d="M304.083,286.736l-31.112-31.112,29.615-29.616-1.342-15.642-8.478-8.48L256,238.652l-36.766-36.766-8.478,8.48-1.342,15.642,29.615,29.616-31.112,31.112,16.97,16.97L256,272.594l31.113,31.112Z"
            fill="#acabb1"
          />
          <path
            d="M301.255,210.367l-16.971,16.971a40,40,0,1,1-56.57,0l-16.971-16.971a64,64,0,1,0,90.512,0Z"
            fill="#6da544"
          />
        </g>
      </svg>
    );
  }
}
