import React, { createRef, PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { Form, InputGroup, FormControl, Dropdown } from "react-bootstrap";
import { SEARCH_PAGE_LOADER } from "storefront/features/shared/utils";

import * as actions from "../actions";
import { setFlagUpdateProductGrid } from "storefront/features/productGrid/actions";
import { getDropDownSearchProducts } from "../selectors";
import SearchDropdown from "./SearchDropdown";
import Icon from "storefront/features/shared/icons";
import { StyledInputGroup, StyledDropdown } from "./Search.styled";
import { getProduct } from "storefront/features/productGrid/selectors";
import { getBuyingFormat } from "../utils";
import { getSearchParams } from "storefront/features/shared/utils";

class SearchInput extends PureComponent {
  constructor() {
    super();
    this.searchInput = createRef();
  }

  state = {
    showDropDown: 0,
    searchValue: "",
    products: [],
  };

  componentDidUpdate(prevProps, prevState) {
    const { searchProducts, location } = this.props;
    const { searchValue } = this.state;
    this.setState({ products: searchProducts });
    if (!isEmpty(searchValue) || prevState.searchValue.length === 1) {
      this.searchInput.current.focus();
    }
    if (location.pathname !== prevProps.location.pathname) {
      this.setState({ searchValue: "" });
      this.hideDropDown();
      this.searchInput.current.blur();
    }
  }

  hideDropDown = () => {
    this.setState({ showDropDown: 0 });
  };

  showDropDown = () => {
    this.setState({ showDropDown: 1 });
  };

  getBuyingFormatValue = () => {
    const { product, location } = this.props;
    const searchBuyingFormat = getSearchParams(
      location.search,
      "buying_format"
    );
    return searchBuyingFormat || getBuyingFormat(product, location.pathname);
  };

  handleChange = (e) => {
    const { fetchDropDownSearchRequest } = this.props;
    const searchValue = e.target.value;
    const limit = 5;
    const buyingFormat = this.getBuyingFormatValue();
    this.setState({ searchValue });

    if (searchValue.length >= 3) {
      clearTimeout(delayTimer); // eslint-disable-line no-use-before-define
      const delayTimer = setTimeout(() => {
        fetchDropDownSearchRequest(
          searchValue,
          limit,
          buyingFormat,
          SEARCH_PAGE_LOADER
        );
        this.showDropDown();
      }, 1000);
    }
    if (searchValue.length < 3) {
      this.hideDropDown();
    }
  };

  handleClick = (e) => {
    if (e.target.value.length >= 3) {
      this.showDropDown();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.redirectToSearchPage();
  };

  redirectToSearchPage = () => {
    const { history, fetchSearchRequest } = this.props;
    const { searchValue } = this.state;
    const buyingFormat = this.getBuyingFormatValue();
    const isBuyingFormatEmpty = buyingFormat === "";
    const historySearch = isBuyingFormatEmpty
      ? `title=${searchValue}`
      : `title=${searchValue}&buying_format=${buyingFormat}`;
    if (!isEmpty(searchValue)) {
      fetchSearchRequest(searchValue, buyingFormat, SEARCH_PAGE_LOADER);
      history.push({
        pathname: "/search",
        search: historySearch,
      });
    }
  };

  render() {
    const { showDropDown, searchValue, products } = this.state;

    const CustomToggle = () => (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <StyledInputGroup>
            <FormControl
              placeholder="Search"
              onChange={this.handleChange}
              value={searchValue}
              onClick={this.handleClick}
              data-cy="searchInput"
              ref={this.searchInput}
            />
            <InputGroup.Append>
              <InputGroup.Text
                onClick={this.redirectToSearchPage}
                data-cy="searchButton"
              >
                <Icon type="search" size={25} />
              </InputGroup.Text>
            </InputGroup.Append>
          </StyledInputGroup>
        </Form.Group>
      </Form>
    );

    return (
      <StyledDropdown showDropDown={showDropDown}>
        <Dropdown.Toggle as={CustomToggle} />
        <SearchDropdown products={products} hideDropDown={this.hideDropDown} />
      </StyledDropdown>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  searchProducts: getDropDownSearchProducts,
  product: getProduct,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDropDownSearchRequest: (title, limit, buyingFormat, loader) => {
    dispatch(
      actions.fetchDropDownSearchRequest({ title, limit, buyingFormat, loader })
    );
  },
  fetchSearchRequest: (title, buyingFormat, loader) => {
    dispatch(actions.fetchSearchRequest({ title, buyingFormat, loader }));
  },
  setFlagUpdateProductGrid: (value) => {
    dispatch(setFlagUpdateProductGrid(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withImmutablePropsToJS(SearchInput)));
