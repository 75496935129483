import React, { PureComponent } from "react";
import { Modal, Button, Form as BSForm } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form } from "react-final-form";
import { withRouter } from "react-router-dom";

import Field from "storefront/features/shared/components/field/Field";
import {
  validateEmail,
  validateRequired,
  composeValidators,
} from "storefront/features/shared/components/field/utils";
import { connect } from "react-redux";
import * as actions from "storefront/features/shared/actions";
import { getBidDataObj, bidEventName } from "storefront/features/shared/utils";

class UnregisteredUserModal extends PureComponent {
  handlePlaceBid = ({ email }) => {
    const { product, postBidRequest, onClose, location } = this.props;
    const data = getBidDataObj(product, bidEventName.NEW_ANONYMOUS_BID, email);
    const redirectLink = location.pathname;
    postBidRequest(data, redirectLink, onClose);
  };

  render() {
    const { onClose } = this.props;
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header
          closeButton
          className="h-0 pr-3 border-bottom-0"
        ></Modal.Header>
        <Modal.Body className="text-center mx-5">
          <h2 className="text-uppercase">What`s your email?</h2>
          <p>We`ll contact you if (or when!) you win.</p>
          <Form
            noValidate
            onSubmit={(value) => this.handlePlaceBid(value)}
            render={({ handleSubmit, submitting, pristine }) => (
              <BSForm onSubmit={handleSubmit}>
                <BSForm.Group>
                  <Field
                    name="email"
                    type="email"
                    className="w-100"
                    placeholder="Enter your email"
                    validate={composeValidators(
                      validateEmail,
                      validateRequired
                    )}
                    disabled={submitting}
                  />
                </BSForm.Group>
                <BSForm.Group>
                  <Button
                    className="text-uppercase w-100 mt-3"
                    type="submit"
                    variant="primary"
                    disabled={submitting || pristine}
                  >
                    Place my bid
                  </Button>
                </BSForm.Group>
              </BSForm>
            )}
          />
          <p>
            Have an account?{" "}
            <Link to="/account/login">Login to track your auctions</Link>
          </p>
          <p>
            Don`t have an account?{" "}
            <Link to="/account/register">Create one</Link>
          </p>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  postBidRequest: (data, redirectLink, callbackFunc) => {
    dispatch(actions.postBidRequest({ data, redirectLink, callbackFunc }));
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(withRouter(UnregisteredUserModal));
