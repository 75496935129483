import React, { PureComponent } from "react";

export default class WishlistIcon extends PureComponent {
  render() {
    return (
      <svg viewBox="0 0 23.12 31.98" width="20" height="20">
        <path
          d="M27.56.05V32L16,25.65,4.44,32h0V0M24.77,2.79H12.62V11H10.51V2.79H7.23c0,8.23,0,16,0,24.23l8.7-4.81L24.82,27S24.77,11.6,24.77,2.79Z"
          transform="translate(-4.44 -0.02)"
        />
      </svg>
    );
  }
}
