import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";

import * as actions from "storefront/features/shared/actions";

export function* fetchMakeAnOfferRequest(action) {
  const { loader } = action.payload;
  const query = {
    page: action.payload.page,
    tags: action.payload.tags,
  };
  const request = () =>
    superagent.get(`/api/collections/make-an-offer`).query(query);
  const response = yield call(request);
  yield put(actions.fetchAllProductsRequestResult(response.body));
  yield put(actions.removeLoader({ loader }));
}

export default [
  takeLatest(actions.FETCH_MAKE_AN_OFFER_REQUEST, fetchMakeAnOfferRequest),
];
