import React, { PureComponent, createRef } from "react";
import { Button, Row, Col, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { Field } from "storefront/features/shared";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import Select from "react-select";

import * as actions from "storefront/features/vendor/actions";
import {
  StyledLogo,
  LogoWrapper,
  StyledH2,
  StyledOption,
  StyledSelect,
} from "./VendorSettings.styled";
import Icon from "storefront/features/shared/icons";
import { theme } from "storefront/features/shared/styles/schemes";
import { getVendorSettings } from "storefront/features/shared/selectors";
import { reactSelectStyles, vendorShippingProperties } from "../utils";
import ShippingField from "./ShippingField";

class VendorSettings extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  state = {
    logo: "",
    imagePreviewUrl: "",
    successMsg: null,
    errorMsg: null,
    disabledBtn: true,
    colorSchemeTitle: "",
    isColorSchemeChanged: false,
  };

  componentDidMount() {
    const { fetchVendorSettings, id } = this.props;
    fetchVendorSettings(id);
  }

  componentDidUpdate(prevProps) {
    const {
      vendorSettings: {
        logo,
        color_scheme: { color_scheme_title },
      },
    } = this.props;
    const { colorSchemeTitle } = this.state;
    const colorScheme = colorSchemeTitle || color_scheme_title || "undefined";
    if (this.state.colorSchemeTitle !== colorScheme) {
      this.setState({ colorSchemeTitle: colorScheme });
    }
    logo !== prevProps.vendorSettings.logo && this.updateImagePreviewUrl();
    this.clearMessageLine();
  }

  updateImagePreviewUrl = () => {
    const {
      vendorSettings: { logo },
    } = this.props;
    if (logo) {
      this.setState({
        imagePreviewUrl: logo,
      });
    }
  };

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleResponseSuccess = (successMsg) => {
    this.setState({ successMsg, isColorSchemeChanged: false });
  };

  handleSubmitLogoUpload = () => {
    const {
      fetchChangeVendorLogo,
      vendorSettings: { slug },
    } = this.props;
    const { logo } = this.state;

    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    fetchChangeVendorLogo({ logo, slug, handleErrors, handleSuccess });
    this.setState({
      disabledBtn: true,
    });
  };

  clearMessageLine = () => {
    const { successMsg, errorMsg } = this.state;
    const delay = 5000;
    if (successMsg || errorMsg) {
      setTimeout(() => {
        this.setState({ successMsg: null, errorMsg: null });
      }, delay);
    }
  };

  handleSubmitSave = ({
    store_name = "",
    phone = "",
    description = "",
    background_color = "",
    color_scheme_title = "",
    usa = "",
    canada = "",
    europe = "",
    australia = "",
    rest_of_the_world = "",
  }) => {
    const {
      fetchVendorSettingsSave,
      vendorSettings: { slug },
    } = this.props;
    const data = {
      phone,
      store_name,
      description,
      color_scheme: {
        background_color,
        color_scheme_title,
      },
      shipping_rates: {
        usa,
        canada,
        europe,
        australia,
        rest_of_the_world,
      },
    };
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    fetchVendorSettingsSave({ slug, data, handleErrors, handleSuccess });
  };

  capitalizeCamelCase = (text) => {
    const result = text.toLowerCase().split("_");
    return result
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  };

  handleImageChange = (e) => {
    const reader = new FileReader();
    const logo = e.target.files[0];

    if (logo.size > 2097152) {
      this.setState({ errorMsg: "File is too big." });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        logo,
        imagePreviewUrl: reader.result,
        disabledBtn: false,
      });
    };

    reader.readAsDataURL(logo);
  };

  handleClick = () => {
    if (!this.ref.current) {
      return;
    }
    this.ref.current.click();
  };

  handleDelete = () => {
    const {
      fetchDeleteVendorLogo,
      vendorSettings: { slug },
    } = this.props;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    this.setState({
      imagePreviewUrl: "",
      logo: "",
    });
    fetchDeleteVendorLogo({ slug, handleErrors, handleSuccess });
  };

  handleColorSchemeChange = (e) => {
    const { colorSchemeTitle } = this.state;
    if (colorSchemeTitle !== e.value) {
      this.setState({
        colorSchemeTitle: e.value,
        isColorSchemeChanged: true,
      });
    }
  };

  setOptions = (themeColors) => {
    return Object.entries(themeColors).map(([value, key]) => ({
      value: value,
      label: <StyledOption backgroundcolor={key.strip} />,
    }));
  };

  render() {
    const {
      imagePreviewUrl,
      disabledBtn,
      errorMsg,
      successMsg,
      colorSchemeTitle,
      isColorSchemeChanged,
    } = this.state;
    const { status, vendorSettings } = this.props;
    const { backgroundColor, themeColors } = theme;
    const options = this.setOptions(themeColors);
    const defaultColorSchemeTitle =
      colorSchemeTitle ||
      vendorSettings?.color_scheme?.color_scheme_title ||
      null;
    const defaultColorSchemeValue = options.find(
      (option) => option.value === defaultColorSchemeTitle
    );

    return (
      <Row className="flex-column">
        <Col className="text-left w-100">
          <p>Partner approval status: {status}</p>
        </Col>
        <Col className="w-100">
          <StyledH2>Vendor settings</StyledH2>
        </Col>
        <Col xs={12} md={3} className="text-left my-auto">
          {errorMsg && (
            <p className="invalid-feedback d-block text-center">{errorMsg}</p>
          )}
          {successMsg && (
            <p className="valid-feedback d-block text-center">{successMsg}</p>
          )}
        </Col>
        <Col className="text-left w-100">
          <Form
            noValidate
            onSubmit={this.handleSubmitLogoUpload}
            render={({ handleSubmit }) => (
              <BSForm onSubmit={handleSubmit} data-cy="vendor-logo-upload-form">
                <BSForm.Row className="flex-column">
                  <Col
                    xs={"auto"}
                    md={"auto"}
                    className="text-sm-left text-center"
                  >
                    <BSForm.Group className="d-flex align-items-center justify-content-center justify-content-sm-start flex-column flex-sm-row">
                      <label className="mb-0 mr-3">Add/change logo:</label>
                      <Field name="logo">
                        {({ input }) => (
                          <input
                            {...input}
                            onChange={this.handleImageChange}
                            type="file"
                            className="d-none"
                            ref={this.ref}
                            accept="image/*"
                            data-cy="vendor-logo-picker"
                          />
                        )}
                      </Field>
                      {imagePreviewUrl ? (
                        <StyledLogo
                          data-cy="vendor-logo-preview"
                          src={imagePreviewUrl}
                          roundedCircle
                          onClick={this.handleClick}
                        />
                      ) : (
                        <LogoWrapper
                          className="d-inline-flex justify-content-center"
                          onClick={this.handleClick}
                        >
                          <Icon type="login" size={25} />
                        </LogoWrapper>
                      )}
                    </BSForm.Group>
                  </Col>
                  <Col
                    xs={12}
                    md={"auto"}
                    className="text-sm-left text-center p-0"
                  >
                    <BSForm.Group>
                      <Button
                        className="mr-3"
                        type="submit"
                        variant="primary"
                        disabled={disabledBtn}
                        data-cy="vendor-logo-upload-btn"
                      >
                        Upload
                      </Button>
                      <Button
                        variant="primary"
                        onClick={this.handleDelete}
                        data-cy="vendor-logo-delete-btn"
                      >
                        Delete
                      </Button>
                    </BSForm.Group>
                  </Col>
                </BSForm.Row>
              </BSForm>
            )}
          />
          <Form
            noValidate
            initialValues={{
              store_name: vendorSettings?.store_name || "",
              phone: vendorSettings?.phone || "",
              description: vendorSettings?.description || "",
              usa: vendorSettings?.shipping_rates?.usa || "",
              canada: vendorSettings?.shipping_rates?.canada || "",
              europe: vendorSettings?.shipping_rates?.europe || "",
              australia: vendorSettings?.shipping_rates?.australia || "",
              rest_of_the_world:
                vendorSettings?.shipping_rates?.rest_of_the_world || "",
              background_color:
                vendorSettings?.color_scheme?.background_color || "",
              color_scheme_title: defaultColorSchemeTitle,
            }}
            onSubmit={this.handleSubmitSave}
            render={({ handleSubmit, submitting, pristine }) => (
              <BSForm
                onSubmit={handleSubmit}
                className="mt-4"
                data-cy="vendor-settings-form"
              >
                <ShippingField data={vendorShippingProperties} />
                <BSForm.Row className="justify-content-center justify-content-sm-start">
                  <BSForm.Group as={Col} xs={10} md={4} xl={6}>
                    <label htmlFor="phone">Contact phone</label>
                    <Field
                      size="sm"
                      name="phone"
                      placeholder="Enter store phone"
                      id="storePhone"
                      data-cy="vendor-settings-store-phone"
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row className="justify-content-center justify-content-sm-start">
                  <BSForm.Group as={Col} xs={10} md={4} xl={6}>
                    <label htmlFor="storeName">
                      Store name (this field will be used as the public name of
                      the store)
                    </label>
                    <Field
                      size="sm"
                      name="store_name"
                      placeholder="Enter store name"
                      id="storeName"
                      data-cy="vendor-settings-store-name"
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row className="justify-content-center justify-content-sm-start">
                  <BSForm.Group as={Col} xs={10} md={8} xl={6}>
                    <label htmlFor="storeDescription">
                      Store meta description (for SEO optimization)
                    </label>
                    <Field
                      as="textarea"
                      size="sm"
                      name="description"
                      placeholder="Few words about your store"
                      id="storeDescription"
                      data-cy="vendor-settings-store-description"
                    />
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row className="justify-content-center justify-content-sm-start">
                  <BSForm.Group as={Col} xs={10} md={4} xl={3}>
                    <label htmlFor="backgroundColor">Page background</label>
                    <StyledSelect>
                      <Field
                        as="select"
                        size="sm"
                        name="background_color"
                        id="backgroundColor"
                        data-cy="vendor-settings-background-color"
                      >
                        <option key="default">Select background color</option>
                        {Object.keys(backgroundColor).map((key) => (
                          <option key={key} value={key}>
                            {this.capitalizeCamelCase(key)}
                          </option>
                        ))}
                      </Field>
                    </StyledSelect>
                  </BSForm.Group>
                  <BSForm.Group as={Col} xs={10} md={4} xl={3}>
                    <Field className="d-none" name="color_scheme_title"></Field>
                    <label htmlFor="schemaColor">Page color schema</label>
                    {!!defaultColorSchemeTitle && (
                      <Select
                        defaultValue={defaultColorSchemeValue}
                        id="schemaColor"
                        data-cy="vendor-settings-color-scheme-title"
                        placeholder="Select color scheme"
                        options={options}
                        styles={reactSelectStyles}
                        onChange={this.handleColorSchemeChange}
                      />
                    )}
                  </BSForm.Group>
                </BSForm.Row>
                <BSForm.Row className="justify-content-center justify-content-sm-start text-center">
                  <BSForm.Group as={Col} xs={10} md={8} xl={6}>
                    <Button
                      className="w-50 mt-4"
                      type="submit"
                      variant="primary"
                      data-cy="vendor-settings-submit"
                      disabled={
                        submitting || (pristine && !isColorSchemeChanged)
                      }
                    >
                      Save
                    </Button>
                  </BSForm.Group>
                </BSForm.Row>
              </BSForm>
            )}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorSettings: getVendorSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchChangeVendorLogo: (data) => {
    dispatch(actions.fetchChangeVendorLogoRequest(data));
  },
  fetchDeleteVendorLogo: (data) => {
    dispatch(actions.fetchDeleteVendorLogoRequest(data));
  },
  fetchVendorSettings: (vendor_id) => {
    dispatch(actions.fetchVendorSettingsRequest(vendor_id));
  },
  fetchVendorSettingsSave: (data) => {
    dispatch(actions.fetchVendorSettingsSaveRequest(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(VendorSettings));
