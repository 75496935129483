import React, { PureComponent } from "react";

export default class MG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <path d="M512,426.666V85.329H0V426.666Z" fill="#6da544" />
          <path d="M512,85.331H0V256H512Z" fill="#d80027" />
          <rect
            width="181.793"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
