import React, { PureComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";
export default class BuiltBy extends PureComponent {
  render() {
    return (
      <Container fluid>
        <Row className="py-3 border-top">
          <Col className="text-center d-flex justify-content-center">
            <p className="my-0">
              <small>
                Website designed and built by{" "}
                <a href="https://www.vtlabs.org/">VT Labs</a>
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
