import React, { PureComponent } from "react";

export default class AS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 344.154"
      >
        <g transform="translate(0 -83.923)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 83.923)"
            fill="#0052b4"
          />
          <path
            d="M512,108.546,59.7,258.293,512,408.038v20.039L0,258.293,512,88.508Z"
            fill="#d80027"
          />
          <path
            d="M512,374.2v33.837L59.7,258.293,512,108.546v33.838"
            fill="#f0f0f0"
          />
          <path
            d="M493.3,253.493H464.026a23.257,23.257,0,0,0-1.432-31.31,23.26,23.26,0,0,0,0-32.9l-.546.549c9.082-9.082,9.63-24.359.546-33.441L331.019,287.971a22.945,22.945,0,0,0,32.634-.108l2.532-2.314,63-5.728v27.1h21.37v-29.04l32.056-2.913Z"
            fill="#a2001d"
          />
          <path
            d="M333.03,316.084,311.652,305.4l21.378-10.685H471.938v21.37Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
