import React, { PureComponent } from "react";

export default class BQ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path d="M0,426.663H512V85.337h0Z" fill="#0052b4" />
          <path d="M250.435,85.337H0V252.282Z" fill="#ffda44" />
          <path d="M188.052,250.239l14.708-7.967-14.708-7.967a51.976,51.976,0,0,0-43.366-43.366l-7.967-14.708-7.967,14.708a51.977,51.977,0,0,0-43.366,43.366l-14.707,7.968,14.708,7.967a51.976,51.976,0,0,0,43.366,43.366l7.966,14.707,7.967-14.708A51.976,51.976,0,0,0,188.052,250.239Zm-51.334,21.714a29.681,29.681,0,1,1,29.682-29.68A29.682,29.682,0,0,1,136.718,271.953Z" />
          <path
            d="M136.718,220.012l6.427,11.13H156l-6.426,11.131L156,253.4H143.145l-6.427,11.131L130.293,253.4H117.441l6.425-11.13-6.425-11.131h12.852Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
