import React, { PureComponent } from "react";

export default class GD extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#a2001d"
          />
          <path d="M256,256,44.522,382.141V129.859Z" fill="#496e2d" />
          <g>
            <path d="M256,256,467.478,382.141H44.522Z" fill="#ffda44" />
            <path d="M467.478,129.859,256,256,44.522,129.859Z" fill="#ffda44" />
          </g>
          <path d="M467.478,129.859V382.141L256,256Z" fill="#496e2d" />
          <g>
            <path
              d="M256,95.722l3.316,10.2h10.728l-8.679,6.306,3.316,10.2L256,116.128l-8.68,6.307,3.316-10.2-8.68-6.306h10.728Z"
              fill="#ffda44"
            />
            <path
              d="M170.094,95.722l3.316,10.2h10.727l-8.678,6.306,3.316,10.2-8.681-6.307-8.679,6.307,3.315-10.2-8.68-6.306h10.728Z"
              fill="#ffda44"
            />
            <path
              d="M341.906,95.722l3.316,10.2H355.95l-8.679,6.306,3.315,10.2-8.68-6.307-8.679,6.307,3.316-10.2-8.68-6.306H338.59Z"
              fill="#ffda44"
            />
            <path
              d="M256,389.565l3.316,10.2h10.728l-8.679,6.307,3.316,10.2L256,409.972l-8.68,6.306,3.316-10.2-8.68-6.307h10.728Z"
              fill="#ffda44"
            />
            <path
              d="M170.094,389.565l3.316,10.2h10.727l-8.678,6.307,3.316,10.2-8.681-6.306-8.679,6.306,3.315-10.2-8.68-6.307h10.728Z"
              fill="#ffda44"
            />
            <path
              d="M341.906,389.565l3.316,10.2H355.95l-8.679,6.307,3.315,10.2-8.68-6.306-8.679,6.306,3.316-10.2-8.68-6.307H338.59Z"
              fill="#ffda44"
            />
          </g>
          <circle
            cx="37.988"
            cy="37.988"
            r="37.988"
            transform="translate(218.012 218.012)"
            fill="#a2001d"
          />
          <g>
            <path
              d="M256,218.008l8.516,26.21h27.561l-22.3,16.2,8.515,26.211-22.3-16.2-22.3,16.2,8.517-26.211-22.3-16.2h27.56Z"
              fill="#ffda44"
            />
            <path
              d="M107.655,253.181a14.841,14.841,0,0,1-25.079,15.875c-7.937-12.54-3.335-33.016-3.335-33.016S99.716,240.642,107.655,253.181Z"
              fill="#ffda44"
            />
          </g>
          <circle
            cx="7.42"
            cy="7.42"
            r="7.42"
            transform="translate(91.663 259.966)"
            fill="#a2001d"
          />
        </g>
      </svg>
    );
  }
}
