import { isEqual, get } from "lodash";

export const getWinnerNickname = (product) => {
  const { winner, winning_customer, winning_customer_country } = product;
  const winnerName = winner || winning_customer;
  return { countryCode: winning_customer_country, winnerName };
};

export const amountWithoutCents = (value) => {
  return value / 100;
};

export const amountWithCents = (value) => {
  return value * 100;
};

export const getFirstAvailableVariant = (variants) => {
  const variantData = {};
  const firstAvaliableVariant = variants.find(({ quantity }) => quantity > 0);
  if (firstAvaliableVariant) {
    const {
      compare_at_price,
      price,
      shopify_id,
      selected_options,
      quantity,
    } = firstAvaliableVariant;
    variantData.variantId = shopify_id;
    variantData.productPrice = price;
    variantData.productRetailPrice = compare_at_price;
    variantData.activeOptions = selected_options;
    variantData.quantityAvailable = quantity;
  }
  return variantData;
};

export const SHARE_BUTTON_BLUE_COLOR = `#3b5998`;
export const SHARE_BUTTON_LIGHT_BLUE_COLOR = `#3cf`;
export const SHARE_BUTTON_GREEN_COLOR = `#075e54`;
export const SHARE_BUTTON_RED_COLOR = `#dc4a38`;

export const getVariantDataByOptions = (variants, options) => {
  const choosedVariant = variants.find(({ selected_options }) =>
    isEqual(selected_options, options)
  );
  return {
    id: get(choosedVariant, "shopify_id", undefined),
    productPrice: get(choosedVariant, "price", undefined),
    productRetailPrice: get(choosedVariant, "compare_at_price", undefined),
    variantAvailable: get(choosedVariant, "quantity", 0) > 0 ? true : false,
    selectedOptions: get(choosedVariant, "selected_options", options),
  };
};

export const AUCTION_FORMAT = "auction";
export const BUY_NOW_FORMAT = "buy_now";
export const MAKE_AN_OFFER_FORMAT = "make_an_offer";

export const getProductType = (product) => {
  const { buying_format, current_bid } = product;
  const caseValue = current_bid ? 3 : buying_format;

  switch (caseValue) {
    case 3:
      return AUCTION_FORMAT;
    case 0:
      return BUY_NOW_FORMAT;
    case 1:
      return MAKE_AN_OFFER_FORMAT;
    default:
      return BUY_NOW_FORMAT;
  }
};
