import { isEmpty, get } from "lodash";

export const getMetaTags = (product) => {
  const isProductExist = !isEmpty(product);
  return {
    metaTitle: isProductExist ? product.title : "",
    metaDescription: isProductExist ? product.description : "",
    image: isProductExist ? get(product, "images.edges.0.node.grid", "") : "",
  };
};
