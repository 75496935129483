import React, { PureComponent } from "react";

export default class TV extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#338af3"
          />
          <g>
            <path
              d="M321.691,333.909l3.684,11.338H337.3l-9.644,7.006,3.684,11.338-9.645-7.007-9.644,7.007,3.684-11.338-9.644-7.006h11.921Z"
              fill="#ffda44"
            />
            <path
              d="M416.628,259.706l3.684,11.338h11.92l-9.644,7.006,3.684,11.337-9.644-7.006-9.644,7.006,3.684-11.337-9.645-7.006h11.921Z"
              fill="#ffda44"
            />
            <path
              d="M440.416,148.4l3.684,11.337h11.921l-9.644,7.007,3.684,11.337-9.645-7.007-9.644,7.007,3.684-11.337-9.644-7.007h11.92Z"
              fill="#ffda44"
            />
            <path
              d="M446.309,230.025l3.683,11.338h11.921l-9.643,7.006,3.684,11.337-9.645-7.007-9.644,7.007,3.684-11.337-9.645-7.006h11.921Z"
              fill="#ffda44"
            />
            <path
              d="M338.785,307.245l10.623,5.412,8.428-8.429L355.971,316l10.622,5.412-11.775,1.865-1.864,11.774-5.412-10.622L335.768,326.3l8.43-8.429Z"
              fill="#ffda44"
            />
            <path
              d="M338.785,262.723l10.623,5.412,8.428-8.429-1.865,11.774,10.622,5.411-11.775,1.866-1.864,11.773-5.412-10.621-11.774,1.865,8.43-8.429Z"
              fill="#ffda44"
            />
            <path
              d="M375.886,291.262l10.623,5.411,8.429-8.429-1.864,11.774,10.62,5.412L391.919,307.3l-1.864,11.774-5.411-10.622-11.774,1.866,8.429-8.429Z"
              fill="#ffda44"
            />
            <path
              d="M375.886,195.941l10.623,5.411,8.429-8.429L393.074,204.7l10.62,5.412-11.775,1.865-1.864,11.774-5.411-10.622-11.774,1.866,8.429-8.43Z"
              fill="#ffda44"
            />
            <path
              d="M411.844,194.8l10.623,5.411,8.429-8.428-1.865,11.773,10.621,5.412-11.775,1.865L426.013,222.6,420.6,211.983l-11.774,1.865,8.429-8.429Z"
              fill="#ffda44"
            />
          </g>
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.333)"
            fill="#f0f0f0"
          />
          <path
            d="M144,85.33H112v69.333H0v32H112V256h32V186.663H256v-32H144Z"
            fill="#d80027"
          />
          <g>
            <path d="M196.892,200.344,256,233.182V200.344Z" fill="#0052b4" />
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#0052b4"
            />
            <path d="M229.317,256l-73.491-40.832V256Z" fill="#0052b4" />
          </g>
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#d80027"
          />
          <g>
            <path d="M45.171,200.343,0,225.439v-25.1Z" fill="#0052b4" />
            <path d="M100.174,207.421V256H12.745Z" fill="#0052b4" />
          </g>
          <path
            d="M71.846,200.344,0,240.259V256H0l100.174-55.652Z"
            fill="#d80027"
          />
          <g>
            <path d="M59.108,140.982,0,108.144v32.838Z" fill="#0052b4" />
            <path
              d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
              fill="#0052b4"
            />
            <path d="M26.683,85.33l73.491,40.832V85.33Z" fill="#0052b4" />
          </g>
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#d80027"
          />
          <g>
            <path d="M210.829,140.983,256,115.888v25.1Z" fill="#0052b4" />
            <path d="M155.826,133.906V85.331h87.429Z" fill="#0052b4" />
          </g>
          <path
            d="M184.154,140.982,256,101.067V85.33h0L155.826,140.982Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
