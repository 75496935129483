import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isLogin } from "storefront/features/shared/utils";

export default class SuccessPasswordReset extends PureComponent {
  render() {
    return (
      <Container>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>
              Your Password <br /> successfully reset
            </h1>
            {!isLogin() && (
              <p>
                Please, use the following link to{" "}
                <Link to="/account/login">Login</Link> with your credentials
              </p>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
