import React, { PureComponent } from "react";
import { Row } from "react-bootstrap";

import Icon from "storefront/features/shared/icons";
import { StyledIconsContainer } from "./PaymentIconsList.styled";

const PAYMENT_SERVICES = [
  "Klarna",
  "Amex",
  "ApplePay",
  "DinersClub",
  "Discover",
  "Elo",
  "GooglePay",
  "JCB",
  "Mastercard",
  "PayPal",
  "ShopPay",
  "Venmo",
  "Visa",
];

export default class PaymentIconsList extends PureComponent {
  iconsList = () =>
    PAYMENT_SERVICES.map((service) => (
      <span key={service} className="m-2">
        <Icon type={`payment${service}`} data-cy={`payment-icon-${service}`} />
      </span>
    ));

  render() {
    return (
      <Row>
        <StyledIconsContainer
          className="text-center my-3"
          data-cy="payment-icons"
        >
          {this.iconsList()}
        </StyledIconsContainer>
      </Row>
    );
  }
}
