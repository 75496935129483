import React, { PureComponent } from "react";

export default class BZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#0052b4"
          />
          <circle
            cx="81.619"
            cy="81.619"
            r="81.619"
            transform="translate(174.381 174.375)"
            fill="#f0f0f0"
          />
          <circle
            cx="66.783"
            cy="66.783"
            r="66.783"
            transform="translate(189.217 189.211)"
            fill="#6da544"
          />
          <circle
            cx="44.522"
            cy="44.522"
            r="44.522"
            transform="translate(211.478 211.472)"
            fill="#f0f0f0"
          />
          <path
            d="M256,244.864l-24.116,11.13v18.55L256,289.386l24.116-14.842v-18.55Z"
            fill="#0052b4"
          />
          <rect
            width="48.228"
            height="22.261"
            transform="translate(231.88 233.734)"
            fill="#ffda44"
          />
          <g>
            <rect
              width="512"
              height="29.68"
              transform="translate(0 85.331)"
              fill="#a2001d"
            />
            <rect
              width="512"
              height="29.682"
              transform="translate(0 396.984)"
              fill="#a2001d"
            />
          </g>
        </g>
      </svg>
    );
  }
}
