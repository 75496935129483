import React, { PureComponent } from "react";

export default class SO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <path
            d="M256,157.273l22.663,69.748H352l-59.332,43.106,22.664,69.749L256,296.769l-59.332,43.107,22.664-69.749L160,227.021h73.337Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
