import React, { PureComponent } from "react";

export default class SX extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <path d="M512,85.331v166.69L0,256.173V85.331Z" fill="#d80027" />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#f0f0f0" />
          <g>
            <path
              d="M59.621,256c-.116,1.509-.193,3.031-.193,4.57a59.429,59.429,0,1,0,118.858,0c0-1.539-.078-3.061-.193-4.57Z"
              fill="#ffda44"
            />
            <circle
              cx="18.286"
              cy="18.286"
              r="18.286"
              transform="translate(100.576 192.001)"
              fill="#ffda44"
            />
          </g>
          <path
            d="M77.715,205.714v59.429c0,31.494,41.144,41.143,41.144,41.143S160,296.637,160,265.143V205.714Z"
            fill="#d80027"
          />
          <path
            d="M118.877,287.148C111.245,284.4,96,277.381,96,265.142V224h45.715v41.144C141.715,277.422,126.472,284.425,118.877,287.148Z"
            fill="#338af3"
          />
          <path
            d="M128,246.856v-9.142l-9.144-4.571-9.142,4.571v9.142l-4.571,4.573v18.285h27.428V251.429Z"
            fill="#f3f3f3"
          />
        </g>
      </svg>
    );
  }
}
