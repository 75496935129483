import React, { PureComponent } from "react";
import { Button, Row, Col, Form as BSForm } from "react-bootstrap";
import { Form } from "react-final-form";
import { connect } from "react-redux";

import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import {
  validateEmail,
  validateRequired,
  composeValidators,
  validateMinLength,
  validateMaxLength,
} from "storefront/features/shared/components/field/utils";
import withRedirectRegisteredUser from "storefront/features/shared/components/RedirectRegisteredUser";
import {
  StyledLinkForgotPass,
  StyledLinkRegister,
  StyledContainer,
} from "./Login.styled";

export class Login extends PureComponent {
  state = { errorMsg: undefined };

  handleResponsErrors = () => {
    this.setState({ errorMsg: "Incorrect email or password." });
  };

  handleSubmitForm = (values) => {
    const { getCustomerAccessToken } = this.props,
      callback = this.handleResponsErrors;
    this.setState({ errorMsg: undefined });
    getCustomerAccessToken({ callback, ...values });
  };

  render() {
    return (
      <StyledContainer>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>LOGIN</h1>
            <p>Please enter your e-mail and password:</p>
            <Form
              noValidate
              onSubmit={this.handleSubmitForm}
              render={({
                handleSubmit,
                submitting,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <BSForm onSubmit={handleSubmit}>
                  <BSForm.Group>
                    <Field
                      id="login-email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      validate={composeValidators(
                        validateEmail,
                        validateRequired,
                        validateMaxLength(250)
                      )}
                      disabled={submitting}
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      id="login-password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      disabled={submitting}
                      validate={composeValidators(
                        validateRequired,
                        validateMinLength(5),
                        validateMaxLength(40)
                      )}
                    />
                    <StyledLinkForgotPass
                      to="/account/recover_password"
                      id="login-account-recover-pass"
                    >
                      Forgot password?
                    </StyledLinkForgotPass>
                  </BSForm.Group>
                  <BSForm.Group>
                    {this.state.errorMsg && !dirtySinceLastSubmit && (
                      <p
                        id="form-login-error"
                        className="invalid-feedback d-block"
                      >
                        {this.state.errorMsg}
                      </p>
                    )}
                    <Button
                      id="login-submit"
                      className="w-100"
                      type="submit"
                      variant="primary"
                      disabled={submitting || pristine}
                    >
                      Submit
                    </Button>
                  </BSForm.Group>
                </BSForm>
              )}
            />
            <p className="form-footer-info">
              Don&#39;t have an account?{" "}
              <StyledLinkRegister
                to="/account/register"
                id="login-account-register"
              >
                Create one to access your auctions
              </StyledLinkRegister>
            </p>
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCustomerAccessToken: (values) => {
    dispatch(actions.signInRequest(values));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withRedirectRegisteredUser(Login));
