import React, { PureComponent } from "react";

export default class TC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#0052b4"
          />
          <path
            d="M332.058,192v78.221C332.058,308.32,384,320,384,320s51.942-11.675,51.942-49.779V192Z"
            fill="#ffda44"
          />
          <path
            d="M376.579,220.44c0,7.855-6.644,28.445-14.84,28.445S346.9,228.3,346.9,220.44s14.84-14.222,14.84-14.222S376.579,212.585,376.579,220.44Z"
            fill="#ff9811"
          />
          <path
            d="M415.961,235.93c2.394-5.6,4.257-13.785,4.257-17.86,0-6.546-8.9-11.852-8.9-11.852s-8.9,5.306-8.9,11.852c0,4.075,1.862,12.26,4.257,17.86l-5.141,11.123a27.077,27.077,0,0,0,19.576,0Z"
            fill="#a2001d"
          />
          <path
            d="M372.87,270.217s-7.421,14.222-7.421,28.445h37.1c0-14.222-7.421-28.445-7.421-28.445L384,263.106Z"
            fill="#6da544"
          />
          <path
            d="M395.13,270.217v-3.555a11.14,11.14,0,0,0-22.26,0v3.555Z"
            fill="#d80027"
          />
          <path
            d="M256,85.333v30.553l-45.167,25.1H256v59.359H196.9L256,233.179V256H229.32L155.826,215.17V256H100.174V207.423L12.744,256H0V225.442l45.167-25.1H0V140.985H59.1L0,108.139V85.333H26.68l73.494,40.825V85.333h55.652V133.9l87.43-48.572Z"
            fill="#f0f0f0"
          />
          <path
            d="M144,85.33H112v69.332H0v32H112V256h32V186.662H256v-32H144Z"
            fill="#d80027"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#0052b4"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#d80027"
            />
            <path
              d="M71.846,200.344,0,240.259V256H0l100.174-55.652Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#0052b4"
          />
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
              fill="#d80027"
            />
            <path
              d="M184.154,140.982,256,101.067V85.33h0L155.826,140.982Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
