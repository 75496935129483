import React, { PureComponent } from "react";

export default class ME extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="281.978"
            transform="translate(0 115.011)"
            fill="#a2001d"
          />
          <g>
            <path
              d="M218.9,211.478h74.2V181.8l-14.84,7.42L256,166.957l-22.261,22.26L218.9,181.8Z"
              fill="#ffda44"
            />
            <path
              d="M322.783,256H285.695a17.117,17.117,0,1,0-29.92-16.626A17.117,17.117,0,1,0,225.855,256H189.219c0,11.349,9.885,20.549,21.232,20.549h-.685a20.554,20.554,0,0,0,14.68,19.694l-16.678,16.678,15.741,15.741,22.329-22.329a16.927,16.927,0,0,0,2.714.752L235.06,337.549a55.68,55.68,0,0,0,41.882,0L263.45,307.085a17.107,17.107,0,0,0,2.714-.752l22.329,22.329,15.741-15.741-16.678-16.678a20.555,20.555,0,0,0,14.68-19.694h-.685C312.9,276.549,322.783,267.349,322.783,256Z"
              fill="#ffda44"
            />
          </g>
          <path
            d="M256,267.13l-24.116,11.131v22.261L256,315.362l24.116-14.84V278.261Z"
            fill="#6da544"
          />
          <rect
            width="48.228"
            height="29.682"
            transform="translate(231.88 256)"
            fill="#0052b4"
          />
        </g>
      </svg>
    );
  }
}
