import { Home } from "../features/home";
import { Auctions, AuctionsWithTags } from "../features/auctions";
import { BuyItNow, BuyItNowWithTags } from "../features/buyItNow";
import { MakeAnOffer, MakeAnOfferWithTags } from "../features/makeAnOffer";
import { Vendor } from "../features/vendor";
import { AuctionProduct } from "../features/auctionProduct";
import { Collection } from "../features/collection";
import { Product } from "../features/product";
import { Account } from "../features/account";
import { Cart } from "../features/cart";
import { Wishlist } from "../features/wishlist";
import { Search } from "../features/search";
import { FourOFour } from "../features/404";
import { ModalProductView } from "../features/productGrid";
import { ContactUs, StaticPage, MakeMoney } from "../features/page";
import { ProductsSettings } from "../features/productsSettings";
import ActiveAuctions from "../features/account/components/ActiveAuctions";

export const routes = {
  home: {
    path: ["/", "/quick-view/:handle"],
    exact: true,
    component: Home,
  },
  vendor: {
    path: ["/vendors/:handle", "/vendors/:handle/quick-view/:handle"],
    exact: true,
    component: Vendor,
  },
  auctions: {
    path: [
      "/collections/all-auctions",
      "/collections/all-auctions/quick-view/:handle",
    ],
    exact: true,
    component: Auctions,
  },
  auctionsWithTags: {
    path: [
      "/collections/all-auctions/:tags",
      "/collections/all-auctions/:tags/quick-view/:handle",
    ],
    exact: true,
    component: AuctionsWithTags,
  },
  buyItNow: {
    path: [
      "/collections/buy-it-now",
      "/collections/buy-it-now/quick-view/:handle",
    ],
    exact: true,
    component: BuyItNow,
  },
  buyItNowWithTags: {
    path: [
      "/collections/buy-it-now/:tags",
      "/collections/buy-it-now/:tags/quick-view/:handle",
    ],
    exact: true,
    component: BuyItNowWithTags,
  },
  makeAnOffer: {
    path: [
      "/collections/make-an-offer",
      "/collections/make-an-offer/quick-view/:handle",
    ],
    exact: true,
    component: MakeAnOffer,
  },
  makeAnOfferWithTags: {
    path: [
      "/collections/make-an-offer/:tags",
      "/collections/make-an-offer/:tags/quick-view/:handle",
    ],
    exact: true,
    component: MakeAnOfferWithTags,
  },
  auctionProduct: {
    path: "/collections/all-auctions/products/:handle",
    exact: true,
    component: AuctionProduct,
  },
  collectionWithTags: {
    path: "/collections/:collectionHandle/:tags",
    exact: true,
    component: Collection,
  },
  collection: {
    path: "/collections/:collectionHandle",
    exact: true,
    component: Collection,
  },
  product: {
    path: "/products/:handle",
    exact: true,
    component: Product,
  },
  wishlist: {
    path: "/account/wishlist",
    exact: true,
    component: Wishlist,
  },
  activeAuctions: {
    path: [
      "/account/active-auctions",
      "/account/active-auctions/quick-view/:handle"
    ],
    exact: true,
    component: ActiveAuctions,
  },
  contactUs: {
    path: "/pages/contact-us",
    exact: true,
    component: ContactUs,
  },
  makeMoney: {
    path: "/pages/ez-make-money-with-bids",
    exact: true,
    component: MakeMoney,
  },
  page: {
    path: "/pages/:handle",
    exact: false,
    component: StaticPage,
  },
  account: {
    path: "/account",
    exact: false,
    component: Account,
  },
  cart: {
    path: "/cart",
    exact: true,
    component: Cart,
  },
  search: {
    path: ["/search", "/search/quick-view/:handle"],
    exact: true,
    component: Search,
  },
  adminPanel: {
    path: "/products-settings",
    exact: true,
    component: ProductsSettings,
  },
  fourOFour: {
    component: FourOFour,
  },
};

export const productsRoutes = {
  quickViewRoutes: {
    path: [
      "/collections/all-auctions/:tags/quick-view/:handle",
      "/collections/buy-it-now/:tags/quick-view/:handle",
      "/collections/make-an-offer/:tags/quick-view/:handle",
      "/quick-view/:handle",
      "/search/quick-view/:handle",
      "/collections/all-auctions/quick-view/:handle",
      "/collections/buy-it-now/quick-view/:handle",
      "/collections/make-an-offer/quick-view/:handle",
      "/vendors/:handle/quick-view/:handle",
      "/account/active-auctions/quick-view/:handle"
    ],
    exact: true,
    component: ModalProductView,
  },
};
