import React, { PureComponent, createRef } from "react";
import { Col, Form as BSForm } from "react-bootstrap";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import { isEqual } from "lodash";

import { Field } from "storefront/features/shared";
import * as actions from "../actions";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import { StyledImage, IconWrapper } from "./ProductImageForm.styled";
import Icon from "storefront/features/shared/icons";
import {
  filterImagesUrls,
  getImagesSources,
  IMAGES_COUNT_LIMIT,
  IMAGES_SIZE_LIMIT,
} from "../utils";

class ProductImageForm extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  state = {
    images: [],
    imageIndex: "",
    imagePreviewUrl: [],
    successMsg: null,
    errorMsg: null,
  };

  componentDidMount() {
    const { imagePreviewUrl = [] } = this.props;
    this.setState({
      imagePreviewUrl,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { images, imageIndex } = this.state;
    if (
      images[imageIndex]?.size > 0 &&
      !isEqual(images[imageIndex], prevState.images[imageIndex])
    ) {
      this.uploadProductImage();
    }
  }

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleResponseSuccess = (successMsg, imgUrl) => {
    const { addProductImage } = this.props;
    this.setState({ successMsg });
    addProductImage({ src: imgUrl });
  };

  uploadProductImage = () => {
    const {
      fetchAddProductImageRequest,
      userAuctionData: { store_name: vendor },
    } = this.props;
    const variant = false;
    const { images, imageIndex } = this.state;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    fetchAddProductImageRequest({
      image: images[imageIndex],
      vendor,
      variant,
      handleErrors,
      handleSuccess,
    });
  };

  handleImageChange = (e) => {
    const reader = new FileReader();
    const image = e.target.files[0];
    const { imagePreviewUrl } = this.state;

    if (imagePreviewUrl.length > IMAGES_COUNT_LIMIT) {
      this.setState({ errorMsg: "Upload is limited to 3 images." });
      return;
    }

    if (image.size > IMAGES_SIZE_LIMIT) {
      this.setState({ errorMsg: "File is too big." });
      return;
    }

    reader.onloadend = () => {
      this.setState((prevState) => ({
        images: [...prevState.images, image],
        imagePreviewUrl: [...prevState.imagePreviewUrl, reader.result],
        imageIndex: prevState.images.length,
      }));
    };

    reader.readAsDataURL(image);
  };

  handleClick = () => {
    if (!this.ref.current) {
      return;
    }
    this.ref.current.click();
  };

  handleDelete = (e) => {
    const { images, imagePreviewUrl } = this.state;
    const { deleteProductImage } = this.props;
    const filteredImagePreviewUrl = filterImagesUrls(imagePreviewUrl, e);
    const filteredFilesArray = filterImagesUrls(images, e);

    this.setState({
      images: filteredFilesArray,
      imagePreviewUrl: filteredImagePreviewUrl,
    });
    const imagesSources = getImagesSources(filteredImagePreviewUrl);
    deleteProductImage(imagesSources);
  };

  render() {
    const { imagePreviewUrl } = this.state;
    const { index } = this.props;
    return (
      <>
        <BSForm.Row className="mx-0" data-cy="image-row">
          <Col xs={"auto"} md={"auto"} className="text-left my-auto mr-3">
            <span>Please select product image:</span>
          </Col>

          <Col xs={"auto"} md={"auto"} className="text-left my-auto mr-3">
            <BSForm.Group className="mb-0">
              <Field name={index ? `image-${index}` : `images[]`}>
                {({ input }) => (
                  <input
                    {...input}
                    onChange={this.handleImageChange}
                    type="file"
                    className="d-none"
                    ref={this.ref}
                    accept="image/*"
                  />
                )}
              </Field>
              {imagePreviewUrl.length > 0 ? (
                <div className="d-flex">
                  {imagePreviewUrl.map((image, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <StyledImage
                          src={image}
                          rounded
                          onClick={this.handleClick}
                          data-cy="product-image"
                        />
                        <div
                          onClick={() => this.handleDelete(idx)}
                          data-cy="delete-image"
                        >
                          <Icon type="deleteIcon" size={33} />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <IconWrapper
                  className="rounded"
                  onClick={this.handleClick}
                  data-cy="no-image-icon"
                >
                  <Icon type="noImage" size={80} />
                </IconWrapper>
              )}
            </BSForm.Group>
          </Col>
        </BSForm.Row>
        <BSForm.Row className="justify-content-start">
          <Col xs={12} md={12} className="text-left my-auto mr-3">
            {this.state.errorMsg && (
              <p
                className="invalid-feedback d-block"
                data-cy="image-load-error"
              >
                {this.state.errorMsg}
              </p>
            )}
            {this.state.successMsg && (
              <p
                className="valid-feedback d-block"
                data-cy="image-load-success"
              >
                {this.state.successMsg}
              </p>
            )}
          </Col>
        </BSForm.Row>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAddProductImageRequest: (data) => {
    dispatch(actions.fetchAddProductImageRequest(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(ProductImageForm));
