import styled from "styled-components";
import { Tooltip } from "react-bootstrap";

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: red;
  }
  .arrow {
    &:before {
      border-bottom-color: transparent;
    }
  }
`;
