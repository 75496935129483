import React, { PureComponent, createRef } from "react";
import { Button, Col, Form as BSForm, Table } from "react-bootstrap";
import { Form } from "react-final-form";
import { isEqual, isEmpty } from "lodash";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";

import * as actions from "../actions";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import { Field } from "storefront/features/shared";
import ProductOptions from "./ProductOptions";
import ProductVariants from "./ProductVariants";
import ProductImageForm from "./ProductImageForm";
import {
  validateRequired,
  composeValidators,
  validateMaxLength,
} from "storefront/features/shared/components/field/utils";
import {
  getSplicedArray,
  splitOptionValues,
  generateVariants,
  getValidVariants,
  getVariantData,
  tagsList,
  getTagsValue,
  getInitState,
  getDefaultVariant,
  getOptionsValues,
  getSelectedVariants,
  getProductsImageSources,
  MAX_STRING_LENGTH,
} from "../utils";

export class ProductForm extends PureComponent {
  constructor() {
    super();
    this.optionsCheck = createRef();
  }

  state = {
    errorMsg: undefined,
    optionValues: [],
    optionKeys: [],
    optionsList: [],
    variantsList: [],
    variantsData: [],
    productImages: [],
    variantsImage: [],
    productData: {},
    checkedTag: "Buying Format_Buy Now",
    tagsValue: "",
    defaultVariant: {
      options: [],
      price: 0,
      compare_at_price: 0,
      quantity: 0,
      sku: "",
    },
  };

  componentDidMount() {
    const {
      product,
      product: { id, options },
    } = this.props;
    if (id) {
      const initState = getInitState(product);
      const optionsList = initState.optionKeys.map((option, i) =>
        this.getProductOptionsComponent(
          i,
          initState.optionValues[i],
          initState.optionKeys[i]
        )
      );
      const newState = { ...initState, optionsList };
      this.setState(newState);
    }

    if (options && options.length > 0) {
      this.optionsCheck.current.checked = true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { optionValues } = this.state;

    if (
      optionValues.length > 0 &&
      !isEqual(optionValues, prevState.optionValues)
    ) {
      this.createVariants();
    }
  }

  componentWillUnmount() {
    this.deleteProductImage();
    this.clearState();
  }

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleResponseSuccess = () => {
    const { handleModalClose } = this.props;
    this.clearState();
    handleModalClose();
  };

  handleSubmitForm = () => {
    const {
      fetchAddProductRequest,
      fetchUpdateProductRequest,
      userAuctionData: { vendor_id },
      product: { id = "" },
    } = this.props;
    const {
      variantsData,
      productData,
      checkedTag,
      tagsValue,
      defaultVariant,
      optionKeys,
      productImages,
      variantsImage,
    } = this.state;
    const tags = checkedTag.concat(",", tagsValue);
    const variants = getValidVariants(variantsData, defaultVariant);
    const images = productImages.concat(variantsImage);
    const product = {
      ...productData,
      tags,
      variants,
      options: optionKeys,
      images,
    };
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    const requestData = { vendor_id, product };

    this.setState({ errorMsg: "" });
    id
      ? fetchUpdateProductRequest({
          requestData,
          handleErrors,
          handleSuccess,
          id,
        })
      : fetchAddProductRequest({ requestData, handleErrors, handleSuccess });
  };

  addProductImage = (productImage) => {
    this.setState({
      productImages: [...this.state.productImages, productImage],
    });
  };

  addVariantsImage = (value, index) => {
    const { variantsImage } = this.state;
    const variantsImageArr = getSplicedArray(variantsImage, value, index);

    this.setState({ variantsImage: variantsImageArr });
  };

  deleteProductImage = (productImages) => {
    this.setState({ productImages });
  };

  deleteVariantsImage = (index) => {
    const { variantsImage } = this.state;
    const variantsImageArr = [...variantsImage];

    variantsImageArr.splice(index, 1);
    this.setState({ variantsImage: variantsImageArr });
  };

  addOptionValues = (value, index) => {
    const { optionValues } = this.state;
    const optionValuesArr = getSplicedArray(optionValues, value, index);

    this.setState({ optionValues: optionValuesArr });
  };

  addOptionKeys = (value, index) => {
    const { optionKeys } = this.state;
    const optionKeysArr = getSplicedArray(optionKeys, value, index);

    this.setState({ optionKeys: optionKeysArr });
  };

  handleVariantChange = (value, index) => {
    const { variantsData } = this.state;
    const variantsDataArr = getSplicedArray(variantsData, value, index);

    this.setState({ variantsData: variantsDataArr });
  };

  addOption = (e) => {
    const { optionsList, optionKeys } = this.state;

    this.addOptionKeys("Size", optionKeys.length);
    this.setState({
      optionsList: optionsList.concat(this.getProductOptionsComponent()),
    });
  };

  createVariants = () => {
    const { optionValues, variantsData } = this.state;
    const {
      product: { options, variants },
    } = this.props;
    const productOptionValues = getOptionsValues(options);
    const splittedOptionValues = splitOptionValues(optionValues);
    const generatedVariants = generateVariants(splittedOptionValues);
    let variantsListArr = [];

    if (
      productOptionValues.length > 0 &&
      !isEqual(productOptionValues, optionValues)
    ) {
      const variantsDataArr = generatedVariants.map((variant) =>
        getVariantData(variant)
      );
      variantsListArr = generatedVariants.map((variant, i) =>
        this.getProductVariantsComponent({ variant, i })
      );
      this.setState({
        variantsData: variantsDataArr,
        variantsList: variantsListArr,
      });
    } else {
      const selectedVariants = getSelectedVariants(variants);
      const variantsArr = isEmpty(selectedVariants[0])
        ? generatedVariants
        : selectedVariants;
      variantsListArr = variantsArr.map((variant, i) =>
        this.getProductVariantsComponent({
          variant,
          i,
          variantData: variantsData[i],
        })
      );
      this.setState({
        variantsList: variantsListArr,
      });
    }
  };

  getProductVariantsComponent = ({ variant, i, variantData = {} }) => {
    const key = Date.now();
    const index = `${key}-${i}`;

    return (
      <ProductVariants
        key={index}
        index={index}
        positionNumber={i}
        variant={variant.replace(/\/$/, "")}
        handleVariantChange={this.handleVariantChange}
        addVariantsImage={this.addVariantsImage}
        deleteVariantsImage={this.deleteVariantsImage}
        variantData={variantData}
      />
    );
  };

  clearState = () => {
    this.setState({
      optionValues: [],
      optionKeys: [],
      optionsList: [],
      variantsList: [],
      variantsData: [],
      variantsImage: [],
    });
  };

  removeOption = (index) => {
    const { optionsList, optionValues, optionKeys } = this.state;
    const optionsListArr = [...optionsList];
    const optionValuesArr = [...optionValues];
    const optionKeysArr = [...optionKeys];

    optionValuesArr.splice(index, 1);
    optionKeysArr.splice(index, 1);
    optionsListArr.splice(index, 1);

    const listArr = optionsListArr.map((option, i) =>
      this.getProductOptionsComponent(i, optionValuesArr[i], optionKeysArr[i])
    );

    if (listArr.length === 0) {
      this.optionsCheck.current.checked = false;
      this.clearState();
    } else {
      this.setState({
        optionsList: listArr,
        optionValues: optionValuesArr,
        optionKeys: optionKeysArr,
      });
    }
  };

  getProductOptionsComponent = (
    i = "",
    optionValue = "",
    optionKey = "Size"
  ) => {
    const { optionsList } = this.state;
    const key = Date.now();
    const positionNumber = i === "" ? optionsList.length : i;
    const index = `${key}-${positionNumber}`;

    return (
      <ProductOptions
        key={index}
        index={index}
        positionNumber={positionNumber}
        addOptionValues={this.addOptionValues}
        addOptionKeys={this.addOptionKeys}
        removeOption={this.removeOption}
        optionValue={optionValue}
        optionKey={optionKey}
      />
    );
  };

  handleAddOptionChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      this.addOption();
    } else {
      this.clearState();
    }
  };

  handleTagsRadioChange = (e) => {
    const checkedTag = e.target.value;
    this.setState({
      checkedTag,
    });
  };

  handleTagsChange = (value) => {
    this.setState({ tagsValue: value });
  };

  handleVariantDefaultChange = (value, key) => {
    const { defaultVariant } = this.state;
    const defaultVariantObj = { ...defaultVariant, [key]: value };

    this.setState({
      defaultVariant: defaultVariantObj,
    });
  };

  handleProductChange = (value, key) => {
    const { productData } = this.state;
    const productDataObj = { ...productData, [key]: value };

    this.setState({
      productData: productDataObj,
    });
  };

  render() {
    const { optionsList, variantsList } = this.state;
    const { buyNow, makeAnOffer } = tagsList;
    const {
      product: {
        title,
        description_html,
        tags,
        images = [],
        options,
        variants,
        video_src,
      },
    } = this.props;
    const productImagesSources = getProductsImageSources(variants, images);
    const defaultData = getDefaultVariant(options, variants);

    return (
      <>
        <Form
          data-cy="product-form"
          noValidate
          onSubmit={this.handleSubmitForm}
          render={({ handleSubmit, submitting, dirtySinceLastSubmit }) => (
            <BSForm onSubmit={handleSubmit}>
              <ProductImageForm
                addProductImage={this.addProductImage}
                deleteProductImage={this.deleteProductImage}
                imagePreviewUrl={productImagesSources}
              />

              <BSForm.Group>
                <label htmlFor="title">Title</label>
                <Field
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Title"
                  data-cy="product-title"
                  disabled={submitting}
                  initialValue={title || ""}
                  validate={composeValidators(
                    validateRequired,
                    validateMaxLength(MAX_STRING_LENGTH)
                  )}
                />
                <OnChange name="title">
                  {(value) => {
                    this.handleProductChange(value, "title");
                  }}
                </OnChange>
              </BSForm.Group>
              <BSForm.Group>
                <label htmlFor="descriptionHtml">Description</label>
                <Field
                  id="descriptionHtml"
                  as="textarea"
                  rows={3}
                  name="description_html"
                  type="text"
                  placeholder="Description"
                  data-cy="product-description"
                  disabled={submitting}
                  initialValue={description_html || ""}
                />
                <OnChange name="description_html">
                  {(value) => {
                    this.handleProductChange(value, "description_html");
                  }}
                </OnChange>
              </BSForm.Group>
              <BSForm.Row className="mx-0 mb-1">
                <Col className="col-5 pl-0 my-auto">
                  <label htmlFor="tags">Tags</label>
                  <BSForm.Group>
                    <Field
                      id="tags"
                      name="tags"
                      type="text"
                      placeholder="Tags"
                      data-cy="product-tags"
                      initialValue={getTagsValue(tags)}
                    />
                    <OnChange name="tags">
                      {(value) => {
                        this.handleTagsChange(value);
                      }}
                    </OnChange>
                  </BSForm.Group>
                </Col>
                <Col className="col-7 pr-0 text-right my-auto">
                  <BSForm.Group>
                    <BSForm.Check
                      type={"radio"}
                      inline
                      label={buyNow}
                      checked={this.state.checkedTag === buyNow}
                      onChange={this.handleTagsRadioChange}
                      value={buyNow}
                      name="buyNow"
                      data-cy="product-tag-buy-now"
                    />
                    <BSForm.Check
                      className="mr-0"
                      type={"radio"}
                      inline
                      label={makeAnOffer}
                      checked={this.state.checkedTag === makeAnOffer}
                      onChange={this.handleTagsRadioChange}
                      value={makeAnOffer}
                      name="makeAnOffer"
                      data-cy="product-tag-make-an-offer"
                    />
                  </BSForm.Group>
                </Col>
              </BSForm.Row>
              <BSForm.Group>
                <label htmlFor="title">Embedded Video URL</label>
                <Field
                  id="video_src"
                  name="video_src"
                  type="text"
                  placeholder="Embedded Video URL"
                  data-cy="video-src"
                  disabled={submitting}
                  initialValue={video_src || ""}
                />
                <OnChange name="video_src">
                  {(value) => {
                    this.handleProductChange(value, "video_src");
                  }}
                </OnChange>
              </BSForm.Group>
              {variantsList.length === 0 && (
                <BSForm.Group>
                  <label htmlFor="price">Price</label>
                  <Field
                    id="price"
                    name="price"
                    type="number"
                    placeholder="Price"
                    min="0"
                    step="0.01"
                    data-cy="product-price"
                    initialValue={defaultData.price}
                    validate={composeValidators(validateRequired)}
                  />
                  <OnChange name="price">
                    {(value) => {
                      this.handleVariantDefaultChange(Number(value), "price");
                    }}
                  </OnChange>
                </BSForm.Group>
              )}
              {variantsList.length === 0 && (
                <BSForm.Group>
                  <label htmlFor="compareAtPrice">Compare at Price</label>
                  <Field
                    id="compareAtPrice"
                    name="compare_at_price"
                    type="number"
                    placeholder="Compare at price"
                    min="0"
                    step="0.01"
                    data-cy="product-compare-at-price"
                    initialValue={defaultData.compare_at_price}
                    validate={composeValidators(validateRequired)}
                  />
                  <OnChange name="compare_at_price">
                    {(value) => {
                      this.handleVariantDefaultChange(
                        Number(value),
                        "compare_at_price"
                      );
                    }}
                  </OnChange>
                </BSForm.Group>
              )}
              {variantsList.length === 0 && (
                <BSForm.Group>
                  <label htmlFor="quantity">Quantity</label>
                  <Field
                    id="quantity"
                    name="quantity"
                    type="number"
                    placeholder="Quantity"
                    min="0"
                    step="1"
                    data-cy="product-quantity"
                    initialValue={defaultData.quantity}
                    validate={composeValidators(validateRequired)}
                  />
                  <OnChange name="quantity">
                    {(value) => {
                      this.handleVariantDefaultChange(
                        Number(value),
                        "quantity"
                      );
                    }}
                  </OnChange>
                </BSForm.Group>
              )}
              {variantsList.length === 0 && (
                <BSForm.Group>
                  <label htmlFor="sku">SKU (Stock Keeping Unit)</label>
                  <Field
                    id="sku"
                    name="sku"
                    type="text"
                    placeholder="SKU (Stock Keeping Unit)"
                    data-cy="product-sku"
                    initialValue={defaultData.sku}
                    validate={composeValidators(validateRequired)}
                  />
                  <OnChange name="sku">
                    {(value) => {
                      this.handleVariantDefaultChange(value, "sku");
                    }}
                  </OnChange>
                </BSForm.Group>
              )}
              <BSForm.Group>
                <BSForm.Check
                  type="checkbox"
                  label="This product has multiple options, like different sizes or colors"
                  onChange={this.handleAddOptionChange}
                  ref={this.optionsCheck}
                  name="optionsCheckbox"
                  data-cy="product-option-checkbox"
                />
              </BSForm.Group>
              {optionsList.length > 0 && (
                <>
                  {optionsList}
                  {optionsList.length < 3 && (
                    <BSForm.Row className="mx-0 mb-2">
                      <Col className="col-4 pl-0">
                        <Button
                          variant="primary"
                          onClick={this.addOption}
                          data-cy="add-option"
                        >
                          Add another option
                        </Button>
                      </Col>
                    </BSForm.Row>
                  )}
                </>
              )}
              {variantsList.length > 0 && (
                <>
                  <BSForm.Row className="mx-0 mt-2 justify-content-center">
                    <Col className="col-12 pl-0">
                      {variantsList.length > 99 && (
                        <p className="invalid-feedback d-block">
                          {`You can't have more than 100 product variants. To save
                          product, remove some options to keep variants under
                          100.`}
                        </p>
                      )}
                    </Col>
                  </BSForm.Row>
                  <BSForm.Row className="mx-0 mt-2 justify-content-center">
                    <Col className="col-12 pl-0">
                      <div className="table-responsive">
                        <Table
                          striped
                          hover
                          responsive
                          className="table"
                          data-cy="variant-table"
                        >
                          <thead>
                            <tr>
                              <th className="pr-1" data-cy="variant-th">
                                Variant
                              </th>
                              <th className="pl-1" data-cy="image-th">
                                Image
                              </th>
                              <th className="px-1" data-cy="price-th">
                                Price
                              </th>
                              <th
                                className="px-1"
                                data-cy="compare-at-price-th"
                              >
                                Compare at price
                              </th>
                              <th className="px-1" data-cy="quantity-th">
                                Quantity
                              </th>
                              <th className="px-1" data-cy="sku-th">
                                SKU
                              </th>
                              <th className="pl-1" data-cy="delete-th"></th>
                            </tr>
                          </thead>
                          <tbody>{variantsList}</tbody>
                        </Table>
                      </div>
                    </Col>
                  </BSForm.Row>
                </>
              )}
              <BSForm.Group>
                {this.state.errorMsg && !dirtySinceLastSubmit && (
                  <p className="invalid-feedback d-block" data-cy="error-msg">
                    {this.state.errorMsg}
                  </p>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  disabled={submitting}
                  data-cy="product-save-btn"
                >
                  Save
                </Button>
              </BSForm.Group>
            </BSForm>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAddProductRequest: (values) => {
    dispatch(actions.fetchAddProductRequest(values));
  },
  fetchUpdateProductRequest: (values) => {
    dispatch(actions.fetchUpdateProductRequest(values));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(ProductForm));
