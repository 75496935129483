import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";

import * as actions from "./actions";

export function* fetchBannerRequest() {
  const request = () => superagent.get(`/api/prismic/get_banner`);
  try {
    const response = yield call(request);
    yield put(actions.fetchBannerRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchStripeBannerRequest() {
  const request = () => superagent.get(`/api/prismic/get_stripe_banner`);
  try {
    const response = yield call(request);
    yield put(actions.fetchStripeBannerRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchHomepageBannersRequest() {
  const request = () => superagent.get(`/api/prismic/get_homepage_banners`);
  try {
    const response = yield call(request);
    yield put(actions.fetchHomepageBannersRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export function* fetchReviewsCarouselRequest() {
  const request = () => superagent.get(`/api/prismic/get_reviews_carousel`);
  try {
    const response = yield call(request);
    yield put(actions.fetchReviewsCarouselRequestResult(response.body));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export default [
  takeLatest(actions.FETCH_BANNER_REQUEST, fetchBannerRequest),
  takeLatest(actions.FETCH_STRIPE_BANNER_REQUEST, fetchStripeBannerRequest),
  takeLatest(
    actions.FETCH_REVIEWS_CAROUSEL_REQUEST,
    fetchReviewsCarouselRequest
  ),
  takeLatest(
    actions.FETCH_HOMEPAGE_BANNERS_REQUEST,
    fetchHomepageBannersRequest
  ),
];
