import React, { PureComponent } from "react";

export default class UA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g>
          <rect width="512" height="341.326" fill="#ffda44" />
          <rect width="512" height="170.663" fill="#338af3" />
        </g>
      </svg>
    );
  }
}
