export const FETCH_BANNER_REQUEST = "banner/FETCH_BANNER_REQUEST";
export const FETCH_BANNER_REQUEST_RESULT = "banner/FETCH_BANNER_REQUEST_RESULT";
export const FETCH_STRIPE_BANNER_REQUEST = "banner/FETCH_STRIPE_BANNER_REQUEST";
export const FETCH_STRIPE_BANNER_REQUEST_RESULT =
  "banner/FETCH_STRIPE_BANNER_REQUEST_RESULT";
export const FETCH_REVIEWS_CAROUSEL_REQUEST =
  "banner/FETCH_REVIEWS_CAROUSEL_REQUEST";
export const FETCH_REVIEWS_CAROUSEL_REQUEST_RESULT =
  "banner/FETCH_REVIEWS_CAROUSEL_REQUEST_RESULT";
export const FETCH_HOMEPAGE_BANNERS_REQUEST =
  "banner/FETCH_HOMEPAGE_BANNERS_REQUEST";
export const FETCH_HOMEPAGE_BANNERS_REQUEST_RESULT =
  "banner/FETCH_HOMEPAGE_BANNERS_REQUEST_RESULT";

export function fetchBannerRequest() {
  return { type: FETCH_BANNER_REQUEST };
}

export function fetchBannerRequestResult(params) {
  return { type: FETCH_BANNER_REQUEST_RESULT, payload: params };
}

export function fetchStripeBannerRequest() {
  return { type: FETCH_STRIPE_BANNER_REQUEST };
}

export function fetchStripeBannerRequestResult(params) {
  return { type: FETCH_STRIPE_BANNER_REQUEST_RESULT, payload: params };
}

export function fetchReviewsCarouselRequest(params) {
  return { type: FETCH_REVIEWS_CAROUSEL_REQUEST, payload: params };
}

export function fetchReviewsCarouselRequestResult(params) {
  return { type: FETCH_REVIEWS_CAROUSEL_REQUEST_RESULT, payload: params };
}

export function fetchHomepageBannersRequest(params) {
  return { type: FETCH_HOMEPAGE_BANNERS_REQUEST, payload: params };
}

export function fetchHomepageBannersRequestResult(params) {
  return { type: FETCH_HOMEPAGE_BANNERS_REQUEST_RESULT, payload: params };
}
