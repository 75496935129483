import React, { PureComponent } from "react";

export default class PG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect width="512" height="341.337" transform="translate(0 85.333)" />
          <path d="M0,85.33H512V426.662" fill="#a2001d" />
          <g>
            <path
              d="M204.631,326.488l2.762,8.5h8.941l-7.233,5.255,2.763,8.5-7.233-5.254-7.233,5.254,2.762-8.5-7.233-5.255h8.94Z"
              fill="#f0f0f0"
            />
            <path
              d="M181.8,244.866l4.6,14.172h14.9L189.247,267.8l4.605,14.172L181.8,273.208l-12.055,8.759,4.606-14.172-12.056-8.757h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M181.8,348.749l4.6,14.173h14.9l-12.057,8.757,4.606,14.173L181.8,377.093l-12.055,8.759,4.6-14.173-12.054-8.757h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M225.363,281.967l4.606,14.172h14.9L232.814,304.9l4.605,14.172-12.056-8.759-12.054,8.759,4.606-14.172-12.056-8.758h14.9Z"
              fill="#f0f0f0"
            />
            <path
              d="M138.23,281.967l4.606,14.172h14.9L145.681,304.9l4.6,14.172L138.23,310.31l-12.055,8.759,4.6-14.172-12.055-8.758h14.9Z"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M376.526,204.163a44.186,44.186,0,0,0-28.189-12.908l31.88-24.795A59.207,59.207,0,0,0,333.9,149.242a44.31,44.31,0,0,0-11.4-19.511l-12.593,25.186A18.55,18.55,0,1,0,284.724,180.1l-25.186,12.593a44.3,44.3,0,0,0,19.519,11.4,59.179,59.179,0,0,0,17.209,46.317L324.12,214.6A11.128,11.128,0,0,1,339.8,230.4l10.494,10.494a25.979,25.979,0,0,0,2.454-33.9,29.658,29.658,0,0,1,13.288,49.637l10.494,10.494a44.522,44.522,0,0,0,0-62.962Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
