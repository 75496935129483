import React, { PureComponent } from "react";

export default class BB extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#ffda44"
          />
          <g>
            <rect
              width="170.663"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#0052b4"
            />
            <rect
              width="170.663"
              height="341.337"
              transform="translate(341.337 85.331)"
              fill="#0052b4"
            />
          </g>
          <path d="M307.942,189.212l9.955,4.978-9.955-4.978-9.955-4.978c-.6,1.2-13.6,27.649-15.723,68.05H267.13V189.211L256,174.372l-11.13,14.84v63.073H229.737c-2.124-40.4-15.123-66.849-15.723-68.05L194.1,194.19c.137.273,13.666,27.811,13.666,69.226v11.13h37.1v63.073h22.261V274.546h37.1v-11.13a187.21,187.21,0,0,1,6.813-50.109,123.651,123.651,0,0,1,6.865-19.141Z" />
        </g>
      </svg>
    );
  }
}
