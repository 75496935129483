import React, { PureComponent } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SubMenuItem from "./SubMenuItem";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as actions from "../actions";
import { getMenuState } from "../selectors";

import { NavStyled, VendorInfoStyled, VendorLogoStyled } from "./NavBar.styled";
import { handeleize, generatorUrl } from "../utils";

class NavBar extends PureComponent {
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    const { menuToggle } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      menuToggle(undefined);
    }
  };

  render() {
    const { isVendorPage, vendorInfo } = this.props;
    const Menu = () => {
      const { menu, prefix } = this.props;
      return menu.map(({ title, url, children }, index) => (
        <Nav.Item key={title + index}>
          {children ? (
            <SubMenuItem title={title} data={children} />
          ) : (
            <Nav.Link
              id={`menu-link-${handeleize(title)}`}
              as={NavLink}
              to={generatorUrl(prefix, url)}
              exact={true}
            >
              {title}
            </Nav.Link>
          )}
        </Nav.Item>
      ));
    };

    return (
      <Navbar className="header-navbar border-top border-bottom py-0">
        <Container ref={this.setWrapperRef}>
          <Row className="mx-auto w-100">
            <Col>
              {isVendorPage ? (
                <VendorInfoStyled data-cy="vendor-info">
                  <Col className="col-8" data-cy="vendor-page--logo">
                    {vendorInfo?.logo ? (
                      <VendorLogoStyled
                        src={vendorInfo.logo}
                        alt={vendorInfo.store_name}
                      />
                    ) : (
                      <h3
                        className="mb-0 text-break text-center"
                        data-cy="vendor-page--store-name"
                      >
                        {vendorInfo.store_name}
                      </h3>
                    )}
                  </Col>
                  {vendorInfo?.phone && (
                    <Col className="text-right" data-cy="vendor-page--phone">
                      Phone:&nbsp;
                      <a href={`tel:${vendorInfo.phone}`}>{vendorInfo.phone}</a>
                    </Col>
                  )}
                  {vendorInfo?.email && (
                    <Col className="text-right" data-cy="vendor-page--email">
                      Email:&nbsp;
                      {vendorInfo.email}
                    </Col>
                  )}
                </VendorInfoStyled>
              ) : (
                <NavStyled
                  variant="pills"
                  defaultActiveKey="/"
                  className="justify-content-center text-uppercase"
                  data-cy="second-level-menu"
                >
                  <Menu />
                </NavStyled>
              )}
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  menuState: getMenuState,
});

const mapDispatchToProps = (dispatch) => ({
  menuToggle: (value) => {
    dispatch(actions.menuToggle(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
