import React, { PureComponent } from "react";

export default class CX extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#0052b4"
          />
          <path d="M0,85.33H512V426.662" fill="#6da544" />
          <circle
            cx="44.522"
            cy="44.522"
            r="44.522"
            transform="translate(211.478 211.474)"
            fill="#ffda44"
          />
          <g>
            <path
              d="M127.576,352.46l4.684,9.793,10.575-2.444-4.736,9.767,8.5,6.745-10.588,2.385.029,10.855-8.468-6.791-8.468,6.791.029-10.855-10.59-2.385,8.506-6.745-4.736-9.767,10.575,2.444Z"
              fill="#f0f0f0"
            />
            <path
              d="M96.941,270.836l4.683,9.793,10.575-2.444-4.735,9.768,8.5,6.744-10.588,2.386.029,10.855-8.468-6.791-8.469,6.791.029-10.855L77.913,294.7l8.5-6.744-4.736-9.768,10.575,2.444Z"
              fill="#f0f0f0"
            />
            <path
              d="M127.576,204.054l4.684,9.792,10.575-2.444L138.1,221.17l8.5,6.744L136.015,230.3l.029,10.856-8.468-6.792-8.468,6.792.029-10.856-10.589-2.386,8.5-6.744-4.735-9.768,10.575,2.444Z"
              fill="#f0f0f0"
            />
            <path
              d="M165.631,256l4.684,9.793,10.576-2.446-4.736,9.769,8.5,6.744-10.588,2.387L174.1,293.1l-8.468-6.791-8.467,6.791.029-10.854-10.59-2.387,8.505-6.744-4.736-9.769,10.576,2.446Z"
              fill="#f0f0f0"
            />
            <path
              d="M138.419,300.518l3.684,11.337h11.921l-9.644,7.007,3.684,11.337-9.645-7.006-9.643,7.006,3.684-11.337-9.645-7.007h11.921Z"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M256,285.678h14.84s6.431-11.376,0-22.261l14.84-14.84-7.42-14.841h-7.421s-3.71,11.13-18.551,11.13-18.55-11.13-18.55-11.13h-7.42l7.42,14.84-7.421,14.84,7.421,7.421S241.159,256,256,263.416C256,263.417,262.308,272.692,256,285.678Z"
            fill="#6da544"
          />
          <path
            d="M396.449,200.819a29.7,29.7,0,0,0-20-33.867,55.681,55.681,0,0,1,5.005,33.253,30.456,30.456,0,0,0-42.707-9.322,56.86,56.86,0,0,1,23.335,11.1c5.536,4.4,10.01,10.364,13.434,16.116L330.2,232.291c44.522,7.421,81.623-22.261,81.623-22.261C407.716,201.809,401.33,200.424,396.449,200.819Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
