import React, { PureComponent } from "react";

export default class GR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 362.023 241.346"
      >
        <g transform="translate(0 -60.338)">
          <rect
            width="362.023"
            height="241.346"
            transform="translate(0 60.338)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="362.023"
              height="30.168"
              transform="translate(0 60.338)"
              fill="#338af3"
            />
            <rect
              width="362.023"
              height="30.168"
              transform="translate(0 120.679)"
              fill="#338af3"
            />
            <rect
              width="362.023"
              height="30.168"
              transform="translate(0 181.014)"
              fill="#338af3"
            />
            <rect
              width="362.023"
              height="30.168"
              transform="translate(0 241.35)"
              fill="#338af3"
            />
            <rect
              width="133.788"
              height="120.676"
              transform="translate(0 60.338)"
              fill="#338af3"
            />
          </g>
          <g>
            <rect
              width="133.788"
              height="26.814"
              transform="translate(0 107.27)"
              fill="#f0f0f0"
            />
            <rect
              width="26.814"
              height="120.676"
              transform="translate(53.487 60.338)"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
