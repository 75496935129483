import { fromJS } from "immutable";
import * as actions from "./actions";

const initialState = fromJS({
  page: {},
});

const saveStaticPageFromPrismic = (state, action) =>
  state.set("page", fromJS(action.payload));

const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_STATIC_PAGE_FROM_PRISMIC:
      return saveStaticPageFromPrismic(state, action);
    default:
      return state;
  }
};

export default pagesReducer;
