import React, { PureComponent } from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  getCustomerAddresses,
  getCustomerDefaultAddressId,
} from "../selectors";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import AddAddressModal from "./AddAddressModal";
import AddressesList from "./AddressesList";
import { connect } from "react-redux";
import * as actions from "../actions";
import { StyledContainer } from "./AddressesPage.styled";
import { BackButton } from "storefront/features/shared";

class AddressesPage extends PureComponent {
  handleOpenModal = () => {
    this.props.setModalAddresses(true, {});
  };

  render() {
    const { defaultAddressId, addressesList } = this.props;
    return (
      <StyledContainer>
        <Row className="my-4 my-md-5">
          <Col>
            <p>
              <BackButton
                className="btn btn-primary text-uppercase"
                color="white"
              />
            </p>
            <h1 className="text-uppercase">My addresses</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={this.handleOpenModal}
              variant="link"
              className="text-uppercase add-new-address mb-5 mt-0 ml-5"
              data-cy="ap-add-new-address-btn"
            >
              Add a new address
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center justify-content-lg-start">
          {defaultAddressId ? (
            <AddressesList
              addresses={addressesList}
              defaultAddressId={defaultAddressId}
              editButtons={true}
              width={4}
            />
          ) : (
            <p className="no-addresses-text ml-5">
              No addresses were saved yet
            </p>
          )}
        </Row>
        <AddAddressModal />
      </StyledContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  addressesList: getCustomerAddresses,
  defaultAddressId: getCustomerDefaultAddressId,
});

const mapDispatchToProps = (dispatch) => ({
  setModalAddresses: (open, data) => {
    dispatch(actions.setModalAddresses({ open, data }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(AddressesPage));
