import { createSelector } from "reselect";

export const pageSelector = (state) => state.getIn(["pagesReducer", "page"]);

export const getPageTitle = createSelector(pageSelector, (page) =>
  page.get("page_title")
);

export const getPageContent = createSelector(pageSelector, (page) =>
  page.get("page_content")
);
