import React, { PureComponent } from "react";

export default class AO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect width="512" height="341.337" transform="translate(0 85.331)" />
          <rect
            width="512"
            height="170.663"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <g>
            <path
              d="M232.6,221.069l14.469,10.5-5.513,17.005,14.456-10.516,14.47,10.5-5.536-17L279.4,221.04l-17.877.012-5.535-17-5.515,17.006Z"
              fill="#ffda44"
            />
            <path
              d="M298.666,182.094A84.776,84.776,0,0,0,256,170.69v22.255a63.074,63.074,0,1,1-52.616,97.79L184.8,303.005A85.327,85.327,0,1,0,298.666,182.094Z"
              fill="#ffda44"
            />
            <path
              d="M206.792,241.154a22.263,22.263,0,0,0,8.846,30.214l72.3,39.506c-4.916,8.988-2.306,19.927,6.682,24.843L314.15,346.4a18.551,18.551,0,0,0,25.178-7.372L350.011,319.5Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
