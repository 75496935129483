import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";
import * as actions from "./actions";

export function* fetchSproutBannerRequest() {
  const host = "https://sprout-app.thegoodapi.com";
  const shop = "bidscom.myshopify.com";
  const locale = "en";

  const request = () =>
    superagent
      .get(`${host}/app/badges/tree_count?shop=${shop}&locale=${locale}`)
      .responseType("text");

  try {
    const response = yield call(request);
    yield put(actions.fetchSproutBannerRequestResult(response.text));
  } catch (res) {
    console.log(`error - ${res}`);
  }
}

export default [
  takeLatest(actions.FETCH_SPROUT_BANNER_REQUEST, fetchSproutBannerRequest),
];
