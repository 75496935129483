import bid_auctions from "../features/shared/sagas";
import quick_view from "../features/productGrid/sagas";
import header from "../features/shared/components/Header/sagas";
import account from "../features/account/sagas";
import auctions from "../features/auctions/sagas";
import buy_it_now from "../features/buyItNow/sagas";
import make_an_offer from "../features/makeAnOffer/sagas";
import cart from "../features/cart/sagas";
import search from "../features/search/sagas";
import banner from "../features/prismicBanner/sagas";
import sprout_banner from "../features/sproutBanner/sagas";
import productsSettings from "../features/productsSettings/sagas";
import vendor from "../features/vendor/sagas";

export default {
  bid_auctions,
  quick_view,
  header,
  account,
  auctions,
  buy_it_now,
  make_an_offer,
  cart,
  search,
  banner,
  sprout_banner,
  productsSettings,
  vendor,
};
