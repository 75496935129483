import React, { PureComponent } from "react";

export default class GH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#496e2d"
          />
          <path d="M255.883,199.111,270,242.569l40.366,3.2L273.4,272.63l19.453,40.259L255.883,286.03l-36.97,26.859,14.123-43.459-36.969-26.861h45.7Z" />
        </g>
      </svg>
    );
  }
}
