import React, { PureComponent } from "react";

export default class SC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path d="M235.454,85.337,0,426.663,427.345,85.337Z" fill="#ffda44" />
          <path d="M512,329.393,0,426.663H512Z" fill="#6da544" />
          <path d="M512,85.337H427.345L0,426.663,512,222.151Z" fill="#d80027" />
          <path d="M0,85.337V426.663L235.454,85.337Z" fill="#0052b4" />
        </g>
      </svg>
    );
  }
}
