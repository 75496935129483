import React, { PureComponent } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

class VendorFilter extends PureComponent {
  handleFilterChange = (e) => {
    const { filterItemHandleChange } = this.props;
    const value = e.target.value;
    filterItemHandleChange(value);
  };

  render() {
    const { filter } = this.props;
    return (
      <Container>
        <Row className="justify-content-end mt-4">
          <Col xs={12} sm={4} md={3} className="px-sm-2">
            <Form>
              <Form.Control
                as="select"
                size="sm"
                onChange={this.handleFilterChange}
                name="vendor-filter"
                value={filter}
              >
                <option value="">All products</option>
                <option value="auction">All auction</option>
                <option value="buy-now">All buy now</option>
                <option value="make-an-offer">All make an offer</option>
              </Form.Control>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default VendorFilter;
