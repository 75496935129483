import React, { PureComponent } from "react";

export default class ET extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#d80027"
          />
          <circle
            cx="81.619"
            cy="81.619"
            r="81.619"
            transform="translate(174.381 174.381)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M255.982,192.8l14.735,45.346h47.757l-38.65,28.028,14.849,45.481-38.691-28.16L217.352,311.6l14.79-45.429-38.616-28.028h47.722Z"
              fill="#ffda44"
            />
            <path
              d="M314.748,267.286,268.005,252.1l28.888-39.759-12.005-8.724L256,243.376l-28.887-39.76-12.006,8.722L243.992,252.1l-46.74,15.186,4.585,14.115,46.742-15.188v49.148h14.842V266.213l46.74,15.188Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
