import React, { PureComponent } from "react";

export default class MZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="103.88"
            transform="translate(0 322.783)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="104.515"
            transform="translate(0 85.337)"
            fill="#496e2d"
          />
          <rect width="512" height="89.656" transform="translate(0 210.877)" />
          <path d="M256,256.006,0,426.668V85.331Z" fill="#a2001d" />
          <path
            d="M83.477,195.132l15.107,46.5h48.894l-39.554,28.739,15.107,46.5L83.477,288.13,43.922,316.868l15.11-46.5L19.478,241.63H68.37Z"
            fill="#ffda44"
          />
          <rect
            width="88.42"
            height="40.578"
            transform="translate(39.268 256)"
            fill="#f0f0f0"
          />
          <path d="M144.443,209.479,130.1,195.132,83.478,241.75,36.861,195.132,22.514,209.479l46.658,46.577L22.514,302.714l14.347,14.154,46.617-46.534L130.1,316.868l14.347-14.154L97.784,256.056Z" />
        </g>
      </svg>
    );
  }
}
