import React, { PureComponent } from "react";

export default class SB extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#496e2d"
          />
          <path d="M0,426.663V85.329H512" fill="#0052b4" />
          <path
            d="M0,396.494v30.169H45.255L512,115.5V85.329H466.745Z"
            fill="#ffda44"
          />
          <g>
            <path
              d="M85.688,108.787l5.12,15.756h16.566l-13.4,9.737,5.12,15.757-13.4-9.738-13.405,9.738L77.4,134.28,64,124.543H80.567Z"
              fill="#f0f0f0"
            />
            <path
              d="M170.312,108.787l5.121,15.756H192l-13.4,9.737,5.12,15.757-13.4-9.738-13.4,9.738,5.12-15.757-13.4-9.737h16.566Z"
              fill="#f0f0f0"
            />
            <path
              d="M85.688,191.289l5.12,15.756h16.566l-13.4,9.738,5.12,15.757-13.4-9.739L72.283,232.54l5.12-15.757L64,207.045H80.567Z"
              fill="#f0f0f0"
            />
            <path
              d="M170.312,191.289l5.121,15.756H192l-13.4,9.738,5.12,15.757-13.4-9.739-13.4,9.739,5.12-15.757-13.4-9.738h16.566Z"
              fill="#f0f0f0"
            />
            <path
              d="M128,150.037l5.12,15.756h16.568l-13.4,9.738,5.12,15.758L128,181.55l-13.4,9.739,5.12-15.758-13.405-9.738H122.88Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
