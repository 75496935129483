import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";

import ProductForm from "./ProductForm";

class ModalProduct extends PureComponent {
  handleClose = () => {
    const { handleModalClose } = this.props;
    handleModalClose();
  };

  render() {
    const { show, product } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop="static"
        size="lg"
        data-cy="product-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {product.id ? "Edit product" : "Add product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm handleModalClose={this.handleClose} product={product} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalProduct;
