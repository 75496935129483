import styled from "styled-components";

export const StyledReviewsCarouselContainer = styled.div`
  display: none;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1024px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
    padding: 0 20px;
    object-fit: scale-down;

    @media (min-width: 1024px) {
      height: 200px;
    }

    @media (min-width: 1360px) {
      height: 250px;
    }

    @media (min-width: 1600px) {
      height: 300px;
    }
  }
`;
