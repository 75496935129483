import React, { PureComponent } from "react";
import { Col, InputGroup, Form } from "react-bootstrap";

class CartRingSizingOptions extends PureComponent {
  handleVariantChange = (e) => {
    const { handleRingSizingOptionsChange } = this.props;
    const receivedValue =
      e.target.value !== "Original Size" ? e.target.value : undefined;
    handleRingSizingOptionsChange(receivedValue);
  };

  render() {
    const { ringSizing } = this.props;
    return (
      <Col>
        <Form>
          <InputGroup className="w-auto mb-2 align-items-center">
            <Form.Label className="mr-2 mb-0">Resizing options</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              onChange={this.handleVariantChange}
              name={"ring-resizing"}
              value={ringSizing ? ringSizing : "default"}
            >
              <option key="default">Original Size</option>
              <option key="one_size_up">One Size Up</option>
              <option key="two_sizes_up">Two Sizes Up</option>
              <option key="one_size_down">One Size Down</option>
              <option key="two_sizes_down">Two Sizes Down</option>
            </Form.Control>
          </InputGroup>
        </Form>
      </Col>
    );
  }
}

export default CartRingSizingOptions;
