import React, { PureComponent } from "react";

export default class MT extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <rect
            width="256"
            height="341.337"
            transform="translate(256 85.331)"
            fill="#d80027"
          />
          <path
            d="M208.231,138.671V117.337H186.9v21.334H165.565V160H186.9v21.334h21.332V160h21.334V138.671Z"
            transform="translate(-131)"
            fill="#acabb1"
          />
        </g>
      </svg>
    );
  }
}
