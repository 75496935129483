import React, { PureComponent } from "react";
import QuickViewImageSlider from "./QuickViewImageSlider";
import { Image } from "react-bootstrap";

class ProductImage extends PureComponent {
  render() {
    const { src, altText, shadow, images } = this.props;

    if (images?.length > 1) {
      return <QuickViewImageSlider images={images} />;
    }

    return (
      <picture>
        <Image
          fluid
          src={src}
          alt={altText}
          className={`${
            shadow ? "shadow-sm p-3 mb-3 mb-md-5 bg-white rounded" : ""
          }`}
        />
      </picture>
    );
  }
}

export default ProductImage;
