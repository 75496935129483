export const getSchema = (background = "white", themeName = "default") => {
  const backgroundColor = theme.backgroundColor[background] ?? "#ffffff",
    stripColor = theme.themeColors[themeName]?.strip ?? "#ffffff",
    buttonColor = theme.themeColors[themeName]?.button ?? {
      start: "#0079bc",
      end: "#00509d",
    };

  return {
    backgroundColor,
    stripColor,
    buttonColor,
  };
};

export const theme = {
  backgroundColor: {
    white: "#ffffff",
    light: "#f8f8f8",
  },
  themeColors: {
    native: {
      strip: "#ffffff",
      button: { start: "#0079bc", end: "#00509d" },
    },
    bruges: {
      strip: "#cdcdcd",
      button: { start: "#cdcdcd", end: "#cdcdcd" },
    },
    zurich: {
      strip: "#595959",
      button: { start: "#595959", end: "#595959" },
    },
    prague: {
      strip: "#212121",
      button: { start: "#212121", end: "#212121" },
    },
    queenstown: {
      strip: "#964000",
      button: { start: "#964000", end: "#964000" },
    },
    singapore: {
      strip: "#009fe3",
      button: { start: "#009fe3", end: "#009fe3" },
    },
    jodhpur: { strip: "#ffed00", button: { start: "#ffed00", end: "#ffed00" } },
    porto: { strip: "#af8655", button: { start: "#cf9e64", end: "#b38856" } },
    chefchaouen: {
      strip: "#7e6b5d",
      button: { start: "#a18976", end: "#816d5f" },
    },
    edinburgh: {
      strip: "#f28d4f",
      button: { start: "#ffb464", end: "#f89050" },
    },
    muscat: { strip: "#ea5b0c", button: { start: "#ff740e", end: "#ff740e" } },
    quito: { strip: "#e50051", button: { start: "#ff0067", end: "#ea0052" } },
    cartagena: {
      strip: "#688816",
      button: { start: "#85ae1b", end: "#6a8b16" },
    },
    procida: { strip: "#0069b4", button: { start: "#008ff5", end: "#006bb9" } },
    chiang_mai: {
      strip: "#6a0f5a",
      button: { start: "#a8168f", end: "#6e0f5d" },
    },
    moscow: { strip: "#00534f", button: { start: "#00847d", end: "#565200" } },
    cape_town: {
      strip: "#009a93",
      button: { start: "#00bbb2", end: "#009c95" },
    },
    dallol: { strip: "#557800", button: { start: "#006c99", end: "#00567a" } },
  },
};
