import React, { PureComponent } from "react";

export default class BW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="103.88"
              transform="translate(0 322.783)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="104.515"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
          </g>
          <rect width="512" height="89.656" transform="translate(0 210.877)" />
        </g>
      </svg>
    );
  }
}
