import React, { PureComponent } from "react";
import Icon from "storefront/features/shared/icons";
import MobileMenu from "./MobileMenu";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { MenuContainer } from "./Header.styled";

export default class MobileNavBar extends PureComponent {
  render() {
    const { isUserLogged, prefix, isVendorPage } = this.props;
    return (
      <MenuContainer>
        <Row>
          <Col className="py-2 text-sm-left text-right">
            <Button
              variant="link"
              onClick={this.props.closeFunction}
              data-cy="menu-mobile-close-btn"
            >
              <Icon type="close" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link
              id="header-mobile-auction-link"
              className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
              to="/collections/all-auctions"
            >
              Auction
            </Link>
            {/* <Link
              id="header-mobile-make-an-offer-link"
              className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
              to="/collections/make-an-offer"
            >
              Make an offer
            </Link> */}
            <Link
              id="header-mobile-buy-now-link"
              className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
              to="/collections/buy-it-now"
            >
              Buy now
            </Link>
            {!isUserLogged ? (
              <a
                id="header-mobile-partner-dashboard-link"
                className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
                href="https://bids.tapfiliate.com/login"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Partner dashboard
              </a>
            ) : (
              <Link
                id="header-mobile-make-money-link"
                className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
                to="/pages/ez-make-money-with-bids"
              >
                Make money
              </Link>
            )}
            <a
              id="header-mobile-partner-investors-link"
              className="d-block text-center btn btn-outline-dark w-100 my-2 py-1"
              href="https://bidscom.mailchimpsites.com/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Investors
            </a>
          </Col>
        </Row>
        {isUserLogged ? (
          <Row>
            <Col className="text-center">
              <Link
                id="header-mobile-login-link"
                className="btn my-2 py-1"
                to="/account/login"
              >
                Login
              </Link>
            </Col>
            <Col className="text-center">
              <Link
                id="header-mobile-register-link"
                className="btn my-2 py-1"
                to="/account/register"
              >
                Register
              </Link>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="text-center">
              <Link
                id="header-mobile-account-link"
                className="btn my-2 py-1"
                to="/account/my-account"
              >
                My Account
              </Link>
            </Col>
          </Row>
        )}
        <Row data-cy="second-level-menu-mobile">
          <Col>
            {!isVendorPage && (
              <MobileMenu menuItems={this.props.menu} prefix={prefix} />
            )}
          </Col>
        </Row>
      </MenuContainer>
    );
  }
}
