import React, { PureComponent } from "react";

export default class UY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="48.762"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="48.762"
              transform="translate(0 182.862)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="48.762"
              transform="translate(0 280.387)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="48.762"
              transform="translate(0 377.9)"
              fill="#338af3"
            />
          </g>
          <rect
            width="256"
            height="195.05"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <path
            d="M192,182.857l-26.151,12.3,13.925,25.327-28.4-5.433-3.6,28.687L128,222.641l-19.78,21.1-3.6-28.687-28.4,5.432,13.925-25.326L64,182.857l26.151-12.3L76.226,145.23l28.4,5.432,3.6-28.687L128,143.074l19.78-21.1,3.6,28.687,28.4-5.432-13.926,25.327Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
