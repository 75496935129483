import React, { PureComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";

import RegisterForm from "storefront/features/account/components/RegisterForm";
import { StyledVideo } from "./MakeMoney.styled";
import { Helmet } from "storefront/features/shared";
import { metaTagsMakeMoney } from "../metaTags";

class MakeMoney extends PureComponent {
  render() {
    return (
      <>
        <Helmet metaTags={metaTagsMakeMoney} />
        <Container className="my-5 ">
          <h1 className="text-uppercase text-center mb-5">
            Join our affiliate program and start earning today!!
          </h1>
          <Row>
            <Col xs={12} lg={6}>
              <StyledVideo autoPlay loop controls muted playsInline>
                <source
                  src="https://cdn.shopify.com/s/files/1/0071/2081/4195/files/VIDEO_1_v2.mp4?v=1605458088"
                  type="video/mp4"
                />
              </StyledVideo>
            </Col>
            <Col xs={12} lg={6}>
              <RegisterForm />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MakeMoney;
