import { call, put, takeLatest } from "redux-saga/effects";
import superagent from "superagent";

import * as actions from "storefront/features/shared/actions";

export function* fetchBuyItNowRequest(action) {
  const { loader } = action.payload;
  const request = () =>
    superagent.get(`/api/collections/buy-it-now`).query(action.payload);
  const response = yield call(request);
  yield put(actions.fetchAllProductsRequestResult(response.body));
  yield put(actions.removeLoader({ loader }));
}

export default [
  takeLatest(actions.FETCH_BUY_IT_NOW_REQUEST, fetchBuyItNowRequest),
];
