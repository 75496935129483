import React, { PureComponent } from "react";

export default class CloseIcon extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.557"
        height="15.556"
        viewBox="0 0 15.557 15.556"
      >
        <path
          style={{ fill: "#494949" }}
          d="M7.778,9.192,1.414,15.557,0,14.142,6.364,7.778,0,1.414,1.414,0,7.778,6.364,14.142,0l1.415,1.414L9.192,7.778l6.364,6.364-1.415,1.415Z"
        />
      </svg>
    );
  }
}
