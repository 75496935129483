import React, { PureComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Icon from "storefront/features/shared/icons";
import { SocialLinkContainerStyled } from "./Footer.style";

export default class FooterSocialIcons extends PureComponent {
  render() {
    return (
      <Container fluid>
        <Row className="py-3 border-top">
          <Col className="text-center d-flex justify-content-center">
            <SocialLinkContainerStyled
              id="footer-social-link-facebook"
              color={"#3b5998"}
              className="mx-3"
              href="https://www.facebook.com/BidsAuction"
              target="_blank"
            >
              <Icon type="facebook" size={25} />
            </SocialLinkContainerStyled>
            {/* <SocialLinkContainerStyled
              id="footer-social-link-twitter"
              color={"#3cf"}
              className="mx-3"
              href="https://twitter.com/BidsAuction"
              target="_blank"
            >
              <Icon type="twitter" size={25} />
            </SocialLinkContainerStyled>
            <SocialLinkContainerStyled
              id="footer-social-link-instagram"
              color={"#dc4a38"}
              className="mx-3"
              href="https://www.instagram.com/bidscom"
              target="_blank"
            >
              <Icon type="instagram" size={25} />
            </SocialLinkContainerStyled> */}
          </Col>
        </Row>
      </Container>
    );
  }
}
