import React, { PureComponent } from "react";

export default class TL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <path
            d="M256,256,0,90.691v44.242L155.826,256,0,377.067v44.242Z"
            fill="#ffda44"
          />
          <path d="M0,90.691V421.309L189.217,256Z" />
          <path
            d="M44.184,213.36,69.1,236.937l30.121-16.41-14.723,30.98L109.4,275.082l-34.012-4.43L60.67,301.63l-6.3-33.716-34.012-4.43,30.119-16.408Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
