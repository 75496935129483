import React, { PureComponent } from "react";

export default class EH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect width="512" height="113.775" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#496e2d"
          />
          <g>
            <path d="M256,256.006,0,426.668V85.331Z" fill="#d80027" />
            <path
              d="M305.716,256a41.56,41.56,0,0,1,32.649-40.587,41.553,41.553,0,1,0,0,81.174A41.56,41.56,0,0,1,305.716,256Z"
              fill="#d80027"
            />
            <path
              d="M340.112,229.287l5.894,18.14h19.072l-15.43,11.211,5.894,18.139-15.43-11.21-15.431,11.21,5.895-18.139-15.431-11.211h19.072Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
