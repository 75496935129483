const getPusher = () => {
  try {
    return window.Pusher;
  } catch (err) {
    console.log(err);
  }
};

const initPusher = () => {
  try {
    const Pusher = getPusher();
    return new Pusher(`${process.env.PUSHER_KEY}`, {
      cluster: `${process.env.PUSHER_CLUSTER}`,
      encrypted: true,
    });
  } catch (err) {
    console.log(err);
  }
};

export const pusher = initPusher();
