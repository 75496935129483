import React, { PureComponent } from "react";

export default class AuctionIcon extends PureComponent {
  render() {
    const { size = 20 } = this.props;
    return (
      <svg fill="#000000" width={size} height={size} version="1.1" id="Layer_1" viewBox="0 0 512 512">
      <g>
        <g>
          <path d="M478.382,445.206H311.397c-18.445,0-33.397,14.952-33.397,33.397v16.698c0,9.222,7.476,16.698,16.698,16.698H495.08
            c9.222,0,16.698-7.476,16.698-16.698v-16.698C511.779,460.158,496.827,445.206,478.382,445.206z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M291.44,9.783c-13.046-13.046-34.18-13.04-47.225-0.006l-97.419,97.413c-13.046,13.046-13.046,34.191,0,47.231
            c13.046,13.046,34.176,13.043,47.225,0.006l97.419-97.413C304.486,43.968,304.486,22.823,291.44,9.783z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M480.341,198.684c-13.046-13.035-34.18-13.04-47.225,0.006l-97.408,97.413c-13.046,13.04-13.046,34.185,0,47.231
            c13.049,13.039,34.181,13.039,47.225-0.006l97.408-97.413C493.387,232.874,493.387,211.73,480.341,198.684z"/>
        </g>
      </g>
      <g>
        <g>
          <polygon points="313.579,82.104 219.119,176.558 313.569,271.011 408.027,176.548"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M207.312,211.982L14.833,404.461c-19.482,19.482-19.482,51.32,0,70.801l0.111,0.111
            c20.141,19.911,51.792,19.003,70.69-0.111l192.525-192.431L207.312,211.982z"/>
        </g>
      </g>
      </svg>
    );
  }
}
