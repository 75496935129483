import styled from "styled-components";
import { Image } from "react-bootstrap";

export const StyledLogo = styled(Image)`
  width: 200px;
  cursor: pointer;
`;

export const LogoWrapper = styled.div`
  border: 1px solid black;
  width: 200px;
  padding: 8px 0;
  cursor: pointer;
`;

export const StyledH2 = styled.h2`
  font-size: 2.5rem;
  margin: 1rem 0 2rem 0;
  text-transform: uppercase;
`;

export const StyledOption = styled.div`
  border: 1px solid black;
  width: 200px;
  height: 20px;
  background: ${(props) => props.backgroundcolor};
`;

export const StyledSelect = styled.div`
  select {
    height: 35px;
  }
`;
