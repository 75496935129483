import React, { PureComponent } from "react";

export default class AZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#338af3"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#6da544"
          />
          <g>
            <path
              d="M259.2,297.6a41.6,41.6,0,1,1,19.789-78.2,51.2,51.2,0,1,0,0,73.2A41.409,41.409,0,0,1,259.2,297.6Z"
              fill="#f0f0f0"
            />
            <path
              d="M291.2,227.2l5.51,15.5,14.855-7.062L304.5,250.489,320,256l-15.5,5.511,7.062,14.854L296.71,269.3l-5.51,15.5-5.51-15.5-14.855,7.062,7.062-14.854L262.4,256l15.5-5.511-7.062-14.854L285.69,242.7Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
