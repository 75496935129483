import React, { PureComponent } from "react";

export default class MA extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <path d="M0,85.337V426.663H512V85.337Z" fill="#d80027" />
          <path
            d="M352,226.484H278.663L256,156.738l-22.663,69.748H160l59.331,43.107-22.663,69.749L256,296.232l59.332,43.107L292.669,269.59ZM235.937,264.2,243.6,240.61h24.8l7.663,23.585h0L256,278.772,235.937,264.2Zm27.873-37.711H248.191L256,202.447Zm24.269,28.982-4.827-14.856h25.274ZM228.748,240.61l-4.827,14.856L203.474,240.61Zm-5.211,61.748,7.81-24.036,12.636,9.181ZM268.016,287.5l12.636-9.181,7.81,24.036Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
