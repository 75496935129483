import React, { PureComponent } from "react";

export default class MX extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <path d="M341.334,85.33H0V426.662H512V85.33Z" fill="#d80027" />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#6da544"
          />
          <rect
            width="170.663"
            height="341.337"
            transform="translate(170.663 85.333)"
            fill="#f0f0f0"
          />
          <path d="M208,256a48,48,0,0,0,96,0V240H208Z" fill="#6da544" />
          <path
            d="M320,224H272a16,16,0,0,0-32,0H192c0,8.836,7.7,16,16.533,16H208a16,16,0,0,0,16,16,16,16,0,0,0,16,16h32a16,16,0,0,0,16-16,16,16,0,0,0,16-16h-.533C312.3,240,320,232.833,320,224Z"
            fill="#ff9811"
          />
        </g>
      </svg>
    );
  }
}
