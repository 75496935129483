import { trimString } from "./utils";

export const metaTagsMakeMoney = {
  metaTitle: "Join our affiliate program and start earning today!!",
  metaDescription: "Join our affiliate program and start earning today!!",
  image:
    "https://cdn.shopify.com/s/files/1/0071/2081/4195/files/Bids_Logo_Meta_cc7f2304-fe85-4e3e-96a6-c7b2845d7a37.png?v=1605511280",
};

export const metaTagsContactUs = {
  metaTitle: "Contact Us",
  metaDescription:
    "By Department: Customer Service: support@bids.com Marketing: marketing@bids.com Information: info@bids.com Legal: legal@bids.com Billing &amp;amp; Invoices: billing@bids.com Vendor Inquiries: vendor@bids.com   Customer Service:+1-310-855-3525 Operating Hours:Monday - Friday8am - 5pm EST Address: 27240 Turnberry Lane 200Val",
  image:
    "https://cdn.shopify.com/s/files/1/0071/2081/4195/files/Bids_Logo_Meta_cc7f2304-fe85-4e3e-96a6-c7b2845d7a37.png?v=1605511280",
};

export const metaTagsStaticPage = (pageTitle, pageContent) => ({
  metaTitle: pageTitle || "",
  metaDescription: trimString(pageContent, 300),
  image:
    "https://cdn.shopify.com/s/files/1/0071/2081/4195/files/Bids_Logo_Meta_cc7f2304-fe85-4e3e-96a6-c7b2845d7a37.png?v=1605511280",
});
