import React, { PureComponent } from "react";

export default class UZ extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="103.88"
            transform="translate(0 322.783)"
            fill="#6da544"
          />
          <rect
            width="512"
            height="104.515"
            transform="translate(0 85.337)"
            fill="#338af3"
          />
          <rect
            width="512"
            height="89.656"
            transform="translate(0 210.877)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="104.515"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
          </g>
          <g>
            <path
              d="M188.688,137.589a33.4,33.4,0,0,1,26.236-32.614,33.391,33.391,0,1,0,0,65.228A33.4,33.4,0,0,1,188.688,137.589Z"
              fill="#f0f0f0"
            />
            <path
              d="M234.658,152.766l2.261,6.957h7.315l-5.918,4.3,2.261,6.956-5.919-4.3-5.918,4.3L231,164.024l-5.918-4.3H232.4Z"
              fill="#f0f0f0"
            />
            <path
              d="M258.006,152.766l2.26,6.957h7.315l-5.918,4.3,2.261,6.956-5.918-4.3-5.918,4.3,2.26-6.956-5.917-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M281.353,152.766l2.26,6.957h7.315l-5.917,4.3,2.26,6.956-5.918-4.3-5.918,4.3,2.26-6.956-5.918-4.3h7.315Z"
              fill="#f0f0f0"
            />
            <path
              d="M304.7,152.766l2.262,6.957h7.315l-5.919,4.3,2.261,6.956-5.919-4.3-5.918,4.3,2.262-6.956-5.919-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M328.048,152.766l2.26,6.957h7.315l-5.918,4.3,2.261,6.956-5.918-4.3-5.918,4.3,2.26-6.956-5.917-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M258.006,128.482l2.26,6.956h7.315l-5.918,4.3,2.261,6.957-5.918-4.3-5.918,4.3,2.26-6.957-5.917-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M281.353,128.482l2.26,6.956h7.315l-5.917,4.3,2.26,6.957-5.918-4.3-5.918,4.3,2.26-6.957-5.918-4.3h7.315Z"
              fill="#f0f0f0"
            />
            <path
              d="M304.7,128.482l2.262,6.956h7.315l-5.919,4.3,2.261,6.957-5.919-4.3-5.918,4.3,2.262-6.957-5.919-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M328.048,128.482l2.26,6.956h7.315l-5.918,4.3,2.261,6.957-5.918-4.3-5.918,4.3,2.26-6.957-5.917-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M281.353,104.2l2.26,6.956h7.315l-5.917,4.3,2.26,6.957-5.918-4.3-5.918,4.3,2.26-6.957-5.918-4.3h7.315Z"
              fill="#f0f0f0"
            />
            <path
              d="M304.7,104.2l2.262,6.956h7.315l-5.919,4.3,2.261,6.957-5.919-4.3-5.918,4.3,2.262-6.957-5.919-4.3h7.314Z"
              fill="#f0f0f0"
            />
            <path
              d="M328.048,104.2l2.26,6.956h7.315l-5.918,4.3,2.261,6.957-5.918-4.3-5.918,4.3,2.26-6.957-5.917-4.3h7.314Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
