import styled from "styled-components";
import { Col, Image } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledLoaderContainer = styled(Col)`
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledTitle = styled.p`
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  color: #454545;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
  @media ${device.tabletL} {
    margin-bottom: 1rem;
  }
`;

export const StyledCurrentBidInfo = styled.p`
  font-size: 15px;
  color: #454545;
  line-height: 1.65;
  b {
    font-size: 17px;
    color: #454545;
    line-height: 1.65;
    lfont-weight: 600;
  }
`;
export const StyledSpan = styled.span`
  font-size: 15px;
  color: #454545;
  line-height: 1.65;
  s {
    margin-left: 10px;
    font-size: 17px;
    color: #9d9d9d;
    line-height: 1.65;
    font-weight: 600;
  }
`;
export const StyledP = styled.p`
  font-size: 15px;
  color: #454545;
  line-height: 1.65;
  margin-bottom: 0;
  &.counter {
    text-transform: uppercase;
  }
`;

export const StyledImage = styled(Image)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

export const IconWrapper = styled.div`
  border: 1px solid black;
  width: 18px;
  height: 18px;
  padding-top: 3px;
  margin-right: 5px;
`;

export const StyledFlag = styled.span`
  svg {
    margin-left: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const StyledVideoColumn = styled(Col)`
  @media ${device.mobileS} {
    min-height: 200px;
  }
`;

export const StyledLabel = styled.div`
  align-items: center;
  text-align: center;
  width: 150px;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
`;
