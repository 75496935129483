import React, { PureComponent } from "react";

export default class LY extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect width="512" height="341.326" transform="translate(0 85.337)" />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#496e2d"
          />
          <g>
            <path
              d="M281.424,236.026l8.962,12.35L304.9,243.67l-8.975,12.339,8.961,12.349-14.509-4.724-8.976,12.34.01-15.258-14.51-4.725,14.514-4.706Z"
              fill="#f0f0f0"
            />
            <path
              d="M256.965,286.868a30.868,30.868,0,1,1,14.684-58.026,37.992,37.992,0,1,0,0,54.318A30.738,30.738,0,0,1,256.965,286.868Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
