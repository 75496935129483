import styled from "styled-components";
import { Nav, Row } from "react-bootstrap";

export const NavStyled = styled(Nav)`
  & .nav-item {
    .nav-link {
      padding: 10px 20px;
      font-weight: bold;
      color: #000;
      transition: all 0.1s ease-in;
      letter-spacing: 1px;
      font-size: 15px;
      border-radius: initial;
      &.active,
      &:hover {
        color: #fff;
        background-color: #000;
      }
    }
  }
`;

export const VendorInfoStyled = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VendorLogoStyled = styled.img`
  max-height: 40px;
`;
