import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import superagent from "superagent";

export function* fetchWonAuctionsRequest() {
  const multipassToken = localStorage.getItem("Multipass-Token");
  if (!multipassToken) {
    return;
  }
  const request = () =>
    superagent
      .get(`/api/my_account/won_auctions`)
      .query({ token: multipassToken });
  const response = yield call(request);
  yield put(actions.fetchWonAuctionsRequestResult(response.body));
}

export function* fetchWonAuctionsCountRequest() {
  const multipassToken = localStorage.getItem("Multipass-Token");
  if (!multipassToken) {
    return;
  }
  const request = () =>
    superagent
      .get(`/api/my_account/won_auctions_count`)
      .query({ token: multipassToken });
  const response = yield call(request);
  yield put(actions.fetchWonAuctionsCountRequestResult(response.body));
}

export function* fetchCheckout(action) {
  const {
    buy_now_data,
    auctions_data,
    ring_sizing,
    discount,
    handleErrors,
  } = action.payload;
  const token = localStorage.getItem("Multipass-Token");
  const checkoutData = {
    token,
    buy_now_data,
    auctions_data,
    ring_sizing,
    discount,
  };
  const request = () =>
    superagent.post(`/api/my_account/create_draft_order`).send(checkoutData);
  try {
    const response = yield call(request);
    const responseInvalidReason = response.body.reason;
    if (responseInvalidReason && responseInvalidReason.length > 0) {
      handleErrors(responseInvalidReason);
    } else {
      const multipassToken = response.body.multipass_token;
      const redirectLink = `https://${process.env.CHECKOUT_URL}/account/login/multipass/${multipassToken}`;
      window.location.replace(redirectLink);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* removeAuctionRequest(action) {
  const { auctionId } = action.payload;
  const multipassToken = localStorage.getItem("Multipass-Token");
  if (!multipassToken) {
    return;
  }
  const request = () =>
    superagent
      .put(`/api/cart/remove_auction_from_cart`)
      .send({ token: multipassToken, auction_id: auctionId });
  try {
    const response = yield call(request);
    yield put(
      actions.fetchWonAuctionsRequestResult(response.body.won_auctions)
    );
    yield put(
      actions.fetchWonAuctionsCountRequestResult(
        response.body.won_auctions_count
      )
    );
  } catch (error) {
    console.log(error);
  }
}

export function* fetchShippingMessageDataRequest() {
  const request = () => superagent.get(`/api/prismic/shipping_message_enabled`);
  const response = yield call(request);
  yield put(
    actions.fetchShippingMessageDataRequestResult(
      response.body.shipping_message_enabled
    )
  );
}

export default [
  takeLatest(actions.FETCH_WON_AUCTIONS_REQUEST, fetchWonAuctionsRequest),
  takeLatest(
    actions.FETCH_WON_AUCTIONS_COUNT_REQUEST,
    fetchWonAuctionsCountRequest
  ),
  takeLatest(actions.FETCH_CHECKOUT, fetchCheckout),
  takeLatest(actions.REMOVE_AUCTION_REQUEST, removeAuctionRequest),
  takeLatest(
    actions.FETCH_SHIPPING_MESSAGE_DATA_REQUEST,
    fetchShippingMessageDataRequest
  ),
];
