import styled from "styled-components";
import { Carousel } from "react-bootstrap";

export const StyledCarousel = styled(Carousel)`
  .carousel-indicators li {
    background-color: #6e6e6e;
    opacity: 1;
  }
  .carousel-indicators .active {
    background-color: #d4d4d4;
  }
`;
