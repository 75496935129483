import { call, put, takeLatest } from "redux-saga/effects";
import { has } from "lodash";

import * as actions from "./actions";
import superagent from "superagent";
import { getHeaders } from "storefront/features/shared/utils";

export function* fetchAddProductImageRequest(action) {
  const {
    image,
    vendor,
    variant,
    handleErrors,
    handleSuccess,
  } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent
      .post(`/api/s3/direct_post`)
      .set(headers)
      .field({ vendor, variant })
      .attach("file", image);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess("Image has been updated", responseData.url);
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchAddProductRequest(action) {
  const { handleErrors, handleSuccess, requestData } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent.post(`/api/marketplace/products`).set(headers).send(requestData);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      yield put(actions.fetchProductRequestResult(responseData));
      handleSuccess();
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchProductRequest(action) {
  const request = () =>
    superagent.get(`/api/marketplace/products`).query(action.payload);
  try {
    const response = yield call(request);
    yield put(actions.fetchProductRequestResult(response.body));
  } catch (res) {
    console.log(res);
  }
}

export function* fetchUpdateProductRequest(action) {
  const { handleErrors, handleSuccess, requestData, id } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent
      .put(`/api/marketplace/products/${id}`)
      .set(headers)
      .send(requestData);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      yield put(actions.fetchProductRequestResult(responseData));
      handleSuccess();
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchDeleteProductRequest(action) {
  const { handleErrors, handleSuccess, id, vendor_id } = action.payload;
  let response;
  const request = () =>
    superagent.delete(`/api/marketplace/products/${id}`).query({ vendor_id });
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess("Product was deleted successfully");
      yield put(actions.fetchProductRequestResult(responseData));
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchAddAuctionRequest(action) {
  const { handleErrors, handleSuccess, requestData } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent
      .post(`/api/marketplace/product_auctions`)
      .set(headers)
      .send(requestData);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess("Auction was created successfully");
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchUpdateAuctionRequest(action) {
  const { handleErrors, handleSuccess, requestUpdateData, id } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent
      .put(`/api/marketplace/product_auctions/${id}`)
      .set(headers)
      .send(requestUpdateData);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess("Auction was updated successfully");
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchDeleteAuctionRequest(action) {
  const { handleErrors, handleSuccess, id, vendor_id } = action.payload;
  let response;
  const request = () =>
    superagent
      .delete(`/api/marketplace/product_auctions/${id}`)
      .query({ vendor_id });
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess("Auction was deleted successfully");
      yield put(actions.fetchProductRequestResult(responseData));
    }
  } catch (res) {
    console.log(res);
  }
}

export function* fetchUploadFileRequest(action) {
  const { file, id, handleErrors, handleSuccess } = action.payload;
  const headers = getHeaders();
  let response;
  const request = () =>
    superagent
      .post(`/api/marketplace/products/products_import`)
      .set(headers)
      .field({ vendor_id: id })
      .attach("products_csv", file);
  try {
    response = yield call(request);
    const responseData = response.body;
    if (has(responseData, "error")) {
      handleErrors(responseData.error);
    } else {
      handleSuccess();
    }
  } catch (res) {
    console.log(res);
  }
}

export default [
  takeLatest(
    actions.FETCH_ADD_PRODUCT_IMAGE_REQUEST,
    fetchAddProductImageRequest
  ),
  takeLatest(actions.FETCH_ADD_PRODUCT_REQUEST, fetchAddProductRequest),
  takeLatest(actions.FETCH_UPDATE_PRODUCT_REQUEST, fetchUpdateProductRequest),
  takeLatest(actions.FETCH_DELETE_PRODUCT_REQUEST, fetchDeleteProductRequest),
  takeLatest(actions.FETCH_PRODUCT_REQUEST, fetchProductRequest),
  takeLatest(actions.FETCH_ADD_AUCTION_REQUEST, fetchAddAuctionRequest),
  takeLatest(actions.FETCH_UPDATE_AUCTION_REQUEST, fetchUpdateAuctionRequest),
  takeLatest(actions.FETCH_DELETE_AUCTION_REQUEST, fetchDeleteAuctionRequest),
  takeLatest(actions.FETCH_UPLOAD_FILE_REQUEST, fetchUploadFileRequest),
];
