import React, { PureComponent } from "react";

export default class IO extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M461.664,283.341c-4.016,0-13.378-5.315-20.212-9.2-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,268.147,9.57,262.487,0,262.083v22.332c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V262.083c-9.57.4-19.565,6.064-30.125,12.061C475.041,278.025,465.681,283.341,461.664,283.341Z"
              fill="#0052b4"
            />
            <path
              d="M0,117.459c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V95.126c-9.57.4-19.565,6.064-30.125,12.061-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,101.19,9.57,95.53,0,95.126Z"
              fill="#0052b4"
            />
            <path
              d="M461.664,172.036c-4.016,0-13.378-5.315-20.212-9.2-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,156.842,9.57,151.182,0,150.778V173.11c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V150.778c-9.57.4-19.565,6.064-30.125,12.061C475.041,166.721,465.681,172.036,461.664,172.036Z"
              fill="#0052b4"
            />
            <path
              d="M512,206.431c-9.57.4-19.565,6.064-30.125,12.061-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,212.494,9.57,206.835,0,206.431v22.332c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V206.431Z"
              fill="#0052b4"
            />
            <path
              d="M461.664,338.993c-4.016,0-13.378-5.315-20.212-9.2-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,323.8,9.57,318.139,0,317.735v22.332c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V317.735c-9.57.4-19.565,6.064-30.125,12.061C475.041,333.677,465.681,338.993,461.664,338.993Z"
              fill="#0052b4"
            />
            <path
              d="M512,373.387c-9.57.4-19.565,6.064-30.125,12.061-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.834,3.881-16.2,9.2-20.211,9.2s-13.378-5.315-20.212-9.2c-10.958-6.223-21.308-12.1-31.2-12.1s-20.246,5.877-31.2,12.1c-6.835,3.881-16.2,9.2-20.212,9.2s-13.378-5.315-20.211-9.2C19.565,379.451,9.57,373.791,0,373.387v22.332c4.437.746,12.847,5.516,19.131,9.086,10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.834-3.881,16.195-9.2,20.211-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.211,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.835-3.881,16.2-9.2,20.212-9.2s13.378,5.315,20.212,9.2c10.958,6.223,21.308,12.1,31.2,12.1s20.246-5.877,31.2-12.1c6.284-3.57,14.694-8.34,19.131-9.086V373.387Z"
              fill="#0052b4"
            />
          </g>
          <g>
            <path d="M256,85.329h0Z" fill="#f0f0f0" />
            <path
              d="M255.544,256H256v-.456Q255.772,255.768,255.544,256Z"
              fill="#f0f0f0"
            />
          </g>
          <path d="M278.261,256Z" fill="#496e2d" />
          <path d="M350.609,168.809h66.782L384,202.2Z" fill="#6da544" />
          <g>
            <rect
              width="14.84"
              height="40.812"
              transform="translate(376.576 194.779)"
              fill="#a2001d"
            />
            <rect
              width="14.84"
              height="40.812"
              transform="translate(376.576 302.377)"
              fill="#a2001d"
            />
          </g>
          <path
            d="M428.522,280.113a22.258,22.258,0,0,0-37.1-16.589V250.431h7.421v-14.84h-7.421V228.17h-14.84v7.421H369.16v14.84h7.421v13.093A22.258,22.258,0,1,0,346.9,296.7v13.093h74.2V296.7A22.208,22.208,0,0,0,428.522,280.113Z"
            fill="#ffda44"
          />
          <path
            d="M406.261,272.692a7.31,7.31,0,0,0-4.943,1.889l-9.9,8.861H376.58l-9.9-8.861a7.309,7.309,0,0,0-4.942-1.889,7.418,7.418,0,0,0-4.941,12.954l4.941,4.424v4.883h44.522v-4.884l4.941-4.424a7.418,7.418,0,0,0-4.942-12.953Z"
            fill="#d80027"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M144,85.329H112v69.334H0v32H112V256h32V186.663H256v-32H144Z"
              fill="#d80027"
            />
            <path d="M0,85.329v15.082l57.377,38.252H80Z" fill="#d80027" />
          </g>
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.082l-57.377,38.252H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,85.329v15.082l57.377,38.252H80Z" fill="#d80027" />
          <g>
            <path d="M0,107.951v30.712H46.069Z" fill="#2e52b2" />
            <path d="M96,85.331v48.913L22.628,85.331Z" fill="#2e52b2" />
          </g>
          <path d="M256,85.329v15.082l-57.377,38.252H176Z" fill="#d80027" />
          <g>
            <path d="M256,107.951v30.712H209.931Z" fill="#2e52b2" />
            <path d="M160,85.331v48.913l73.372-48.913Z" fill="#2e52b2" />
          </g>
          <path d="M0,256V240.915l57.377-38.252H80Z" fill="#d80027" />
          <g>
            <path d="M0,233.376V202.663H46.069Z" fill="#2e52b2" />
            <path d="M96,255.994V207.082L22.628,255.994Z" fill="#2e52b2" />
          </g>
          <path d="M256,256V240.915l-57.377-38.252H176Z" fill="#d80027" />
          <g>
            <path d="M256,233.376V202.663H209.931Z" fill="#2e52b2" />
            <path d="M160,255.994V207.082l73.372,48.912Z" fill="#2e52b2" />
          </g>
        </g>
      </svg>
    );
  }
}
