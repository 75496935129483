import React, { PureComponent } from "react";

export default class PH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <g>
            <rect
              width="512"
              height="341.326"
              transform="translate(0 85.337)"
              fill="#f0f0f0"
            />
          </g>
          <path d="M512,85.337V256H256L0,85.337Z" fill="#0052b4" />
          <path d="M512,256V426.663H0L256,256Z" fill="#d80027" />
          <g>
            <path
              d="M161.908,256,134.62,243.165l14.532-26.428L119.52,222.4l-3.755-29.933-20.64,22.015L74.486,192.472,70.731,222.4,41.1,216.736l14.531,26.428L28.343,256l27.288,12.835L41.1,295.263,70.733,289.6l3.753,29.933,20.639-22.015,20.64,22.015L119.52,289.6l29.631,5.669-14.532-26.427Z"
              fill="#ffda44"
            />
            <path
              d="M21.789,117.607l9.081,12.7,14.879-4.714-9.268,12.56,9.081,12.694-14.809-4.932L21.485,158.47l.117-15.608L6.793,137.928l14.88-4.712Z"
              fill="#ffda44"
            />
            <path
              d="M21.789,353.53l9.081,12.7,14.879-4.714-9.268,12.56,9.081,12.7-14.809-4.933-9.268,12.558.117-15.607L6.793,373.851l14.88-4.712Z"
              fill="#ffda44"
            />
            <path
              d="M210.4,235.569l-9.081,12.7-14.879-4.713L195.7,256.11l-9.081,12.7,14.809-4.933,9.268,12.558-.117-15.607,14.809-4.934-14.88-4.713Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
