import styled from "styled-components";
import { Col } from "react-bootstrap";
import { device } from "storefront/features/shared/styles/mediaQuery";

export const StyledColSubtotal = styled(Col)`
  span {
    &.subtotal-title {
      color: #00509d;
      font-size: 1.2rem;
      @media ${device.tabletS} {
        font-size: 1rem;
      }
    }
    &.subtotal-price {
      font-size: 1.4rem;
      font-weight: bold;
      @media ${device.tabletS} {
        font-size: 1.2rem;
      }
    }
  }
`;
