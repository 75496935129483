import React, { PureComponent } from "react";

export default class PK extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.338"
      >
        <g transform="translate(0 -85.331)">
          <g>
            <path
              d="M393.508,170.516l17.512,18.875,23.363-10.821-12.541,22.487,17.513,18.876-25.263-4.978-12.539,22.488-3.073-25.564L373.217,206.9l23.363-10.82Z"
              fill="#f0f0f0"
            />
            <rect
              width="512"
              height="341.326"
              transform="translate(0 85.343)"
              fill="#f0f0f0"
            />
          </g>
          <rect
            width="384"
            height="341.337"
            transform="translate(128 85.331)"
            fill="#496e2d"
          />
          <g>
            <path
              d="M361.909,298.793a69.333,69.333,0,1,1-49.6-124.959,85.334,85.334,0,1,0,71.452,98.89A69.048,69.048,0,0,1,361.909,298.793Z"
              fill="#f0f0f0"
            />
            <path
              d="M360.58,172.889l17.484,18.842,23.322-10.8-12.519,22.447L406.35,222.22l-25.219-4.968L368.612,239.7l-3.067-25.521-25.22-4.969,23.323-10.8Z"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
