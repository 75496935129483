import React, { PureComponent } from "react";
import Icon from "../icons";
import { StyledLink } from "./BackButton.styled";

class BackButton extends PureComponent {
  render() {
    const { className, color } = this.props;
    return (
      <StyledLink
        color={color}
        to="/account/my-account"
        data-cy="ap-back-to-account"
        className={className}
      >
        <div className="d-flex flex-row align-items-center">
          <Icon type="arrowLeftIcon" color={color} />
          <p className="m-0 ml-2">Back to Account</p>
        </div>
      </StyledLink>
    );
  }
}

export default BackButton;
