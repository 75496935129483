import React, { PureComponent } from "react";

export default class DM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#496e2d"
          />
          <path d="M0,224v64H512V224Z" />
          <rect
            width="512"
            height="21.337"
            transform="translate(0 224)"
            fill="#ffda44"
          />
          <rect
            width="512"
            height="21.337"
            transform="translate(0 266.663)"
            fill="#f0f0f0"
          />
          <rect width="64" height="341.326" transform="translate(224 85.337)" />
          <rect
            width="21.326"
            height="341.326"
            transform="translate(224 85.337)"
            fill="#ffda44"
          />
          <rect
            width="21.326"
            height="341.326"
            transform="translate(266.674 85.337)"
            fill="#f0f0f0"
          />
          <circle
            cx="81.619"
            cy="81.619"
            r="81.619"
            transform="translate(174.381 174.381)"
            fill="#d80027"
          />
          <g>
            <path
              d="M274.6,265.107c-6.071-12.142-13.963-24.449-13.963-24.449s.2-5.6.2-8.942a9.1,9.1,0,0,0-18.174-.786,7.177,7.177,0,0,0-2.848,13.529,10.344,10.344,0,0,1,6.141-5.7,9.1,9.1,0,0,0,1.451.977l1.166.559s-4.326,12.193-4.326,18.745c0,18.416,12.142,24.1,12.142,24.1v.19l-6.071,6.069h12.142V277.249l6.071,6.072C268.58,283.206,280.026,275.967,274.6,265.107Z"
              fill="#496e2d"
            />
            <path
              d="M256,189.217l2.763,8.5h8.94l-7.233,5.256,2.764,8.5L256,206.224l-7.234,5.254,2.764-8.5L244.3,197.72h8.94Z"
              fill="#496e2d"
            />
            <path
              d="M216.746,201.972l7.232,5.254,7.233-5.254-2.762,8.5,7.233,5.255h-8.939l-2.764,8.5-2.763-8.5h-8.94l7.233-5.255Z"
              fill="#496e2d"
            />
            <path
              d="M192.486,235.363h8.939l2.763-8.5,2.764,8.5h8.939l-7.232,5.255,2.764,8.5-7.235-5.254-7.233,5.253,2.763-8.5Z"
              fill="#496e2d"
            />
            <path
              d="M192.486,276.637l7.232-5.254-2.763-8.5,7.233,5.254,7.233-5.255-2.762,8.5,7.234,5.255h-8.941l-2.764,8.5-2.763-8.5Z"
              fill="#496e2d"
            />
            <path
              d="M216.746,310.028l2.763-8.5-7.234-5.256h8.941l2.763-8.5,2.764,8.5h8.939l-7.233,5.256,2.762,8.5-7.233-5.254Z"
              fill="#496e2d"
            />
            <path
              d="M256,322.783l-2.763-8.5H244.3l7.233-5.256-2.764-8.5L256,305.776l7.233-5.254-2.763,8.5,7.233,5.255h-8.94Z"
              fill="#496e2d"
            />
            <path
              d="M295.254,310.028l-7.232-5.254-7.233,5.255,2.762-8.5-7.234-5.255h8.94l2.763-8.5,2.764,8.5h8.94l-7.233,5.256Z"
              fill="#496e2d"
            />
            <path
              d="M319.514,276.637h-8.939l-2.763,8.5-2.764-8.5h-8.941l7.234-5.256-2.764-8.5,7.235,5.254,7.232-5.254-2.762,8.5Z"
              fill="#496e2d"
            />
            <path
              d="M319.514,235.363l-7.232,5.255,2.763,8.5-7.233-5.254-7.234,5.255,2.763-8.5-7.234-5.254h8.941l2.763-8.5,2.764,8.5Z"
              fill="#496e2d"
            />
            <path
              d="M295.254,201.972l-2.763,8.5,7.233,5.255h-8.94l-2.762,8.5-2.765-8.5h-8.94l7.234-5.256-2.762-8.5,7.233,5.253Z"
              fill="#496e2d"
            />
          </g>
        </g>
      </svg>
    );
  }
}
