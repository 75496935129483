import React, { PureComponent } from "react";

export default class MV extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <rect
            width="222.609"
            height="163.25"
            transform="translate(144.696 174.375)"
            fill="#6da544"
          />
          <path
            d="M283.484,304.226a48.232,48.232,0,1,1,22.943-90.667,59.362,59.362,0,1,0,0,84.87A47.994,47.994,0,0,1,283.484,304.226Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
