import React, { PureComponent } from "react";

export default class CU extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 511.999 422.955"
      >
        <g transform="translate(0 -44.522)">
          <path d="M0,44.522H0V467.477H0V44.522Z" fill="#ff9811" />
          <rect
            width="511.999"
            height="341.325"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="511.999"
              height="68.263"
              transform="translate(0 85.337)"
              fill="#0052b4"
            />
            <rect
              width="511.999"
              height="68.263"
              transform="translate(0 221.874)"
              fill="#0052b4"
            />
            <rect
              width="511.999"
              height="68.263"
              transform="translate(0 358.399)"
              fill="#0052b4"
            />
          </g>
          <path d="M256,256.005,0,426.668V85.332Z" fill="#d80027" />
          <path
            d="M83.478,211.479l11.05,34.01h35.763L101.36,266.51l11.049,34.011L83.478,279.5l-28.931,21.02L65.6,266.51,36.667,245.489H72.428Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
