import { compose } from "redux";
import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { getActiveAuctions } from "../selectors";
import * as actions from "../actions";
import { isLogin } from "storefront/features/shared/utils";
import ActiveAuction from './ActiveAuction';
import withRedirectUnregisteredUser from "storefront/features/shared/components/RedirectUnregisteredUser";

class ActiveAuctions extends PureComponent {
  componentDidMount() {
    const { fetchActiveAuctionsData } = this.props;
    if (isLogin()) {
      fetchActiveAuctionsData();
    }
  }

  render() {
    const {
      products
    } = this.props;

    return (
      <Container>
        <Row className="justify-content-center my-5">
          <Col className="text-center w-100">
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center mb-5">YOUR ACTIVE AUCTIONS</h1>
                </Col>
              </Row>
              { Object.entries(products).length > 0 ? (
                <div>
                  <Row className="d-flex justify-content-between w-100">
                    <span className="d-none d-md-block col-md-2"></span>
                    <span className="text-uppercase d-none d-md-block col-md-3">Product</span>
                    <span className="text-uppercase d-none d-md-block col-md-1">Current Bid</span>
                    <span className="text-uppercase d-none d-md-block col-md-2">Time Left</span>
                    <span className="text-uppercase d-none d-md-block col-md-2">Winner</span>
                    <span className="text-uppercase d-none d-md-block col-md-2"></span>
                  </Row>
                  <hr />
                  {Object.entries(products)?.sort((a, b) => (a.end_at > b.end_at) ? -1 : 1).map(([key, product]) => (
                    <ActiveAuction
                      key={key}
                      product={product}>
                    </ActiveAuction>
                  ))}
                </div>
              ) : (
                <Row>
                  <Col className="text-left" sm={12}>
                    <span className="text-uppercase">No Active Auctions</span>
                    <hr />
                    <p>You are not currently bidding on any auctions</p>
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getActiveAuctions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchActiveAuctionsData: () => {
    dispatch(actions.fetchCustomerActiveAuctionsRequest());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  withRedirectUnregisteredUser,
)(ActiveAuctions);
