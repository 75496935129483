import React, { PureComponent } from "react";

export default class NI extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 85.337)"
              fill="#338af3"
            />
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#338af3"
            />
          </g>
          <path
            d="M256,214.447A41.553,41.553,0,1,0,297.553,256,41.552,41.552,0,0,0,256,214.447Zm0,65.3A23.745,23.745,0,1,1,279.745,256,23.745,23.745,0,0,1,256,279.745Z"
            fill="#ffda44"
          />
          <path
            d="M276.563,261.936,256,256l-20.563,5.936-6.855,11.873h54.836Z"
            fill="#0052b4"
          />
          <path
            d="M256,226.32l-13.709,23.744L256,256l13.709-5.936Z"
            fill="#338af3"
          />
          <path
            d="M235.437,261.936h41.126l-6.854-11.872H242.291Z"
            fill="#6da544"
          />
        </g>
      </svg>
    );
  }
}
