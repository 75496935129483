import { fromJS } from "immutable";

import * as actions from "./actions";

const initialState = fromJS({
  info: {},
  settings: {},
  vendorNames: [],
});

const setVendorData = (state, action) =>
  state.set("info", fromJS(action.payload));
const setVendorSettings = (state, action) =>
  state.set("settings", fromJS(action.payload));
const setVendorNames = (state, action) =>
  state.set("vendorNames", fromJS(action.payload));

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VENDOR_DATA_REQUEST_RESULT:
      return setVendorData(state, action);
    case actions.FETCH_VENDOR_SETTINGS_REQUEST_RESULT:
      return setVendorSettings(state, action);
    case actions.FETCH_VENDOR_NAMES_REQUEST_RESULT:
      return setVendorNames(state, action);
    default:
      return state;
  }
};

export default vendorReducer;
