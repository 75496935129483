import React, { PureComponent } from "react";
import BidButton from "./BidButton";
import AutoBidButton from "./AutoBidButton";

class BidButtons extends PureComponent {
  render() {
    const {
      showAutoBidBtn,
      closed,
      shopifyProductId,
      autoBidValue,
      product,
      onModalOpen,
      vendor,
      width,
      padding,
    } = this.props;

    return (
      <>
        {showAutoBidBtn ? (
          <AutoBidButton
            closed={closed}
            shopifyProductId={shopifyProductId}
            autoBidValue={autoBidValue}
            product={product}
          />
        ) : (
          <BidButton
            vendorPresent={vendor}
            closed={closed}
            product={product}
            onModalOpen={onModalOpen}
            width={width}
            padding={padding}
          />
        )}
      </>
    );
  }
}

export default BidButtons;
