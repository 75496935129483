import React, { PureComponent } from "react";
import { StyledIframe } from "./ProductVideo.styled";

class ProductVideo extends PureComponent {
  render() {
    const { videoSrc } = this.props;

    return (
      <StyledIframe
        src={videoSrc}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="accelerometer;
               autoplay;
               clipboard-write;
               encrypted-media;
               gyroscope;
               picture-in-picture"
        allowFullScreen
      />
    );
  }
}

export default ProductVideo;
