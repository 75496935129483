import { createSelector } from "reselect";

const getAccount = (state) => state.get("accountReducer");
const getCustomer = (state) => state.getIn(["headerReducer", "customer"]);

export const getActiveAuctions = createSelector(getAccount, (state) =>
  state.get("auctions")
);
export const getCurrentAddress = createSelector(getAccount, (state) =>
  state.get("data")
);
export const getModalState = createSelector(getAccount, (state) =>
  state.get("open")
);
export const getCustomerFullName = createSelector(getCustomer, (state) =>
  state.get("displayName")
);
export const getCustomerOrders = createSelector(getCustomer, (state) =>
  state.get("orders")
);
export const getCustomerOrderByID = (state, { location: { search } }) => {
  const customerData = getCustomer(state);
  const order =
    customerData.size &&
    customerData
      .getIn(["orders", "edges"])
      .find(
        (itemList) =>
          itemList.getIn(["node", "id"]).split("?")[0] === search.split("?")[1]
      )
      .get("node");
  return order;
};
export const getCustomerBillingAddress = createSelector(getCustomer, (state) =>
  state.get("defaultAddress")
);
export const getCustomerDefaultAddressId = createSelector(
  getCustomer,
  (state) => state.getIn(["defaultAddress", "id"])
);
export const getCustomerAddresses = createSelector(getCustomer, (state) =>
  state.get("addresses")
);
