import React, { PureComponent } from "react";

export default class JE extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.342"
      >
        <g transform="translate(0 -85.329)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.334)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="341.337"
              transform="translate(0 85.334)"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M301.255,256,512,115.5V85.329H466.745L256,225.826,45.255,85.329H0V115.5L210.745,256,0,396.494v30.169H45.255L256,286.167l210.745,140.5H512V396.494Z"
            fill="#d80027"
          />
          <path
            d="M233.739,166.953,256,172.518l22.261-5.565V148.031l-8.9,4.453L256,139.127l-13.357,13.357-8.9-4.453Z"
            fill="#ffda44"
          />
          <path
            d="M233.739,166.953v13.914c0,17.04,22.261,22.261,22.261,22.261s22.261-5.221,22.261-22.261V166.953Z"
            fill="#d80027"
          />
        </g>
      </svg>
    );
  }
}
