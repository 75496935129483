import React, { PureComponent } from "react";

export default class KG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <g>
            <rect
              width="512"
              height="341.337"
              transform="translate(0 85.331)"
              fill="#d80027"
            />
            <circle
              cx="170.663"
              cy="170.663"
              r="170.663"
              transform="translate(85.337 85.331)"
              fill="#d80027"
            />
          </g>
          <path
            d="M339.465,255.994l-34.1,16.042,18.161,33.031-37.035-7.085L281.8,335.391,256,307.879l-25.795,27.512-4.693-37.409-37.033,7.083,18.161-33.031-34.1-16.04,34.106-16.04-18.162-33.033,37.031,7.087,4.7-37.41L256,204.111,281.8,176.6l4.69,37.41,37.036-7.087-18.161,33.034Z"
            fill="#ffda44"
          />
          <circle
            cx="51.942"
            cy="51.942"
            r="51.942"
            transform="translate(204.058 204.052)"
            fill="#d80027"
          />
          <g>
            <path
              d="M230.029,255.994a36.871,36.871,0,0,0-3.705.186,29.551,29.551,0,0,0,6.947,18.894,52.064,52.064,0,0,1,10.035-16.614A36.9,36.9,0,0,0,230.029,255.994Z"
              fill="#ffda44"
            />
            <path
              d="M246.017,283.95a29.733,29.733,0,0,0,19.966,0A37.122,37.122,0,0,0,256,266.634,37.122,37.122,0,0,0,246.017,283.95Z"
              fill="#ffda44"
            />
            <path
              d="M281.7,241.159a29.674,29.674,0,0,0-51.406,0,51.61,51.61,0,0,1,25.7,6.984A51.6,51.6,0,0,1,281.7,241.159Z"
              fill="#ffda44"
            />
            <path
              d="M268.7,258.46a52.021,52.021,0,0,1,10.035,16.614,29.555,29.555,0,0,0,6.947-18.894c-1.22-.121-2.455-.186-3.7-.186A36.9,36.9,0,0,0,268.7,258.46Z"
              fill="#ffda44"
            />
          </g>
        </g>
      </svg>
    );
  }
}
