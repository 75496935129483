import React, { PureComponent } from "react";

export default class TG extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#ffda44"
          />
          <g>
            <rect
              width="512"
              height="68.263"
              transform="translate(0 85.337)"
              fill="#496e2d"
            />
            <rect
              width="512"
              height="68.263"
              transform="translate(0 358.4)"
              fill="#496e2d"
            />
            <rect
              width="512"
              height="68.263"
              transform="translate(0 221.863)"
              fill="#496e2d"
            />
          </g>
          <rect
            width="204.054"
            height="204.054"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <path
            d="M102.026,133.938l13.26,40.812H158.2l-34.718,25.226,13.26,40.814-34.718-25.224L67.307,240.79,80.57,199.976,45.852,174.75H88.765Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
