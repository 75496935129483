import styled from "styled-components";

export const StyledProductRetailPrice = styled.span`
  color: #454545;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 15px;
  font-style: normal;
  display: block;
  span {
    min-height: 24px;
  }
`;
