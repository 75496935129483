import React, { PureComponent } from "react";

export default class IL extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M320,219.045H277.333L256,182.094l-21.333,36.95H192l21.335,36.951L192,292.944h42.667L256,329.895l21.333-36.95H320l-21.335-36.951Zm-37.791,36.949-13.1,22.7H242.9l-13.1-22.7,13.1-22.7h26.209ZM256,210.6l4.877,8.448h-9.754Zm-39.315,22.7h9.755l-4.877,8.448Zm0,45.4,4.877-8.448,4.877,8.448ZM256,301.392l-4.876-8.448h9.754Zm39.315-22.7H285.56l4.877-8.448Zm-9.755-45.4h9.755l-4.877,8.448Z"
              fill="#2e52b2"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 106.657)"
              fill="#2e52b2"
            />
            <rect
              width="512"
              height="42.663"
              transform="translate(0 362.657)"
              fill="#2e52b2"
            />
          </g>
        </g>
      </svg>
    );
  }
}
