import React, { PureComponent } from "react";

export default class IR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#6da544"
          />
          <g>
            <rect
              width="512"
              height="113.775"
              transform="translate(0 312.888)"
              fill="#d80027"
            />
            <path
              d="M300.345,220.383H282.482c.148,1.963.232,3.944.232,5.936,0,13.207-3.3,26.01-9.057,35.122A32.272,32.272,0,0,1,264.9,270.9V220.383H247.095V270.9a32.323,32.323,0,0,1-8.753-9.456c-5.756-9.113-9.056-21.915-9.056-35.122,0-1.992.085-3.974.232-5.936H211.655q-.173,2.93-.177,5.936c0,36.617,19.557,65.3,44.523,65.3s44.522-28.682,44.522-65.3C300.522,224.315,300.459,222.336,300.345,220.383Z"
              fill="#d80027"
            />
          </g>
          <g>
            <rect
              width="22.261"
              height="24.421"
              transform="translate(44.522 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(0 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(89.043 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(133.565 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(178.087 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(222.609 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(267.13 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(311.652 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(356.174 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(400.696 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(445.217 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="24.421"
              transform="translate(489.739 184.275)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(44.522 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(0 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(89.043 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(133.565 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(178.087 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(222.609 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(267.13 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(311.652 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(356.174 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(400.696 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(445.217 302.191)"
              fill="#f0f0f0"
            />
            <rect
              width="22.261"
              height="25.534"
              transform="translate(489.739 302.191)"
              fill="#f0f0f0"
            />
          </g>
        </g>
      </svg>
    );
  }
}
