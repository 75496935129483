import React, { PureComponent } from "react";

export default class TW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#d80027"
          />
          <rect
            width="256"
            height="170.663"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <path
            d="M186.435,170.669,162.558,181.9l12.714,23.125-25.927-4.961-3.286,26.192L128,206.993l-18.06,19.263-3.285-26.192-25.927,4.96L93.442,181.9l-23.877-11.23,23.877-11.231L80.728,136.313l25.927,4.96,3.286-26.192L128,134.344l18.06-19.263,3.285,26.192,25.928-4.96-12.715,23.125Z"
            fill="#f0f0f0"
          />
          <circle
            cx="29.006"
            cy="29.006"
            r="29.006"
            transform="translate(98.994 141.668)"
            fill="#0052b4"
          />
          <path
            d="M128,190.06a19.391,19.391,0,1,1,19.391-19.391A19.414,19.414,0,0,1,128,190.06Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
