import React, { PureComponent } from "react";

export default class Klarna extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="24"
        viewBox="0 0 38 24"
        aria-labelledby="pi-klarna"
      >
        <title id="pi-klarna">Klarna</title>
        <g id="klarna" transform="translate(0 -0.111)">
          <rect
            width="38"
            height="24"
            rx="4.321"
            transform="translate(0 0.111)"
            fill="#ffb3c7"
          />
          <path
            d="M35.135,13.674a.9.9,0,1,0,.639.268.9.9,0,0,0-.639-.268Zm-2.962-.7a1.3,1.3,0,1,0-1.3,1.243,1.272,1.272,0,0,0,1.3-1.243Zm.005-2.418h1.434v4.834H32.177V15.08a2.5,2.5,0,0,1-1.422.442,2.55,2.55,0,0,1,0-5.1,2.494,2.494,0,0,1,1.422.442Zm-11.478.63v-.629H19.231v4.834H20.7V13.132c0-.762.817-1.17,1.385-1.17H22.1V10.556A1.782,1.782,0,0,0,20.7,11.185Zm-3.658,1.788a1.3,1.3,0,1,0-1.3,1.243,1.272,1.272,0,0,0,1.3-1.243Zm.005-2.418h1.435v4.834H17.047V15.08a2.5,2.5,0,0,1-1.422.442,2.55,2.55,0,0,1,0-5.1,2.494,2.494,0,0,1,1.422.442Zm8.633-.129A1.738,1.738,0,0,0,24.2,11.1v-.544H22.774v4.834h1.446v-2.54a1.015,1.015,0,0,1,1.077-1.1c.63,0,.993.38.993,1.085v2.55h1.432V12.316a1.9,1.9,0,0,0-2.042-1.89ZM11.008,15.389h1.5V8.4h-1.5Zm-6.6,0H6V8.4H4.414ZM9.975,8.4a5.585,5.585,0,0,1-1.626,3.968l2.2,3.023H8.582L6.195,12.106l.616-.466A4.026,4.026,0,0,0,8.417,8.4H9.973Z"
            transform="translate(-0.686 0.203)"
            fill="#0a0b09"
          />
        </g>
      </svg>
    );
  }
}
