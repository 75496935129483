import React, { PureComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import RegisterVendor from "./RegisterVendor";
import ResetPassword from "./ResetPassword";
import RecoverPassword from "./RecoverPassword";
import SingleOrder from "./SingleOrder";
import ActivateAccount from "./ActivateAccount";
import AccountPage from "./AccountPage";
import AddressesPage from "./AddressesPage";
import SuccessAccountCreation from "./SuccessAccountCreation";
import SuccessRecoverRequest from "./SuccessRecoverRequest";
import SuccessPasswordReset from "./SuccessPasswordReset";
import UpdateCustomerFirstLastName from "./UpdateCustomerFirstLastName";
import AccountSettings from "./AccountSettings";
import AccountDetails from "./AccountDetails";
import { Helmet } from "storefront/features/shared";
import { metaTags } from "../metaTags";

class Account extends PureComponent {
  render() {
    return (
      <>
        <Helmet metaTags={metaTags} />
        <Switch>
          <Route
            path="/account/my-account"
            component={AccountPage}
            exact={true}
          />
          <Route path="/account/login" component={Login} exact={true} />
          <Route path="/account/register" component={Register} exact={true} />
          <Route
            path="/account/register_vendor"
            component={RegisterVendor}
            exact={true}
          />
          <Route
            path="/account/update_first_last_name"
            component={UpdateCustomerFirstLastName}
            exact={true}
          />
          <Route
            path="/account/reset_password"
            component={ResetPassword}
            exact={true}
          />
          <Route
            path="/account/recover_password"
            component={RecoverPassword}
            exact={true}
          />
          <Route path="/account/order" component={SingleOrder} exact={false} />
          <Route
            path="/account/activate"
            component={ActivateAccount}
            exact={true}
          />
          <Route
            path="/account/addresses"
            component={AddressesPage}
            exact={true}
          />
          <Route
            path="/account/success-account-creation"
            component={SuccessAccountCreation}
            exact={true}
          />
          <Route
            path="/account/success-recover-request"
            component={SuccessRecoverRequest}
            exact={true}
          />
          <Route
            path="/account/success-password-reset"
            component={SuccessPasswordReset}
            exact={true}
          />
          <Route
            path="/account/settings"
            component={AccountSettings}
            exact={true}
          />
          <Route
            path="/account/details/:user_id"
            component={AccountDetails}
            exact={true}
          />
          <Redirect to="/FourOFour" />
        </Switch>
      </>
    );
  }
}

export default Account;
