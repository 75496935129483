import React, { PureComponent } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Product from "./Product";

class ProductGrid extends PureComponent {
  render() {
    const { products } = this.props;
    return (
      <Container>
        <Row>
          {products &&
            products.map((product, index) => (
              <Col
                id={`product-card-${index}`}
                key={product.id + index}
                className="col-6 col-md-4 col-xl-3 mt-4 px-1 px-sm-2"
              >
                <Product product={product} />
              </Col>
            ))}
        </Row>
      </Container>
    );
  }
}

export default ProductGrid;
