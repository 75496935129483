import React, { PureComponent } from "react";

export default class PlusIcon extends PureComponent {
  render() {
    return (
      <svg width="15" height="15" x="0px" y="0px" viewBox="0 0 512 512">
        <path
          style={{ fill: "white" }}
          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67
           0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0
           17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32
           32h32c17.67 0 32-14.33 32-32V304h144c17.67 0
           32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    );
  }
}
