import React, { PureComponent } from "react";

export default class MS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.333)"
            fill="#0052b4"
          />
          <path
            d="M334.222,192v78.221C334.222,308.32,384,320,384,320s49.778-11.675,49.778-49.779V192Z"
            fill="#338af3"
          />
          <path
            d="M334.222,270.217C334.222,308.32,384,320,384,320s49.778-11.675,49.778-49.779Z"
            fill="#a2001d"
          />
          <path d="M405.334,227.552H391.11V213.329H376.888v14.223H362.666v14.223h14.222V284.44H391.11V241.775h14.224Z" />
          <path
            d="M256,85.333v30.553l-45.167,25.1H256v59.359H196.9L256,233.179V256H229.32L155.826,215.17V256H100.174V207.423L12.744,256H0V225.442l45.167-25.1H0V140.985H59.1L0,108.139V85.333H26.68l73.494,40.825V85.333h55.652V133.9l87.43-48.572Z"
            fill="#f0f0f0"
          />
          <path
            d="M144,85.33H112v69.332H0v32H112V256h32V186.662H256v-32H144Z"
            fill="#d80027"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#0052b4"
          />
          <path
            d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M155.826,200.344,256,256V240.259l-71.847-39.915Z"
              fill="#d80027"
            />
            <path
              d="M71.846,200.344,0,240.259V256H0l100.174-55.652Z"
              fill="#d80027"
            />
          </g>
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#0052b4"
          />
          <path
            d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
            fill="#f0f0f0"
          />
          <g>
            <path
              d="M100.174,140.982,0,85.33v15.737l71.847,39.915Z"
              fill="#d80027"
            />
            <path
              d="M184.154,140.982,256,101.067V85.33h0L155.826,140.982Z"
              fill="#d80027"
            />
          </g>
        </g>
      </svg>
    );
  }
}
