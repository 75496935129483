import React, { PureComponent } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { getVendorSettings } from "../../shared/selectors";
import * as actions from "storefront/features/vendor/actions";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { moneyFormat } from "../../shared/utils";
import { Link } from "react-router-dom";

class AccountDetails extends PureComponent {
  componentDidMount() {
    const {
      fetchVendorSettings,
      match: { params },
    } = this.props;
    fetchVendorSettings(params.user_id);
  }

  render() {
    const {
      vendorSettings: {
        account_numbers,
        tax_id,
        legal_name,
        fulfillment_service_name,
        shipping_rates,
        shipping_labels,
        payment_method,
        payment_method_details = {},
      },
    } = this.props;
    const {
      usa = "",
      rest_of_the_world = "",
      canada = "",
      europe = "",
      australia = "",
    } = shipping_rates || {};
    return (
      <Container className="my-5">
        <Row className="mb-3">
          <Col>
            <Link
              to="/account/settings"
              data-cy="ap-back-to-account-settings"
              className="btn btn-primary text-uppercase"
            >{`< back to account settings`}</Link>
          </Col>
        </Row>
        <Row className="mb-3 text-center">
          <Col>
            <h1 className="text-uppercase">Account details</h1>
          </Col>
        </Row>
        <ListGroup as="ul">
          <ListGroup.Item as="li" data-cy="account-numbers-item">
            Payment Details: {account_numbers}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="legal-name-item">
            Legal Name: {legal_name}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="tax-id-item">
            Tax Id: {tax_id}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ship-rates-usa-item">
            Shipping Rates USA: {moneyFormat(usa)}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ship-rates-canada-item">
            Shipping Rates Canada: {moneyFormat(canada)}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ship-rates-europe-item">
            Shipping Rates Europe: {moneyFormat(europe)}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ship-rates-australia-item">
            Shipping Rates Australia: {moneyFormat(australia)}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ship-rates-rest-item">
            Shipping Rates Rest of the World: {moneyFormat(rest_of_the_world)}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="ful-service-name-item">
            Fulfillment Service Name: {fulfillment_service_name}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="shipping-labels-item">
            Shipping Labels: {shipping_labels ? "Yes" : "No"}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="payment-method">
            Payment Method: {payment_method}
          </ListGroup.Item>
          <ListGroup.Item as="li" data-cy="payment-method-details">
            <strong>Payment Method Details</strong>
          </ListGroup.Item>
          {Object.keys(payment_method_details).map((key) => (
            <ListGroup.Item as="li" data-cy="payment_method" key={key}>
              {key}: {payment_method_details[key]}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorSettings: getVendorSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorSettings: (vendorId) => {
    dispatch(actions.fetchVendorSettingsRequest(vendorId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(AccountDetails));
