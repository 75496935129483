import React, { PureComponent } from "react";

export default class PR extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <g>
            <rect
              width="512"
              height="68.263"
              transform="translate(0 85.337)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="68.263"
              transform="translate(0 221.863)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="68.263"
              transform="translate(0 358.4)"
              fill="#d80027"
            />
          </g>
          <path d="M256,256.006,0,426.668V85.331Z" fill="#0052b4" />
          <path
            d="M83.477,195.132l15.107,46.5h48.894l-39.554,28.739,15.107,46.5L83.477,288.13,43.922,316.868l15.11-46.5L19.478,241.63H68.37Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
