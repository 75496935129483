import React, { PureComponent } from "react";
import { Button, Collapse } from "react-bootstrap";
import MobileSubMenuLinkList from "./MobileSubMenuLinkList";
import { Link } from "react-router-dom";
import Icon from "storefront/features/shared/icons";

export default class MobileSubMenuItem extends PureComponent {
  state = { open: false };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleMenuItemClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  };

  render() {
    const menuItemsWithoutImages = this.props.data.filter((item) => {
      return item.type !== "image";
    });

    const CollapsedContent = () =>
      menuItemsWithoutImages.map(({ title, children, url }, index) =>
        children ? (
          <MobileSubMenuLinkList
            key={title + index}
            title={title}
            data={children}
          />
        ) : (
          <Link
            key={title + index}
            className="btn btn-outlined-dark my-2 ml-3 w-100 text-left"
            to={url}
          >
            {title}
          </Link>
        )
      );

    const { open } = this.state;

    return (
      <div ref={this.setWrapperRef}>
        <Button
          variant="light"
          className="d-flex w-100 justify-content-between align-items-center my-2"
          onClick={this.handleMenuItemClick}
          aria-controls="sub-level-menu-1"
          aria-expanded={open}
        >
          {this.props.title}
          <Icon type="close" />
        </Button>
        <Collapse in={open}>
          <div className="sub-level-menu-1">
            <CollapsedContent />
          </div>
        </Collapse>
      </div>
    );
  }
}
