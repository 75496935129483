import { fromJS } from "immutable";
import * as actions from "./actions";
import { mergeProductAuctionData } from "./utils";
import { isEmpty } from "lodash";

const initialState = fromJS({
  products: {},
});

const getAllAuctionProducts = (state, action) =>
  state.set("products", fromJS(mergeProductAuctionData(action.payload)));

const getAllProducts = (state, action) =>
  state.set("products", fromJS(action.payload));

const setVendorProducts = (state, action) =>
  state.set("products", fromJS(mergeProductAuctionData(action.payload)));

const findIndexOfListingToUpdate = (state, payload) => {
  const products = state.getIn(["products", "products"]);
  if (!isEmpty(products)) {
    return products.findIndex((listItem) => {
      return listItem.get("bidAuctionId") === payload.bidAuctionId;
    });
  } else {
    return -1;
  }
};

export const updateProduct = (state, action) => {
  const indexOfListingToUpdate = findIndexOfListingToUpdate(
    state,
    action.payload
  );
  if (indexOfListingToUpdate >= 0) {
    return state.setIn(
      ["products", "products", indexOfListingToUpdate],
      fromJS(action.payload)
    );
  } else {
    return state;
  }
};

const bidAuctionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ALL_AUCTION_PRODUCTS_REQUEST_RESULT:
      return getAllAuctionProducts(state, action);
    case actions.FETCH_ALL_PRODUCTS_REQUEST_RESULT:
      return getAllProducts(state, action);
    case actions.FETCH_VENDOR_PRODUCTS_REQUEST_RESULT:
      return setVendorProducts(state, action);
    case actions.UPDATE_PRODUCT:
      return updateProduct(state, action);
    default:
      return state;
  }
};

export default bidAuctionsReducer;
