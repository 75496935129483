import React, { PureComponent } from "react";

export default class CS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 340.583"
      >
        <path d="M0,0H512V340.583H0Z" fill="#fff" />
        <path
          d="M0,333.333H512V446.861H0Z"
          transform="translate(0 -106.278)"
          fill="#de0000"
        />
        <path d="M0,0H512V113.528H0Z" fill="#00338d" />
      </svg>
    );
  }
}
