import { createSelector } from "reselect";

export const menuSelector = (state) => {
  return state.getIn(["ssrReducer", "top_menu"]);
};

export const shippingPricesSelector = (state) => {
  return state.getIn(["ssrReducer", "shipping_prices"]);
};

export const productSelector = (state) => {
  return state.getIn(["bidAuctionsReducer", "products"]);
};

export const getProducts = createSelector(productSelector, (products) => {
  return products.get("products");
});

export const getPageParams = createSelector(productSelector, (products) =>
  products.get("page_params")
);

export const productReducerSelector = (state) => {
  return state.getIn(["productReducer"]);
};

export const getFlagUpdateProductGrid = createSelector(
  productReducerSelector,
  (obj) => obj.get("updateProductGridRequired")
);

const vendorInfoSelector = (state) => state.get("vendorReducer");

export const getVendorInfo = createSelector(vendorInfoSelector, (state) =>
  state.get("info")
);

export const getVendorSettings = createSelector(vendorInfoSelector, (state) =>
  state.get("settings")
);

export const getVendorNames = createSelector(vendorInfoSelector, (state) =>
  state.get("vendorNames")
);
