import React, { PureComponent } from "react";
import { compose } from "redux";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Orders from "./Orders";
import withRedirectUnregisteredUser from "storefront/features/shared/components/RedirectUnregisteredUser";
import {
  getCustomerFullName,
  getCustomerOrders,
  getCustomerBillingAddress,
} from "../selectors";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import * as actions from "../actions";
import { getCustomerAccessToken } from "storefront/features/shared/utils";
import { WithLoader } from "storefront/features/shared/components/Loader";
import {
  ACCOUNT_PAGE_LOADER,
  isVendorAccepted,
} from "storefront/features/shared/utils";
import AddressLines from "./AddressLines";
import { StyledContainer } from "./AccountPage.styled";
import { clearLocalStorage } from "../utils";

class AccountPage extends PureComponent {
  handleLogout = () => {
    const { fetchCustomerAccessTokenDelete } = this.props;
    const customerAccessToken = getCustomerAccessToken();
    const redirectLink = `https://${process.env.SHOP}/account/logout`;
    clearLocalStorage();
    fetchCustomerAccessTokenDelete(customerAccessToken, redirectLink);
  };

  render() {
    const {
      customerFullName,
      customerOrders,
      defaultAddress,
      userAuctionData: { vendor, vendor_status },
    } = this.props;
    const isVendor = isVendorAccepted(vendor, vendor_status);
    return (
      <StyledContainer className="mb-5">
        <Row className="my-4 my-md-5">
          <Col>
            <Row className="mb-3">
              <Col xs={12} md={8}>
                <Button
                  id="account-logout"
                  className="text-uppercase mr-3 mb-3 mb-xl-0"
                  onClick={this.handleLogout}
                  data-cy="ap-logout-btn"
                >
                  Logout
                </Button>
                <Link
                  to="/account/settings"
                  className="text-uppercase btn btn-primary mr-3 mb-3 mb-xl-0"
                  data-cy="ap-account-settings-btn"
                >
                  Account settings
                </Link>
                {isVendor && (
                  <Link
                    to="/products-settings"
                    className="text-uppercase btn btn-primary mb-3 mb-xl-0"
                    data-cy="ap-product-settings-btn"
                  >
                    Products settings
                  </Link>
                )}
              </Col>
              <Col xs={12} md={4} className="text-md-right mt-0 mt-md-0">
                <Link
                  to="/pages/contact-us"
                  className="text-uppercase btn btn-primary"
                  data-cy="ap-contact-us-btn"
                >
                  Complaints & Suggestions
                </Link>
              </Col>
            </Row>

            <h1 className="text-uppercase">My Store</h1>
            {customerFullName && (
              <small>Welcome back, {customerFullName}!</small>
            )}
          </Col>
        </Row>
        <WithLoader loader={ACCOUNT_PAGE_LOADER}>
          <Row>
            <Col lg={8}>
              <p className="border-bottom mb-0 text-uppercase pb-2">
                My Orders
              </p>
              {customerOrders && <Orders orders={customerOrders} />}
            </Col>
            <Col className="mb-5" lg={4}>
              <p className="address-title border-bottom mb-4 text-uppercase pb-2">
                Primary address
              </p>
              {defaultAddress ? (
                <AddressLines data={defaultAddress} />
              ) : (
                <p className="text-uppercase" data-cy="ap-no-address-msg">
                  No addresses
                </p>
              )}
              <div className="text-center mb-5">
                <Link
                  to="/account/addresses"
                  variant="link"
                  data-cy="ap-edit-addresses-btn"
                >
                  Edit addresses
                </Link>
              </div>
            </Col>
          </Row>
        </WithLoader>
      </StyledContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerFullName: getCustomerFullName,
  defaultAddress: getCustomerBillingAddress,
  customerOrders: getCustomerOrders,
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomerAccessTokenDelete: (customerAccessToken, redirectLink) => {
    dispatch(
      actions.fetchCustomerAccessTokenDelete({
        customerAccessToken,
        redirectLink,
      })
    );
  },
});

export default compose(
  withRedirectUnregisteredUser,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(AccountPage);
