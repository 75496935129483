import React, { PureComponent } from "react";

export default class IM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#d80027"
          />
          <path
            d="M319.192,199.736,307.13,242.784,270.936,236.1l-23.3-48.023L184.7,210.411l-4.964-13.986L163.271,194.4l12.407,34.966,43.313-11.077L231.3,252.971l-29.941,44.188,50.81,43.337-9.631,11.292,6.476,15.277,24.078-28.229-31.249-31.971,23.885-28,53.238,3.837,12.127-65.673,14.594,2.7,9.993-13.246Zm-63.2,56.242Z"
            fill="#f0f0f0"
          />
        </g>
      </svg>
    );
  }
}
