import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Form as BSForm } from "react-bootstrap";
import { connect } from "react-redux";
import { Form } from "react-final-form";

import * as actions from "../actions";
import { Field } from "storefront/features/shared";
import {
  composeValidators,
  validateRequired,
  validateMaxLength,
} from "storefront/features/shared/components/field/utils";
import { StyledContainer } from "./ResetPassword.styled";

class UpdateCustomerFirstLastName extends PureComponent {
  state = { errorMsg: "" };

  handleResponseErrors = (error) => {
    this.setState({ errorMsg: error });
  };

  handleSubmitForm = (values) => {
    const { location, fetchCustomerUpdateFirstLastNameRequest } = this.props,
      callback = this.handleResponseErrors;
    values.password = location.state.password;
    values.email = location.state.email;
    fetchCustomerUpdateFirstLastNameRequest({ callback, ...values });
  };

  render() {
    return (
      <StyledContainer>
        <Row className="justify-content-center my-5">
          <Col className="m-5 text-center col-md-6 col-10">
            <h1>Please complete you registration</h1>
            <p>Please enter your first name and last name:</p>
            <Form
              noValidate
              onSubmit={this.handleSubmitForm}
              render={({
                handleSubmit,
                submitting,
                pristine,
                dirtySinceLastSubmit,
              }) => (
                <BSForm onSubmit={handleSubmit}>
                  <BSForm.Group>
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      disabled={submitting}
                      validate={composeValidators(
                        validateRequired,
                        validateMaxLength(250)
                      )}
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      disabled={submitting}
                      validate={composeValidators(
                        validateRequired,
                        validateMaxLength(250)
                      )}
                    />
                  </BSForm.Group>
                  <BSForm.Group>
                    {this.state.errorMsg && !dirtySinceLastSubmit && (
                      <p className="invalid-feedback d-block">
                        {this.state.errorMsg}
                      </p>
                    )}
                    <Button
                      className="w-100"
                      type="submit"
                      variant="primary"
                      disabled={submitting || pristine}
                    >
                      Save
                    </Button>
                  </BSForm.Group>
                </BSForm>
              )}
            />
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  fetchCustomerUpdateFirstLastNameRequest: (values) => {
    dispatch(actions.fetchCustomerUpdateFirstLastNameRequest(values));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(UpdateCustomerFirstLastName));
