import { Button, Modal } from "react-bootstrap";
import React, { PureComponent } from "react";

class RestrictVendorModal extends PureComponent {
  render() {
    const { onHide, show } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Action Forbidden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please, use your customer account to make a purchase.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RestrictVendorModal;
