import React, { PureComponent } from "react";

export default class CD extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#338af3"
          />
          <path
            d="M512,152.222V85.337H411.67L0,359.778v66.885H100.33Z"
            fill="#ffda44"
          />
          <path
            d="M512,85.337v40.125L60.193,426.663H0V386.538l451.807-301.2Z"
            fill="#d80027"
          />
          <path
            d="M176.287,137.278,185.5,165.62h29.8l-24.11,17.517,9.21,28.342-24.11-17.517-24.109,17.517,9.209-28.342-24.11-17.517h29.8Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
