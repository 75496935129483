import React, { PureComponent, createRef } from "react";
import { Col, Form as BSForm } from "react-bootstrap";
import { connect } from "react-redux";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { createStructuredSelector } from "reselect";
import { isEqual } from "lodash";

import { Field } from "storefront/features/shared";
import * as actions from "../actions";
import { getAuctionData } from "storefront/features/shared/components/Header/selectors";
import {
  StyledImage,
  IconWrapper,
  DeleteIconWrapper,
} from "./ProductImageForm.styled";
import Icon from "storefront/features/shared/icons";
import { IMAGES_SIZE_LIMIT } from "../utils";

class VariantImageForm extends PureComponent {
  constructor() {
    super();
    this.ref = createRef();
  }

  state = {
    image: "",
    imagePreviewUrl: "",
    successMsg: null,
    errorMsg: null,
  };

  componentDidMount() {
    const { imagePreviewUrl = "" } = this.props;
    this.setState({
      imagePreviewUrl,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { image } = this.state;
    if (image.size > 0 && !isEqual(image, prevState.image)) {
      this.uploadVariantImage();
    }
  }

  handleResponseErrors = (errorMsg) => {
    this.setState({ errorMsg });
  };

  handleResponseSuccess = (successMsg, imgUrl) => {
    const { addVariantImage } = this.props;
    this.setState({ successMsg });
    addVariantImage({ src: imgUrl });
  };

  uploadVariantImage = () => {
    const {
      fetchAddProductImageRequest,
      userAuctionData: { store_name: vendor },
    } = this.props;
    const variant = true;
    const { image } = this.state;
    const handleErrors = this.handleResponseErrors;
    const handleSuccess = this.handleResponseSuccess;
    fetchAddProductImageRequest({
      image,
      vendor,
      variant,
      handleErrors,
      handleSuccess,
    });
  };

  handleImageChange = (e) => {
    const reader = new FileReader();
    const image = e.target.files[0];

    if (image.size > IMAGES_SIZE_LIMIT) {
      this.setState({ errorMsg: "File is too big." });
      return;
    }

    reader.onloadend = () => {
      this.setState({
        image,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(image);
  };

  handleClick = () => {
    if (!this.ref.current) {
      return;
    }
    this.ref.current.click();
  };

  handleDelete = () => {
    const { deleteVariantImage } = this.props;
    this.setState({
      imagePreviewUrl: "",
    });
    deleteVariantImage();
  };

  render() {
    const { imagePreviewUrl } = this.state;
    const { index } = this.props;
    return (
      <>
        <BSForm.Row className="mx-0" data-cy="image-row">
          <Col xs={"auto"} md={"auto"} className="text-left my-auto mr-3">
            <BSForm.Group className="mb-0">
              <Field name={index ? `image-${index}` : `image`}>
                {({ input }) => (
                  <input
                    {...input}
                    onChange={this.handleImageChange}
                    type="file"
                    className="d-none"
                    ref={this.ref}
                    accept="image/*"
                  />
                )}
              </Field>
              {imagePreviewUrl ? (
                <>
                  <StyledImage
                    src={imagePreviewUrl}
                    rounded
                    onClick={this.handleClick}
                    isvariants={1}
                    data-cy="product-image"
                  />

                  <DeleteIconWrapper
                    onClick={this.handleDelete}
                    isvariants={1}
                    data-cy="delete-image"
                  >
                    <Icon type="deleteIcon" size={23} />
                  </DeleteIconWrapper>
                </>
              ) : (
                <IconWrapper
                  className="rounded"
                  onClick={this.handleClick}
                  data-cy="no-image-icon"
                >
                  <Icon type="noImage" size={35} />
                </IconWrapper>
              )}
            </BSForm.Group>
          </Col>
        </BSForm.Row>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAuctionData: getAuctionData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAddProductImageRequest: (data) => {
    dispatch(actions.fetchAddProductImageRequest(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(VariantImageForm));
