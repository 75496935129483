import React, { PureComponent } from "react";

export default class VolumeUp extends PureComponent {
  render() {
    const { size = 20 } = this.props;
    return (
      <svg viewBox="0 0 50 50" width={size} height={size}>
        <path
          stroke="black"
          strokeWidth="1"
          d="M 26.607422 2 C 25.943075 2 25.306426 2.2764497 24.853516 2.7636719 L 12.564453 16 L 5 16 C 3.3547453 16 2 17.354745 2 19 L 2 31 C 2 32.645255 3.3547453 34 5 34 L 12.564453 34 L 24.853516 47.234375 C 25.306191 47.723196 25.943075 48 26.607422 48 C 27.917384 48 29 46.917384 29 45.607422 L 29 4.3925781 C 29 3.0826158 27.917384 2 26.607422 2 z M 26.607422 4 C 26.83546 4 27 4.1645404 27 4.3925781 L 27 45.607422 C 27 45.83546 26.83546 46 26.607422 46 C 26.497769 46 26.393637 45.95418 26.320312 45.875 L 14 32.609375 L 14 17.392578 L 26.318359 4.125 C 26.393449 4.0442222 26.497769 4 26.607422 4 z M 39.695312 10.419922 L 38.572266 12.072266 C 43.008266 14.681266 46 19.492 46 25 C 46 30.508 43.008266 35.318734 38.572266 37.927734 L 39.695312 39.580078 C 44.659312 36.608078 48 31.194 48 25 C 48 18.806 44.659312 13.391922 39.695312 10.419922 z M 36.324219 15.380859 L 35.193359 17.042969 C 38.048359 18.553969 40 21.552 40 25 C 40 28.448 38.048359 31.446031 35.193359 32.957031 L 36.324219 34.619141 C 39.705219 32.740141 42 29.135 42 25 C 42 20.865 39.705219 17.259859 36.324219 15.380859 z M 5 18 L 12 18 L 12 32 L 5 32 C 4.4412547 32 4 31.558745 4 31 L 4 19 C 4 18.441255 4.4412547 18 5 18 z M 32.921875 20.386719 L 31.753906 22.107422 C 33.042906 22.443422 34 23.607 34 25 C 34 26.393 33.042906 27.555578 31.753906 27.892578 L 32.921875 29.613281 C 34.726875 28.857281 36 27.076 36 25 C 36 22.924 34.726875 21.141719 32.921875 20.386719 z"
        />
      </svg>
    );
  }
}
