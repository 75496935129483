import React, { PureComponent } from "react";

export default class LS extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#f0f0f0"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <rect
            width="512"
            height="113.775"
            transform="translate(0 312.888)"
            fill="#6da544"
          />
          <path d="M267.13,252.29V211.478H244.87V252.29l-26.887,26.888a44.529,44.529,0,0,0,76.034,0Z" />
        </g>
      </svg>
    );
  }
}
