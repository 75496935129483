import React, { PureComponent } from "react";

export default class LC extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.337"
      >
        <g transform="translate(0 -85.331)">
          <rect
            width="512"
            height="341.337"
            transform="translate(0 85.331)"
            fill="#338af3"
          />
          <path d="M192.927,315.356H319.073L256,166.951Z" fill="#f3f3f3" />
          <path d="M214.847,300.516,256,206.91l41.153,93.606Z" fill="#333" />
          <path d="M192.927,315.356H319.073L256,255.994Z" fill="#ffda44" />
        </g>
      </svg>
    );
  }
}
