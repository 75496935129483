import React, { PureComponent } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import PaginationWrapper from "./PaginationWrapper";
import { getSearchValue } from "../utils";

class Pagination extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      pageParams: { current_page },
      history,
      location,
    } = this.props;
    const searchValue = getSearchValue(location.search, current_page);
    if (current_page !== prevProps.pageParams.current_page) {
      history.push({
        pathname: location.pathname,
        search: searchValue,
      });
    }
  }

  handleClick = (page) => {
    const { pageItemHandleClick } = this.props;
    pageItemHandleClick(page);
  };

  render() {
    const {
      pageParams: { current_page, total_pages },
    } = this.props;
    const paginationConfig = {
      totalPages: total_pages,
      currentPage: current_page,
      onClick: this.handleClick,
    };

    return (
      total_pages > 0 && (
        <Container className="mt-3">
          <PaginationWrapper {...paginationConfig} />
        </Container>
      )
    );
  }
}

export default withRouter(Pagination);
