import React, { PureComponent } from "react";
import { Button } from "react-bootstrap";

import Icon from "storefront/features/shared/icons";
import { StyledTd, StyledImage, StyledButton } from "./ProductTableRow.styled";
import ModalDeleteTableRow from "./ModalDeleteTableRow";
import { getProductType } from "../../productGrid/utils";

export class ProductTableRow extends PureComponent {
  state = {
    show: false,
  };

  showProductModal = () => {
    const { product, handleModalShow } = this.props;
    handleModalShow(product);
  };

  showAuctionModal = () => {
    const { product, handleAuctionModalShow } = this.props;
    handleAuctionModalShow(product);
  };

  handleModalDeleteTableRowShow = () => {
    this.setState(({ show }) => ({ show: !show }));
  };

  deleteVendorProduct = () => {
    const {
      deleteProduct,
      product: { id },
    } = this.props;
    deleteProduct(id);
    this.handleModalDeleteTableRowShow();
  };

  render() {
    const {
      product: { title, images, status },
      product,
      positionNumber,
    } = this.props;
    const productType = getProductType(product);
    const { show } = this.state;

    return (
      <>
        <ModalDeleteTableRow
          show={show}
          handleModalClose={this.handleModalDeleteTableRowShow}
          title={title}
          deleteTableRow={this.deleteVendorProduct}
        />
        <tr data-cy="product-row">
          <td className="align-middle pl-3" data-cy="position-number-td">
            {positionNumber}
          </td>
          <td className="align-middle" data-cy="image-td">
            {images.length > 0 ? (
              <StyledImage src={images[0].src} rounded />
            ) : (
              <div className="rounded">
                <Icon type="noImage" size={35} />
              </div>
            )}
          </td>
          <td className="align-middle" data-cy="title-td">
            {title}
          </td>
          <td className="align-middle" data-cy="product-status-td">
            {status}
          </td>
          <td className="align-middle" data-cy="product-format-td">
            {productType}
          </td>
          <StyledTd className="align-middle pr-2 text-right">
            <StyledButton
              backgroundcolor="#007bff"
              variant="link"
              data-cy="add-auction-btn"
              onClick={this.showAuctionModal}
            >
              Start Auction
            </StyledButton>
            <Button
              variant="link"
              data-cy="edit-product-btn"
              onClick={this.showProductModal}
            >
              Edit
            </Button>
            <Button
              variant="link"
              data-cy="delete-product-btn"
              onClick={this.handleModalDeleteTableRowShow}
            >
              Delete
            </Button>
          </StyledTd>
        </tr>
      </>
    );
  }
}

export default ProductTableRow;
