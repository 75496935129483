import React, { PureComponent } from "react";

export default class MH extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.326"
      >
        <g transform="translate(0 -85.337)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.337)"
            fill="#0052b4"
          />
          <g>
            <path
              d="M233.839,185.115l-20.844,9.8L224.1,215.1l-22.633-4.33-2.868,22.864-15.765-16.816-15.766,16.816L164.2,210.775l-22.634,4.33,11.1-20.187-20.843-9.8,20.843-9.805-11.1-20.185,22.633,4.328,2.869-22.864L182.829,153.4l15.765-16.816,2.868,22.864,22.634-4.328L213,175.311Z"
              fill="#f0f0f0"
            />
            <path
              d="M426.917,85.337,0,421.52v3.762L503.756,85.337Z"
              fill="#f0f0f0"
            />
          </g>
          <path
            d="M512,85.337h-8.244L0,425.282v1.381H3.723L512,137Z"
            fill="#ff9811"
          />
        </g>
      </svg>
    );
  }
}
