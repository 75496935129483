import styled from "styled-components";

export const StyledBadge = styled.span`
    position: relative;
    &:after {
      content: "${(props) => props.title || "NEW"}";
      text-transform: uppercase;
      top: -15px;
      right: -20px;
      position: absolute;
      background-color: ${(props) => props.color || "#00509d"};
      color: #fff;
      padding: 2px 6px 0px;
      font-size: 9px;
      letter-spacing: 1px;
      border-radius: 10px;
      box-shadow: 0 3px 0 rgba(0,0,0,0.04);
    }
`;
