import React, { PureComponent } from "react";

export default class SM extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.332"
      >
        <g transform="translate(0 -85.334)">
          <rect
            width="512"
            height="341.326"
            transform="translate(0 85.34)"
            fill="#338af3"
          />
          <path d="M512,85.334v166.69L0,256.175V85.334Z" fill="#f0f0f0" />
          <path
            d="M323.744,203.1,256,270.843,188.256,203.1a81.157,81.157,0,0,0-13.879,45.483v22.261a81.765,81.765,0,0,0,55.148,77.213,24.686,24.686,0,0,0,2.712,26.671c8.326-6.673,16.945-13.58,24.258-19.439,7.313,5.859,15.932,12.767,24.258,19.439a24.691,24.691,0,0,0,2.564-26.968,81.76,81.76,0,0,0,54.308-76.916V248.582A81.174,81.174,0,0,0,323.744,203.1Z"
            fill="#6da544"
          />
          <path
            d="M256,330.206a59.43,59.43,0,0,1-59.362-59.362V248.583a59.362,59.362,0,1,1,118.724,0v22.261A59.43,59.43,0,0,1,256,330.206Z"
            fill="#ffda44"
          />
          <path
            d="M293.1,270.843V248.582a37.1,37.1,0,0,0-74.2,0v22.261l37.1,7.421Z"
            fill="#338af3"
          />
          <path
            d="M218.9,270.843h0a37.1,37.1,0,1,0,74.2,0H218.9Z"
            fill="#6da544"
          />
          <path
            d="M300.522,189.22a22.258,22.258,0,0,0-37.1-16.589V159.538h7.421V144.7h-7.421v-7.421h-14.84V144.7H241.16v14.84h7.421v13.093A22.258,22.258,0,1,0,218.9,205.809V218.9h74.2V205.809A22.207,22.207,0,0,0,300.522,189.22Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
