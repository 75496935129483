import styled from "styled-components";

export const StyledSproutCartBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background: rgba(180, 180, 185, 0.15);
  // color:var(--color-base-text);
  // font-family:var(--font-heading-family);
  font-size: 13px;
  line-height: 180%;

  img {
    width: 100%;
    width: 36px;
  }

  #sprout-cart-badge-text {
    font-weight: 800;
  }

  #sprout-cart-badge-icon {
    margin-right: 15px;
  }
`;
