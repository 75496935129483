import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  .modal-header {
    height: 1px;
    z-index: 9999;
    padding: 10px 12px 0 0;
  }
  button.close {
    font-family: Futura;
    &:focus,
    &:active {
      outline: none;
    }
  }
`;
