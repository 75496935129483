import React, { PureComponent } from "react";

export default class DinersClub extends PureComponent {
  render() {
    return (
      <svg
        viewBox="0 0 38 24"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        width="38"
        height="24"
        aria-labelledby="pi-diners_club"
      >
        <title id="pi-diners_club">Diners Club</title>
        <path
          opacity=".07"
          d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
        ></path>
        <path
          fill="#fff"
          d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
        ></path>
        <path
          d="M12 12v3.7c0 .3-.2.3-.5.2-1.9-.8-3-3.3-2.3-5.4.4-1.1 1.2-2 2.3-2.4.4-.2.5-.1.5.2V12zm2 0V8.3c0-.3 0-.3.3-.2 2.1.8 3.2 3.3 2.4 5.4-.4 1.1-1.2 2-2.3 2.4-.4.2-.4.1-.4-.2V12zm7.2-7H13c3.8 0 6.8 3.1 6.8 7s-3 7-6.8 7h8.2c3.8 0 6.8-3.1 6.8-7s-3-7-6.8-7z"
          fill="#3086C8"
        ></path>
      </svg>
    );
  }
}
