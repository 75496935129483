import React, { PureComponent } from "react";
import { Container } from "react-bootstrap";

class Wishlist extends PureComponent {
  render() {
    return (
      <>
        <Container className="my-5">Wishlist Page</Container>
      </>
    );
  }
}

export default Wishlist;
