import React, { PureComponent } from "react";

export default class ZW extends PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="13"
        viewBox="0 0 512 341.34"
      >
        <g transform="translate(0 -85.33)">
          <rect width="512" height="341.337" transform="translate(0 85.333)" />
          <g>
            <rect
              width="512"
              height="48.868"
              transform="translate(0 134.196)"
              fill="#ffda44"
            />
            <rect
              width="512"
              height="48.868"
              transform="translate(0 329.668)"
              fill="#ffda44"
            />
          </g>
          <rect
            width="512"
            height="48.868"
            transform="translate(0 85.333)"
            fill="#6da544"
          />
          <g>
            <rect
              width="512"
              height="48.868"
              transform="translate(0 183.069)"
              fill="#d80027"
            />
            <rect
              width="512"
              height="48.868"
              transform="translate(0 280.806)"
              fill="#d80027"
            />
          </g>
          <rect
            width="512"
            height="48.128"
            transform="translate(0 378.542)"
            fill="#6da544"
          />
          <path
            d="M276.992,256,106.329,426.659H0V85.333H106.329Z"
            fill="#f0f0f0"
          />
          <path d="M256,256,85.334,426.662h20.987L276.988,256,106.321,85.33H85.334Z" />
          <path
            d="M102.465,202.57l13.259,40.812h42.917l-34.718,25.226,13.26,40.814L102.465,284.2l-34.72,25.224,13.262-40.814L46.289,243.382H89.2Z"
            fill="#d80027"
          />
          <path
            d="M138.94,259.335l-34.559-12.243s-2.553-23.955-2.708-24.766a13.355,13.355,0,1,0-25.994,6.034l-9.689,9.755H83.219C83.219,256,69.875,256,69.875,273.806l7.4,17.809H121.8l7.422-17.809h0a17.782,17.782,0,0,0,1.381-5.231C136.995,265.986,138.94,259.335,138.94,259.335Z"
            fill="#ffda44"
          />
        </g>
      </svg>
    );
  }
}
