import React, { PureComponent } from "react";
import { Row, Col } from "react-bootstrap";
import { isEqual, flattenDeep, get } from "lodash";
import CartRingSizingOptions from "./CartRingSizingOptions";
import { RING_SIZING, isTagExist } from "storefront/features/shared/utils";

import {
  StyledRow,
  InformIcon,
  StyledColWithPrice,
  StyledColWithTitle,
} from "./CartProduct.styled";
import { ProductVariantPicker } from "storefront/features/shared";

class CartProduct extends PureComponent {
  increaseLineItems = () => {
    const {
      cartProduct: { variantId },
      addLineItems,
    } = this.props;
    const checkoutId = localStorage.getItem("Checkout-Id");
    const lineItemsToAdd = [
      {
        variantId,
        quantity: 1,
      },
    ];

    addLineItems({ checkoutId, lineItemsToAdd });
  };

  decreaseLineItems = () => {
    const {
      cartProduct: { quantity, id },
      updateLineItems,
    } = this.props;
    const checkoutId = localStorage.getItem("Checkout-Id");
    const lineItemsToUpdate = [
      {
        id,
        quantity: quantity - 1,
      },
    ];

    updateLineItems({ checkoutId, lineItemsToUpdate });
  };

  removeCheckoutLineItems = () => {
    const {
      cartProduct: { id },
      removeLineItems,
    } = this.props;
    const checkoutId = localStorage.getItem("Checkout-Id");
    const lineItemIdsToRemove = [id];

    removeLineItems({ checkoutId, lineItemIdsToRemove });
  };

  saveForLater = () => {
    const {
      cartProduct: { id },
      updateAuctionLineItems,
    } = this.props;

    updateAuctionLineItems(id, true);
  };

  addToCart = () => {
    const {
      cartProduct: { id },
      updateAuctionLineItems,
    } = this.props;

    updateAuctionLineItems(id, false);
  };

  handleOptionsChange = (choosedOptions) => {
    const {
      cartProduct: { id, variants },
      updateAuctionLineItemsVariant,
    } = this.props;

    const pickedVariant = variants.find((variant) =>
      isEqual(variant.selected_options, choosedOptions)
    );
    const variantId = get(pickedVariant, "shopify_id", "");
    updateAuctionLineItemsVariant(id, variantId, choosedOptions);
  };

  handleRingSizingOptionsChange = (choosedOptions) => {
    const {
      cartProduct: { id },
      updateAuctionLineItemsRingSizing,
    } = this.props;
    updateAuctionLineItemsRingSizing(id, choosedOptions);
  };

  checkOptions = () => {
    const {
      cartProduct: { options, variants },
    } = this.props;
    if (options === undefined) return false;
    const optionsValues = options.map(({ values }) => values);
    if (
      variants.length === 1 ||
      flattenDeep(optionsValues).includes("Default")
    ) {
      return false;
    }
    return true;
  };

  removeAuction = () => {
    const {
      cartProduct: { id },
      removeAuctionFromCart,
    } = this.props;

    removeAuctionFromCart(id);
  };

  render() {
    const {
      cartProduct: {
        variants,
        imageSrc,
        productTitle,
        price,
        quantity,
        saveForLater,
        activeOptions,
        options,
        variantImageUrl,
        notAvailable,
        ringSizing,
        product_tags,
      },
    } = this.props;
    const isRingSizingAvailable = isTagExist(product_tags, RING_SIZING);
    const ringWithSingleVariantValue =
      isRingSizingAvailable &&
      variants.length === 1 &&
      get(variants, "[0].selected_options.Size", undefined);
    return (
      <>
        <StyledRow
          className={`mt-2 ${saveForLater ? "save-for-late" : ""}`}
          data-cy="checkout-lineItem"
        >
          <Col sm={3} className="mb-3 mb-sm-0">
            <img
              className="img-fluid"
              src={variantImageUrl ? variantImageUrl : imageSrc}
            />
          </Col>
          <StyledColWithTitle sm={7} className="mt-2 my-sm-auto">
            {!quantity && (
              <div className="mb-3">
                <InformIcon>i</InformIcon>
                <small>
                  You have commited to buy this item. You need to go to checkout and pay for these items.
                </small>
              </div>
            )}
            <p className="text-sm-left cart-line-item-title">
              <b>{productTitle}</b>
            </p>
            {ringWithSingleVariantValue && (
              <p>{`Ring size: ${ringWithSingleVariantValue}`}</p>
            )}
          </StyledColWithTitle>
          <StyledColWithPrice
            sm={2}
            className="my-2 my-sm-auto text-center text-sm-right"
          >
            <span className="cart-line-item-price">{price}</span>
          </StyledColWithPrice>
          {isRingSizingAvailable && (
            <Col className="offset-sm-3" sm={6}>
              <CartRingSizingOptions
                ringSizing={ringSizing}
                handleRingSizingOptionsChange={
                  this.handleRingSizingOptionsChange
                }
                data-cy="ring-size-picker"
              />
            </Col>
          )}
          <Col className="offset-sm-3" sm={6}>
            {this.checkOptions() && (
              <ProductVariantPicker
                options={options}
                variantAvailable={!notAvailable}
                activeOptions={activeOptions}
                handleOptionsChange={this.handleOptionsChange}
                data-cy="product-variant-picker"
              />
            )}
          </Col>
        </StyledRow>
        <Row>
          {quantity ? (
            <>
              <Col sm={6} className="my-auto mx-auto">
                <label
                  type="button"
                  className="py-2 my-0 text-primary"
                  onClick={this.removeCheckoutLineItems}
                  data-cy="lineItem-remove-btn"
                >
                  <u>Remove</u>
                </label>
              </Col>
              <Col sm={6} className="my-auto mx-auto text-right">
                <span className="border border-secondary py-2">
                  <label
                    type="button"
                    className="p-3 my-0"
                    onClick={this.decreaseLineItems}
                    data-cy="lineItem-quantity-decrease"
                  >
                    -
                  </label>
                  {quantity}
                  <label
                    type="button"
                    className="p-3 my-0"
                    onClick={this.increaseLineItems}
                    data-cy="lineItem-quantity-increase"
                  >
                    +
                  </label>
                </span>
              </Col>
            </>
          ) : (
            <>
              <Col sm={6} className="my-auto mx-auto">
                <label
                  type="button"
                  className="py-2 my-0 text-primary"
                  onClick={this.removeAuction}
                  data-cy="lineItem-remove-btn"
                >
                  <u>Remove</u>
                </label>
              </Col>
              <Col sm={6} className="my-auto mx-auto text-right">
                {saveForLater ? (
                  <label
                    type="button"
                    className="p-2 my-0 text-primary"
                    onClick={this.addToCart}
                    data-cy="lineItem-add-to-cart-btn"
                  >
                    <u>Add to cart</u>
                  </label>
                ) : (
                  <label
                    type="button"
                    className="p-2 my-0 text-primary"
                    onClick={this.saveForLater}
                    data-cy="lineItem-save-for-later-btn"
                  >
                    <u>Save for later</u>
                  </label>
                )}
              </Col>
            </>
          )}
        </Row>
        <hr />
      </>
    );
  }
}

export default CartProduct;
