import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import withImmutablePropsToJS from "with-immutable-props-to-js";
import { withRouter } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import { WithLoader } from "storefront/features/shared/components/Loader";
import { BUY_IT_NOW_PAGE_LOADER } from "storefront/features/shared/utils";
import * as actions from "storefront/features/shared/actions";
import {
  getProducts,
  getPageParams,
} from "storefront/features/shared/selectors";
import ProductGrid from "storefront/features/productGrid/components/ProductGrid";
import { Pagination, Helmet } from "storefront/features/shared";
import { getSearchParams, getTags } from "storefront/features/shared/utils";
import { metaTags } from "../metaTags";
import { CollectionFilter } from "storefront/features/shared/components/CollectionFilter";

class BuyItNowWithTags extends PureComponent {
  state = {
    filter: "default",
  };

  componentDidMount() {
    const { location, fetchBuyItNow, history } = this.props;
    const tags = getTags(location.pathname);
    const page = getSearchParams(location.search, "page");
    const loader = BUY_IT_NOW_PAGE_LOADER;
    history.action === "PUSH" && fetchBuyItNow({ tags, page, loader });
  }

  componentDidUpdate(prevProps) {
    const { location, fetchBuyItNow } = this.props;
    const tags = getTags(location.pathname);
    const loader = BUY_IT_NOW_PAGE_LOADER;
    if (tags !== getTags(prevProps.location.pathname)) {
      const page = getSearchParams(location.search, "page");
      fetchBuyItNow({ tags, page, loader });
    }
  }

  pageItemHandleClick = (page) => {
    const { location, fetchBuyItNow } = this.props;
    const tags = getTags(location.pathname);
    const { filter } = this.state;
    const loader = BUY_IT_NOW_PAGE_LOADER;
    fetchBuyItNow({ tags, page, loader, filter });
  };

  handleProductsFilterChange = (filter) => {
    this.setState({ filter });
    const { location, fetchBuyItNow } = this.props;
    const tags = getTags(location.pathname);
    const page = getSearchParams(location.search, "page");
    const loader = BUY_IT_NOW_PAGE_LOADER;
    fetchBuyItNow({ page, tags, loader, filter });
  };

  render() {
    const { products, pageParams } = this.props;
    const { filter } = this.state;
    return (
      <>
        <Helmet metaTags={metaTags} />
        <WithLoader loader={BUY_IT_NOW_PAGE_LOADER}>
          <Container>
            <Row className="justify-content-end mt-4">
              <CollectionFilter
                filterItemHandleChange={this.handleProductsFilterChange}
                filter={filter}
              />
            </Row>
          </Container>
          <ProductGrid products={products} />
        </WithLoader>
        <Pagination
          pageItemHandleClick={this.pageItemHandleClick}
          pageParams={pageParams}
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  products: getProducts,
  pageParams: getPageParams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBuyItNow: (value) => {
    dispatch(actions.fetchBuyItNowRequest(value));
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(BuyItNowWithTags);
